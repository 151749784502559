import { ButtonArrowBackProps } from '@/interfaces/index';
import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export default function ButtonArrowBack({ fontSize = 'large', onClick, ...rest }: ButtonArrowBackProps) {
  return (
    <IconButton onClick={onClick} {...rest}>
      <ArrowBack fontSize={fontSize} />
    </IconButton>
  );
}
