import { TableFooterCustomProps } from '@/interfaces/table';
import { BORDER_RIGHT } from '@/shared/constant/common';
import { TableCell, TableRow } from '@mui/material';

export default function TableFooter({ tableFooter }: TableFooterCustomProps) {
  const renderData = (item) => {
    if (item.child) return item.child;
    return item.title && <b>{item.title}</b>;
  };

  return (
    <TableRow className="sticky-footer">
      {tableFooter &&
        tableFooter.map((item, index) => {
          return (
            <TableCell
              className={item.className}
              key={item.id}
              colSpan={item.colSpan}
              rowSpan={item.rowSpan}
              sx={{
                borderRight: BORDER_RIGHT,
                ...(index === 0 && { borderLeft: BORDER_RIGHT }),
              }}
            >
              {item.title && <b>{item.title}</b>}
            </TableCell>
          );
        })}
    </TableRow>
  );
}
