import { Box, TableCell, TableRow, Tooltip } from '@mui/material';
import { MenuButton } from './MenuButton';
import { formatCurrency, formatDateTime, handleError, objectPhoneNumber } from '@/shared/utils/common';
import { TableBodyProps } from '@/interfaces/table';
import { BORDER_RIGHT, img } from '@/shared/constant/common';

const ignoredKeys = [
  'id',
  'userId',
  'weight',
  'shell',
  'isDelete',
  'isEdit',
  'isReturn',
  'idTrip',
  'checkStatus',
  'dataSub',
  'subData',
  'isReStatus',
  'isApprove',
  'isReject',
  'isBlock',
  'dataList',
  'isCancel',
  'isSpend',
  'isTextDelete',
  'isTextBlock',
  'size',
  'isTextUnBlock',
  'isActive',
  'isTextEdit',
  'isRead',
  'isTextResetPassword',
];

const renderClassName = (key, item) => {
  switch (key) {
    case 'nameDriver':
    case 'address':
    case 'fullName':
    case 'departurePoint':
    case 'destination':
    case 'driver':
    case 'customer':
    case 'email':
    case 'name':
    case 'role':
      return 'tab-name-width';

    case 'address1':
    case 'address2':
      return 'min-width-270';
    case 'startDate':
    case 'endDate':
      return 'min-width-170';

    case 'driverStatus':
    case 'status':
      return 'min-width-130';

    case 'waittingMoney':
      return 'min-width-140';

    case 'date1':
    case 'type':
    case 'codeNotification':
      return 'min-width-210';

    case 'addressStart':
    case 'addressEnd':
    case 'date2':
    case 'date3':
    case 'date4':
      return 'min-width-270';

    case 'discountName':
    case 'descriptionOffer':
      return 'min-width-300';

    case 'content':
      return 'content-width';

    case 'customerName':
    case 'driverName':
      return 'max-width-300';

    case 'dateTime':
      return 'w-130';

    case 'code':
      return 'w-50';

    case 'totalMoney':
    case 'money':
    case 'provisional':
    case 'VNPAY':
    case 'totalRevenue':
    case 'totalCash':
    case 'totalVNPay':
      return 'total-money';

    case 'firstPrice':
    case 'middlePrice':
    case 'finalPrice':
    case 'surcharge':
    case 'totalTrip':
    case 'totalComplete':
    case 'totalCancel':
    case 'totalTripCustomerCancel':
    case 'totalTripDriverCancel':
    case 'totalTripEmployeeCancel':
      return 'price-money';

    case 'provinceName':
      return 'province-name';

    case 'totalTime':
      return 'total-align-col';

    case 'actions':
      return 'actions-css actions';

    case 'iconDelete':
      return 'tabelcell-icon';
    case 'stt':
      return 'stt';

    case 'contentNotification':
      return !item?.isRead ? 'bg-notification hover-pointer' : '';

    default:
      return '';
  }
};

const renderStyle = (key, value) => {
  switch (key) {
    default:
      return {};
  }
};
export default function TableBody({
  listData,
  className,
  read,
  handleImage,
  tableHead,
  callLink,
  ...rest
}: TableBodyProps) {
  const renderData = (key, value, item) => {
    switch (key) {
      case 'actions':
        return <MenuButton actions={value} item={item} {...rest} />;

      case 'aboutPayment':
        return (
          <Tooltip title={value}>
            <span className="contentExpense">{value}</span>
          </Tooltip>
        );

      case 'totalGas':
      case 'totalGasInHour':
      case 'totalDate':
        return (
          <Box display="flex" justifyContent="center">
            {value}
          </Box>
        );

      case 'productName':
      case 'nameCommodity':
        return (
          <>
            <div>{value}</div>
            {item.dataSub?.map((item) => {
              return (
                <>
                  <div>
                    <span className="title-name-commodity">{item?.key}: </span>
                    <span className="value-name-commodity">{item?.value}</span>
                  </div>
                </>
              );
            })}
          </>
        );

      case 'payDay':
      case 'creationTime':
      case 'creationTimeRenueve':
      case 'createDate':
      case 'create_at':
      case 'creationTimeDebt':
        return formatDateTime(value);

      case 'image':
        return <img src={value} onError={handleError} className="img-default" />;

      case 'orderStatus':
        return (
          <Box display="flex">
            {value.child}
            <MenuButton actions={value.array} item={item} {...rest} />
          </Box>
        );

      case 'priceFix':
        return <Box display="flex">{value.child}</Box>;

      case 'phoneNumberSell':
        const listNumber = objectPhoneNumber(value);
        return (
          <>
            {listNumber?.map((item, index) => {
              return (
                <div key={index + 1}>
                  <a href={`tel:${item?.phoneNumber}`}>{item?.phoneNumber}</a>
                </div>
              );
            })}
          </>
        );

      case 'products':
        const listNumberProduct = objectPhoneNumber(value);
        return (
          <>
            {listNumberProduct?.map((item, index) => {
              return <div key={index + 1}>{item?.phoneNumber}</div>;
            })}
          </>
        );

      case 'imagePaymentSpend':
        const isNumberImage = value?.length - 1;
        return (
          <div className="container-image" onClick={() => handleImage?.(value)}>
            <img
              src={value ? value[0] : img}
              className="img-default"
              onError={handleError}
              style={{ opacity: isNumberImage > 0 ? 0.6 : 1 }}
            />
            {isNumberImage > 0 && <div className="overlay-text">+ {isNumberImage}</div>}
          </div>
        );

      case 'totalPrice':
      case 'PriceImport':
      case 'PriceSold':
      case 'revenue':
        return formatCurrency(value);
      case 'params':
        return null;

      case 'locate':
        return (
          <Tooltip title={value}>
            {value ? (
              <a href={value} rel="noopener noreferrer" target="_blank" className="locate">
                {value}
              </a>
            ) : (
              <Box className="custom-empty-data" />
            )}
          </Tooltip>
        );

      case 'contentExpense':
        return (
          <Box>{value ? <div className="contentExpense">{value}</div> : <Box className="custom-empty-data" />}</Box>
        );

      case 'content':
        return (
          <Box>{value ? <div className="contentExpense">{value}</div> : <Box className="custom-empty-data" />}</Box>
        );

      case 'dateToLimit':
        return <Box title={value}>{value ? <p>{value}</p> : <p>Chưa xác định</p>}</Box>;

      default:
        return value;
    }
  };
  return (
    <>
      {listData?.map((item, index) => {
        const filteredKeys = Object.keys(item).filter((key) => !ignoredKeys.includes(key));
        const bgClass =
          item?.isReturn !== undefined && item?.isReturn !== null
            ? item?.isReturn
              ? 'bg-color-import'
              : 'bg-color-return'
            : '';
        return (
          <TableRow key={index} className={bgClass}>
            {filteredKeys.map((key, columnIndex) => (
              <TableCell
                key={columnIndex}
                sx={(renderStyle(key, item[key]), { borderRight: BORDER_RIGHT, fontSize: '12px' })}
                className={renderClassName(key, item)}
              >
                {renderData(key, item[key], item)}
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </>
  );
}
