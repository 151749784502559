import { MAIL_GOSAFE } from '@/shared/config/setting';
import { Card, Grid, List, ListItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function PrivacyPolicy() {
  const arrayMapList = [
    {
      title: '1. THÔNG TIN CÁ NHÂN ĐƯỢC CHÚNG TÔI THU THẬP',
      subTitle:
        'Chúng tôi thu thập thông tin cá nhân của Người dùng trong quá trình cung cấp Dịch vụ của Chúng tôi cho Người dùng. Thông tin cá nhân là thông tin đủ để xác định chính xác danh tính một cá nhân, bao gồm ít nhất nội dung trong những thông tin sau đây: Họ tên, ngày sinh, giới tính, nơi sống, số điện thoại, địa chỉ email. Dưới đây là các nguồn thông tin được Chúng tôi thu thập:',
      dataSub: [
        'Thông tin Người dùng cung cấp cho Chúng tôi: Chúng tôi thu thập bất kỳ thông tin nào Người dùng cung cấp liên quan đến Dịch vụ của Chúng tôi',
        'Thông tin tự động: Chúng tôi tự động thu thập một số loại thông tin nhất định khi Người dùng tương tác với Dịch vụ của Chúng tôi.',
        'Thông tin từ các nguồn khác: Chúng tôi có thể thu thập thông tin về Người dùng từ các nguồn hợp pháp và công khai có sẵn khác.',
      ],
    },
    {
      title: '2. CÁCH THỨC CHÚNG TÔI SỬ DỤNG THÔNG TIN CÁ NHÂN',
      subTitle:
        'Chúng tôi có thể sử dụng thông tin cá nhân của Người dùng để vận hành, cung cấp và cải thiện Dịch vụ của Chúng tôi. Mục đích sử dụng thông tin cá nhân của Chúng tôi bao gồm:',
      dataSub: [
        'Cung cấp Dịch vụ của Chúng tôi: Chúng tôi có thể sử dụng thông tin cá nhân của Người dùng để cung cấp Dịch vụ của Chúng tôi và xử lý các giao dịch liên quan đến Dịch vụ của Chúng tôi.',
        'Đo lường, hỗ trợ và cải thiện Dịch vụ của Chúng tôi: Chúng tôi có thể sử dụng thông tin cá nhân của Người dùng để đo lường việc sử dụng, phân tích hiệu suất, sửa lỗi, cung cấp hỗ trợ, cải thiện chất lượng và phát triển Dịch vụ của Chúng tôi.',
        'Đưa ra khuyến nghị và cá nhân hóa trải nghiệm: Chúng tôi có thể sử dụng thông tin cá nhân của Người dùng để đề xuất Dịch vụ của Chúng Tôi mà Người dùng có thể quan tâm, nhận diện sở thích của Người dùng và cá nhân hóa trải nghiệm của Người dùng với Dịch vụ của Chúng Tôi.',
        'Tuân thủ nghĩa vụ pháp lý: Trong một số trường hợp nhất định, Chúng Tôi có nghĩa vụ pháp lý để thu thập, sử dụng hoặc lưu trữ thông tin cá nhân của Người dùng.',
        'Liên lạc với Người dùng: Chúng Tôi có thể sử dụng thông tin cá nhân của Người dùng để liên lạc với Người dùng liên quan đến Dịch vụ của Chúng Tôi thông qua các kênh khác nhau (ví dụ: email, chat) và để trả lời yêu cầu của Người dùng.',
        'Tiếp thị: Chúng Tôi có thể sử dụng thông tin cá nhân của Người dùng để tiếp thị và quảng bá Dịch vụ của Chúng Tôi theo quy định của pháp luật. Chúng Tôi có thể hiển thị quảng cáo Dịch vụ của Chúng Tôi dựa trên sở thích của Người dùng.',
        'Phòng chống gian lận và lạm dụng, rủi ro tín dụng: Chúng Tôi có thể sử dụng thông tin cá nhân của Người dùng để ngăn chặn, phát hiện gian lận và lạm dụng nhằm bảo vệ an ninh của Người dùng, Chúng Tôi và những người khác. Chúng Tôi cũng có thể sử dụng các phương pháp chấm điểm để đánh giá và quản trị rủi ro tín dụng.',
        'Một số mục đích cụ thể cần sự đồng ý của Người dùng: Chúng Tôi có thể xin chấp thuận của Người dùng về việc sử dụng thông tin cá nhân cho một mục đích cụ thể nào đó khi Chúng Tôi liên hệ với Người dùng.',
      ],
    },
    {
      title: '3. THU THẬP VÀ SỬ DỤNG COOKIES',
      subTitle:
        'Chúng tôi sử dụng cookie, pixel và các công nghệ tương tự khác (gọi chung là “cookie”) để nhận diện thiết bị của Người dùng, tìm hiểu thêm về sở thích của Người dùng, cung cấp cho Người dùng các tính năng và dịch vụ thiết yếu và cho các mục đích bổ sung khác, bao gồm:',
      dataSub: [
        'Nhận diện Người dùng khi Người dùng đăng nhập sử dụng dịch vụ của Chúng Tôi. Điều này cho phép Chúng Tôi cung cấp cho Người dùng các đề xuất, hiển thị nội dung được cá nhân hóa và cung cấp các tính năng và dịch vụ tùy chỉnh khác.',
        'Lưu tâm đến các tùy chọn mà Người dùng đã chấp thuận. Điều này cho phép Chúng Tôi tôn trọng những điều Người dùng thích và không thích, chẳng hạn như ngôn ngữ và cấu hình mà Người dùng lựa chọn.',
        'Tiến hành nghiên cứu và phân tích để cải thiện Dịch vụ của Chúng Tôi.',
        'Ngăn chặn hành vi gian lận.',
        'Cải thiện an ninh.',
        'Cung cấp nội dung, bao gồm quảng cáo, có liên quan đến sở thích của Người dùng trên ứng dụng của Chúng Tôi và trang web của bên thứ ba.',
        'Đo lường và phân tích chất lượng của các Dịch vụ của Chúng Tôi.',
      ],
      sub3: 'Cookie cho phép Người dùng tận dụng một số tính năng cần thiết của Chúng tôi. Ví dụ, nếu Người dùng chặn hoặc từ chối cookie của Chúng tôi, Người dùng sẽ không thể sử dụng một số dịch vụ nhất định yêu cầu Người dùng đăng nhập hoặc Người dùng có thể phải tự tay điều chỉnh một số tùy chọn hoặc cài đặt ngôn ngữ mỗi khi Người dùng truy cập lại các ứng dụng của Chúng tôi.',
      sub4: 'Các bên thứ ba được chấp thuận cũng có thể đặt cookie khi Người dùng tương tác với các Dịch vụ của Chúng tôi. Các bên thứ ba này thường bao gồm các công cụ tìm kiếm, nhà cung cấp dịch vụ đo lường và phân tích, mạng xã hội và các công ty quảng cáo. Các bên thứ ba sử dụng cookie trong quá trình cung cấp nội dung, bao gồm quảng cáo liên quan đến sở thích của Người dùng, để đo lường hiệu quả của quảng cáo và thực hiện một số dịch vụ thay mặt cho Chúng tôi. ',
    },
    {
      title: '4. CÁCH THỨC CHÚNG TÔI CHIA SẺ THÔNG TIN CÁ NHÂN',
      subTitle:
        'Thông tin về Người dùng là một phần quan trọng trong hoạt động của Chúng tôi, và Chúng tôi không cung cấp thông tin cá nhân của Người dùng cho bất kỳ bên thứ ba nào trừ các trường hợp được nêu tại mục (i).',
      dataSub: [
        'Giao dịch liên quan đến bên thứ ba: Chúng tôi cung cấp cho Người dùng các dịch vụ, phần mềm và nội dung do bên thứ ba cung cấp để sử dụng trên hoặc thông qua Dịch vụ Của Chúng tôi. Người dùng có thể biết khi nào bên thứ ba tham gia vào các giao dịch của Người dùng và Chúng tôi sẽ chia sẻ thông tin liên quan đến các giao dịch đối với bên thứ ba đó.',
        'Bên thứ ba cung cấp dịch vụ: Chúng tôi sử dụng và/hoặc hợp tác với các công ty và cá nhân khác để thực hiện một số công việc và chương trình như chương trình ưu đãi, bán hàng chéo,… dành cho Người dùng. Ví dụ bao gồm: gửi thông tin liên lạc, xử lý thanh toán, đánh giá rủi ro tín dụng và tuân thủ, thực hiện chương trình hỗ trợ lãi suất, phân tích dữ liệu, cung cấp hỗ trợ tiếp thị và bán hàng (bao gồm quản lý quảng cáo và sự kiện), quản lý quan hệ Người dùng và đào tạo. Các bên thứ ba cung cấp dịch vụ này có quyền truy cập vào thông tin cá nhân cần thiết để thực hiện các chức năng của họ, nhưng không được sử dụng cho các mục đích khác. Ngoài ra, họ phải tuân thủ Chính sách quyền riêng tư này và pháp luật về bảo vệ quyền riêng tư liên quan.',
        'Tái cấu trúc, Chuyển nhượng doanh nghiệp: Trong quá trình phát triển kinh doanh, Chúng tôi có thể bán hoặc mua các doanh nghiệp hoặc tái cấu trúc doanh nghiệp hoặc dịch vụ khác phù hợp với quy định của pháp luật. Trong các giao dịch như vậy, thông tin cá nhân, cơ sở dữ liệu và quyền sử dụng thông tin nói chung là một trong những tài sản kinh doanh được chuyển nhượng nhưng bên nhận chuyển nhượng vẫn phải tuân theo các quy định của Chính sách quyền riêng tư này (hoặc khi được Người dùng chấp thuận). Ngoài ra, trong trường hợp GOSAFE hoặc phần lớn tài sản của GOSAFE được một công ty khác mua, thông tin của Người dùng sẽ trở thành một trong những tài sản được chuyển nhượng.',
        'Bảo vệ Chúng tôi và những người khác: Chúng tôi được phép tiết lộ tài khoản và thông tin cá nhân khác khi Chúng tôi tin rằng việc đó là phù hợp để tuân thủ pháp luật, để thực thi hoặc áp dụng các điều khoản và thỏa thuận khác của Chúng tôi hoặc để bảo vệ quyền, tài sản hoặc an ninh của Chúng tôi, Người dùng của Chúng Tôi, hoặc bất kỳ người nào khác. Các công việc nêu trên có thể bao gồm việc trao đổi thông tin với các công ty và tổ chức khác để ngăn chặn và phát hiện gian lận và giảm rủi ro tài sản và tín dụng.',
        'Tùy theo sự lựa chọn của Người dùng: Ngoài các quy định trên, Người dùng sẽ nhận được thông báo khi thông tin cá nhân về Người dùng có thể được chia sẻ với bên thứ ba và Người dùng sẽ có quyền không chấp thuận việc chia sẻ thông tin.',
      ],
    },
    {
      title: '5. LƯU TRỮ THÔNG TIN CÁ NHÂN',
      subTitle:
        'Thông tin cá nhân của Người dùng được chúng tôi lưu trữ theo đúng quy định của pháp luật liên quan và áp dụng các biện pháp kỹ thuật phù hợp để đảm bảo thông tin được lưu trữ an toàn và bảo mật.',
    },
    {
      title: '6. CÁCH THỨC CHÚNG TÔI BẢO VỆ THÔNG TIN CÁ NHÂN',
      subTitle:
        'Tại GOSAFE, bảo mật là ưu tiên cao nhất của Chúng tôi. Hệ thống của Chúng tôi được thiết kế có tính đến khả năng bảo đảm an toàn và riêng tư cho thông tin của Người dùng và tài xế',
      dataSub: [
        'Chúng tôi có các biện pháp thích hợp về kỹ thuật và an ninh để ngăn chặn việc truy cập, sử dụng trái phép thông tin cá nhân. Chúng tôi cũng thường xuyên phối hợp với các chuyên gia bảo mật nhằm cập nhật những thông tin mới nhất về an ninh mạng để đảm bảo sự an toàn cho thông tin cá nhân. Khi thu thập dữ liệu, Chúng tôi thực hiện lưu giữ và bảo mật thông tin cá nhân tại hệ thống máy chủ và các thông tin cá nhân này được bảo đảm an toàn bằng các hệ thống tường lửa, các biện pháp kiểm soát truy cập, mã hóa dữ liệu.',
        'Các thông tin thẻ thanh toán của Người dùng do các tổ chức tài chính phát hành được Chúng tôi bảo vệ theo tiêu chuẩn quốc tế với nguyên tắc không ghi nhận các dữ liệu quan trọng của thẻ thanh toán (số thẻ, họ tên, số CVV) trên hệ thống của Chúng tôi. Giao dịch thanh toán của Người dùng được thực hiện trên hệ thống của ngân hàng liên quan.',
      ],
    },
    {
      title: '7. QUẢNG CÁO TRÊN INTERNET VÀ BÊN THỨ BA',
      subTitle:
        'Các Dịch vụ của Chúng tôi có thể bao gồm quảng cáo của bên thứ ba và đường link tới các trang web và ứng dụng khác. Các đối tác quảng cáo bên thứ ba có thể thu thập thông tin về Người dùng khi Người dùng tương tác với nội dung, quảng cáo hoặc dịch vụ của họ.',
    },
    {
      title: '8. TRUY CẬP VÀ LỰA CHỌN',
      subTitle:
        'Người dùng có thể xem, cập nhật và xóa một số thông tin nhất định về tài khoản và các tương tác của Người dùng với Dịch vụ của Chúng tôi. Nếu không thể tự truy cập hoặc cập nhật thông tin của mình, Người dùng luôn có thể liên hệ với Chúng tôi để được hỗ trợ. Người dùng có nhiều lựa chọn về việc thu thập và sử dụng thông tin cá nhân của Người dùng. Nhiều Dịch vụ của Chúng tôi bao gồm chức năng cho phép Người dùng tùy chọn về cách thông tin của Người dùng đang được sử dụng. Người dùng có thể chọn không cung cấp một số thông tin nhất định, nhưng sau đó Người dùng có thể không tận dụng được một số Dịch vụ của Chúng tôi.',
      dataSub: [
        'Thông tin tài khoản: Nếu Người dùng muốn bổ sung, cập nhật hoặc xóa thông tin liên quan đến tài khoản của mình, vui lòng truy cập vào tài khoản của Người dùng tại ứng dụng để thực hiện. Khi Người dùng cập nhật hoặc xóa bất kỳ thông',
        'Thiết bị: Tính năng Trợ giúp trên hầu hết các thiết bị.',
      ],
    },
    {
      title: '9. THỜI HẠN LƯU TRỮ THÔNG TIN CÁ NHÂN',
      subTitle:
        'Chúng tôi lưu trữ thông tin cá nhân của Người dùng để đảm bảo cho Người dùng khả năng sử dụng liên tục các Dịch vụ của Chúng tôi, và lưu trữ trong thời hạn cần thiết để thực hiện được các mục tiêu quy định tại Chính sách quyền riêng tư này, hoặc theo quy định của pháp luật (bao gồm cả cho mục đích thuế và kế toán), hoặc để thực hiện các công việc khác như được thông báo trước cho Người dùng. Thời gian Chúng tôi lưu giữ thông tin cá nhân cụ thể khác nhau tùy thuộc vào mục đích sử dụng và Chúng tôi sẽ xóa thông tin cá nhân của Người dùng theo quy định của pháp luật hiện hành.',
    },
    {
      title: '10. THÔNG TIN LIÊN LẠC, THÔNG BÁO VÀ SỬA ĐỔI',
      subTitle: `Nếu Người dùng có bất kỳ câu hỏi nào về quyền riêng tư tại GOSAFE hoặc muốn liên hệ với đơn vị kiểm soát thông tin của Chúng tôi, vui lòng liên hệ với Chúng tôi và Chúng tôi sẽ cố gắng trả lời câu hỏi của Người dùng. Người dùng cũng có thể liên hệ với Chúng tôi theo địa chỉ dưới đây:
Địa chỉ: 71 Ngô Thì Hương, Phường Nại Hiên Đông, Quận Sơn Trà, Thành phố Đà Nẵng
Email: Gosafe@gmail.com
Điện thoại: 0374180180
Hoạt động kinh doanh của Chúng tôi liên tục thay đổi và Chính sách quyền riêng tư này cũng có thể được sửa đổi, Người dùng nên truy cập và kiểm tra trang web của Chúng tôi thường xuyên để cập nhật những thay đổi gần nhất. Trừ trường hợp có quy định khác, Chính sách quyền riêng tư hiện tại của Chúng tôi áp dụng cho tất cả thông tin cá nhân Chúng tôi có về Người dùng và tài khoản của Người dùng. Việc sửa đổi Chính sách này trong mọi trường hợp sẽ không làm giảm mức độ bảo vệ thông tin cá nhân được thu thập trong quá khứ mà không thông báo cho Người dùng liên quan và cho Người dùng được quyền lựa chọn.
`,
    },
    {
      title: '11. VÍ DỤ VỀ CÁC THÔNG TIN ĐƯỢC CHÚNG TÔI THU THẬP',
      subTitle:
        'Thông tin Người dùng cung cấp cho Chúng tôi \n. Người dùng chủ yếu cung cấp thông tin cho Chúng tôi khi Người dùng: ',
      dataSub: [
        'Tìm kiếm, đăng ký hoặc sử dụng Dịch vụ của Chúng tôi;',
        'Tạo hoặc quản lý tài khoản của Người dùng;',
        'Cấu hình cài đặt của Người dùng cho, cung cấp quyền truy cập dữ liệu cho, hoặc tương tác với Dịch vụ của Chúng tôi;',
        'Mua hoặc sử dụng nội dung hoặc dịch vụ từ các nhà cung cấp bên thứ ba thông qua Dịch vụ của chúng tôi;',
        'Cung cấp nội dung hoặc dịch vụ của Người dùng trên hoặc thông qua Dịch vụ của Chúng tôi;',
        'Liên lạc với Chúng tôi qua tất cả các phương tiện chính thống như tổng đài chăm sóc Người dùng, gọi điện, email;',
        'Hoàn thành bảng câu hỏi hoặc các phiếu cung cấp thông tin khác;',
        'Đăng tải nội dung trên Dịch vụ của Chúng tôi.',
      ],
      sub3: 'Tùy thuộc vào việc Người dùng sử dụng Dịch vụ của Chúng tôi, Người dùng có thể cung cấp cho Chúng tôi các thông tin ví dụ như:',
      dataSub2: [
        'Tên, địa chỉ email, địa chỉ thực, số điện thoại và thông tin liên lạc tương tự khác;',
        'Thông tin thanh toán, bao gồm thông tin thẻ tín dụng và tài khoản ngân hàng;',
        'Thông tin về vị trí của Người dùng;',
        'Thông tin về tổ chức của Người dùng và đầu mối liên hệ của Người dùng, chẳng hạn như đồng nghiệp hoặc những người trong tổ chức của Người dùng;',
        'Tên người dùng và thông tin xác thực và bảo mật khác;',
        'Nội dung phản hồi, lời chứng thực, câu hỏi, trao đổi với Chúng tôi;',
      ],
      sub4: 'Chúng tôi chủ yếu thu thập thông tin tự động khi Người dùng:      ',
      dataSub3: [
        'Truy cập, tương tác hoặc sử dụng Dịch vụ của Chúng tôi (kể cả khi Người dùng sử dụng thiết bị khác để tương tác với Dịch vụ của Chúng tôi);',
        'Tải nội dung từ Chúng tôi;',
        'Mở email hoặc nhấp vào liên kết trong email từ Chúng tôi; và',
        'Tương tác hoặc liên lạc với Chúng tôi.',
      ],
      sub5: 'Ví dụ về các thông tin được Chúng tôi thu thập tự động:',
      dataSub4: [
        'Thông tin mạng và kết nối, thông tin về nhà cung cấp dịch vụ Internet của Người dùng;',
        'Thông tin về thiết bị, hệ điều hành hoặc cài đặt múi giờ;',
        'Vị trí của thiết bị;',
        'Thông tin xác thực và bảo mật;',
        'Các số liệu Dịch vụ của Chúng tôi, chẳng hạn như việc sử dụng Dịch vụ, lỗi kỹ thuật, báo cáo, tùy chọn cài đặt của Người dùng, thông tin sao lưu backup, API calls, và nhật ký khác;',
        'Địa chỉ email và số điện thoại được sử dụng để liên hệ với Chúng tôi.',
      ],
      sub6: `Thông tin từ các nguồn khác
      Ví dụ về các thông tin Chúng tôi có được từ các nguồn khác:
      `,
      dataSub5: [
        'Thông tin tiếp thị, tạo doanh số và tuyển dụng, bao gồm tên, địa chỉ email, địa chỉ thực, số điện thoại và thông tin liên hệ tương tự khác;',
        'Thông tin về đăng ký, mua, hỗ trợ hoặc thông tin khác về tương tác của Người dùng với các Dịch vụ của Chúng tôi hoặc với các Dịch vụ của bên thứ ba liên quan đến Dịch vụ của Chúng tôi;',
        'Kết quả tìm kiếm và liên kết, bao gồm các danh sách Dịch vụ được trả tiền (như Liên kết được Tài trợ); và',
        'Thông tin lịch sử tín dụng.',
      ],
    },
    {
      title: '12. GIẢI QUYẾT KHIẾU NẠI',
      subTitle: 'Quy trình giải quyết khiếu nại của GOSAFE',
      dataSub: [
        'Bước 1: Bộ phận Chăm Sóc Người dùng của GOSAFE sẽ tiếp nhận các khiếu nại của Người dùng, chủ động giải quyết nhanh chóng và trả lời ngay kết quả giải quyết các khiếu nại trên nếu có cơ sở các chính sách mà Chúng tôi đã công bố.',
        'Bước 2: Trong trường hợp phức tạp hoặc không được quy định tại các chính sách mà Chúng tôi đã công bố thì Bộ phận Chăm Sóc Người dùng sẽ cam kết thời hạn phản hồi cho Người dùng không quá 7 (bảy) ngày.',
        'Bước 3: Chuyển kết quả giải quyết khiếu nại cho các bộ phận có liên quan để thực hiện và gọi điện xác nhận với Người dùng về kết quả khiếu nại đã được giải quyết.',
      ],
    },
  ];

  return (
    <>
      <Card className="width-privacy">
        <h1 className="title-privacy">
          <strong> CHÍNH SÁCH BẢO MẬT</strong>
        </h1>
        {arrayMapList?.map((item) => {
          return (
            <>
              <div>
                <h2>
                  <strong> {item?.title}</strong>
                </h2>
              </div>
              <div>{item?.subTitle}</div>
              {item?.dataSub?.map((item1, index1) => {
                return (
                  <>
                    <ul key={index1}>
                      <li>{item1}</li>
                    </ul>
                  </>
                );
              })}
              <div style={{ marginBottom: '8px' }}>{item?.sub3}</div>
              {item?.dataSub2?.map((item2, index2) => {
                return (
                  <>
                    <ul key={index2}>
                      <li>{item2}</li>
                    </ul>
                  </>
                );
              })}
              <div>{item?.sub4}</div>
              {item?.dataSub3?.map((item3, index3) => {
                return (
                  <>
                    <ul key={index3}>
                      <li>{item3}</li>
                    </ul>
                  </>
                );
              })}

              <div>{item?.sub5}</div>

              {item?.dataSub4?.map((item4, index4) => {
                return (
                  <>
                    <ul key={index4}>
                      <li>{item4}</li>
                    </ul>
                  </>
                );
              })}
              <div>{item?.sub6}</div>
              {item?.dataSub5?.map((item5, index5) => {
                return (
                  <>
                    <ul key={index5}>
                      <li>{item5}</li>
                    </ul>
                  </>
                );
              })}
            </>
          );
        })}
        <div className="footer-privacy">
          <span>Người dùng có thể gửi khiếu nại tại địa chỉ:</span> <br />
          <strong>Gosafe – Dịch vụ lái xe hộ uy tín trên toàn quốc</strong> <br />
          <span>
            <strong>Địa chỉ</strong>: 71 Ngô Thì Hương, Phường Nại Hiên Đông, Quận Sơn Trà, Thành phố Đà Nẵng
          </span>
          <br />
          <span>
            <strong>Email</strong>:
            <a href={`${MAIL_GOSAFE}`}>
              <span> Gosafe@gmail.com</span>
            </a>
          </span>
          <br />
          <span>
            <strong>Điện thoại</strong>:0374180180
          </span>
          <br />
          <span>
            <strong>Giờ làm việc</strong>:Từ 8 Sáng- 17 Chiều ( Từ T2 – T7, Chủ nhật nghỉ )
          </span>
        </div>
      </Card>
    </>
  );
}
