import { Card, FormControl, Grid, useMediaQuery } from '@mui/material';
import { InputForm, InputFormDate, RadioInputForm, SelectSearch } from '@/common/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { genderSelect, optionsRoleAccount, optionsStatusAccount } from '@/shared/options';
import { useNavigate, useParams } from 'react-router-dom';
import { ACTIONS, CONST_ROUTER } from '@/shared/constant/common';
import { BackPage } from '@/components/BackPage';
import { schemaAddAccount } from '@/common/Schema';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { DeleteOutline, Edit } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { HandleButtonAddEdit } from '@/components/HandleButtonAddEdit';
import { convertDateDob } from '@/shared/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountData,
  clearAccountDetail,
  getAccountDetail,
  postAccount,
  putAccountDetail,
} from '@/redux/slices/account';
import LoadingPage from '@/components/LoadingPage';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { getProfileDetail } from '@/redux/slices/auth';
import AccountAvatar from '@/components/Avatar/AccountAvatar';
import AvatarMenu from '@/components/Avatar/AvatarMenu';

export default function AddAndEditAccount() {
  const [avatar, setAvatar] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { itemId } = useParams();
  const [file, setFile] = useState<File | any>();
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(accountData);
  const [isDelete, setIsDelete] = useState<boolean>(false);

  useEffect(() => {
    if (itemId) {
      reset();
      dispatch(getAccountDetail(itemId));
    }
    return () => {
      dispatch(clearAccountDetail());
    };
  }, []);

  const handleMenuClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handleDeleteImage() {
    setAvatar(null);
    setFile(null);
    setIsDelete(true);
  }

  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const lang = window.lang;
  const {
    control,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaAddAccount(lang)),
    defaultValues: {
      surnameAndMiddleName: '',
      lastName: '',
      phoneNumber: '',
      dob: null,
      status: 2,
      email: '',
      address: '',
      gender: 1,
      role: 1,
      userName: '',
    },
  });

  const dataApi = dataSelector?.accounDetail?.data;
  const isLoading = dataSelector.loadingAccountDetail;
  const FEMALE = 2;
  const ACTIVE = 2;
  const ADMIN = 4;
  const CompareData = (input, dataApi) => {
    if (typeof dataApi === 'number') {
      return watch(input) === dataApi || watch(input) === dataApi.toString() ? true : false;
    }
    if (input === 'dob') {
      return watch(input) === dataApi || convertDateDob(watch(input)) === dataApi ? true : false;
    }
    return watch(input) === dataApi ? true : false;
  };
  const isNewImage = (isDelete || !file) && !isDelete;
  const isNewData =
    CompareData('userName', dataApi?.userName) &&
    CompareData('surnameAndMiddleName', dataApi?.surnameAndMiddleName) &&
    CompareData('lastName', dataApi?.lastName) &&
    CompareData('dob', dataApi?.dateOfBirth) &&
    CompareData('phoneNumber', dataApi?.phoneNumber) &&
    CompareData('email'?.toLowerCase(), dataApi?.email?.toLowerCase()) &&
    CompareData('address', dataApi?.address) &&
    CompareData('gender', dataApi?.gender === 0 ? FEMALE : dataApi?.gender) &&
    CompareData('role', dataApi?.roles === 0 ? ADMIN : dataApi?.roles) &&
    CompareData('status', dataApi?.status === 0 ? ACTIVE : dataApi?.status) &&
    isNewImage;

  useEffect(() => {
    if (itemId && dataApi) {
      setAvatar(dataApi?.avatarPath);
      setValue('surnameAndMiddleName', dataApi?.surnameAndMiddleName);
      setValue('lastName', dataApi?.lastName);
      setValue('phoneNumber', dataApi?.phoneNumber);
      setValue('dob', dataApi?.dateOfBirth);
      setValue('status', dataApi?.status === 0 ? ACTIVE : dataApi?.status);
      setValue('email', dataApi?.email ? dataApi.email : '');
      setValue('address', dataApi?.address);
      setValue('gender', dataApi?.gender === 0 ? FEMALE : dataApi?.gender);
      setValue('role', dataApi?.roles === 0 ? ADMIN : dataApi?.roles);
      setValue('userName', dataApi?.userName);
    }
  }, [dataApi, itemId]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('UserName', data?.userName);
    formData.append('surnameAndMiddleName', data?.surnameAndMiddleName);
    formData.append('lastName', data?.lastName);
    formData.append('PhoneNumber', data?.phoneNumber);
    formData.append('Address', data?.address);
    data.dob && formData.append('DateOfBirth', convertDateDob(data.dob));
    formData.append('Email', data?.email?.toLowerCase());
    formData.append('Gender', data?.gender === FEMALE ? 0 : data.gender);
    formData.append('Status', data?.status === ACTIVE ? 0 : data.status);
    formData.append('Roles', data.role === ADMIN ? 0 : data.role);
    file && formData.append('AvatarImage', file);

    const payload = {
      params: formData,
      callbackSuccess: () => {
        showSuccessToast('Thêm tài khoản thành công');
        reset();
        navigate(CONST_ROUTER.ACCOUNT);
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(postAccount(payload));
  };

  const onCancel = () => {
    setAvatar('');
    reset();
  };

  const handleBackPage = () => {
    reset();
    navigate(CONST_ROUTER.ACCOUNT);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    setAvatar(dataApi?.avatarPath ? dataApi?.avatarPath : '');
    setValue('surnameAndMiddleName', dataApi?.surnameAndMiddleName);
    setValue('lastName', dataApi?.lastName);
    setValue('phoneNumber', dataApi?.phoneNumber || '');
    setValue('dob', dataApi?.dateOfBirth);
    setValue('status', dataApi?.status === 0 ? ACTIVE : dataApi?.status);
    setValue('email', dataApi?.email ? dataApi.email : '');
    setValue('address', dataApi?.address || '');
    setValue('gender', dataApi?.gender === 0 ? FEMALE : dataApi?.gender);
    setValue('role', dataApi?.roles === 0 ? ADMIN : dataApi?.roles);
    setValue('userName', dataApi?.userName);
    setIsDelete(false);
    setIsEdit(false);
    clearErrors();
  };
  const handleSave = (data) => {
    const formData = new FormData();
    itemId && formData.append('Id', itemId);
    formData.append('UserName', data.userName);
    formData.append('UserId', dataApi?.userId);
    formData.append('surnameAndMiddleName', data.surnameAndMiddleName);
    formData.append('lastName', data.lastName);
    data.phoneNumber && formData.append('PhoneNumber', data.phoneNumber);
    data.address && formData.append('Address', data.address);
    data.dob ? formData.append('DateOfBirth', convertDateDob(data.dob)) : null;
    formData.append('Email', data.email?.toLowerCase());
    formData.append('Gender', data.gender === FEMALE ? 0 : data.gender);
    formData.append('Roles', data.role === ADMIN ? 0 : data.role);
    formData.append('Status', data.status === ACTIVE ? 0 : data.status);
    file ? formData.append('AvatarImage', file) : !isDelete && formData.append('AvatarPath', dataApi?.avatarPath);
    const payload = {
      id: itemId,
      params: formData,
      callbackSuccess: () => {
        showSuccessToast(lang.account.editAccountSuccess);
        itemId && dispatch(getAccountDetail(itemId));
        dispatch(getProfileDetail());
        setIsEdit(false);
        reset();
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(putAccountDetail(payload));
  };

  const actions = [ACTIONS.EDIT, ACTIONS.DELETE];
  const actionInfo: any = [
    {
      action: ACTIONS.EDIT,
      label: avatar ? lang.button.actions.editImage : lang.button.actions.addImage,
      disabled: false,
      icon: <Edit sx={{ mr: 2 }} />,
      isUpload: true,
    },
    {
      action: ACTIONS.DELETE,
      label: lang.button.actions.deleteImage,
      disabled: !avatar,
      icon: <DeleteOutline sx={{ mr: 2 }} />,
      onClick: handleDeleteImage,
      sx: { color: 'error.main' },
    },
  ];

  return (
    <>
      <BackPage title={itemId ? lang.account.editAccount : lang.account.addAccount} handleBackPage={handleBackPage} />
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Card sx={{ p: 2 }}>
            <AccountAvatar
              avatar={avatar}
              isEdit={isEdit || !itemId}
              handleMenuClick={handleMenuClick}
              setAvatar={setAvatar}
              setFile={setFile}
              setIsDelete={setIsDelete}
            />
            <AvatarMenu
              actions={actions}
              actionInfo={actionInfo}
              handleCloseMenu={handleCloseMenu}
              anchorEl={anchorEl}
            />
            <Grid container spacing={!isMobile ? 8 : 1}>
              <Grid item xs={12} sm={6} md={6} className="driver-form-data">
                <Grid className="driver-form-spacing">
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Tên tài khoản:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="userName"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Họ và tên đệm:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="surnameAndMiddleName"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {isMobile && (
                    <Grid container mt={2}>
                      <Grid item xs={5} sm={5} md={3} mt={1}>
                        <strong className="fz-14">
                          Tên:<span className="required">*</span>
                        </strong>
                      </Grid>
                      <Grid item xs={7} sm={7} md={9}>
                        <FormControl fullWidth>
                          <InputForm
                            name="lastName"
                            control={control}
                            size="small"
                            fullWidth
                            errors={errors}
                            disabled={!!itemId && !isEdit}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Số điện thoại:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="phoneNumber"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">Ngày sinh:</strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputFormDate name="dob" control={control} errors={errors} disabled={!!itemId && !isEdit} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Vai trò:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <RadioInputForm
                          options={optionsRoleAccount || []}
                          name="role"
                          control={control}
                          size="small"
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="driver-form-data">
                <Grid className="driver-form-spacing">
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Email:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="email"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {!isMobile && (
                    <Grid container mt={2}>
                      <Grid item xs={5} sm={5} md={3} mt={1}>
                        <strong className="fz-14">
                          Tên:<span className="required">*</span>
                        </strong>
                      </Grid>
                      <Grid item xs={7} sm={7} md={9}>
                        <FormControl fullWidth>
                          <InputForm
                            name="lastName"
                            control={control}
                            size="small"
                            fullWidth
                            errors={errors}
                            disabled={!!itemId && !isEdit}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">Địa chỉ:</strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="address"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Giới tính:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <RadioInputForm
                          options={genderSelect}
                          name="gender"
                          control={control}
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Trạng thái:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <RadioInputForm
                          options={optionsStatusAccount || []}
                          name="status"
                          control={control}
                          size="small"
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <HandleButtonAddEdit
            itemId={itemId}
            isEdit={isEdit}
            handleEdit={handleEdit}
            handleCancel={handleCancel}
            handleSave={handleSubmit(handleSave)}
            onCancel={onCancel}
            onSubmit={handleSubmit(onSubmit)}
            isNewData={!!isNewData}
          />
          <CustomBackdrop
            open={
              dataSelector?.loadingPostAccount || dataSelector?.loadingAccountDetail || dataSelector?.loadingPutAccount
            }
          />
        </>
      )}
    </>
  );
}
