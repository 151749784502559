import {
  ACTIVE_DRIVER,
  AVAILABLE_DRIVER,
  BLOCK_DRIVER,
  DELETE_DRIVER,
  DETAIL_DRIVER,
  DOWFILE_DRIVER,
  DRIVER,
  DRIVER_GOOGLE_MAP_UPDATE,
  DROPDOWN_DRIVER,
  HISTORY_DRIVER,
  RESET_DRIVER_PASSWORD,
  UN_BLOCK_DRIVER,
} from '@/shared/constant/constantUrl';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api';

const initialState = {
  loadingPostDriver: false,
  listDetail: [],
  loadingDetail: false,
  loadingputDriver: false,
  loadingPutBlockDriver: false,
  loadingDeleteDriver: false,
  listDriver: [],
  loadingListDriver: false,
  loadingUnblock: false,
  activeDriveList: [],
  loadingActiveDriveList: false,
  availableDriveList: [],
  loadingAvailableDriveList: false,
  listDropdown: [],
  loadingDowFileDriver: false,
  listHistory: [],
  loadingListHistory: false,
  listDriverGooogleMap: [],
  loadingListDriverGoogleMap: false,
  loadingResetPass: false,
};

export const postDriver = createAsyncThunk(
  'postDriver/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(DRIVER, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const putDriver = createAsyncThunk('putDriver/post', async ({ params, callbackSuccess, callbackError }: any) => {
  try {
    const response = await Api.put(DRIVER, params);
    callbackSuccess();
    return response?.data;
  } catch (error: any) {
    callbackError(error?.data?.message || error?.data?.Message);
    return error;
  }
});

export const putDriverBlock = createAsyncThunk(
  'putDriver/block',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(BLOCK_DRIVER(params.driverId), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const putUnBlockDriver = createAsyncThunk(
  'putUnBlockDriver/block',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(UN_BLOCK_DRIVER(params.driverId), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const deleteDriver = createAsyncThunk(
  'deleteDriver/delete',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.delete(DELETE_DRIVER(params.id));
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const detailDriver = createAsyncThunk('detailDriver/get', async (params: any) => {
  try {
    const response = await Api.get(DETAIL_DRIVER, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getDriver = createAsyncThunk('getDriver', async (params: any) => {
  try {
    const response = await Api.get(DRIVER, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getDriverGoogleMap = createAsyncThunk('getDriverGooogleMap', async () => {
  try {
    const response = await Api.get(DRIVER_GOOGLE_MAP_UPDATE);
    return response?.data;
  } catch (error: any) {
    return error;
  }
});
export const getActiveDriver = createAsyncThunk('getActiveDriver', async (params: any) => {
  try {
    const response = await Api.get(ACTIVE_DRIVER, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getAvailableDriver = createAsyncThunk('getAvailableDriver', async (params: any) => {
  try {
    const response = await Api.get(AVAILABLE_DRIVER, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getHistoryDriver = createAsyncThunk('getHistoryDriver', async (params: any) => {
  try {
    const response = await Api.get(HISTORY_DRIVER(params?.driverId), { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getExportFileDriver = createAsyncThunk(
  'getExportFileTripDriver',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.get(DOWFILE_DRIVER, { params, responseType: 'arraybuffer' });
      callbackSuccess?.(response);
      return response.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const getDropdownDriver = createAsyncThunk('getDropdownDriver', async () => {
  try {
    const response = await Api.get(DROPDOWN_DRIVER);
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const postResetDriverPass = createAsyncThunk(
  'postResetDriverPass/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(RESET_DRIVER_PASSWORD(params.id), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const driver = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    clearDetailDriver: (state) => {
      state.listDetail = [];
    },
    updateStatusDriver: (state) => {
      state.loadingListDriver = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postDriver.pending, (state) => {
        state.loadingPostDriver = true;
      })
      .addCase(postDriver.fulfilled, (state, action) => {
        state.loadingPostDriver = false;
      })
      .addCase(postDriver.rejected, (state) => {})

      .addCase(putDriver.pending, (state) => {
        state.loadingputDriver = true;
      })
      .addCase(putDriver.fulfilled, (state, action) => {
        state.loadingputDriver = false;
      })
      .addCase(putDriver.rejected, (state) => {})

      .addCase(detailDriver.pending, (state) => {
        state.loadingDetail = true;
        state.listDetail = [];
      })
      .addCase(detailDriver.fulfilled, (state, action) => {
        state.loadingDetail = false;
        state.listDetail = action.payload;
      })
      .addCase(detailDriver.rejected, (state) => {})

      .addCase(getDriver.pending, (state) => {
        state.loadingListDriver = true;
      })
      .addCase(getDriver.fulfilled, (state, action) => {
        state.loadingListDriver = false;
        state.listDriver = action.payload;
      })
      .addCase(getDriver.rejected, (state) => {
        state.loadingListDriver = true;
        state.listDriver = [];
      })

      .addCase(getActiveDriver.pending, (state) => {
        state.loadingActiveDriveList = true;
      })
      .addCase(getActiveDriver.fulfilled, (state, action) => {
        state.loadingActiveDriveList = false;
        state.activeDriveList = action.payload;
      })
      .addCase(getActiveDriver.rejected, (state) => {
        state.loadingActiveDriveList = false;
        state.activeDriveList = [];
      })

      .addCase(getHistoryDriver.pending, (state) => {
        state.loadingListHistory = true;
      })
      .addCase(getHistoryDriver.fulfilled, (state, action) => {
        state.loadingListHistory = false;
        state.listHistory = action.payload;
      })
      .addCase(getHistoryDriver.rejected, (state) => {
        state.loadingListHistory = false;
        state.listHistory = [];
      })

      .addCase(getAvailableDriver.pending, (state) => {
        state.loadingAvailableDriveList = true;
      })
      .addCase(getAvailableDriver.fulfilled, (state, action) => {
        state.availableDriveList = action.payload;
        state.loadingAvailableDriveList = false;
      })
      .addCase(getAvailableDriver.rejected, (state) => {
        state.loadingAvailableDriveList = false;
        state.availableDriveList = [];
      })

      .addCase(getDropdownDriver.pending, (state) => {})
      .addCase(getDropdownDriver.fulfilled, (state, action) => {
        state.listDropdown = action.payload;
      })
      .addCase(getDropdownDriver.rejected, (state) => {
        state.listDropdown = [];
      })

      .addCase(putDriverBlock.pending, (state) => {
        state.loadingPutBlockDriver = true;
      })
      .addCase(putDriverBlock.fulfilled, (state, action) => {
        state.loadingPutBlockDriver = false;
      })
      .addCase(putDriverBlock.rejected, (state) => {})

      .addCase(deleteDriver.pending, (state) => {
        state.loadingDeleteDriver = true;
      })
      .addCase(deleteDriver.fulfilled, (state) => {
        state.loadingDeleteDriver = false;
      })
      .addCase(deleteDriver.rejected, (state) => {})

      .addCase(putUnBlockDriver.pending, (state) => {
        state.loadingUnblock = true;
      })
      .addCase(putUnBlockDriver.fulfilled, (state) => {
        state.loadingUnblock = false;
      })
      .addCase(putUnBlockDriver.rejected, (state) => {})

      .addCase(getExportFileDriver.pending, (state) => {
        state.loadingDowFileDriver = true;
      })
      .addCase(getExportFileDriver.fulfilled, (state, action) => {
        state.loadingDowFileDriver = false;
      })
      .addCase(getExportFileDriver.rejected, (state) => {
        state.loadingDowFileDriver = false;
      })

      .addCase(getDriverGoogleMap.pending, (state) => {
        state.loadingListDriverGoogleMap = true;
      })
      .addCase(getDriverGoogleMap.fulfilled, (state, action) => {
        state.loadingListDriverGoogleMap = false;
        state.listDriverGooogleMap = action.payload;
      })
      .addCase(getDriverGoogleMap.rejected, (state) => {
        state.loadingListDriverGoogleMap = false;
        state.listDriverGooogleMap = [];
      })
      .addCase(postResetDriverPass.pending, (state) => {
        state.loadingResetPass = true;
      })
      .addCase(postResetDriverPass.fulfilled, (state, action) => {
        state.loadingResetPass = false;
      })
      .addCase(postResetDriverPass.rejected, (state) => {
        state.loadingResetPass = false;
      });
  },
});

export const { actions } = driver;
export const driverData = (state) => state.driver;
export default driver.reducer;
export const { clearDetailDriver, updateStatusDriver } = driver.actions;
