import React from 'react';
import { Grid, Typography, FormHelperText, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import CustomImage from '@/components/CustomImage';

function ImageUploadForm({
  setIsDelete,
  itemId,
  isEdit,
  frontImage,
  backImage,
  frontImageCheck,
  backImageCheck,
  setFrontImage,
  setBackImage,
  setFrontFile,
  setBackFile,
  handleRemoveImage,
  errors,
  isMobile,
  control,
  handleImage,
  formName,
  setIsOpen1,
  setIsOpen2,
}) {
  return (
    <Grid container mt={2}>
      <Grid item xs={12} md={3}>
        <Grid item xs={12}>
          <strong className="fz-14">{formName}</strong>
          <span className="required">*</span>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9} mt={isMobile ? 1 : 0}>
        <Grid container item xs={12} justifyContent="center" spacing={2}>
          {[frontImage, backImage].map((image, index) => {
            return (
              <>
                <Grid item xs={6} sm={6} md={6} key={index + 1}>
                  {image && image.length > 0 ? (
                    <CustomImage
                      itemId={itemId}
                      className="img-driver-id"
                      src={image}
                      isEdit={isEdit}
                      style={{ margin: '5px' }}
                      alt={`default_image_${index + 1}`}
                      onDelete={() => {
                        handleRemoveImage(
                          index === 0 ? setFrontImage : setBackImage,
                          index === 0 ? setFrontFile : setBackFile,
                          image,
                          index === 0 ? frontImageCheck : backImageCheck,
                        );
                        setIsDelete(true);
                      }}
                      onClickImage={index === 0 ? () => setIsOpen1(true) : () => setIsOpen2(true)}
                    />
                  ) : (
                    <label className="add-img" htmlFor={index === 0 ? frontImageCheck : backImageCheck}>
                      <Grid className="aspect-ratio-container">
                        <Typography className="add-image-driver fz-14">
                          {image.length === 0
                            ? `+ Thêm ảnh mặt ${index === 0 ? 'trước' : 'sau'}`
                            : `Thay ảnh mặt ${index === 0 ? 'trước' : 'sau'}`}
                        </Typography>
                      </Grid>
                    </label>
                  )}
                  <Grid item xs={12}>
                    <FormHelperText error sx={{ padding: '0 5px', textAlign: 'center' }}>
                      {errors &&
                      errors[index === 0 ? frontImageCheck : backImageCheck]?.message &&
                      typeof errors[index === 0 ? frontImageCheck : backImageCheck]?.message === 'string' &&
                      image.length === 0
                        ? errors[index === 0 ? frontImageCheck : backImageCheck]?.message
                        : ''}
                    </FormHelperText>
                    <Controller
                      name={index === 0 ? frontImageCheck : backImageCheck}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          className="image-hidden"
                          {...field}
                          inputProps={{ accept: 'image/*' }}
                          id={index === 0 ? frontImageCheck : backImageCheck}
                          type="file"
                          onChange={(event) =>
                            handleImage(
                              field,
                              event,
                              index === 0 ? setFrontImage : setBackImage,
                              index === 0 ? setFrontFile : setBackFile,
                              setIsDelete,
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ImageUploadForm;
