import { Card, Divider, FormControl, Grid } from '@mui/material';
import TitlePage from '@/components/TitlePage';
import { InputForm, SelectSearch } from '@/common/Form';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { optionsStatusVehicle } from '@/shared/options';
import { Close, Done } from '@mui/icons-material';
import TableComponent from '@/components/TableComponent';
import { VEHICLE_CLASS } from '@/shared/constant/TableHead';
import { useEffect, useState } from 'react';
import { parseDataVehicleClass } from '@/shared/constant/dataTable';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import ConfirmPopup from '@/components/ConfirmPopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteVehicleClass,
  getVehicleClass,
  updateStatusVehicle,
  vehicleClassData,
} from '@/redux/slices/vehicleClass';
import { createNewPath, useQueryParameters } from '@/shared/utils/common';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';

export default function VehicleClass() {
  const notActive = '2';
  const dispatch = useDispatch<any>();
  const dataSelecor = useSelector(vehicleClassData);
  const navigate = useNavigate();
  const lang = window.lang;
  const schema = yup.object().shape({
    text: yup.string().nullable(),
    status: yup.string().nullable(),
  });
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const queriesParams: any = useQueryParameters();
  const [queryFilter, setQueryfilter] = useState(queriesParams);
  const { pathname } = location;
  const router = useNavigate();

  const defaultValue = {
    page: 1,
    take: PAGE_SIZE,
    Text: queriesParams?.text || '',
    Status: queriesParams?.status === notActive ? 0 : queriesParams?.status || '',
  };

  useEffect(() => {
    dispatch(getVehicleClass(defaultValue));
  }, []);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      text: queriesParams?.text || '',
      status: queriesParams?.status === notActive ? 0 : queriesParams?.status || '',
    },
  });

  const onSubmit = (data) => {
    setCurrentPage(1);
    const dataSubmit = {
      text: data.text,
      status: data.status === notActive ? 0 : +data.status || '',
      page: 1,
      take: PAGE_SIZE,
    };
    setQueryfilter(dataSubmit);
    dispatch(getVehicleClass(dataSubmit));
    const newPath = createNewPath(pathname, dataSubmit);
    router(newPath);
  };

  const handleResetFilter = () => {
    reset();
    setValue('text', '');
    setValue('status', '');
    setCurrentPage(1);
    setQueryfilter({
      page: 1,
      take: PAGE_SIZE,
      Text: '',
      Status: '',
    });
    dispatch(getVehicleClass({ page: 1, take: PAGE_SIZE, Text: '', Status: '' }));
    router(pathname);
  };

  const enumStatus = (value) => {
    const typeObject = {
      1: <Done color="success" />,
      0: <Close color="error" />,
    };
    return typeObject[+value];
  };

  const handleDelete = (id) => {
    const confirmDelete = () => {
      const payload = {
        params: {
          id: id,
        },
        callbackSuccess: () => {
          setCurrentPage(1);
          showSuccessToast(lang.vehicleClass.successDelete);
          dispatch(getVehicleClass(defaultValue));
          dispatch(updateStatusVehicle());
        },
        callbackError: (isError) => {
          showErrorToast(isError);
        },
      };
      dispatch(deleteVehicleClass(payload));
    };
    openPopup(lang.vehicleClass.confirmDelete, confirmDelete);
  };

  const dataTable = parseDataVehicleClass(
    dataSelecor?.listVehicle?.data,
    currentPage,
    enumStatus,
    dataSelecor?.listVehicle?.totalCount,
  );

  const handleAddVehicleClass = () => {
    navigate(CONST_ROUTER.VEHICLE_CLASS_ADD);
  };

  const handleDownFile = () => {};

  const handleEdit = (id) => {
    navigate(CONST_ROUTER.DETAIL_VEHICLE_ITEM(id));
  };

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      page: page,
      take: PAGE_SIZE,
    };

    dispatch(getVehicleClass(data));
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mt={2}>
        <Grid item>
          <TitlePage title={lang.vehicleClass.title} />
        </Grid>
      </Grid>
      <Divider className="bd-title-tab" />

      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4} lg={4}>
            <InputForm name="text" control={control} placeholder={lang.vehicleClass.text} size="small" fullWidth />
          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={2.1}>
            <FormControl fullWidth>
              <SelectSearch
                label={lang.managementCustomer.status}
                control={control}
                name="status"
                options={optionsStatusVehicle || []}
                errors={errors}
              />
            </FormControl>
          </Grid>
        </Grid>
        <ButtonSearch
          submit={handleSubmit(onSubmit)}
          resetSearch={handleResetFilter}
          add={handleAddVehicleClass}
          handleDownFile={null}
        />
      </Card>

      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={VEHICLE_CLASS}
          listData={dataTable}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecord={dataSelecor?.listVehicle?.data?.length}
          totalItems={dataSelecor?.listVehicle?.totalCount}
          onEdit={handleEdit}
          onDelete={(id) => handleDelete(id)}
          isLoading={dataSelecor.loadingListVehicle}
          pagingCallback={(page) => handlePaging(page)}
          className="scroll-table"
        />
      </Grid>
      <ConfirmationPopup />
    </>
  );
}
