import { SelectSearch } from '@/common/Form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, FormControl, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPaymentSystemDetail,
  getPaymentSystemList,
  paymentSystemData,
  putPaymentStatus,
} from '@/redux/slices/paymentSystem';
import { SkeletonSelect } from '@/components/LoadingSelect';

export function ModalChangeStatus({ setOpen, open, optionStatus, paymentItem, paymentId }) {
  const lang = window.lang;
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(paymentSystemData);
  const dataApi = dataSelector?.paymentSystemDetail.data;
  const isLoading = dataSelector.loadingPaymentSystemDetail;
  useEffect(() => {
    dispatch(getPaymentSystemDetail(paymentId));
  }, [paymentId]);
  const schema = yup.object().shape({
    paymentStatus: yup.number().required(lang.tripManagement.driverRequire),
  });
  const {
    control,
    setValue,
    reset,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      paymentStatus: dataApi?.isActive,
    },
  });
  useEffect(() => {
    if (dataApi) {
      setValue('paymentStatus', dataApi?.isActive === true ? 1 : 2);
    }
  }, [dataApi]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeStatus = (data) => {
    const params = {
      id: dataApi?.id,
      isActive: data.paymentStatus === 1 ? true : false,
    };
    const payload = {
      params: params,
      callbackSuccess: () => {
        showSuccessToast(lang.paymentSetting.successEdit);
        reset();
        dispatch(getPaymentSystemList(''));
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(putPaymentStatus(payload));
    handleClose();
  };

  return (
    <>
      <>
        <Dialog
          onClose={handleClose}
          open={open}
          fullWidth
          PaperProps={{
            style: {
              width: '100%',
              maxHeight: '100%',
            },
          }}
        >
          <>
            <DialogContent className="modal-dialog-trip">
              <Typography className="modal-trip" variant="h6" mb={1} pb={1}>
                <b>{lang.title.titleModal.changeStatus}</b>
              </Typography>
              <Grid container className="dialog-content-trip" gap={1}>
                <Grid xs={5} sm={4} md={4} mb={1}>
                  <Typography className="fz-14">
                    <b>{lang.paymentSetting.paymentName}:</b>
                  </Typography>
                </Grid>
                <Grid xs={6} sm={6} md={6}>
                  {isLoading ? (
                    <SkeletonSelect height={30} />
                  ) : (
                    <Typography className="fz-14" color="#1F56C1" height={30}>
                      <b>{dataApi?.name}</b>
                    </Typography>
                  )}
                </Grid>
                <Grid xs={5} sm={4} md={4} mb={1}>
                  <Typography className="fz-14">
                    <b>{lang.paymentSetting.status}:</b>
                  </Typography>
                </Grid>

                <Grid xs={6} sm={6} md={6}>
                  <FormControl fullWidth>
                    {isLoading ? (
                      <SkeletonSelect height={37} />
                    ) : (
                      <SelectSearch
                        placeholder={lang.paymentSetting.status}
                        name="paymentStatus"
                        options={optionStatus || []}
                        control={control}
                        size="small"
                        errors={errors}
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="dialog-button-action">
              <Grid container direction="row" justifyContent="center" alignItems="center" gap={2}>
                <ButtonCustom
                  fullWidth={false}
                  color="error"
                  onClick={handleClose}
                  title={lang.title.titlePage.close}
                />
                <ButtonCustom
                  fullWidth={false}
                  color="primary"
                  onClick={handleSubmit(handleChangeStatus)}
                  title={lang.title.titlePage.confirm}
                />
              </Grid>
            </DialogActions>
          </>
        </Dialog>
      </>
    </>
  );
}
