import LoadingPage from '@/components/LoadingPage';
import { FROM_DATE, MONTH_YEAR, QUARTER, YEAR } from '@/shared/constant/text';
import { formatCurrency } from '@/shared/utils/common';
import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ date, listChartRevenue, loadingChart, labelY, labelX, title1, title2, color1, color2 }: any) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    let startDate, endDate;
    let categories = [];

    switch (date?.type) {
      case FROM_DATE:
        startDate = new Date(date?.date1);
        endDate = new Date(date?.date2);
        break;
      case MONTH_YEAR:
        const date1 = new Date(date?.date1);
        const month = date1.getMonth() + 1;
        const year = date1.getFullYear();
        startDate = new Date(year, month - 1, 1);
        endDate = new Date(year, month, 0);
        break;
      case QUARTER:
        const year3 = new Date(date?.date1).getFullYear();
        const quarter = +date?.date2;

        switch (quarter) {
          case 1:
            startDate = new Date(year3, 0, 1); // Tháng 1
            endDate = new Date(year3, 2, 31); // Tháng 3
            break;
          case 2:
            startDate = new Date(year3, 3, 1); // Tháng 4
            endDate = new Date(year3, 5, 30); // Tháng 6
            break;
          case 3:
            startDate = new Date(year3, 6, 1); // Tháng 7
            endDate = new Date(year3, 8, 30); // Tháng 9
            break;
          case 4:
            startDate = new Date(year3, 9, 1); // Tháng 10
            endDate = new Date(year3, 11, 31); // Tháng 12
            break;
        }
        break;
      case YEAR:
        const date4 = new Date(date?.date1);
        const year4 = date4.getFullYear();
        startDate = new Date(year4, 0, 1); // Tháng 1
        endDate = new Date(year4, 11, 31); // Tháng 12
        break;
    }

    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dateString = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}` as never;
      categories.push(dateString);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setData(categories);
    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: categories,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
    }));

    const listData1 = listChartRevenue?.map((item) => item?.item1);
    const listData2 = listChartRevenue?.map((item) => item?.item2);

    setSeries([
      {
        ...series[0],
        data: listData1 || [],
      },
      {
        ...series[1],
        data: listData2 || [],
      },
    ]);
  }, [date, listChartRevenue]);

  const [series, setSeries] = useState([
    {
      name: title1,
      data: [],
      color: color1,
    },
    {
      name: title2,
      data: [],
      color: color2,
    },
  ]);

  const [options, setOptions] = useState<any>({
    chart: {
      height: 50,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 0, // Set borderRadius to 0 to make bars square
        columnWidth: '20px', // Width of the bars
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    xaxis: {
      categories: data,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
      title: {
        text: labelX,
        style: {
          color: '#304758',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      },
    },
    yaxis: {
      title: {
        text: labelY,
        style: {
          color: '#304758',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      },
      labels: {
        formatter: function (value) {
          return formatCurrency(value, false);
        },
      },
      axisBorder: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: 'Arial',
      left: '450px !important',
      labels: {
        colors: ['#304758'],
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },

    tooltip: {
      enabled: true,
    },
  });

  return (
    <>
      <Card className="apex-chart-container">
        {loadingChart ? (
          <LoadingPage />
        ) : (
          <div
            className={
              data?.length < 15
                ? 'apex-chart-wrapper-900'
                : data?.length > 15 && data?.length < 50
                ? 'apex-chart-wrapper-3000'
                : data?.length > 50 && data?.length < 120
                ? 'apex-chart-wrapper-6000'
                : 'apex-chart-wrapper-max'
            }
          >
            <ReactApexChart options={options} series={series} type="bar" height="500px" />
          </div>
        )}
      </Card>
    </>
  );
};

export default ApexChart;
