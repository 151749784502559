import { Card, Grid, useMediaQuery } from '@mui/material';
import { InputForm } from '@/common/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TableComponent from '@/components/TableComponent';
import { TRIPS_HISTORY } from '@/shared/constant/TableHead';
import { useEffect, useState } from 'react';
import { parseTripsHistoryData } from '@/shared/constant/dataTable';
import { useNavigate, useParams } from 'react-router-dom';
import { convertToYYYY, createNewPath, formatDate } from '@/shared/utils/common';
import { CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { schemaHistoryDriver } from '@/common/Schema';
import { useDispatch, useSelector } from 'react-redux';
import { driverData, getHistoryDriver } from '@/redux/slices/driver';
import { CustomDateField } from '@/components/CustomDateField';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';

export default function TripsHistory({ valueTab }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLarge = useMediaQuery('(min-width:1200px)');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { itemId } = useParams();
  const lang = window.lang;
  const dispatch = useDispatch<any>();
  const selectorData = useSelector(driverData);
  const [queryFilter, setQueryfilter] = useState<any>();
  const router = useNavigate();

  const navigateToTrip = (id) => {
    const params = {
      isRouter: 'driver',
      valueTab: valueTab,
      id: itemId,
    };
    const newPath = createNewPath(CONST_ROUTER.TRIP_DETAIL1_ITEM(id), params);
    router(newPath);
  };

  const defaultValue = {
    Page: 1,
    Take: PAGE_SIZE,
    driverId: itemId,
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaHistoryDriver(lang)),
    defaultValues: {
      text: '',
      addressStart: '',
      addressEnd: '',
      dateFilter: '0',
      year: null,
      monthYear: null,
      quarterYear: null,
      quarter: null,
      startDay: null,
      endDay: null,
    },
  });

  useEffect(() => {
    if (itemId) {
      dispatch(getHistoryDriver(defaultValue));
    }
  }, [itemId]);

  const onSubmit = (data) => {
    const dataSubmit = {
      driverId: itemId,
      text: data.text,
      AddressStart: data.addressStart,
      AddressEnd: data.addressEnd,
      DateType: data.dateFilter === '0' ? null : data.dateFilter,
      Quarter: data.quarter,
      Year: convertToYYYY(data.year) || convertToYYYY(data.quarterYear),
      StartDate: dateFilter === '2' ? formatDate(data.monthYear) : formatDate(data.startDay),
      EndDate: formatDate(data.endDay),
      Page: 1,
      Take: PAGE_SIZE,
    };
    setCurrentPage(1);
    setQueryfilter(dataSubmit);
    dispatch(getHistoryDriver(dataSubmit));
  };

  const handleResetFilter = () => {
    reset();
    setQueryfilter(null);
    dispatch(getHistoryDriver(defaultValue));
  };

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      Page: page,
      driverId: itemId,
      Take: PAGE_SIZE,
    };
    dispatch(getHistoryDriver(data));
  };
  const dataTable = parseTripsHistoryData(
    selectorData?.listHistory?.data,
    selectorData?.listHistory?.totalCount,
    currentPage,
    navigateToTrip,
  );
  const dateFilter = watch('dateFilter');

  useEffect(() => {
    if (dateFilter) {
      setValue('year', null);
      setValue('quarterYear', null);
      setValue('monthYear', null);
      setValue('quarter', null);
      setValue('startDay', null);
      setValue('endDay', null);
    }
  }, [dateFilter]);

  return (
    <>
      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container gap={2} flexWrap={isLarge ? 'nowrap' : 'wrap'}>
          <Grid container xs={12} md={12} lg={7} gap={1} flexWrap={isMobile ? 'wrap' : 'nowrap'}>
            <Grid container xs={12} sm={8} md={8} gap={1} flexWrap="nowrap">
              <Grid item xs={6} md={6}>
                <InputForm
                  name="text"
                  control={control}
                  placeholder="Nhập vào tên khách hàng, số điện thoại"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputForm
                  name="addressStart"
                  control={control}
                  placeholder={lang.tripsHistory.departurePoint}
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container xs={12} sm={4} md={4} gap={1} flexWrap="nowrap">
              <Grid item xs={6} sm={12} md={12}>
                <InputForm
                  name="addressEnd"
                  control={control}
                  placeholder={lang.tripsHistory.destination}
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <CustomDateField dateFilter={dateFilter} control={control} errors={errors} />
        </Grid>
        <ButtonSearch
          submit={handleSubmit(onSubmit)}
          resetSearch={handleResetFilter}
          handleDownFile={null}
          add={null}
        />
      </Card>

      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={TRIPS_HISTORY}
          listData={dataTable}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecord={selectorData?.listHistory?.data?.length}
          totalItems={selectorData?.listHistory?.totalCount}
          isLoading={selectorData?.loadingListHistory}
          pagingCallback={(page) => handlePaging(page)}
          className="scroll-table"
        />
      </Grid>
    </>
  );
}
