import { CircularProgress, FormControl, FormHelperText, InputAdornment, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '../../interfaces';

export const InputForm = ({
  control,
  inputValue,
  label,
  name,
  type = 'text',
  size = 'small',
  errors,
  arrayErrors,
  onChange,
  isLoading,
  isLabel = false,
  InputLabelProps,
  inputProps,
  readOnly,
  endAdornment,
  onKeyDown,
  className,
  multiline = false,
  isTextEnd = false,
  value,
  ...rest
}: FormInputProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const errorMaxValue = inputProps && inputProps.max !== undefined && inputProps.max < +field?.value;

          return (
            <>
              <TextField
                {...field}
                {...rest}
                error={!!arrayErrors || errors?.[name] || errorMaxValue}
                size={size}
                type={type}
                className={`${className} "input-field"`}
                multiline={multiline}
                label={label}
                inputProps={inputProps ? inputProps : { min: 0 }}
                placeholder={rest.placeholder}
                value={value || field.value}
                onKeyDown={onKeyDown}
                onChange={(event) => {
                  field.onChange(event);
                  if (typeof onChange === 'function') {
                    onChange(event);
                  }
                }}
                InputLabelProps={isLabel ? { shrink: true } : {}}
                InputProps={{
                  className: 'fz-14',
                  readOnly: readOnly,
                  startAdornment: inputValue && (
                    <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                      <span className="input-default">{inputValue}</span>
                    </InputAdornment>
                  ),
                  endAdornment: endAdornment
                    ? endAdornment
                    : isTextEnd && (
                        <InputAdornment position="end" sx={{ fontWeight: 'bold' }}>
                          <strong>%</strong>
                        </InputAdornment>
                      ),
                }}
              />
              {arrayErrors && <FormHelperText error>{arrayErrors?.message}</FormHelperText>}
              {errors?.[name] && <FormHelperText error>{errors[name]?.message}</FormHelperText>}
            </>
          );
        }}
      />
    </>
  );
};
