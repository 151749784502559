import { IconPassword } from '@/components/IconPassword';
import { FormInputProps } from '@/interfaces';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

export const InputPasswordForm = ({
  control,
  label,
  name,
  size = 'small',
  sizeLabel = 'small',
  errors,
  ...rest
}: FormInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth variant="outlined">
          <InputLabel
            htmlFor={`outlined-adornment-${name}`}
            size={sizeLabel}
            color={errors?.[name] ? 'error' : 'primary'}
            sx={{ color: errors?.[name] ? 'error.main' : 'primary' }}
          >
            {label}
            {rest?.required && <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>}
          </InputLabel>
          <OutlinedInput
            {...field}
            {...rest}
            size={size}
            error={errors?.[name]}
            id={`outlined-adornment-${name}`}
            type={!showPassword ? 'text' : 'password'}
            endAdornment={<IconPassword onClick={handleClickShowPassword} showPassword={showPassword} />}
            label={label}
          />
          {errors?.[name] && <FormHelperText error>{errors[name]?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
