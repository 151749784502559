import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useState } from 'react';

export const ConfirmPopup = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [onConfirm, setOnConfirm] = useState<(() => void) | null>(null);

  const openPopup = (message, onConfirm) => {
    setMessage(message);
    setOnConfirm(() => onConfirm);
    setOpen(true);
  };

  const closePopup = () => {
    setOpen(false);
    setMessage('');
    setOnConfirm(null);
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    closePopup();
  };

  const ConfirmationPopup = () => (
    <Dialog open={open} onClose={closePopup}>
      <DialogTitle className="popup-title">
        <b>Xác nhận</b>
      </DialogTitle>
      <Divider className="popup-divider" />
      <DialogContent className="popup-text">
        <DialogContentText sx={{ whiteSpace: 'pre-line', color: 'var(--color-input-default)' }}>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions className="group-button">
        <Button onClick={closePopup} color="error" variant="outlined">
          Đóng
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus variant="outlined">
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { openPopup, ConfirmationPopup };
};

export default ConfirmPopup;
