import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ModalChangePasswordProps } from '@/interfaces/index';
import { InputPasswordForm } from '@/common/Form/inputPassword';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import ConfirmPopup from '@/components/ConfirmPopup';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { SkeletonSelect } from '@/components/LoadingSelect';
import { passwordNonWhitespacePattern, passwordRegExpLogin } from '@/shared/utils/regex';
import { clearDetailDriver, detailDriver, driverData, postResetDriverPass } from '@/redux/slices/driver';

export default function ModalResetDriverPassword(props: ModalChangePasswordProps) {
  const { open, setOpen, id, userId } = props;
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const dataSelector = useSelector(driverData);
  const dataApi = dataSelector?.listDetail?.data;
  const { loadingDetail, loadingResetPass } = dataSelector;
  const lang = window.lang;
  const dispatch = useDispatch<any>();
  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required(lang.auth.passwordRequired)
      .min(6, lang.auth.minPass)
      .max(20, lang.auth.maxPass)
      .matches(passwordRegExpLogin, lang.auth.passwordInvalid)
      .matches(passwordNonWhitespacePattern, lang.auth.passwordInvalid),
    passwordConfirm: yup
      .string()
      .required('Xác nhận mật khẩu không được để trống')
      .max(20, 'Xác nhận mật khẩu không được vượt quá 20 ký tự')
      .oneOf([yup.ref('newPassword')], 'Xác nhận mật khẩu phải trùng với mật khẩu mới '),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      newPassword: '',
      passwordConfirm: '',
    },
  });
  useEffect(() => {
    if (id) {
      dispatch(detailDriver({ driverId: id }));
    }
    return () => {
      dispatch(clearDetailDriver());
    };
  }, []);

  const handleConfirm = (data) => {
    openPopup('Bạn có chắc chắn muốn cấp lại mật khẩu không?', () => onSubmit(data));
  };

  const onSubmit = (data) => {
    const params = {
      id: userId,
      newPassword: data.newPassword,
      confirmPassword: data.passwordConfirm,
    };
    const payload = {
      params: params,
      callbackSuccess: () => {
        reset();
        showSuccessToast('Thay đổi mật khẩu tài xế thành công!');
        setOpen(false);
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(postResetDriverPass(payload));
  };
  const info = [
    {
      title: 'Họ và tên',
      data: (
        <>
          <Typography>
            {dataApi?.surnameAndMiddleName} {dataApi?.lastName}
          </Typography>
          <Typography>{dataApi?.phoneNumber}</Typography>
        </>
      ),
    },
    { title: 'Email', data: dataApi?.email },
  ];
  const handleClose = () => {
    reset();
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogTitle className="modal-title">
          <b>Cấp lại mật khẩu</b>
        </DialogTitle>
        <Divider className="popup-divider" />
        <DialogContent>
          {info.map((user, index) => (
            <Grid container key={index}>
              <Grid item xs={4}>
                <b>{user?.title}:</b>
              </Grid>
              <Grid item xs={8}>
                {loadingDetail ? <SkeletonSelect height={20} /> : <>{user?.data}</>}
              </Grid>
            </Grid>
          ))}
          <Grid container spacing={2}>
            <Grid container xs={12} mt={2} ml={2} pt={3}>
              <Grid xs={4} alignSelf="center">
                <b>Mật khẩu mới:</b>
              </Grid>
              <Grid xs={8}>
                <InputPasswordForm control={control} name="newPassword" label="" errors={errors} />
              </Grid>
            </Grid>
            <Grid container xs={12} ml={2} py={2}>
              <Grid xs={4} alignSelf="center">
                <b>Xác nhận mật khẩu:</b>
              </Grid>
              <Grid xs={8}>
                <InputPasswordForm control={control} name="passwordConfirm" label="" errors={errors} />
              </Grid>
            </Grid>
          </Grid>
          <DialogActions className="no-padding">
            <ButtonCustom fullWidth={false} color="error" onClick={handleClose} title="Đóng" />
            <ButtonCustom fullWidth={false} onClick={handleSubmit(handleConfirm)} title="Lưu" />
          </DialogActions>
          <CustomBackdrop open={loadingResetPass} />
          <ConfirmationPopup />
        </DialogContent>
      </Dialog>
    </>
  );
}
