import { SelectSearch } from '@/common/Form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { showErrorToast } from '@/hooks/useCustomToast';
import { putAssignDriver, tripData } from '@/redux/slices/trip';
import { useDispatch, useSelector } from 'react-redux';
import { convertOptionsDriver, dataAddress, isBooleanArray } from '@/shared/utils/common';
import { driverData, getAvailableDriver } from '@/redux/slices/driver';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { UNKNOW } from '@/shared/constant/text';

export function ModalAssignDriver({
  setOpen,
  open,
  tripId,
  data,
  payloadSuccessAssign,
  payloadSuccessAssignDetail,
}: any) {
  const lang = window.lang;
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(driverData);
  const schema = yup.object().shape({
    driverId: yup.string().required(lang.tripManagement.driverRequire),
  });
  const StartingPoint = 0;
  const StoppingPoint = 1;
  const DestinationPoint = 2;
  const dataSelectorTrip = useSelector(tripData);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      driverId: '',
    },
  });

  useEffect(() => {
    dispatch(getAvailableDriver(''));
  }, []);

  const dataDriver = useSelector(driverData);
  const driverList = convertOptionsDriver(dataDriver?.availableDriveList);

  const handleClose = () => {
    setOpen(false);
  };
  const gridItems = [
    { label: 'Tên khách hàng', value: tripId.customerName },
    {
      label: 'Điểm đi',
      value: data ? dataAddress(data?.tripLocations, StartingPoint) : tripId.addressStart,
    },
    {
      label: 'Điểm dừng',
      value: data ? dataAddress(data?.tripLocations, StoppingPoint) : tripId.addressStopping,
      isAddressStop: true,
    },
    {
      label: 'Điểm đến',
      value: data
        ? isBooleanArray(dataAddress(data?.tripLocations, DestinationPoint))
          ? dataAddress(data?.tripLocations, DestinationPoint)
          : UNKNOW
        : tripId.addressEnd || UNKNOW,
    },
  ];

  const handleAssignDriver = (data) => {
    const params = {
      tripId: tripId?.id,
      driverId: data.driverId,
    };
    const payload = {
      params: params,
      callbackSuccess: () => {
        payloadSuccessAssignDetail?.();
        payloadSuccessAssign?.();
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(putAssignDriver(payload));
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        PaperProps={{
          style: {
            width: '100%',
            maxHeight: '100%',
          },
        }}
      >
        <DialogTitle className="popup-title">
          <b>Xác nhận gắn tài xế cho hành trình {tripId.code}</b>
        </DialogTitle>
        <Divider className="popup-divider" />
        <DialogContent className="modal-dialog-trip">
          <Grid container spacing={1}>
            {gridItems.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={5}>
                  <strong>{item.label}: </strong>
                </Grid>
                <Grid item xs={7}>
                  {item?.isAddressStop ? (
                    <>
                      {item?.value?.map((item1, index1) => {
                        return (
                          <>
                            <div>
                              Đ{index1 + 1}: {item1}
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div>{item?.value}</div>
                  )}
                </Grid>
              </React.Fragment>
            ))}

            <Grid item xs={5}>
              <strong>{lang.tripManagement.driver}:</strong>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <SelectSearch
                  placeholder={lang.tripManagement.driverRequire}
                  name="driverId"
                  options={driverList}
                  control={control}
                  size="small"
                  errors={errors}
                  disabled={dataDriver?.loadingAvailableDriveList}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid>
            <Typography pt={2}>{lang.tripManagement.modalDespritionLine1}</Typography>
            <Typography>{lang.tripManagement.modalDespritionLine2}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-button-action">
          <Grid container direction="row" justifyContent="center" alignItems="center" gap={2}>
            <ButtonCustom fullWidth={false} color="error" onClick={handleClose} title={lang.title.titlePage.close} />
            <ButtonCustom
              fullWidth={false}
              color="primary"
              onClick={handleSubmit(handleAssignDriver)}
              title={lang.title.titlePage.confirm}
            />
          </Grid>
        </DialogActions>
        <CustomBackdrop open={dataSelectorTrip.loadingAssignDriver} />
      </Dialog>
    </>
  );
}
