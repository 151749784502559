import { Controller } from 'react-hook-form';
import { FormInputProps } from '@/interfaces/index';
import { DateOfBirth, dayOfWeekFormatter, isChooseMaxDate, viewDayOfWeek } from '@/shared/constant/common';
import { DatePicker } from '@mui/x-date-pickers';
import { FormControl, FormHelperText } from '@mui/material';
import { addYears } from 'date-fns';
import { MAX_DATE_DRIVER_YEAR } from '@/shared/config/setting';

export const InputFormDate = ({
  control,
  label,
  name,
  size = 'small',
  errors,
  typeDate,
  maxDate = false,
  ...rest
}: FormInputProps) => {
  const currentYear = new Date().getFullYear();
  const maxYear = MAX_DATE_DRIVER_YEAR && currentYear - +MAX_DATE_DRIVER_YEAR;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl error={!!errors}>
            <DatePicker
              maxDate={maxDate ? maxYear && new Date(maxYear, 0, 1) : addYears(new Date(), isChooseMaxDate)} // maxDate : 2500 year
              label={label}
              {...field}
              {...rest}
              value={field.value ? new Date(field.value) : null}
              format={DateOfBirth}
              slotProps={{ textField: { size: size, error: errors?.[name] } }}
              dayOfWeekFormatter={dayOfWeekFormatter}
              views={['year', 'month', 'day']}
              className="fz-14"
            />

            {errors?.[name] && <FormHelperText>{errors[name]?.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
};
