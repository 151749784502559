import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, Typography, TextField, Autocomplete, CircularProgress, Stack, Box } from '@mui/material';
import { SelectFormSearch } from '@/interfaces/index';
import { handleError } from '@/shared/utils/common';

export const SelectSearch = ({
  control,
  options,
  label,
  name,
  size = 'small',
  isLoading,
  loadingOption,
  errors,
  arrayErrors,
  onChange,
  onChangeTextField,
  placeholder,
  required = false,
  filterSelectedOptions = true,
  isSearchApi = false,
  placement = 'bottom-start',
  ...rest
}: SelectFormSearch) => {
  const lang = window.lang;

  const SelectOptions = options?.map((item) => ({
    value: item.id || item.value || item.provinceId,
    label: item.name || item.label || item?.productName || item?.provinceName || item.fullName,
    image: item.image,
    tels: item.tels,
    address: item.address,
    phoneNumber: item.phoneNumber,
    driver: item.driver,
    sizeTypeName: item.sizeTypeName,
    attribute:
      item?.attributeValues &&
      Object.entries(
        item?.attributeValues?.reduce((result, item) => {
          result[item.name] = item.value;
          return result;
        }, {}),
      ).map(([key, value]) => ({ key, value })),
  }));

  const filterOptions = (options, state) => {
    const inputValue = state.inputValue.toLowerCase();
    return (
      options &&
      options.filter(
        (option) =>
          option.label.toLowerCase().includes(inputValue) ||
          (option.phoneNumber && option.phoneNumber.toLowerCase().includes(inputValue)),
      )
    );
  };

  const object = isSearchApi ? { filterOptions: () => SelectOptions } : { filterOptions };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl error={!!errors?.[name]} fullWidth>
            <Autocomplete
              size={size}
              {...field}
              disabled={isLoading}
              {...rest}
              options={SelectOptions || []}
              clearIcon={null}
              noOptionsText={
                loadingOption ? (
                  <Stack alignContent="center" flexWrap="wrap">
                    <CircularProgress size={20} />
                  </Stack>
                ) : (
                  'Không tìm thấy kết quả nào phù hợp'
                )
              }
              loading={isLoading}
              value={SelectOptions?.find((option) => option.value === field.value) || null}
              onChange={(event, value) => {
                field.onChange(value?.value || null);
                if (typeof onChange === 'function') {
                  onChange(event, value);
                }
              }}
              {...object}
              renderInput={({ InputProps, ...params }) => (
                <TextField
                  component="div"
                  {...params}
                  label={label}
                  required={required}
                  placeholder={placeholder}
                  onChange={onChangeTextField}
                  error={!!errors?.[name] || arrayErrors?.message}
                  helperText={errors?.[name]?.message || arrayErrors?.message}
                  InputProps={{
                    ...InputProps,
                    endAdornment: (
                      <>
                        {InputProps.endAdornment}
                        {isLoading && <CircularProgress size={20} />}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box sx={{ width: '100%' }}>
                    <Typography className="icon-label">
                      {option.image && <img src={option.image} onError={handleError} className="logo-bank" />}
                      <Box>
                        <Typography>{option.label}</Typography>
                        {option.phoneNumber && <Typography>{option.phoneNumber}</Typography>}
                      </Box>
                    </Typography>
                    {option.tels && <span className="address-customer-option">{option.tels}</span>}
                    {option.address && <span className="address-customer-option pb-10 ">{option.address}</span>}
                    {option.sizeTypeName && (
                      <div>
                        <span className="title-name-commodity">{lang.labelInput.size}: </span>
                        <span className="value-name-commodity"> {option.sizeTypeName}</span>
                      </div>
                    )}
                    {option?.attribute &&
                      option.attribute?.map((item, index) => (
                        <div key={index}>
                          <span className="title-name-commodity">{item?.key}: </span>
                          <span className="value-name-commodity"> {item?.value}</span>
                        </div>
                      ))}
                  </Box>
                </li>
              )}
            />
          </FormControl>
        )}
      />
    </>
  );
};
