import { Card, FormControl, Grid, useMediaQuery } from '@mui/material';
import { InputForm, InputFormDate, RadioInputForm, SelectSearch } from '@/common/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { driverStatus, genderSelect } from '@/shared/options';
import { useNavigate, useParams } from 'react-router-dom';
import { ACTIONS, CONST_ROUTER } from '@/shared/constant/common';
import { BackPage } from '@/components/BackPage';
import { schemaAddDriver } from '@/common/Schema';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CustomLightbox from '@/components/CustomLightbox';
import ImageUploadForm from './ImageUploadForm';
import { useDispatch, useSelector } from 'react-redux';
import { clearDetailDriver, detailDriver, driverData, postDriver, putDriver } from '@/redux/slices/driver';
import { convertDateDob } from '@/shared/utils/common';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { HandleButtonAddEdit } from '@/components/HandleButtonAddEdit';
import LoadingPage from '@/components/LoadingPage';
import { getProvinceList, priceSystemData } from '@/redux/slices/priceSystem';
import AccountAvatar from '@/components/Avatar/AccountAvatar';
import AvatarMenu from '@/components/Avatar/AvatarMenu';

export default function AddDriver() {
  const [avatar, setAvatar] = useState<string | null>(null);
  const [fileAvatar, setFileAvatar] = useState<any>(null);
  const FAMALE = 0;
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(driverData);
  const [image1, setImage1] = useState<string>('');
  const [image2, setImage2] = useState<string>('');
  const [image3, setImage3] = useState<string>('');
  const [image4, setImage4] = useState<string>('');
  const [file1, setFile1] = useState<string>('');
  const [file2, setFile2] = useState<string>('');
  const [file3, setFile3] = useState<string>('');
  const [file4, setFile4] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { itemId } = useParams();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isOpen1, setIsOpen1] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);
  const [isOpen3, setIsOpen3] = useState<boolean>(false);
  const [isOpen4, setIsOpen4] = useState<boolean>(false);
  const dataAreaSelector = useSelector(priceSystemData);
  const dataApiProvince = dataAreaSelector?.listProvince;
  useEffect(() => {
    if (itemId) {
      dispatch(detailDriver({ driverId: itemId }));
    }
    dispatch(getProvinceList({}));
    return () => {
      dispatch(clearDetailDriver());
    };
  }, []);

  const dataApi = dataSelector?.listDetail?.data;
  const isLoading = dataSelector?.loadingDetail;

  useEffect(() => {
    if (itemId && dataApi) {
      setAvatar(dataApi?.avatarPath ? dataApi?.avatarPath : '');
      setImage1(dataApi?.frontIDCardPath ? dataApi?.frontIDCardPath : '');
      setImage2(dataApi?.backIDCardPath ? dataApi?.backIDCardPath : '');
      setImage3(dataApi?.frontDriverLicensePath ? dataApi?.frontDriverLicensePath : '');
      setImage4(dataApi?.backDriverLicensePath ? dataApi?.backDriverLicensePath : '');
      setValue('surnameAndMiddleName', dataApi?.surnameAndMiddleName);
      setValue('lastName', dataApi?.lastName);
      setValue('phoneNumber', dataApi?.phoneNumber);
      setValue('dob', dataApi?.dateOfBirth);
      setValue('status', dataApi?.status);
      setValue('email', dataApi?.email ? dataApi.email : '');
      setValue('address', dataApi?.provinceId);
      setValue('gender', dataApi?.gender === FAMALE ? 2 : dataApi?.gender);
    }
  }, [dataApi, itemId]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteImage = () => {
    setAvatar(null);
    setFileAvatar(null);
    setIsDelete(true);
  };

  const navigate = useNavigate();
  const lang = window.lang;
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaAddDriver(lang, image1, image2, image3, image4, avatar)),
    mode: 'all',
    defaultValues: {
      surnameAndMiddleName: '',
      lastName: '',
      phoneNumber: '',
      dob: undefined,
      status: 1,
      email: '',
      address: '',
      gender: 1,
    },
  });

  const handleImage = (field, e, setImg, setFile, setIsDelete) => {
    const file = e.target.files[0];
    setFile(file);
    const files = e.target.files;
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    for (let i = 0; i < files.length; i++) {
      try {
        if (!allowedTypes.includes(file.type)) {
          throw new Error(lang.imageOrder.validateImg);
        } else {
          const imgUrl = URL.createObjectURL(file);
          setImg(imgUrl);
          setIsDelete(false);
        }
      } catch (error) {
        showErrorToast(lang.errorMessage.fileType);
      }
    }
    e.target.value = '';
    field.onChange(e);
  };

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append('surnameAndMiddleName', data?.surnameAndMiddleName);
    formData.append('lastName', data?.lastName);
    formData.append('PhoneNumber', data?.phoneNumber);
    formData.append('ProvinceId', data?.address);
    formData.append('DateOfBirth', convertDateDob(data.dob));
    formData.append('Email', data?.email);
    formData.append('Gender', data?.gender === 2 ? FAMALE : data?.gender);
    formData.append('Status', data?.status);
    formData.append('FrontIDCard', file1);
    formData.append('BackIDCard', file2);
    formData.append('FrontDriverLicense', file3);
    formData.append('Avatar', fileAvatar);
    formData.append('BackDriverLicense', file4);
    const payload = {
      params: formData,
      callbackSuccess: () => {
        showSuccessToast(lang.driverManagement.successAdd);
        reset();
        navigate(CONST_ROUTER.DRIVER_MANAGEMENT);
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(postDriver(payload));
  };

  const onCancel = () => {
    reset();
    setAvatar('');
    setImage1('');
    setImage2('');
    setImage3('');
    setImage4('');
  };

  const handleBackPage = () => {
    dispatch(clearDetailDriver());
    reset();
    setAvatar('');
    setImage1('');
    setImage2('');
    setImage3('');
    setImage4('');
    navigate(CONST_ROUTER.DRIVER_MANAGEMENT);
  };
  const actions = [ACTIONS.EDIT, ACTIONS.DELETE];
  const actionInfo: any = [
    {
      action: ACTIONS.EDIT,
      label: avatar ? lang.button.actions.editImage : lang.button.actions.addImage,
      disabled: false,
      icon: <Edit sx={{ mr: 2 }} />,
      isUpload: true,
    },
    {
      action: ACTIONS.DELETE,
      label: lang.button.actions.deleteImage,
      disabled: !avatar,
      icon: <DeleteOutline sx={{ mr: 2 }} />,
      onClick: handleDeleteImage,
      sx: { color: 'error.main', cursor: 'pointer' },
    },
  ];

  const handleRemoveImage = (setImage, setFile, image, name) => {
    setImage('');
    setFile('');
    if (!image) {
      setValue(name, '');
    }
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    reset();
    clearErrors();
    setAvatar(dataApi?.avatarPath ? dataApi?.avatarPath : '');
    setImage1(dataApi?.frontIDCardPath ? dataApi?.frontIDCardPath : '');
    setImage2(dataApi?.backIDCardPath ? dataApi?.backIDCardPath : '');
    setImage3(dataApi?.frontDriverLicensePath ? dataApi?.frontDriverLicensePath : '');
    setImage4(dataApi?.backDriverLicensePath ? dataApi?.backDriverLicensePath : '');
    setValue('surnameAndMiddleName', dataApi?.surnameAndMiddleName);
    setValue('lastName', dataApi?.lastName);
    setValue('phoneNumber', dataApi?.phoneNumber);
    setValue('dob', dataApi?.dateOfBirth);
    setValue('status', dataApi?.status);
    setValue('email', dataApi?.email ? dataApi.email : '');
    setValue('address', dataApi?.provinceId);
    setValue('gender', dataApi?.gender === FAMALE ? 2 : dataApi?.gender);
    setIsEdit(false);
    setIsDelete(false);
  };

  const handleSave = (data) => {
    const formData = new FormData();
    itemId && formData.append('Id', itemId);
    formData.append('surnameAndMiddleName', data?.surnameAndMiddleName);
    formData.append('lastName', data?.lastName);
    formData.append('PhoneNumber', data?.phoneNumber);
    formData.append('ProvinceId', data?.address);
    formData.append('DateOfBirth', convertDateDob(data.dob));
    formData.append('Email', data?.email);
    formData.append('Gender', data?.gender === 2 ? FAMALE : data?.gender);
    formData.append('Status', data?.status);
    file1 && formData.append('FrontIDCard', file1);
    file2 && formData.append('BackIDCard', file2);
    file3 && formData.append('FrontDriverLicense', file3);
    file4 && formData.append('BackDriverLicense', file4);
    fileAvatar
      ? formData.append('Avatar', fileAvatar)
      : !isDelete && formData.append('AvatarPath', dataApi?.avatarPath);

    const payload = {
      params: formData,
      callbackSuccess: (isError) => {
        showSuccessToast(lang.driverManagement.successEdit);
        itemId && dispatch(detailDriver({ driverId: itemId }));
        setIsEdit(false);
        setFile1('');
        setFile2('');
        setFile3('');
        setFile4('');
        setFileAvatar('');
        reset();
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(putDriver(payload));
  };

  const lightboxData = [
    { images: [image1 || dataApi?.frontIDCardPath], isOpen: isOpen1, setIsOpen: setIsOpen1 },
    { images: [image2 || dataApi?.backIDCardPathage], isOpen: isOpen2, setIsOpen: setIsOpen2 },
    { images: [image3 || dataApi?.backIDCardPath], isOpen: isOpen3, setIsOpen: setIsOpen3 },
    { images: [image4 || dataApi?.backDriverLicensePath], isOpen: isOpen4, setIsOpen: setIsOpen4 },
  ];

  const lightboxes = lightboxData.map((item, index) => (
    <CustomLightbox
      key={index}
      images={item.images}
      isOpen={item.isOpen}
      photoIndex={0}
      onClose={() => item.setIsOpen(false)}
    />
  ));
  const checkStatusChange = (watchStatus, dataApiStatus) => {
    if (
      (watchStatus === '1970-01-01' && dataApiStatus === null) ||
      (dataApiStatus === null && watchStatus === '') ||
      (dataApiStatus === 0 && watchStatus === 2) ||
      (dataApiStatus === 0 && watchStatus === 4) ||
      (dataApiStatus === 0 && parseInt(watchStatus) === 2)
    ) {
      return true;
    }
    return watchStatus === dataApiStatus;
  };
  const isNewImage = (isDelete || !fileAvatar) && !isDelete;
  const isImage1 = (isDelete || !file1) && !isDelete;
  const isImage2 = (isDelete || !file2) && !isDelete;
  const isImage3 = (isDelete || !file3) && !isDelete;
  const isImage4 = (isDelete || !file4) && !isDelete;
  const isNewData =
    checkStatusChange(watch('surnameAndMiddleName'), dataApi?.surnameAndMiddleName) &&
    checkStatusChange(watch('lastName'), dataApi?.lastName) &&
    checkStatusChange(watch('phoneNumber'), dataApi?.phoneNumber) &&
    checkStatusChange(watch('address'), dataApi?.provinceId) &&
    checkStatusChange(convertDateDob(watch('dob')), convertDateDob(dataApi?.dateOfBirth)) &&
    checkStatusChange(watch('email').toLowerCase(), dataApi?.email?.toLowerCase()) &&
    checkStatusChange(Number(watch('gender')), dataApi?.gender) &&
    isNewImage &&
    isImage1 &&
    isImage2 &&
    isImage3 &&
    isImage4 &&
    checkStatusChange(watch('status'), dataApi?.status);
  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          {!itemId && (
            <BackPage
              title={lang.driverManagement.addNewDriver}
              handleBackPage={handleBackPage}
              marginbottom={!itemId ? 1 : 0}
            />
          )}
          <Card sx={{ p: 2 }}>
            <AccountAvatar
              avatar={avatar}
              isEdit={isEdit || !itemId}
              handleMenuClick={handleMenuClick}
              setAvatar={setAvatar}
              setFile={setFileAvatar}
              setIsDelete={setIsDelete}
            />
            <AvatarMenu
              actions={actions}
              actionInfo={actionInfo}
              handleCloseMenu={handleCloseMenu}
              anchorEl={anchorEl}
            />
            <Grid container spacing={!isMobile ? 8 : 1}>
              <Grid item xs={12} sm={6} md={6} className="driver-form-data">
                <Grid className="driver-form-spacing">
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Họ và tên đệm:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="surnameAndMiddleName"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {isMobile && (
                    <Grid container mt={2}>
                      <Grid item xs={5} sm={5} md={3} mt={1}>
                        <strong className="fz-14">
                          Tên:<span className="required">*</span>
                        </strong>
                      </Grid>
                      <Grid item xs={7} sm={7} md={9}>
                        <FormControl fullWidth>
                          <InputForm
                            name="lastName"
                            control={control}
                            size="small"
                            fullWidth
                            errors={errors}
                            disabled={!!itemId && !isEdit}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Email:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="email"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Nơi sống:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <SelectSearch
                          options={dataApiProvince || []}
                          name="address"
                          control={control}
                          size="small"
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Giới tính:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <RadioInputForm
                          options={genderSelect}
                          name="gender"
                          control={control}
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {!isMobile && (
                  <ImageUploadForm
                    setIsDelete={setIsDelete}
                    itemId={itemId}
                    isEdit={isEdit}
                    frontImage={image1}
                    backImage={image2}
                    setFrontImage={setImage1}
                    setBackImage={setImage2}
                    setIsOpen1={setIsOpen1}
                    setIsOpen2={setIsOpen2}
                    frontImageCheck="frontIdCheck"
                    backImageCheck="backIdCheck"
                    setFrontFile={setFile1}
                    setBackFile={setFile2}
                    handleRemoveImage={handleRemoveImage}
                    errors={errors}
                    isMobile={isMobile}
                    control={control}
                    handleImage={handleImage}
                    formName={lang.driverManagement.addIdImage}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="driver-form-data">
                <Grid className="driver-form-spacing">
                  {!isMobile && (
                    <Grid container mt={2}>
                      <Grid item xs={5} sm={5} md={3} mt={1}>
                        <strong className="fz-14">
                          Tên:<span className="required">*</span>
                        </strong>
                      </Grid>
                      <Grid item xs={7} sm={7} md={9}>
                        <FormControl fullWidth>
                          <InputForm
                            name="lastName"
                            control={control}
                            size="small"
                            fullWidth
                            errors={errors}
                            disabled={!!itemId && !isEdit}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Số điện thoại:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="phoneNumber"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Ngày sinh:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputFormDate name="dob" control={control} errors={errors} disabled={!!itemId && !isEdit} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Trạng thái:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <SelectSearch
                          options={driverStatus || []}
                          name="status"
                          control={control}
                          size="small"
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <ImageUploadForm
                  setIsDelete={setIsDelete}
                  itemId={itemId}
                  isEdit={isEdit}
                  setIsOpen1={setIsOpen3}
                  setIsOpen2={setIsOpen4}
                  frontImage={image3} // Assuming frontImage is a variable containing the front image
                  backImage={image4} // Assuming backImage is a variable containing the back image
                  setFrontImage={setImage3} // Assuming setImage is a function to set the image
                  setBackImage={setImage4} // Assuming setImage is a function to set the image
                  frontImageCheck="frontLicencseCheck"
                  backImageCheck="backLicencseCheck"
                  setFrontFile={setFile3}
                  setBackFile={setFile4} // Assuming setFile is a function to set the file
                  handleRemoveImage={handleRemoveImage} // Assuming handleRemoveImage is a function to remove the image
                  errors={errors} // Assuming errors is an object containing error messages
                  isMobile={isMobile} // Assuming isMobile is a boolean indicating mobile view
                  control={control} // Assuming control is a prop for form control
                  handleImage={handleImage} // Assuming handleImage is a function to handle image upload
                  formName={lang.driverManagement.addDriverLicenseImage} // Set the form name
                />
                {isMobile && (
                  <ImageUploadForm
                    setIsDelete={setIsDelete}
                    itemId={itemId}
                    isEdit={isEdit}
                    frontImage={image1}
                    backImage={image2}
                    setFrontImage={setImage1}
                    setBackImage={setImage2}
                    setIsOpen1={setIsOpen1}
                    setIsOpen2={setIsOpen2}
                    frontImageCheck="frontIdCheck"
                    backImageCheck="backIdCheck"
                    setFrontFile={setFile1}
                    setBackFile={setFile2}
                    handleRemoveImage={handleRemoveImage}
                    errors={errors}
                    isMobile={isMobile}
                    control={control}
                    handleImage={handleImage}
                    formName={lang.driverManagement.addIdImage}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} display="flex" alignItems="center"></Grid>
          </Card>
          <HandleButtonAddEdit
            itemId={itemId}
            isEdit={isEdit}
            handleEdit={handleEdit}
            handleCancel={handleCancel}
            handleSave={handleSubmit(handleSave)}
            onCancel={onCancel}
            onSubmit={handleSubmit(onSubmit)}
            isNewData={!!isNewData}
          />
          {lightboxes}
          <CustomBackdrop open={dataSelector.loadingPostDriver || dataSelector.loadingputDriver} />
        </>
      )}
    </>
  );
}
