import { addMonths } from 'date-fns';
import { CookieKey } from '../constant/common';
import { getCurrentDomain } from '../utils';
import Cookies from 'js-cookie';

export const CookiesStorage = {
  getCookieData(key: string) {
    return Cookies.get(key);
  },

  setCookieData(key: string, data: string) {
    const domain = getCurrentDomain();
    const currentTime = new Date();
    const expires = addMonths(currentTime, 1);
    Cookies.set(key, data, { domain, expires, path: '/', sameSite: 'strict' });
  },

  clearCookieData(key: string) {
    const cookieData = this.getCookieData(key);
    if (cookieData) {
      const domain = getCurrentDomain();
      Cookies.remove(key, { domain, path: '/' });
    }
  },

  setAccount(data: any) {
    const domain = getCurrentDomain();
    const currentTime = new Date();
    const expires = addMonths(currentTime, 1);
    Cookies.set(CookieKey.account, data, {
      domain,
      path: '/',
      expires,
      sameSite: 'strict',
    });
  },

  getAccount() {
    return Cookies.get(CookieKey.account);
  },

  clearAccount() {
    const domain = getCurrentDomain();
    Cookies.remove(CookieKey.account, { domain, path: '/' });
  },
};
