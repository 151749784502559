import { Card, CardContent, Stack, Typography } from '@mui/material';

const EmptyPage = () => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ minHeight: '200px' }}>
      <Card>
        <CardContent>
          <Typography variant="h6" component="div" align="center">
            Không có dữ liệu
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};
export default EmptyPage;
