import {
  ADD_CUSTOMER,
  BLOCK_CUSTOMER,
  CUSTOMER,
  DETAIL_CUSTOMER,
  DOWFILE_CUSTOMER,
  DROPDOWN_CUSTOMER,
  EDIT_CUSTOMER,
  LIST_VEHICLE_CUSTOMER,
  RESET_PASS_CUSTOMER,
  TRIP_HISTORY,
  UN_BLOCK_CUSTOMER,
} from '@/shared/constant/constantUrl';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api';

const initialState = {
  listCustomer: [],
  loadingListCustomer: false,
  detailCustomer: [],
  loadingDetailCustomer: false,
  loadingPutCustomer: false,
  loadingUnBlockCustomer: false,
  loadingEditCustomer: false,
  loadingAddCustomer: false,
  listVehicleCustomer: [],
  loadingListVehicleCustomer: false,
  listDropdownCustomer: [],
  loadingListDropDown: false,
  listHistoryTrip: [],
  loadingListHistoryTrip: false,
  loadingDowFileCustomer: false,
  loadingResetPassCustomer: false,
};

export const getCustomer = createAsyncThunk('getCustomer', async (params: any) => {
  try {
    const response = await Api.get(CUSTOMER, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getListVehicleCustomer = createAsyncThunk('getListVehicleCustomer', async (params: any) => {
  const { id, data } = params;
  try {
    const response = await Api.get(LIST_VEHICLE_CUSTOMER(id), data);
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getDropdownListCustomer = createAsyncThunk('getDropdownListCustomer', async () => {
  try {
    const response = await Api.get(DROPDOWN_CUSTOMER);
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const postResetPassCustomer = createAsyncThunk(
  'postResetPassCustomer/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(RESET_PASS_CUSTOMER(params.id), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const putCustomer = createAsyncThunk(
  'putCustomer/put',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(BLOCK_CUSTOMER(params.customerId), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const historyTripCustomer = createAsyncThunk('historyTripCustomer/historyTripCustomer', async (params: any) => {
  try {
    const response = await Api.get(TRIP_HISTORY, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const unBlockCustomer = createAsyncThunk(
  'unBlockCustomer/put',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(UN_BLOCK_CUSTOMER(params.customerId), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const getExportFileCustomer = createAsyncThunk(
  'getExportFileTripCustomer',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.get(DOWFILE_CUSTOMER, { params, responseType: 'arraybuffer' });
      callbackSuccess?.(response);
      return response.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const editCustomer = createAsyncThunk(
  'editCustomer/put',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(EDIT_CUSTOMER, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const addCustomer = createAsyncThunk(
  'addCustomer/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(ADD_CUSTOMER, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const detailCustomer = createAsyncThunk('detailCustomer', async (params: any) => {
  try {
    const response = await Api.get(DETAIL_CUSTOMER, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const customer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    clearDetailCustomer: (state) => {
      state.detailCustomer = [];
    },
    updateStatusCustomer: (state) => {
      state.loadingListCustomer = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomer.pending, (state) => {
        state.loadingListCustomer = true;
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.loadingListCustomer = false;
        state.listCustomer = action.payload;
      })
      .addCase(getCustomer.rejected, (state) => {
        state.loadingListCustomer = false;
        state.listCustomer = [];
      })

      .addCase(detailCustomer.pending, (state) => {
        state.loadingDetailCustomer = true;
      })
      .addCase(detailCustomer.fulfilled, (state, action) => {
        state.loadingDetailCustomer = false;
        state.detailCustomer = action.payload;
      })
      .addCase(detailCustomer.rejected, (state) => {
        state.loadingDetailCustomer = false;
        state.detailCustomer = [];
      })

      .addCase(putCustomer.pending, (state) => {
        state.loadingPutCustomer = true;
      })
      .addCase(putCustomer.fulfilled, (state, action) => {
        state.loadingPutCustomer = false;
      })
      .addCase(putCustomer.rejected, (state) => {
        state.loadingPutCustomer = false;
      })

      .addCase(unBlockCustomer.pending, (state) => {
        state.loadingUnBlockCustomer = true;
      })
      .addCase(unBlockCustomer.fulfilled, (state, action) => {
        state.loadingUnBlockCustomer = false;
      })
      .addCase(unBlockCustomer.rejected, (state) => {})

      .addCase(editCustomer.pending, (state) => {
        state.loadingEditCustomer = true;
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        state.loadingEditCustomer = false;
      })
      .addCase(editCustomer.rejected, (state) => {
        state.loadingEditCustomer = false;
      })

      .addCase(addCustomer.pending, (state) => {
        state.loadingAddCustomer = true;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.loadingAddCustomer = false;
      })
      .addCase(addCustomer.rejected, (state) => {
        state.loadingAddCustomer = false;
      })

      .addCase(getListVehicleCustomer.pending, (state) => {
        state.loadingListVehicleCustomer = true;
      })
      .addCase(getListVehicleCustomer.fulfilled, (state, action) => {
        state.loadingListVehicleCustomer = false;
        state.listVehicleCustomer = action.payload;
      })
      .addCase(getListVehicleCustomer.rejected, (state) => {
        state.loadingListVehicleCustomer = false;
        state.listVehicleCustomer = [];
      })

      .addCase(getDropdownListCustomer.pending, (state) => {
        state.loadingListDropDown = true;
      })
      .addCase(getDropdownListCustomer.fulfilled, (state, action) => {
        state.loadingListDropDown = false;
        state.listDropdownCustomer = action.payload;
      })
      .addCase(getDropdownListCustomer.rejected, (state) => {
        state.loadingListDropDown = false;
        state.listDropdownCustomer = [];
      })

      .addCase(historyTripCustomer.pending, (state) => {
        state.loadingListHistoryTrip = true;
      })
      .addCase(historyTripCustomer.fulfilled, (state, action) => {
        state.loadingListHistoryTrip = false;
        state.listHistoryTrip = action.payload;
      })
      .addCase(historyTripCustomer.rejected, (state) => {
        state.loadingListHistoryTrip = false;
        state.listHistoryTrip = [];
      })

      .addCase(getExportFileCustomer.pending, (state) => {
        state.loadingDowFileCustomer = true;
      })
      .addCase(getExportFileCustomer.fulfilled, (state, action) => {
        state.loadingDowFileCustomer = false;
      })
      .addCase(getExportFileCustomer.rejected, (state) => {
        state.loadingDowFileCustomer = false;
      })

      .addCase(postResetPassCustomer.pending, (state) => {
        state.loadingResetPassCustomer = true;
      })
      .addCase(postResetPassCustomer.fulfilled, (state, action) => {
        state.loadingResetPassCustomer = false;
      })
      .addCase(postResetPassCustomer.rejected, (state) => {
        state.loadingResetPassCustomer = false;
      });
  },
});

export const { actions } = customer;
export const customerData = (state) => state.customer;
export default customer.reducer;
export const { clearDetailCustomer, updateStatusCustomer } = customer.actions;
