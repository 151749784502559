import { ProtectedRouteType } from '@/interfaces/index';
import { useAuth } from '@/hooks/useAuth';
import { CONST_ROUTER, employeeAccessibleRoutes } from '@/shared/constant/common';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSliceData } from '@/redux/slices/auth';
import { ROLE_EMPLOYEE } from '@/shared/config/setting';

const ProtectedRoutes = (props: ProtectedRouteType) => {
  const { token } = useAuth();
  const dataSelector = useSelector(authSliceData);
  const { pathname } = useLocation();

  if (props.roleRequired) {
    return token ? (
      props.roleRequired.includes(dataSelector?.profileDetail?.data?.role as string) ? (
        <Outlet />
      ) : dataSelector?.profileDetail?.data?.role === ROLE_EMPLOYEE ? (
        employeeAccessibleRoutes.includes(pathname) ? (
          <Navigate to={pathname} />
        ) : (
          <Navigate to={CONST_ROUTER.DASH_BOARD_CHART} />
        )
      ) : (
        <Navigate to={pathname} />
      )
    ) : (
      <Navigate to={CONST_ROUTER.LOGIN} />
    );
  } else {
    return token ? <Outlet /> : <Navigate to={CONST_ROUTER.LOGIN} />;
  }
};

export default ProtectedRoutes;
