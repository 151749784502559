import { SyntheticEvent, useState, ReactNode, useEffect } from 'react';
import { Box, Typography, Tab, Tabs } from '@mui/material';
import { CONST_ROUTER, TabsTripDetailLabel } from '@/shared/constant/common';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BackPage } from '@/components/BackPage';
import { createNewPath, useQueryParameters } from '@/shared/utils/common';
import TripDetail from './TripDetail';
import TripsLoglHistory from './TripLogHistory';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
  };
}

export default function TabsTripDetail() {
  const queriesParams: any = useQueryParameters();
  const tabData = [{ component: <TripDetail /> }, { component: <TripsLoglHistory /> }];
  const navigate = useNavigate();
  const lang = window.lang;
  const { valueTab, isRouter, id, page } = queriesParams;
  const [value, setValue] = useState<number>(+valueTab || 0);
  const { pathname } = useLocation();
  const { itemId } = useParams();
  const router = useNavigate();
  const handleBackPage = () => {
    const CUSTOMER = 'customer';
    const DRIVER = 'driver';
    const NOTIFICATION = 'notification';
    const EVALUATION = 'evaluation';
    const params = {
      isRouter: isRouter,
      valueTab: valueTab,
      page,
    };
    if (isRouter) {
      const newPath =
        isRouter === CUSTOMER
          ? createNewPath(CONST_ROUTER.DETAIL_CUSTOMER_ITEM(id), params)
          : isRouter === DRIVER
          ? createNewPath(CONST_ROUTER.DRIVER_DETAIL_ITEM(id), params)
          : isRouter === NOTIFICATION
          ? createNewPath(CONST_ROUTER.NOTIFICATION, { page })
          : isRouter === EVALUATION
          ? createNewPath(CONST_ROUTER.EVALUATION_STATISTICS, {})
          : '';
      router(newPath);
    } else {
      navigate(CONST_ROUTER.TRIP_MANAGEMENT);
    }
  };
  useEffect(() => {
    setValue(pathname.includes('/logTrip') ? 1 : 0);
  }, [pathname]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const newPath = createNewPath(pathname, { valueTab: newValue });
    navigate(newPath);
  };

  return (
    <>
      <BackPage title="Xem chi tiết hành trình" handleBackPage={handleBackPage} />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            {TabsTripDetailLabel.map((tab, index) => (
              <Tab
                className="fz-14"
                key={index}
                label={tab.label}
                component={Link}
                to={tab.url(itemId)}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        {tabData.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </Box>
    </>
  );
}
