import { adminEditTrip, getDetailTrip, tripData } from '@/redux/slices/trip';
import { enumDriverStatus, enumTripStatus } from '@/shared/constant/common';
import {
  convertOptionsDriver,
  dataAddress,
  dataAddressObject,
  formatCurrency,
  formatDateTimeNotUTC,
  getMaxIndexByType,
  handleError,
  isBooleanArray,
} from '@/shared/utils/common';
import { Card, Divider, FormControl, FormHelperText, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ModalAssignDriver } from '../../ModalTrip/AssignDriver';
import { driverData } from '@/redux/slices/driver';
import LoadingPage from '@/components/LoadingPage';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { CANCEL, COMPLETE, END, FINISHED, STOP, UNKNOW } from '@/shared/constant/text';
import CustomLightbox from '@/components/CustomLightbox';
import { Add, Delete, Edit } from '@mui/icons-material';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import GoogleMapAutoComplete from './location';
import * as yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomBackdrop } from '@/components/CustomBackdrop';

const TripDetail = () => {
  const lang = window.lang;
  const { itemId } = useParams();
  const enumNotEditTrip = [CANCEL, FINISHED, COMPLETE];
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(tripData);
  const dataDetail = dataSelector?.tripDetail?.data;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [tripId, setTripId] = useState<any>();
  const driverDataSelector = useSelector(driverData);
  const availableDriveList = driverDataSelector?.availableDriveList;
  const StartingPoint = 0;
  const StoppingPoint = 1;
  const DestinationPoint = 2;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [listImage, setListImage] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deletedItems, setDeletedItems] = useState<any>([]);
  const gridRef = useRef<any>(null);
  const [gridWidth, setGridWidth] = useState(null);
  const { loadingTripDetail, loadingEditTrip } = dataSelector;
  const ACTION_TYPE = {
    ADD: 1,
    EDIT: 2,
    DELTETE: 0,
  };
  const [showDeleteStopButton, setShowDeleteStopButton] = useState<boolean | undefined>(true);
  const [showDeleteEndButton, setShowDeleteEndButton] = useState<boolean | undefined>(true);
  const isNotEditTrip = enumNotEditTrip.includes(dataDetail?.status);
  const [maxIndexStop, setMaxIndexStop] = useState<number>();

  const schema = (lang) =>
    yup.object().shape({
      isDeleteEnd: yup.boolean(),
      isOnChangeListEnd: yup.boolean(),
      pointEnd: yup.array().of(
        yup.object().shape({
          id: yup.string().nullable(),
          address: yup.string(),
          location: yup
            .string()
            .test('test', 'Điểm kết thúc không được để trống, vui lòng chọn điểm kết thúc', function (value) {
              const isDeleteEnd = this.from?.[1]?.value?.isDeleteEnd ?? false;
              const isArrayOptions = isBooleanArray(this.from?.[1]?.value?.pointEnd) ?? false;
              return isDeleteEnd || !isArrayOptions ? true : !!value;
            }),
        }),
      ),
      pointStopping: yup.array().of(
        yup.object().shape({
          id: yup.string().nullable(),
          address: yup.string(),
          location: yup.string().required('Điểm dừng không được để trống, vui lòng chọn điểm dừng'),
        }),
      ),
    });

  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current) {
        const width = gridRef.current.clientWidth;
        setGridWidth(width);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isEdit]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema(lang)),
    defaultValues: {
      isDeleteEnd: false,
      pointStopping: [],
      pointEnd: [],
    },
  });

  const listPointStop = watch('pointStopping');
  const listPointEnd = watch('pointEnd');
  const isDeleteEnd = watch('isDeleteEnd'); // check boolean icon remove item point end

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pointStopping',
  }) as {
    fields: { id: string; address: string; index: string; location: any }[];
    append: (data: any) => void;
    remove: (index: number) => void;
  };

  useEffect(() => {
    const handleButtonVisibility = () => {
      const stoppingPoints = getValues('pointStopping');
      setShowDeleteStopButton(Boolean(!(stoppingPoints && isDeleteEnd && stoppingPoints.length === 1))); // hidden icon delete point stop
      setShowDeleteEndButton(Boolean(!(stoppingPoints?.length === 0))); // hidden icon delete point end
    };

    handleButtonVisibility();
  }, [watch('pointStopping'), watch('isDeleteEnd')]);

  const clearData = () => {
    setIsEdit(false);
    reset();
    setDeletedItems([]);
  };

  useEffect(() => {
    if (itemId) {
      dispatch(getDetailTrip(itemId));
    }
    return () => {
      clearData();
    };
  }, [itemId]);

  const setData = (dataDetail) => {
    const arrayAddressEnd = dataAddressObject(dataDetail?.tripLocations, DestinationPoint);
    const arrayAddressStop = dataAddressObject(dataDetail?.tripLocations, StoppingPoint);
    const isAddFieldPointEnd = !!(!isBooleanArray(arrayAddressEnd) && !isBooleanArray(arrayAddressStop));

    const maxIndex = getMaxIndexByType(dataDetail?.tripLocations, StoppingPoint);
    setValue('pointEnd', isAddFieldPointEnd ? [{ id: '', location: '', address: '' }] : arrayAddressEnd);
    setValue('pointStopping', arrayAddressStop);
    setMaxIndexStop(maxIndex);
  };

  useEffect(() => {
    if (dataDetail) {
      setListImage(dataDetail?.paymentImages);
      setData(dataDetail);
    }
  }, [dataDetail, isEdit]);

  const handleEdit = () => setIsEdit(true);

  const handleCancel = () => {
    setData(dataDetail);
    clearData();
    clearErrors();
  };

  const handleSave = (data) => {
    const sortDataStopById = // Sort the array again
      data?.pointStopping?.sort((a, b) => {
        if (a.id === '' && b.id !== '') return -1; // Elements without id go to the beginning of the array
        if (a.id !== '' && b.id === '') return 1; // Element without id goes to the end of the array
        return 0; // Maintain the original position of other elements
      });

    const newArrayStop = sortDataStopById?.map((item, index) => {
      const isAdd = !item?.id;
      return {
        id: item?.id || null,
        address: item?.address,
        location: item?.location && JSON.parse(item?.location),
        index: isAdd ? maxIndexStop + index + 1 : null,
        type: 1,
        actionType: isAdd ? ACTION_TYPE.ADD : ACTION_TYPE.EDIT,
      };
    });
    const newArrayEnd = data?.pointEnd?.map((item) => {
      const isAdd = !item?.id;
      return {
        type: 2,
        id: item?.id || null,
        location: item?.location && JSON.parse(item?.location),
        address: item?.address,
        actionType: isDeleteEnd ? ACTION_TYPE.DELTETE : isAdd ? ACTION_TYPE.ADD : ACTION_TYPE.EDIT,
      };
    });
    const paramsArray = [...newArrayStop, ...deletedItems, ...newArrayEnd];

    const transformedData =
      paramsArray &&
      paramsArray?.length > 0 &&
      paramsArray.map((item) => {
        const { lng, lon, ...location } = item?.location || {};
        return {
          ...item,
          location: {
            ...location,
            lon: lon || lng,
          },
        };
      });

    const payload = {
      id: itemId,
      params: {
        transformedData,
      },
      callbackSuccess: () => {
        showSuccessToast('Chỉnh sửa hành trình thành công!');
        itemId && dispatch(getDetailTrip(itemId));
        handleCancel();
      },
      callbackError: (error) => {
        showErrorToast(error);
      },
    };
    dispatch(adminEditTrip(payload));
  };

  const handleRemoveStopping = (index) => {
    const item: any = (listPointStop && listPointStop?.length > 0 && listPointStop[index]) || [];
    if (item && item.id) {
      setDeletedItems([
        ...deletedItems,
        {
          id: item?.id,
          address: item?.address,
          location: item?.location && JSON.parse(item.location),
          actionType: ACTION_TYPE.DELTETE,
          type: 1,
        },
      ]);
    }
    remove(index);
  };
  const handleRemoveEnd = () => {
    setValue('isDeleteEnd', true);

    const item: any = (listPointEnd && listPointEnd?.length > 0 && listPointEnd[0]) || [];
    if (item && item.id) {
      setDeletedItems([
        ...deletedItems,
        {
          id: item?.id,
          address: item?.address,
          location: JSON.parse(item?.location),
          actionType: ACTION_TYPE.DELTETE,
          type: 2,
        },
      ]);
    }
  };

  const fieldGridItem = (title, data, classNameTitle?, isChip = false) => (
    <>
      <Grid item xs={5} md={3}>
        <strong className="fz-14">{title}: </strong>
      </Grid>
      <Grid item xs={5} md={7}>
        {isChip ? (
          <span className={classNameTitle}>{enumTripStatus(data)}</span>
        ) : (
          <span className={classNameTitle}>{data || '-'}</span>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
  const fieldGridItemAray = (title, data, isText = false) => (
    <>
      <Grid item xs={5} md={3}>
        <strong className="fz-14">{title}: </strong>
      </Grid>
      <Grid item xs={5} md={7}>
        {data?.map((item, index) => (
          <div key={index}>
            {isText && <span> Đ{index + 1}:</span>} <span>{item}</span>
          </div>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );

  const driverList = convertOptionsDriver(availableDriveList || []);

  const handleAssignDriver = (id) => {
    setOpenModal(true);
    setTripId(id);
  };

  const handleCloseDetail = () => setOpenModal(false);

  const payloadSuccessAssignDetail = () => {
    setOpenModal(false);
    showSuccessToast(lang.tripManagement.assignDriver);
    itemId && dispatch(getDetailTrip(itemId));
  };

  const handeOpenImage = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const onMoveNext = () => setPhotoIndex((photoIndex + 1) % listImage.length);

  const onMovePrev = () => setPhotoIndex((photoIndex + listImage.length - 1) % listImage.length);

  const onClose = () => setIsOpen(false);
  return (
    <>
      {loadingTripDetail ? (
        <LoadingPage />
      ) : (
        <>
          <Card sx={{ mt: 1, p: 3 }}>
            <Grid container spacing={2}>
              {fieldGridItem('Mã hành trình', dataDetail?.code, 'detail-trip')}
              {fieldGridItem('Trạng thái', dataDetail?.status, 'chip-detail', true)}
              {fieldGridItem('Ngày đặt', formatDateTimeNotUTC(dataDetail?.createdDate))}
              {fieldGridItem('Ngày xuất phát', formatDateTimeNotUTC(dataDetail?.startTime))}
              <Grid item xs={5} md={3}>
                <strong className="fz-14">Tài xế: </strong>
              </Grid>
              <Grid item xs={5} md={7}>
                <span className="assign-detail">
                  {dataDetail?.isAssign
                    ? dataDetail?.driverName
                    : dataDetail?.status === 6
                    ? '-'
                    : enumDriverStatus(0, dataDetail, handleAssignDriver)}
                </span>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {fieldGridItem('Khách hàng', dataDetail?.customerName)}
              {fieldGridItem('Thông tin xe', dataDetail?.vehicleClassName || dataDetail?.vehicleName)}
              {fieldGridItemAray('Điểm đi', dataAddress(dataDetail?.tripLocations, StartingPoint))}

              {!isEdit ? (
                fieldGridItemAray('Điểm dừng', dataAddress(dataDetail?.tripLocations, StoppingPoint), true)
              ) : (
                <>
                  <Grid item xs={12} md={3} display="flex" alignItems="center">
                    <strong className="fz-14">Điểm dừng: </strong>
                  </Grid>
                  <>
                    <Grid item xs={12} md={9} display="flex" alignItems="center" flexWrap="wrap" gap="10px">
                      {fields?.length > 0 ? (
                        fields?.map((value, index) => {
                          return (
                            <>
                              <Grid item xs={12} display="flex" flexWrap="wrap" key={value?.id}>
                                <Grid item xs={9} md={10} ref={gridRef}>
                                  <FormControl fullWidth>
                                    <GoogleMapAutoComplete
                                      name={`pointStopping.${index}.address`}
                                      control={control}
                                      errors={errors}
                                      id={`stopping-${index}`}
                                      setValue={setValue}
                                      clearErrors={clearErrors}
                                      index={index}
                                      type={STOP}
                                      getValues={getValues}
                                      gridWidth={gridWidth}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={3} md={2} display="flex" alignItems="center">
                                  <IconButton onClick={() => handleRemoveStopping(index)}>
                                    {showDeleteStopButton && <Delete className="color-red" />}
                                  </IconButton>
                                  {fields.length - 1 === index && (
                                    <IconButton onClick={() => append({ id: '', address: '', location: '' })}>
                                      <Add className="color-blue" />
                                    </IconButton>
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  {errors?.pointStopping &&
                                    errors?.pointStopping[index] &&
                                    errors?.pointStopping[index]?.location && (
                                      <FormHelperText error>
                                        {errors.pointStopping[index]?.location?.message}
                                      </FormHelperText>
                                    )}
                                </Grid>
                              </Grid>
                            </>
                          );
                        })
                      ) : (
                        <IconButton onClick={() => append({ id: '', address: '', location: '' })} className="no-hover">
                          <Add className="color-blue no-hover" />
                          <span className="color-blue fz-16 no-hover"> Thêm điểm dừng</span>
                        </IconButton>
                      )}
                    </Grid>
                  </>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}

              {!isEdit || !isBooleanArray(listPointEnd) ? (
                fieldGridItemAray(
                  'Điểm đến',
                  isBooleanArray(dataAddress(dataDetail?.tripLocations, DestinationPoint))
                    ? dataAddress(dataDetail?.tripLocations, DestinationPoint)
                    : [UNKNOW],
                )
              ) : (
                <>
                  <Grid item xs={12} md={3} display="flex" alignItems="center">
                    <strong className="fz-14">Điểm đến: </strong>
                  </Grid>
                  {!watch('isDeleteEnd') && (
                    <>
                      <Grid item xs={12} md={9} display="flex" alignItems="center" flexWrap="wrap">
                        <Grid item xs={9} md={10} ref={gridRef}>
                          <FormControl fullWidth>
                            <GoogleMapAutoComplete
                              name={`pointEnd.0.address`}
                              control={control}
                              id="ends"
                              errors={errors}
                              index={0}
                              type={END}
                              setValue={setValue}
                              clearErrors={clearErrors}
                              gridWidth={gridWidth}
                              getValues={getValues}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} md={2} display="flex" alignItems="center">
                          {showDeleteEndButton && (
                            <IconButton onClick={handleRemoveEnd}>
                              <Delete className="color-red" />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {errors?.pointEnd && errors?.pointEnd[0] && errors?.pointEnd[0]?.location && (
                            <FormHelperText error>{errors.pointEnd[0]?.location?.message}</FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {fieldGridItem('Phương thức thanh toán', dataDetail?.paymentSettingName)}
              {fieldGridItem('Tổng quãng đường', `${dataDetail?.distance} Km`)}
              {fieldGridItem('Tạm tính', formatCurrency(dataDetail?.price, true), 'text-trip-detail')}
              <>
                <Grid item xs={5} md={3}>
                  <strong className="fz-14">Tổng giá chờ: </strong>
                  {dataDetail?.tripWaitDetails?.map((item, index) => (
                    <Typography key={`${item?.id}key`} pl={2}>
                      Giá chờ {index + 1}:
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={5} md={7}>
                  <Typography className="text-trip-detail" fontWeight={600}>
                    {formatCurrency(dataDetail?.waittingFee, true)}
                  </Typography>
                  {dataDetail?.tripWaitDetails?.map((item) => (
                    <Typography key={`${item?.id}price`}>{formatCurrency(item?.price, true)}</Typography>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
              {fieldGridItem('Phí phụ thu', formatCurrency(dataDetail?.surcharge, true), 'text-trip-detail')}
              {fieldGridItem('Khuyến mãi', formatCurrency(dataDetail?.amountDiscounted || 0, true), 'text-trip-detail')}
              {fieldGridItem('Tổng tiền', formatCurrency(dataDetail?.lastPrice, true), 'text-trip-detail')}
              {dataDetail?.status === CANCEL && (
                <>
                  {fieldGridItem('Lý do hủy', dataDetail?.reason)}
                  {fieldGridItem('Người hủy', dataDetail?.cancellerName)}
                  {fieldGridItem('Thời gian hủy', formatDateTimeNotUTC(dataDetail?.cancellationTime))}
                </>
              )}
              {dataDetail?.status === COMPLETE && (
                <>
                  <Grid item xs={12}>
                    <strong className="fz-14">Hình ảnh thanh toán: </strong>
                  </Grid>
                  {listImage?.map((item, index) => (
                    <Grid item xs={6} sm={6} md={3} key={index} onClick={() => handeOpenImage(index)}>
                      <img className="img-complete-trip" src={item} onError={handleError} alt={`Payment ${index}`} />
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
          <Grid container mt={3}>
            <Grid item xs={12} display="flex" gap={3} justifyContent="flex-end">
              {!isEdit ? (
                <ButtonCustom
                  fullWidth={false}
                  title="Chỉnh sửa"
                  variant="contained"
                  onClick={handleEdit}
                  icon={<Edit />}
                  disabled={isNotEditTrip}
                />
              ) : (
                <>
                  <ButtonCustom
                    fullWidth={false}
                    title={lang.button.actions.cancel}
                    variant="contained"
                    color="error"
                    onClick={handleCancel}
                    disabled={isNotEditTrip}
                  />
                  <ButtonCustom
                    fullWidth={false}
                    title={lang.button.actions.save}
                    variant="contained"
                    onClick={handleSubmit(handleSave)}
                    disabled={isNotEditTrip}
                  />
                </>
              )}
            </Grid>
          </Grid>
          <CustomBackdrop open={loadingEditTrip} />
          {isOpen && (
            <CustomLightbox
              images={listImage}
              isOpen={isOpen}
              photoIndex={photoIndex}
              onClose={onClose}
              onMovePrev={onMovePrev}
              onMoveNext={onMoveNext}
            />
          )}
          {openModal && (
            <ModalAssignDriver
              tripId={tripId}
              setOpen={setOpenModal}
              open={openModal}
              optionsDriverList={driverList}
              data={dataDetail}
              closeDetail={handleCloseDetail}
              itemId={itemId}
              payloadSuccessAssignDetail={payloadSuccessAssignDetail}
            />
          )}
        </>
      )}
    </>
  );
};

export default TripDetail;
