import { InputDateMonthAndYear, InputFormDate, SelectForm } from '@/common/Form';
import { FROM_DATE, MONTH_YEAR, QUARTER, YEAR } from '@/shared/constant/text';
import { FormControl, Grid, useMediaQuery } from '@mui/material';
import { optionsFilterDate, optionsQuarter } from '@/shared/options';

export const CustomDateField = ({ dateFilter, control, errors, isAll = true }: any) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLarge = useMediaQuery('(min-width:1200px)');
  const lang = window.lang;
  const renderField = (dateFilter, control, errors, isAll) => {
    if (+dateFilter === 0) {
      return (
        <Grid container xs={6} sm={9} md={isLarge ? 8 : 9} lg={8} gap={1} flexWrap={isMobile ? 'wrap' : 'nowrap'}>
          {!isMobile && !isLarge && <Grid item xs={0} sm={4}></Grid>}
          {!isMobile && !isLarge && <Grid item xs={0} sm={4}></Grid>}
          {!isMobile && !isLarge && <Grid item xs={0} sm={4}></Grid>}
        </Grid>
      );
    }
    if (dateFilter === FROM_DATE) {
      return (
        <Grid container xs={6} sm={9} md={isLarge ? 8 : 9} lg={8} gap={1} flexWrap={isMobile ? 'wrap' : 'nowrap'}>
          <Grid item xs={12} sm={4} md={4} lg={6} minWidth="120px">
            <FormControl fullWidth>
              <InputFormDate
                control={control}
                label={lang.placeholderInput.fromDay}
                name="startDay"
                size="small"
                errors={errors}
                maxDate={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={6} minWidth="120px">
            <FormControl fullWidth>
              <InputFormDate
                control={control}
                errors={errors}
                label={lang.placeholderInput.toDay}
                name="endDay"
                size="small"
                maxDate={false}
              />
            </FormControl>
          </Grid>
          {!isMobile && !isLarge && <Grid item xs={0} sm={4}></Grid>}
        </Grid>
      );
    }
    if (dateFilter === MONTH_YEAR || dateFilter === YEAR) {
      return (
        <Grid container xs={6} sm={9} md={isLarge ? 8 : 9} gap={1} flexWrap="nowrap">
          <Grid item xs={12} sm={4} md={4} lg={6} minWidth="140px">
            <FormControl fullWidth>
              <InputDateMonthAndYear
                control={control}
                errors={errors}
                label={dateFilter === MONTH_YEAR ? 'Tháng/Năm' : 'Năm'}
                name={dateFilter === MONTH_YEAR ? 'monthYear' : 'year'}
                size="small"
                typeDate={dateFilter === MONTH_YEAR ? 'monthYear' : 'year'}
              />
            </FormControl>
          </Grid>
          {!isMobile && !isLarge && <Grid item xs={0} sm={4}></Grid>}
          {!isMobile && !isLarge && <Grid item xs={0} sm={4}></Grid>}
        </Grid>
      );
    }
    if (dateFilter === QUARTER) {
      return (
        <Grid container xs={6} sm={9} md={isLarge ? 8 : 9} lg={8} gap={1} flexWrap={isMobile ? 'wrap' : 'nowrap'}>
          <Grid item xs={12} sm={4} md={4} lg={6}>
            <FormControl fullWidth>
              <SelectForm
                label={lang.labelInput.quarter}
                options={optionsQuarter}
                name="quarter"
                control={control}
                size="small"
                sizeLabel="small"
                errors={errors}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={6}>
            <FormControl fullWidth>
              <InputDateMonthAndYear
                control={control}
                errors={errors}
                label={lang.labelInput.year}
                name="quarterYear"
                size="small"
                typeDate={'year'}
              />
            </FormControl>
          </Grid>
          {!isMobile && !isLarge && <Grid item xs={0} sm={4}></Grid>}
        </Grid>
      );
    }
  };
  return (
    <Grid container xs={12} sm={12} md={12} lg={5} gap={1} flexWrap="nowrap">
      <Grid item xs={6} sm={3} md={isLarge ? 4 : 3} minWidth="140px">
        <FormControl fullWidth>
          <SelectForm
            label={lang.labelInput.typeStatisticsTime}
            options={isAll ? [{ value: '0', label: lang.labelInput.all }, ...optionsFilterDate] : optionsFilterDate}
            name="dateFilter"
            control={control}
            size="small"
            sizeLabel="small"
          />
        </FormControl>
      </Grid>
      {renderField(dateFilter, control, errors, isAll)}
    </Grid>
  );
};
