import { FormHelperText, FormLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { FormInputProps } from '@/interfaces';
import 'react-quill/dist/quill.snow.css';

export const InputEditor = ({
  disabled = false,
  control,
  label,
  name,
  size = 'small',
  errors,
  readOnly,
  ...rest
}: FormInputProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <ReactQuill
              className={disabled ? 'editor-form-opacity' : ' edittor-form'}
              {...field}
              {...rest}
              readOnly={disabled || readOnly}
            />
            {errors?.[name] && <FormHelperText error>{errors[name]?.message}</FormHelperText>}
          </>
        )}
      />
    </>
  );
};
