import TitlePage from '@/components/TitlePage';
import { ArrowRightAlt, PeopleAlt } from '@mui/icons-material';
import { Divider, Grid, Typography } from '@mui/material';
import BasicBars from './ChartColumn';
import { useDispatch, useSelector } from 'react-redux';
import { getTripDashboard, tripData } from '@/redux/slices/trip';
import { useEffect } from 'react';
import { CONST_ROUTER } from '@/shared/constant/common';
import { useNavigate } from 'react-router-dom';
import { BiTrip } from 'react-icons/bi';
import { ImUserTie } from 'react-icons/im';
import { updatePath } from '@/redux/slices/path';
import { formatCurrency } from '@/shared/utils/common';
export const DashBoardChart = () => {
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(tripData);
  const navigate = useNavigate();
  const { listTripDashboard, loadingTripDashboard } = dataSelector;

  const data = [
    {
      label: 'Tổng số lượng tài xế',
      value: (listTripDashboard?.totalDriver && formatCurrency(listTripDashboard?.totalDriver, false)) || 0,
      icon: <ImUserTie className="fz-icon" />,
      backgroundColor: '#e0f2f1',
      border: '2px solid rgb(46 155 149)',
      bg: '#ffe6e6',
      path: CONST_ROUTER.DRIVER_MANAGEMENT,
    },
    {
      label: 'Khách hàng đã đăng ký',
      value: (listTripDashboard?.totalCustomer && formatCurrency(listTripDashboard.totalCustomer, false)) || 0,
      icon: <PeopleAlt className="fz-icon" />,
      backgroundColor: '#fce4ec',
      border: '2px solid rgb(181 75 110)',
      bg: '#9dcdd4',
      path: CONST_ROUTER.MANAGEMENT_CUSTOMER,
    },
    {
      label: 'Tổng hành trình đã được tạo',
      value: (listTripDashboard?.totalTrip && formatCurrency(listTripDashboard.totalTrip, false)) || 0,
      icon: <BiTrip className="fz-icon" />,
      backgroundColor: '#f0f4c3',
      border: '2px solid rgb(133 140 57)',
      bg: '#b38d82',
      path: CONST_ROUTER.TRIP_MANAGEMENT,
    },
  ];

  useEffect(() => {
    dispatch(getTripDashboard());
  }, []);

  const handleClick = (item) => {
    dispatch(updatePath(item?.path));
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mt={1}>
        <Grid item>
          <TitlePage title="Dashboard" />
        </Grid>
      </Grid>
      <Divider className="bd-title-tab" />
      <Grid container className="dashboard-card" gap={1.5}>
        {data.map((item, index) => (
          <Grid
            container
            key={index}
            style={{ backgroundColor: item.backgroundColor, border: item.border, borderRadius: '16px' }}
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            width="200px"
            height="100%"
            alignItems="center"
          >
            <Grid
              item
              display="flex"
              width="100%"
              gap="1rem"
              p={2}
              alignItems="center"
              justifyContent="center"
              minHeight="145px"
            >
              <Grid item width="20%" className="dashboard-icon">
                {item.icon}
              </Grid>
              <Grid item width="80%" display="flex" justifyContent="center" flexDirection="column" gap={1}>
                <Typography fontSize="3rem" fontWeight={800} color="error" lineHeight={1}>
                  {item.value}
                </Typography>
                <Typography fontSize="1.5rem" fontWeight={600} lineHeight={1}>
                  {item.label}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} height="1px">
              <Divider className="popup-divider" />
            </Grid>
            <Grid
              onClick={() => {
                navigate(item?.path);
                handleClick(item);
              }}
              className="view-detail-dashboard"
            >
              <Typography fontWeight="600" fontSize="1rem" lineHeight={1.5} sx={{ textDecoration: 'underline' }}>
                Xem chi tiết
              </Typography>
              <Divider />
              <ArrowRightAlt />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container mt={1}>
        <Grid item xs={12}>
          <BasicBars listTripDashboard={listTripDashboard} loadingTripDashboard={loadingTripDashboard} />
        </Grid>
      </Grid>
    </>
  );
};
