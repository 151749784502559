import { Card, FormControl, Grid, useMediaQuery } from '@mui/material';
import TitlePage from '@/components/TitlePage';
import { SelectSearch } from '@/common/Form';
import { useForm } from 'react-hook-form';
import { schemaRevenueStatistics } from '@/common/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { FROM_DATE, MONTH_YEAR, QUARTER, TRIP_CANCEL, TRIP_SUCCESS } from '@/shared/constant/text';
import { useEffect, useState } from 'react';
import ApexChart from '../ChartBasicCommon';
import TableComponent from '@/components/TableComponent';
import { STATICTIS_REVENUE, STATICTIS_TRIP } from '@/shared/constant/TableHead';
import { parseDataRevenueStatictis, parseDataTrip1Statictis } from '@/shared/constant/dataTable';
import { convertOptionsPhoneNumber, convertToYYYY, formatCurrency, formatDate } from '@/shared/utils/common';
import { CustomDateField } from '@/components/CustomDateField';
import { PAGE_SIZE } from '@/shared/constant/common';
import { saveAs } from 'file-saver';
import {
  getExportFileRevenue,
  getExportFileTripStatistic,
  getListChartTrip,
  getListChartRevenue,
  getListTableRevenue,
  getListTableTrip,
  getProvinceStatistic,
  statisticData,
} from '@/redux/slices/statictis';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentSystemList, paymentSystemData } from '@/redux/slices/paymentSystem';
import { priceSystemData } from '@/redux/slices/priceSystem';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';

export default function StatisticCommon({ isRevenue = true }) {
  const lang = window.lang;
  const currentDate = new Date();
  const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLarge = useMediaQuery('(min-width:1200px)');
  const dispatch = useDispatch<any>();
  const selectorData = useSelector(statisticData);
  const dataTableStatictis = isRevenue
    ? selectorData?.listTableRevenue?.data?.data
    : selectorData?.listTableTrip?.data?.data;
  const isLoading = isRevenue ? selectorData?.loadingListTableRevenue : selectorData?.loadingListTableTrip;
  const dataPayment = useSelector(paymentSystemData);
  const [queryFilter, setQueryfilter] = useState<any>({
    StartDate: formatDate(defaultStartDate),
    EndDate: formatDate(currentDate),
    DateType: FROM_DATE,
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaRevenueStatistics(lang)),
    defaultValues: {
      quarter: null,
      monthYear: null,
      address: '',
      payment: '0',
      endDay: currentDate,
      year: null,
      quarterYear: null,
      startDay: defaultStartDate,
      dateFilter: '1',
    },
  });

  const dateFilter = watch('dateFilter');

  const defaultParams = {
    Page: 1,
    Take: PAGE_SIZE,
    DateType: FROM_DATE,
    StartDate: formatDate(defaultStartDate),
    EndDate: formatDate(currentDate),
  };

  const callApiStatictis = (isRevenue, params, apiChart = true) => {
    if (isRevenue) {
      apiChart && dispatch(getListChartRevenue(params));
      dispatch(getListTableRevenue(params));
    } else {
      apiChart && dispatch(getListChartTrip(params));
      dispatch(getListTableTrip(params));
    }
  };

  useEffect(() => {
    dispatch(getProvinceStatistic());
    dispatch(getPaymentSystemList(''));
    callApiStatictis(isRevenue, defaultParams, true);
  }, []);

  const [date, setDate] = useState<any>({
    date1: defaultStartDate,
    date2: currentDate,
    type: dateFilter,
  });

  useEffect(() => {
    if (dateFilter !== '1') {
      setValue('year', null);
      setValue('monthYear', null);
      setValue('quarterYear', null);
      setValue('quarter', null);
      setValue('startDay', undefined);
      setValue('endDay', undefined);
    }
    clearErrors();
  }, [dateFilter]);

  const onSubmit = (data) => {
    const dataSubmit = {
      ProvinceId: data.address,
      DateType: data.dateFilter,
      Quarter: data.quarter,
      Year: convertToYYYY(data.year) || convertToYYYY(data.quarterYear),
      StartDate:
        data.dateFilter === '1'
          ? formatDate(data.startDay)
          : data.dateFilter === '2'
          ? formatDate(data.monthYear)
          : null,
      EndDate: data.dateFilter === '1' ? formatDate(data.endDay) : null,
      Page: 1,
      Take: PAGE_SIZE,
      ...(isRevenue && { PaymentId: data.payment === '0' ? null : data?.payment }),
    };
    setCurrentPage(1);
    setQueryfilter(dataSubmit);
    callApiStatictis(isRevenue, dataSubmit, true);

    setDate(
      data.dateFilter === FROM_DATE
        ? { date1: data.startDay, date2: data.endDay, type: data.dateFilter }
        : data.dateFilter === MONTH_YEAR
        ? { date1: data.monthYear, type: data.dateFilter }
        : data.dateFilter === QUARTER
        ? { date2: data.quarter, date1: data.quarterYear, type: data.dateFilter }
        : { date1: data.year, type: data.dateFilter },
    );
  };

  const handleResetFilter = () => {
    reset();
    setDate({
      date1: defaultStartDate,
      date2: currentDate,
      type: '1',
    });
    setCurrentPage(1);
    setQueryfilter({
      StartDate: formatDate(defaultStartDate),
      EndDate: formatDate(currentDate),
      DateType: FROM_DATE,
    });
    callApiStatictis(isRevenue, defaultParams, true);
  };

  const dataTable = isRevenue
    ? parseDataRevenueStatictis(dataTableStatictis, currentPage)
    : parseDataTrip1Statictis(dataTableStatictis, currentPage);

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      Page: page,
      Take: PAGE_SIZE,
    };
    callApiStatictis(isRevenue, data, false);
  };

  const handleDownFile = () => {
    if (queryFilter) {
      const filteredQueryFilter = {};
      Object.keys(queryFilter).forEach((key) => {
        if (queryFilter[key] !== null && queryFilter[key] !== undefined && queryFilter[key] !== '') {
          filteredQueryFilter[key] = queryFilter[key];
        }
      });
    }
    const payload = {
      params: queryFilter,
      callbackSuccess: (response) => {
        const contentDispositionHeader = response.headers['content-disposition'];
        let filename = isRevenue ? 'Revenue.xlsx' : 'TripStatistic.xlsx';

        if (contentDispositionHeader) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i;
          const filenameStarRegex = /filename\*=UTF-8''([^;\n]*)/i;
          let matches = contentDispositionHeader.match(filenameStarRegex);

          if (matches !== null) {
            filename = decodeURIComponent(matches[1]);
          } else {
            matches = contentDispositionHeader.match(filenameRegex);
            if (matches !== null) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }
        }
        const blob = new Blob([response.data]);
        saveAs(blob, filename);
        showSuccessToast('Xuất file thành công!');
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    isRevenue ? dispatch(getExportFileRevenue(payload)) : dispatch(getExportFileTripStatistic(payload));
  };

  const tableFooter = [
    {
      id: 'totalAdministractive',
      title: 'Tổng cộng',
      colSpan: 2,
      rowSpan: 1,
    },
    {
      id: 'actions',
      title: isRevenue
        ? formatCurrency(selectorData?.listTableRevenue?.data?.totalTrip, false)
        : formatCurrency(selectorData?.listTableTrip?.data?.totalTrip, false),
      colSpan: 1,
      rowSpan: 1,
      className: 'total-money',
    },
    {
      id: 'actions',
      title: isRevenue
        ? formatCurrency(selectorData?.listTableRevenue?.data?.totalComplete, false)
        : formatCurrency(selectorData?.listTableTrip?.data?.totalComplete, false),
      colSpan: 1,
      rowSpan: 1,
      className: 'total-money',
    },
    {
      id: 'actions',
      title: isRevenue
        ? formatCurrency(selectorData?.listTableRevenue?.data?.totalCancel, false)
        : formatCurrency(selectorData?.listTableTrip?.data?.totalCancel, false),
      colSpan: 1,
      rowSpan: 1,
      className: 'total-money',
    },
    {
      id: 'actions',
      title: isRevenue
        ? formatCurrency(selectorData?.listTableRevenue?.data?.totalRevenue, false)
        : formatCurrency(selectorData?.listTableTrip?.data?.totalTripCustomerCancel, false),
      colSpan: 1,
      rowSpan: 1,
      className: 'total-money',
    },
    {
      id: 'actions',
      title: isRevenue
        ? formatCurrency(selectorData?.listTableRevenue?.data?.totalCash, false)
        : formatCurrency(selectorData?.listTableTrip?.data?.totalTripDriverCancel, false),
      colSpan: 1,
      rowSpan: 1,
      className: 'total-money',
    },
    {
      id: 'actions',
      title: isRevenue
        ? formatCurrency(selectorData?.listTableRevenue?.data?.totalVNPay, false)
        : formatCurrency(selectorData?.listTableTrip?.data?.totalTripEmployeeCancel, false),
      colSpan: 1,
      rowSpan: 1,
      className: 'total-money',
    },
  ];

  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
          <TitlePage title={isRevenue ? 'THỐNG KÊ DOANH THU' : 'THỐNG KÊ HÀNH TRÌNH'} />
        </Grid>
      </Grid>
      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container gap={2} flexWrap={isLarge ? 'nowrap' : 'wrap'}>
          <Grid container xs={12} md={12} lg={isRevenue ? 7 : 3} gap={1} flexWrap={isMobile ? 'wrap' : 'nowrap'}>
            <Grid container xs={12} sm={12} md={12} gap={1} flexWrap="nowrap">
              <Grid item xs={isRevenue ? 6 : 12} sm={6} md={isRevenue ? 6 : 12}>
                <FormControl fullWidth>
                  <SelectSearch
                    label="Khu vực"
                    control={control}
                    name="address"
                    errors={errors}
                    options={selectorData?.listProvinceStatistic || []}
                    // isLoading={isLoading}
                  />
                </FormControl>
              </Grid>

              {isRevenue && (
                <Grid item xs={6} md={6}>
                  <FormControl fullWidth>
                    <SelectSearch
                      label="Hình thức thanh toán"
                      control={control}
                      name="payment"
                      errors={errors}
                      options={[
                        { value: '0', label: lang.labelInput.all },
                        ...convertOptionsPhoneNumber(dataPayment?.listPaymentSystem || []),
                      ]}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>

          <CustomDateField dateFilter={dateFilter} control={control} errors={errors} isAll={false} />
        </Grid>
        <ButtonSearch
          submit={handleSubmit(onSubmit)}
          resetSearch={handleResetFilter}
          handleDownFile={handleDownFile}
          add={null}
        />
      </Card>
      <Grid item xs={12} mt={1}>
        <strong>{isRevenue ? 'Biểu đồ thống kê doanh thu' : 'Biểu đồ thống kê hành trình'}</strong>
      </Grid>
      <Grid item xs={12} mt={1} display="flex" justifyContent="center">
        <ApexChart
          date={date}
          listChartRevenue={isRevenue ? selectorData?.listChartRevenue?.data : selectorData?.listChartTrip?.data}
          loadingChart={isRevenue ? selectorData?.loadingListChartRevenue : selectorData?.loadingListChartTrip}
          labelY={isRevenue ? 'Doanh thu (VNĐ)' : 'Tổng số hành trình (cuốc)'}
          labelX=""
          title1={isRevenue ? 'Tiền mặt' : 'Tổng số hành trình thành công'}
          title2={isRevenue ? 'VNPAY' : 'Tổng số hành trình trình đã hủy'}
          color1={isRevenue ? '#94640e' : TRIP_SUCCESS}
          color2={isRevenue ? '#0c468a' : TRIP_CANCEL}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <strong>Danh sách thống kê dữ liệu của các ngày</strong>
      </Grid>
      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={isRevenue ? STATICTIS_REVENUE : STATICTIS_TRIP}
          listData={dataTable}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={isLoading}
          totalRecord={dataTableStatictis?.length}
          totalItems={
            isRevenue ? selectorData?.listTableRevenue?.data?.totalCount : selectorData?.listTableTrip?.data?.totalCount
          }
          pagingCallback={(page) => handlePaging(page)}
          className="scroll-table"
          tableFooter={tableFooter}
        />
      </Grid>
    </>
  );
}
