import { Box, Grid, Typography } from '@mui/material';
import TitlePage from '@/components/TitlePage';
import TableComponent from '@/components/TableComponent';
import { PAYMENT_SETTING } from '@/shared/constant/TableHead';
import { ACTIONS, enumStatus } from '@/shared/constant/common';
import { useEffect, useState } from 'react';
import { getPaymentSystemList, paymentSystemData } from '@/redux/slices/paymentSystem';
import { useDispatch, useSelector } from 'react-redux';
import { numberPaging } from '@/shared/utils/common';
import { paymentStatus } from '@/shared/options';
import { ModalChangeStatus } from './ModalChangeStatus';
import { CustomBackdrop } from '@/components/CustomBackdrop';

export default function PaymentSetting() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [paymentId, setPaymentId] = useState<string>('');
  const [paymentItem, setPaymentItem] = useState<object>({});
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(paymentSystemData);
  const isLoading = dataSelector.loadingListPaymentSystem;
  const dataApi = dataSelector.listPaymentSystem;
  const lang = window.lang;

  useEffect(() => {
    dispatch(getPaymentSystemList(''));
  }, []);
  function parseSystemPaymentData(data, currentPage) {
    return data?.map((item, index) => ({
      stt: numberPaging(currentPage, index),
      id: item?.id,
      paymentName: <Typography textAlign="center" className='province-name'>{item.name}</Typography>,
      isTextEdit: 'Thay đổi trạng thái',
      isActive: item.isActive,
      status:
        item.isActive === true ? (
          <Box textAlign="center">{enumStatus(1)}</Box>
        ) : (
          <Box textAlign="center">{enumStatus(2)}</Box>
        ),
      actions: [ACTIONS.EDIT],
    }));
  }

  const dataTable = parseSystemPaymentData(dataApi, 1);

  const handleEditPayment = (id, item) => {
    setOpenModal(true);
    setPaymentItem(item);
    setPaymentId(id);
  };
  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
          <TitlePage title={lang.paymentSetting.pageLabel} />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={PAYMENT_SETTING}
          listData={dataTable}
          isLoading={isLoading}
          totalRecord={2}
          totalItems={2}
          onEdit={handleEditPayment}
          className="scroll-table"
        />
      </Grid>
      <CustomBackdrop open={dataSelector?.loadingEditPaymentSystem} />
      {openModal && (
        <ModalChangeStatus
          paymentId={paymentId}
          paymentItem={paymentItem}
          setOpen={setOpenModal}
          open={openModal}
          optionStatus={paymentStatus}
        />
      )}
    </>
  );
}
