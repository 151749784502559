import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '@/api';
import { PAYMENT_STATUS, PAYMENT_SYSTEM, PAYMENT_SYSTEM_DETAIL } from '@/shared/constant/constantUrl';

const initialState = {
  listPaymentSystem: [],
  loadingListPaymentSystem: false,
  paymentSystemDetail: [],
  loadingPaymentSystemDetail: false,
  loadingEditPaymentSystem: false,
};

export const getPaymentSystemList = createAsyncThunk('getPaymentSystemList', async (params: any) => {
  try {
    const response = await Api.get(PAYMENT_SYSTEM, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const putPaymentStatus = createAsyncThunk(
  'putPaymentStatus/editPaymentStatus',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(PAYMENT_STATUS(params.id, params.isActive), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const getPaymentSystemDetail = createAsyncThunk('getPaymentSystemDetail', async (id: string) => {
  try {
    const response = await Api.get(PAYMENT_SYSTEM_DETAIL(id));
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const paymentSystem = createSlice({
  name: 'paymentSystem',
  initialState,
  reducers: {
    clearPaymentSystemDetail: (state) => {
      state.paymentSystemDetail = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentSystemList.pending, (state) => {
        state.loadingListPaymentSystem = true;
      })
      .addCase(getPaymentSystemList.fulfilled, (state, action) => {
        state.loadingListPaymentSystem = false;
        state.listPaymentSystem = action.payload;
      })
      .addCase(getPaymentSystemList.rejected, (state) => {
        state.listPaymentSystem = [];
      })

      .addCase(getPaymentSystemDetail.pending, (state) => {
        state.loadingPaymentSystemDetail = true;
      })
      .addCase(getPaymentSystemDetail.fulfilled, (state, action) => {
        state.loadingPaymentSystemDetail = false;
        state.paymentSystemDetail = action.payload;
      })
      .addCase(getPaymentSystemDetail.rejected, (state) => {
        state.paymentSystemDetail = [];
      })
      //------------------------Edit--------------------------
      .addCase(putPaymentStatus.pending, (state) => {
        state.loadingEditPaymentSystem = true;
      })
      .addCase(putPaymentStatus.fulfilled, (state, action) => {
        state.loadingEditPaymentSystem = false;
      })
      .addCase(putPaymentStatus.rejected, (state) => {});
  },
});

export const { actions } = paymentSystem;
export const paymentSystemData = (state) => state.paymentSystem;
export default paymentSystem.reducer;
export const { clearPaymentSystemDetail } = paymentSystem.actions;
