import { MAX_DATE_DRIVER_YEAR, currency, locate } from '@/shared/config/setting';
import { CookiesStorage } from '../config/cookie';
import { CONST_ROUTER, DateRegExp, PAGE_SIZE } from '../constant/common';
import { format, addHours, compareAsc, formatDistanceToNow } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { showErrorToast } from '@/hooks/useCustomToast';
import { vi } from 'date-fns/locale';
import {
  ReceiveMessageBookADriver,
  ReceiveMessageWhenCustomerOrDriverCancelTrip,
  ReceiveMessageWhenDriverAccept,
  ReceiveMessageWhenDriverRefuses,
} from '../constant/text';
import audioBookADriver from '@/asset/audio/audioNewBookADriver1.mp3';
import imgDefault from '@/asset/img/imageDefault.png';

export const logout401 = () => {
  CookiesStorage.clearCookieData('token');
  localStorage.removeItem('messages');
  if (window.location.pathname !== CONST_ROUTER.LOGIN) {
    window.location.replace(CONST_ROUTER.LOGIN);
  }
};
const lang = window.lang;
export const handleApi500 = () => {
  showErrorToast('Lỗi server, vui lòng thử lại sau!');
};

export const executeAndAwaitAllFunctions = async (...functions) => {
  for (const func of functions) {
    if (typeof func === 'function') {
      await func?.();
    }
  }
};

export const handleError = (event) => {
  event.target.src = imgDefault;
};

export const formatCurrency = (amount, includeVND = true) => {
  if (amount === null || isNaN(amount)) {
    return '';
  }

  if (typeof amount !== 'number') {
    amount = parseFloat(String(amount).replace(/,/g, ''));
  }

  const formattedAmount = amount.toLocaleString('en-EN', {
    style: 'currency',
    currency: 'VND',
  });

  let result = formattedAmount.replace('₫', '');
  result = result.replace(/,/g, '.');

  if (!includeVND) {
    return result;
  }

  result += ' VNĐ';

  return result;
};

export const formatCurrencyStatic = (amount, includeVND = true) => {
  if (amount === null || isNaN(amount)) {
    return '';
  }
  if (typeof amount !== 'number') {
    amount = parseFloat(String(amount).replace(/,/g, ''));
  }
  const absoluteAmount = Math.abs(amount);
  const formattedAmount = absoluteAmount.toLocaleString('en-EN', {
    style: 'currency',
    currency: 'VND',
  });

  let result = formattedAmount.replace('₫', '');

  if (!includeVND) {
    return result;
  }

  result += ' VND';

  return result;
};

export const compareDateTime = (startDate: Date, endDate: Date) => {
  if (startDate && endDate) {
    if (startDate < endDate) {
      return true;
    } else if (startDate.getTime() === endDate.getTime()) {
      const startHour = startDate.getHours();
      const startMinute = startDate.getMinutes();
      const endHour = endDate.getHours();
      const endMinute = endDate.getMinutes();

      if (startHour < endHour || (startHour === endHour && startMinute < endMinute)) {
        return true;
      }
    }
  }
  return false;
};

export const checkNumberValidate = (value) => {
  const regex = /^[0-9]+$/;
  return regex.test(value);
};

export const formatDateTimeSell = (date) => {
  return date && format(new Date(date), 'yyyy-MM-dd HH:mm');
};

export const formatCurrencyPrice = (amount, includeVND = true) => {
  if (amount === null || isNaN(amount)) {
    return '';
  }

  if (typeof amount !== 'number') {
    amount = parseFloat(String(amount).replace(/,/g, ''));
  }

  const formattedAmount = amount.toLocaleString('en-EN', {
    style: 'currency',
    currency: 'VND',
  });

  let result = formattedAmount.replace('₫', '');

  if (!includeVND) {
    return result;
  }

  return result;
};

export const fromatDateTimeUTC = (date: any) => {
  if (!date) return '';
  const formattedDate = date.replace('+00:00', '+07:00');
  return formattedDate;
};

export const parseFormatDateTime = (date) => {
  if (!date) return '';
  const [datePart, timePart] = date.split('T');
  const time = timePart.split('+')[0];

  return `${datePart} ${time}`;
};

export const messageSpaceBr = (inputString) => {
  return inputString.replace(/<br\s*\/?>/gi, '\n');
};

export const currencyFormat = (string) =>
  string.toLocaleString(locate ?? 'vi', { style: 'currency', currency: currency ?? 'VND' });

export const convertToDateOrNull = (value) => {
  if (value instanceof Date || value === null) {
    return value;
  } else if (typeof value === 'string') {
    return new Date(value);
  }
  return null;
};

export const booleanToNumber = (value) => {
  return value ? 1 : 0;
};

export const generateOptions = (statusStore) => {
  if (!statusStore) {
    return undefined;
  }
  return Object.entries(statusStore).map(([label, value]) => ({
    value: typeof value === 'string' || typeof value === 'number' ? value.toString() : undefined,
    label,
  }));
};

export const formatDate = (date) => date && format(new Date(date.toLocaleDateString('en-US')), DateRegExp);

export const formatDateCustomer = (date) => date && format(new Date(date), 'dd/MM/yyyy');

export const convertOptions = (options) => {
  if (options?.length === 0) {
    return [];
  }
  options?.map((option) => ({
    value: option?.id,
    label: option?.value || option?.name,
    categoryId: option.categoryAttributeId,
  }));
};

export const convertOptionsProduct = (options) => {
  if (options?.length === 0) {
    return [];
  }
  options?.map((option) => ({
    value: option.id,
    label: option.value || option.name || option.driver,
  }));
};

export const formatDateTime = (date) => {
  if (date) {
    const utcTime = new Date(date);
    const formattedDate = format(addHours(utcTime, 7), 'dd/MM/yyyy');
    const formattedTime = format(addHours(utcTime, 7), 'HH:mm:ss');
    return `${formattedDate}
  ${formattedTime}`;
  }
};

export const formatDateTimeNotUTC = (date) => {
  if (date) {
    const formattedDate = format(new Date(date), 'dd/MM/yyyy');
    const formattedTime = format(new Date(date), 'HH:mm:ss');
    return `${formattedDate}\n${formattedTime}`;
  }
};

export const formatDateTimeNotTime = (date) => {
  if (date) {
    const formattedDate = format(new Date(date), 'dd/MM/yyyy');
    return `${formattedDate}`;
  }
};

export const readableTime = (date: string = ''): string => {
  if (!date) return '';

  const dateObj = new Date(date);
  const locale: any = vi;

  return formatDistanceToNow(dateObj, {
    locale,
    addSuffix: true,
    includeSeconds: true,
  });
};

export const convertToYYYYMM = (date) => {
  if (!date) return '';

  const selectedDate = new Date(date);
  const year = selectedDate.getFullYear();
  const month = String(selectedDate.getMonth() + 1).padStart(2, '0');

  return `${year}-${month}`;
};

export const convertToYYYY = (date) => {
  if (!date) return '';

  const selectedDate = new Date(date);
  const year = selectedDate.getFullYear();

  return `${year}`;
};

export const convertToMM = (date) => {
  if (!date) return '';

  const selectedDate = new Date(date);
  const month = selectedDate.getMonth() + 1;

  return `${month}`;
};

export const generateRandomCode = (message) => {
  const randomNumber = Math.floor(Math.random() * 90000000) + 10000000;
  const randomId = `${message}${randomNumber}`;
  return randomId;
};

export const useQueryParameters = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const allQueries = {};

  for (let query of searchParams.entries()) {
    const [key, value] = query;
    if (value) {
      allQueries[key] = value;
    }
  }

  return allQueries;
};

export const createNewPath = (pathname, queryObject) => {
  const searchParams = new URLSearchParams();

  for (const key in queryObject) {
    const value = queryObject[key];
    if (value) {
      searchParams.set(key, value);
    }
  }

  return `${pathname}?${searchParams.toString()}`;
};

export const buildQueryParams = (params) => {
  return Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
};

export const extractLastPathSegment = (url) => {
  const pathSegments = url.split('/');
  return pathSegments[pathSegments.length - 1];
};

export const reverseNumbering = (pageSize, totalItems, currentPage, index) => {
  const startItem = totalItems - (currentPage - 1) * pageSize;
  const stt = startItem - index;

  return stt;
};

export const numberPaging = (currentPage, index) => {
  const stt = (currentPage - 1) * PAGE_SIZE + index + 1;
  return stt;
};

export const parsedPrice = (data) => data.replace(/,/g, '');

export const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

export const convertOptionsSelect = (options) =>
  options?.map((option) => ({
    id: option?.id,
    label: option?.value || option?.name,
    type: option?.type,
    paymentImage: option?.paymentImage,
  }));

export const getCurrentTimestamp = () => {
  return Math.floor(Date.now() / 1000);
};

export const playAudio = () => {
  const audio = new Audio(audioBookADriver);
  audio.play().catch((error) => {});
};

export const compareDate = (startDate, endDate) => {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  const result = compareAsc(endDate, startDate);
  return endDate && result !== -1;
};

export const convertTimeStringToHHMM = (dateTimeString) => {
  const date = new Date(dateTimeString);

  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const convertTimeStringToDate = (timeString) => {
  const [hours, minutes] = timeString.split(':');

  const currentDate = new Date();
  currentDate.setHours(parseInt(hours, 10));
  currentDate.setMinutes(parseInt(minutes, 10));

  return currentDate;
};

export const convertToUTC7 = (localDate) => {
  const offsetUTC7 = 7 * 60; // UTC+7 offset in minutes
  const utc7Date = new Date(localDate.getTime() + offsetUTC7 * 60000);
  return utc7Date;
};

export const checkTimestamp = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  const { exp } = JSON.parse(jsonPayload);
  return exp;
};

export const generateDateString = (startDate, endDate) => {
  return (
    (startDate ? `${lang.placeholderInput.fromDay}: ${formatDate(startDate)}` : '') +
    (startDate && endDate ? ' - ' : '') +
    (endDate ? `${lang.placeholderInput.toDay}: ${formatDate(endDate)}` : '')
  );
};

export const objectPhoneNumber = (value) => {
  if (value) {
    const phoneNumbers = value.split(/[/,]/);
    const phoneObjects = phoneNumbers.map((phoneNumber, index) => ({
      phoneNumber: phoneNumber,
    }));
    return phoneObjects;
  }
  return null;
};

export const convertDateDob = (date) => {
  const dateObj = new Date(date);
  const nam = dateObj.getFullYear();
  const thang = dateObj.getMonth() + 1;
  const ngayTrongThang = dateObj.getDate();

  const dateReturn =
    nam + '-' + (thang < 10 ? '0' : '') + thang + '-' + (ngayTrongThang < 10 ? '0' : '') + ngayTrongThang;

  return dateReturn;
};

export const isBooleanArray = (array) => {
  return !!(array?.length > 0);
};

export const calculateTimeDifference = (startTime) => {
  const startDate: any = new Date(startTime);
  const currentDate: any = new Date();
  const timeDifference = currentDate - startDate;
  const totalSeconds = Math.floor(timeDifference / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const totalDays = Math.floor(totalHours / 24);
  const totalWeeks = Math.floor(totalDays / 7);
  const totalMonths = Math.floor(totalDays / 30.44);
  const totalYears = Math.floor(totalDays / 365.25);
  if (totalMinutes === -1 || totalMinutes === 0) {
    return 'Dưới 1 phút trước';
  } else if (totalMinutes < 60) {
    return `${totalMinutes} phút trước`;
  } else if (totalHours < 24) {
    return `${totalHours} giờ trước`;
  } else if (totalDays < 7) {
    return `${totalDays} ngày trước`;
  } else if (totalWeeks < 4.345) {
    return `${totalWeeks} tuần trước`;
  } else if (totalMonths < 12) {
    return `${totalMonths} tháng trước`;
  } else {
    return `${totalYears} năm trước`;
  }
};

export const maxYear = () => {
  const currentYear = new Date().getFullYear();
  const maxYear = MAX_DATE_DRIVER_YEAR && currentYear - +MAX_DATE_DRIVER_YEAR;
  return maxYear;
};

export const convertOptionsPhoneNumber = (options) => {
  return options?.length > 0
    ? options?.map((option) => ({
        value: option?.id,
        label: option?.value || option?.fullName || option?.driver || option?.name,
        phoneNumber: option?.phoneNumber,
      }))
    : [];
};

export const dataAddress = (data, key) => {
  const isArrayAddress = data?.map((item) => {
    if (item?.type === key) {
      return item?.address;
    }
    return null;
  });
  return isArrayAddress?.filter((item) => item !== null);
};

export const dataAddressObject = (data, key) => {
  const isArrayAddress = data?.map((item) => {
    if (item?.type === key) {
      return {
        id: item?.id,
        address: item?.address,
        location: JSON.stringify(item?.location),
      };
    }
    return null;
  });
  const returnMap = isArrayAddress?.filter((item) => item !== null);
  return returnMap;
};

export const getMaxIndexByType = (arr, type) => {
  if (arr?.length === 0) return;
  let maxIndex = -1;
  arr.forEach((item) => {
    if (item.type === type && item.index > maxIndex) {
      maxIndex = item.index;
    }
  });

  return maxIndex > 0 ? maxIndex : 0;
};

export const convertOptionsDriver = (options) =>
  options?.map((option) => ({
    value: option.id,
    label: option.value || option.fullName || option.driver,
    phoneNumber: option.phoneNumber,
  }));

export const objectTypeNotification = (value: string) => {
  switch (value) {
    case 'ReceiveMessageBookADriver':
      return ReceiveMessageBookADriver;
    case 'ReceiveMessageWhenDriverAccept':
      return ReceiveMessageWhenDriverAccept;
    case 'ReceiveMessageWhenDriverRefuses':
      return ReceiveMessageWhenDriverRefuses;
    case 'ReceiveMessageWhenCustomerOrDriverCancelTrip':
      return ReceiveMessageWhenCustomerOrDriverCancelTrip;
    default:
      0;
  }
};
