import {
  AddLocationAlt,
  BarChart,
  CarRental,
  Dashboard,
  EditNotifications,
  ManageAccounts,
  Payment,
  PeopleAlt,
  PriceChange,
  SettingsSuggest,
  StackedLineChart,
} from '@mui/icons-material';
import { CONST_ROUTER } from '@/shared/constant/common';
import { NavItem } from '@/interfaces';
import { ROLE_ADMIN, ROLE_EMPLOYEE } from '../config/setting';
import { BiTrip } from 'react-icons/bi';
import { TbPigMoney } from 'react-icons/tb';
import { ImUserTie } from 'react-icons/im';
import { FaStar } from 'react-icons/fa';
import { MdDiscount } from 'react-icons/md';

const allRole = [ROLE_ADMIN, ROLE_EMPLOYEE];
const adminRole = [ROLE_ADMIN];

export const navConfig = (lang: any): NavItem[] => [
  {
    title: 'Dashboard',
    path: CONST_ROUTER.DASH_BOARD_CHART,
    icon: Dashboard,
    role: allRole,
  },
  {
    title: 'Quản lý tài khoản',
    path: CONST_ROUTER.ACCOUNT,
    icon: ManageAccounts,
    role: adminRole,
  },
  {
    title: 'Tài xế',
    path: null,
    icon: ImUserTie,
    role: allRole,
    subItem: [
      {
        title: 'Quản lý tài xế',
        path: CONST_ROUTER.DRIVER_MANAGEMENT,
        iconSub: CarRental,
        role: allRole,
      },
      {
        title: 'Tài xế đang hoạt động',
        path: CONST_ROUTER.DRIVER_ACTIVE,
        iconSub: AddLocationAlt,
        role: allRole,
      },
    ],
  },
  {
    title: 'Quản lý khách hàng',
    path: CONST_ROUTER.MANAGEMENT_CUSTOMER,
    icon: PeopleAlt,
    role: allRole,
  },
  {
    title: 'Quản lý hành trình',
    path: CONST_ROUTER.TRIP_MANAGEMENT,
    icon: BiTrip,
    role: allRole,
  },
  {
    title: 'Thống kê',
    path: null,
    icon: BarChart,
    role: adminRole,
    subItem: [
      {
        title: 'Thống kê doanh thu',
        path: CONST_ROUTER.REVENUE,
        iconSub: TbPigMoney,
        role: adminRole,
      },
      {
        title: 'Thống kê hành trình',
        path: CONST_ROUTER.TRIP_STATISTICS,
        iconSub: StackedLineChart,
        role: adminRole,
      },
      {
        title: 'Thống kê đánh giá',
        path: CONST_ROUTER.EVALUATION_STATISTICS,
        iconSub: FaStar,
        role: adminRole,
      },
    ],
  },
  {
    title: 'Danh sách thông báo',
    path: CONST_ROUTER.NOTIFICATION,
    icon: EditNotifications,
    role: allRole,
  },
  {
    title: 'Khuyến mãi',
    path: CONST_ROUTER.OFFER,
    icon: MdDiscount,
    role: adminRole,
  },
  {
    title: 'Cấu hình hệ thống',
    path: null,
    icon: SettingsSuggest,
    role: adminRole,
    subItem: [
      {
        title: 'Cấu hình phí hành trình',
        path: CONST_ROUTER.SYSTEM_PRICE_SETTING,
        iconSub: PriceChange,
        role: adminRole,
      },
      {
        title: 'Cấu hình thanh toán',
        path: CONST_ROUTER.PAYMENT_SETTING,
        iconSub: Payment,
        role: adminRole,
      },
      {
        title: 'Cấu hình hạng xe',
        path: CONST_ROUTER.VEHICLE_CLASS,
        iconSub: CarRental,
        role: adminRole,
      },
    ],
  },
];
