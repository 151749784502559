import { useState } from 'react';
import { Grid, FormControl, FormHelperText, OutlinedInput, Card } from '@mui/material';
import { useForm } from 'react-hook-form';
import { schemaUpdatePassword } from '@/common/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { IconPassword } from '@/components/IconPassword';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceData, changePass } from '@/redux/slices/auth';
import { CustomBackdrop } from '@/components/CustomBackdrop';

export const UpdatePasswordProfile = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmed, setShowPasswordConfirmed] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const lang = window.lang;
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowPasswordConfirmed = () => setShowPasswordConfirmed((show) => !show);
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(authSliceData);
  const { loadingChangePass } = dataSelector;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaUpdatePassword),
    defaultValues: {
      new_password_confirmed: '',
      password: '',
      new_password: '',
    },
  });

  const onSubmit = (data) => {
    const params = {
      oldPassword: data.password,
      newPassword: data.new_password,
      confirmPassword: data.new_password_confirmed,
    };
    const payload = {
      params: params,
      callbackSuccess: () => {
        reset();
        showSuccessToast('Thay đổi mật khẩu thành công!');
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(changePass(payload));
  };

  const onCancel = () => {
    setShowPassword(false);
    setShowPasswordConfirmed(false);
    setShowNewPassword(false);
    reset();
  };

  return (
    <Card sx={{ p: 2, mt: 3 }}>
      <Grid container spacing={2} p={2}>
        <Grid item xs={7} display="flex">
          <Grid item xs={4}>
            <strong className="fz-14"> {lang.profile.oldPassword}</strong>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                size="small"
                {...register('password')}
                id="adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={<IconPassword onClick={handleClickShowPassword} showPassword={showPassword} />}
              />

              {errors.password && <FormHelperText error>{errors.password.message}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={7} display="flex">
          <Grid item xs={4}>
            <strong className="fz-14"> {lang.profile.newPassWord} </strong>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                size="small"
                {...register('new_password')}
                id="adornment-new-password"
                type={showNewPassword ? 'text' : 'password'}
                endAdornment={<IconPassword onClick={handleClickShowNewPassword} showPassword={showNewPassword} />}
              />

              {errors.new_password && <FormHelperText error>{errors.new_password.message}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={7} display="flex">
          <Grid item xs={4}>
            <strong className="fz-14"> {lang.profile.confirmPass} </strong>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                size="small"
                {...register('new_password_confirmed')}
                id="outlined-adornment-password"
                type={showPasswordConfirmed ? 'text' : 'password'}
                endAdornment={
                  <IconPassword onClick={handleClickShowPasswordConfirmed} showPassword={showPasswordConfirmed} />
                }
              />

              {errors.new_password_confirmed && (
                <FormHelperText error>{errors?.new_password_confirmed?.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={3}>
        <Grid item xs={12} display="flex" gap={3} justifyContent="flex-end">
          <ButtonCustom
            fullWidth={false}
            color="error"
            title={lang.button.actions.cancel}
            variant="contained"
            onClick={onCancel}
          />
          <ButtonCustom
            fullWidth={false}
            title={lang.button.actions.save}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!(watch('new_password_confirmed') || watch('password') || watch('new_password'))}
          />
        </Grid>
      </Grid>
      <CustomBackdrop open={loadingChangePass} />
    </Card>
  );
};
