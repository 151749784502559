import TableComponent from '@/components/TableComponent';
import TitlePage from '@/components/TitlePage';
import { CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { parseListOffer } from '@/shared/constant/dataTable';
import { Card, FormControl, Grid, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLS_OFFER } from '@/shared/constant/TableHead';
import { compareDateTime, formatDateTimeSell, useQueryParameters } from '@/shared/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { InputDateTime, InputForm } from '@/common/Form';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';
import { deleteOffer, getListOffer, offerData, updateStatusOffer } from '@/redux/slices/offer';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import ConfirmPopup from '@/components/ConfirmPopup';

export default function Offer() {
  const navigate = useNavigate();
  const queriesParams: any = useQueryParameters();
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const selectorOffer = useSelector(offerData);
  const dispatch = useDispatch<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [queryFilter, setQueryfilter] = useState(queriesParams);
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLarge = useMediaQuery('(min-width:1200px)');
  const { loadingListOffer, loadingDelete } = selectorOffer;
  const lang = window.lang;
  const schema = yup.object().shape({
    text: yup.string(),
    startDate: yup.date().nullable().typeError('Thời gian bắt đầu không hợp lệ'),
    endDate: yup
      .date()
      .nullable()
      .test('is-valid-end-date', 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu', function (value: Date | any) {
        const startDate = this.parent.startDate;
        return value ? compareDateTime(startDate, value) : true;
      })
      .typeError('Thời gian kết thúc không hợp lệ'),
  });
  const defaultParams = {
    PageIndex: 1,
    PageSize: PAGE_SIZE,
  };
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: null,
      endDate: null,
      text: '',
    },
  });

  useEffect(() => {
    dispatch(getListOffer(defaultParams));
  }, []);

  const onSubmit = (data) => {
    setCurrentPage(1);
    const dataSubmit = {
      DiscountName: data.text,
      PageIndex: 1,
      PageSize: PAGE_SIZE,
      StartDate: formatDateTimeSell(data?.startDate),
      EndDate: formatDateTimeSell(data?.endDate),
    };
    setQueryfilter(dataSubmit);
    dispatch(getListOffer(dataSubmit));
  };

  const handleResetFilter = () => {
    reset();
    setCurrentPage(1);
    setQueryfilter(defaultParams);
    dispatch(getListOffer(defaultParams));
  };

  const handleDelete = (id) => {
    const payload = {
      id,
      callbackSuccess: () => {
        showSuccessToast('Xóa khuyến mãi thành công');
        dispatch(
          getListOffer({
            ...queryFilter,
            PageIndex: currentPage,
            PageSize: PAGE_SIZE,
          }),
        );
        dispatch(updateStatusOffer());
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    openPopup(lang.offer.deleteOffer, () => dispatch(deleteOffer(payload)));
  };
  const offerDataTable = parseListOffer(
    selectorOffer?.listOffer?.data,
    selectorOffer?.listOffer?.totalCount,
    currentPage,
  );

  const handleAddAccount = () => {
    navigate(CONST_ROUTER.ADD_OFFER);
  };

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      PageIndex: page,
      PageSize: PAGE_SIZE,
    };
    dispatch(getListOffer(data));
  };

  const handleEdit = (id) => {
    navigate(CONST_ROUTER.DETAIL_OFFER(id));
  };
  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
          <TitlePage title="KHUYẾN MÃI" />
        </Grid>
      </Grid>
      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={6}>
            <InputForm name="text" control={control} placeholder="Nhập vào tên khuyến mãi" size="small" fullWidth />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <FormControl fullWidth>
              <InputDateTime
                control={control}
                name="startDate"
                label="Thời gian bắt đầu khuyến mãi"
                size="small"
                errors={errors}
                maxDate={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <FormControl fullWidth>
              <InputDateTime
                control={control}
                label="Thời gian kết thúc khuyến mãi"
                name="endDate"
                size="small"
                errors={errors}
                maxDate={false}
              />
            </FormControl>
          </Grid>
          {!isMobile && !isLarge && <Grid item sm={6}></Grid>}
        </Grid>
        <ButtonSearch
          submit={handleSubmit(onSubmit)}
          resetSearch={handleResetFilter}
          add={handleAddAccount}
          handleDownFile={null}
        />
      </Card>
      <CustomBackdrop open={loadingDelete} />
      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={COLS_OFFER}
          listData={offerDataTable}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecord={selectorOffer?.listOffer?.data?.length}
          totalItems={selectorOffer?.listOffer?.totalCount}
          pagingCallback={(page) => handlePaging(page)}
          onViewDetail={handleEdit}
          onDelete={(id) => handleDelete(id)}
          isLoading={loadingListOffer}
        />
        <ConfirmationPopup />
      </Grid>
    </>
  );
}
