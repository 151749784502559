import { TableCommonProps } from '@/interfaces/table';
import { Card, TableBody as MuiTableBody, Table, TableContainer } from '@mui/material';
import { useEffect } from 'react';
import EmptyPage from '../EmptyPage';
import LoadingPageV2 from '../LoadingPage/v2';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TableHeadCustom from './TableHeader';
import TablePagination from './TablePagination';

export default function TableComponent({
  tableHead,
  isLoading,
  listData,
  currentPage,
  totalItems,
  totalRecord,
  tableFooter,
  isHiddenRecord,
  isPageSize,
  setCurrentPage = () => {},
  pagingCallback,
  className,
  read,
  handleImage,
  isPaging = true,
  ...rest
}: TableCommonProps) {
  useEffect(() => {
    if (listData?.length === 0 && currentPage && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [listData, currentPage]);
  return (
    <Card sx={{ position: 'relative' }}>
      <TableContainer className={`${className} scroll-table`}>
        <Table stickyHeader>
          <TableHeadCustom cols={tableHead} />
          {listData?.length > 0 && !isLoading && (
            <MuiTableBody>
              <TableBody
                tableHead={tableHead}
                isLoading={isLoading}
                listData={listData}
                {...rest}
                handleImage={handleImage}
                read={read}
              />
            </MuiTableBody>
          )}
          {listData?.length > 0 && <TableFooter tableFooter={tableFooter} />}
        </Table>
      </TableContainer>
      {isLoading ? <LoadingPageV2 /> : <>{!(listData?.length > 0) && <EmptyPage />}</>}
      {isPaging && (
        <TablePagination
          currentPage={currentPage}
          totalItems={totalItems}
          setCurrentPage={setCurrentPage}
          totalRecord={totalRecord}
          isHiddenRecord={isHiddenRecord}
          pagingCallback={pagingCallback}
          isLoading={isLoading}
          isPageSize={isPageSize}
        />
      )}
    </Card>
  );
}
