import { LIST_OFFER, LIST_PROVINCES, DETAIL_OFFER, PUT_OFFER } from '@/shared/constant/constantUrl';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api';

const initialState = {
  loadingPostOffer: false,
  listOffer: [],
  loadingListOffer: false,
  loadingDelete: false,
  loadingOfferDetail: false,
  offerDetail: [],
  loadingPutOffer: false,
  loadingResetPass: false,
  listProvinces: [],
};

export const postOffer = createAsyncThunk('postOffer/post', async (payload: any) => {
  const { params, callbackSuccess, callbackError } = payload;
  try {
    const response = await Api.post(LIST_OFFER, params);
    callbackSuccess();
    return response?.data;
  } catch (error: any) {
    callbackError(error?.data?.message || error?.data?.Message);
    return error;
  }
});

export const deleteOffer = createAsyncThunk(
  'deleteOffer/delete',
  async ({ id, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.delete(DETAIL_OFFER(id));
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const getListOffer = createAsyncThunk('getListOffer', async (params: any) => {
  try {
    const response = await Api.get(LIST_OFFER, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getListProvinceId = createAsyncThunk('getListProvinceId', async () => {
  try {
    const response = await Api.get(LIST_PROVINCES);
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getOfferDetail = createAsyncThunk('getOfferDetail/get', async (id: string) => {
  try {
    const response = await Api.get(DETAIL_OFFER(id));
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const putOfferDetail = createAsyncThunk(
  'putOfferDetail/put',
  async ({ id, params, isChangeImage, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(PUT_OFFER(id, isChangeImage), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const offer = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    clearOfferDetail: (state) => {
      state.offerDetail = [];
    },
    updateStatusOffer: (state) => {
      state.loadingListOffer = false;
    },
    updateStatusDetailOffer: (state) => {
      state.loadingOfferDetail = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postOffer.pending, (state) => {
        state.loadingPostOffer = true;
      })
      .addCase(postOffer.fulfilled, (state, action) => {
        state.loadingPostOffer = false;
      })
      .addCase(postOffer.rejected, (state) => {
        state.loadingPostOffer = false;
      })

      .addCase(getListOffer.pending, (state) => {
        state.loadingListOffer = true;
      })
      .addCase(getListOffer.fulfilled, (state, action) => {
        state.loadingListOffer = false;
        state.listOffer = action.payload;
      })
      .addCase(getListOffer.rejected, (state) => {
        state.listOffer = [];
      })

      .addCase(getListProvinceId.pending, (state) => {})
      .addCase(getListProvinceId.fulfilled, (state, action) => {
        state.loadingListOffer = false;
        state.listProvinces = action.payload;
      })
      .addCase(getListProvinceId.rejected, (state) => {
        state.listProvinces = [];
      })

      .addCase(deleteOffer.pending, (state) => {
        state.loadingDelete = true;
      })
      .addCase(deleteOffer.fulfilled, (state, action) => {
        state.loadingDelete = false;
      })
      .addCase(deleteOffer.rejected, (state) => {})

      .addCase(getOfferDetail.pending, (state) => {
        state.loadingOfferDetail = true;
        state.offerDetail = [];
      })
      .addCase(getOfferDetail.fulfilled, (state, action) => {
        state.loadingOfferDetail = false;
        state.offerDetail = action.payload;
      })
      .addCase(getOfferDetail.rejected, (state) => {})

      .addCase(putOfferDetail.pending, (state) => {
        state.loadingPutOffer = true;
      })
      .addCase(putOfferDetail.fulfilled, (state, action) => {
        state.loadingPutOffer = false;
      })
      .addCase(putOfferDetail.rejected, (state) => {
        state.loadingPutOffer = false;
      });
  },
});

export const { actions } = offer;
export const offerData = (state) => state.offer;
export default offer.reducer;
export const { clearOfferDetail, updateStatusOffer, updateStatusDetailOffer } = offer.actions;
