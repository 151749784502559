import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';

const LoadingPage = () => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 525px)' }}>
      <CircularProgress />
    </Stack>
  );
};
export default LoadingPage;
