import { Grid } from '@mui/material';
import ButtonCustom from '../ButtonCustom';
import { BiSearch } from 'react-icons/bi';
import { BsFillEraserFill } from 'react-icons/bs';
import { AddCircle, ArrowCircleDown } from '@mui/icons-material';

export const ButtonSearch = ({ submit, resetSearch, add, handleDownFile }) => {
  const lang = window.lang;
  return (
    <Grid container spacing={2} mt={0.5} justifyContent="space-between">
      <Grid item sx={{ display: 'flex', gap: '1rem' }}>
        <ButtonCustom
          icon={<BiSearch />}
          color="success"
          title={lang.driverManagement.search}
          variant="contained"
          onClick={submit}
          fullWidth={false}
          sx={{ padding: '8px 16px', minWidth: '155px' }}
        />
        <ButtonCustom
          icon={<BsFillEraserFill />}
          color="error"
          title={lang.driverManagement.removeSearch}
          variant="contained"
          onClick={resetSearch}
          fullWidth={false}
          sx={{ padding: '8px 16px', minWidth: '155px' }}
        />
      </Grid>
      <Grid item sx={{ display: 'flex', gap: '1rem' }}>
        {add && (
          <ButtonCustom
            icon={<AddCircle />}
            color="primary"
            title={lang.driverManagement.addNew}
            variant="contained"
            onClick={add}
            fullWidth={false}
            sx={{ padding: '8px 16px' }}
          />
        )}
        {handleDownFile && (
          <ButtonCustom
            icon={<ArrowCircleDown />}
            color="primary"
            title={lang.driverManagement.dowFile}
            variant="contained"
            onClick={() => handleDownFile()}
            fullWidth={false}
            sx={{ padding: '8px 16px' }}
            disabled={handleDownFile === 'disabled' ? true : false}
          />
        )}
      </Grid>
    </Grid>
  );
};
