import { CustomImageProps } from '@/interfaces';
import { handleError } from '@/shared/utils/common';
import { Clear } from '@mui/icons-material';

function CustomImage({ src, alt, onDelete, onClick, className, isEdit, itemId, onClickImage }: CustomImageProps) {
  return (
    <div className="img-container">
      {!itemId && <Clear className="deleteButton" onClick={onDelete} />}
      {itemId && isEdit && <Clear className="deleteButton" onClick={onDelete} />}
      <img
        className={className || 'img-payment-order'}
        src={src}
        onError={handleError}
        alt={alt}
        onClick={onClickImage}
      />
    </div>
  );
}

export default CustomImage;
