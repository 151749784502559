import { Controller } from 'react-hook-form';
import { FormInputProps } from '@/interfaces/index';
import { DatePicker, DateView } from '@mui/x-date-pickers';
import { FormControl, FormHelperText } from '@mui/material';
import { addYears } from 'date-fns';
import { isChooseMaxDate } from '@/shared/constant/common';

export const InputDateMonthAndYear = ({
  control,
  label,
  name,
  size = 'small',
  errors,
  typeDate,
  maxDate = true,
  ...rest
}: FormInputProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl error={!!errors}>
            <DatePicker
              maxDate={maxDate ? new Date() : addYears(new Date(), isChooseMaxDate)} // maxDate : 2500 year
              label={label}
              {...field}
              {...rest}
              value={field.value}
              format={typeDate === 'year' ? 'yyyy' : typeDate === 'month' ? 'MM' : 'MM/yyyy'}
              slotProps={{ textField: { size: size, error: errors?.[name] } }}
              views={typeDate === 'year' ? ['year'] : typeDate === 'month' ? ['month'] : ['month', 'year']}
            />
            {errors?.[name] && <FormHelperText>{errors[name]?.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
};
