import { IOptions, RadioInputFormProps } from '@/interfaces/input';
import { Controller } from 'react-hook-form';
import { FormControl, Radio, RadioGroup, FormControlLabel, FormLabel, FormHelperText } from '@mui/material';

export const RadioInputForm = ({
  control,
  options,
  label,
  name,
  size = 'small',
  sizeLabel = 'small',
  disabled = false,
  errors,
  className,
  readOnly,
  required = false,
  ...rest
}: RadioInputFormProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl>
          <FormLabel sx={{ '&.Mui-focused': { color: '#000000' }, color: '#000000' }} disabled={disabled}>
            {label} {required && <span className="required">*</span>}
          </FormLabel>
          <RadioGroup row {...field} {...rest} className={className} onChange={readOnly ? undefined : field.onChange}>
            {Array.isArray(options) &&
              options.map((item: IOptions) => (
                <FormControlLabel
                  disabled={disabled}
                  key={item?.id || item?.value}
                  value={item?.id || item?.value}
                  control={
                    <Radio
                      disabled={disabled}
                      size={size}
                      checked={field.value?.toString() === (item?.id?.toString() || item?.value?.toString())}
                      onChange={readOnly ? undefined : (e) => field.onChange(e.target.value)}
                    />
                  }
                  label={<span className="fz-14">{item?.label || item?.paymentName || item?.name}</span>}
                />
              ))}
          </RadioGroup>
          {errors?.[name] && <FormHelperText error>{errors[name]?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
