import { Card, FormControl, Grid, useMediaQuery } from '@mui/material';
import { InputForm, InputFormDate, RadioInputForm, SelectSearch } from '@/common/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { genderSelect, optionsStatusCustomer } from '@/shared/options';
import { useParams } from 'react-router-dom';
import { ACTIONS } from '@/shared/constant/common';
import { schemaAddcustomer } from '@/common/Schema';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDetailCustomer,
  customerData,
  detailCustomer,
  editCustomer,
  getListVehicleCustomer,
} from '@/redux/slices/customer';
import { convertDateDob } from '@/shared/utils/common';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import LoadingPage from '@/components/LoadingPage';
import { HandleButtonAddEdit } from '@/components/HandleButtonAddEdit';
import { getProvinceList, priceSystemData } from '@/redux/slices/priceSystem';
import { DeleteOutline, Edit } from '@mui/icons-material';
import AccountAvatar from '@/components/Avatar/AccountAvatar';
import AvatarMenu from '@/components/Avatar/AvatarMenu';

export default function DetailCustomer() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const lang = window.lang;
  const Active = 0;
  const { itemId } = useParams();
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(customerData);
  const isLoading = dataSelector?.loadingDetailCustomer;
  const [file, setFile] = useState<File | any>();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string | null>(null);
  const dataAreaSelector = useSelector(priceSystemData);
  const dataApiProvince = dataAreaSelector?.listProvince;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const payload = {
      id: itemId,
      data: {
        customerId: itemId,
      },
    };
    if (itemId) {
      dispatch(getProvinceList(''));
      dispatch(detailCustomer({ customerId: itemId }));
      dispatch(getListVehicleCustomer(payload));
    }
    return () => {
      dispatch(clearDetailCustomer());
    };
  }, [itemId]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaAddcustomer(lang)),
    mode: 'all',
    defaultValues: {
      surnameAndMiddleName: '',
      lastName: '',
      phoneNumber: '',
      dob: undefined,
      status: 1,
      email: '',
      provinceId: '',
      gender: 1,
    },
  });
  const dataApi = dataSelector?.detailCustomer?.data;

  const checkStatusChange = (watchStatus, dataApiStatus) => {
    if (
      (watchStatus === '1970-01-01' && dataApiStatus === null) ||
      (dataApiStatus === null && watchStatus === '') ||
      (dataApiStatus === 0 && watchStatus === 2) ||
      (dataApiStatus === 0 && watchStatus === 4) ||
      (dataApiStatus === 0 && parseInt(watchStatus) === 2)
    ) {
      return true;
    }
    return watchStatus === dataApiStatus;
  };
  const checkStatusCustomer = (watchStatus, dataApiStatus) => {
    if ((dataApiStatus === null && watchStatus === '') || (dataApiStatus === 0 && watchStatus === 3)) {
      return true;
    }
    return watchStatus === dataApiStatus;
  };
  const isNewImage = (isDelete || !file) && !isDelete;

  const isNewData =
    checkStatusChange(watch('lastName'), dataApi?.lastName) &&
    checkStatusChange(watch('surnameAndMiddleName'), dataApi?.surnameAndMiddleName) &&
    checkStatusChange(watch('provinceId'), dataApi?.provinceId) &&
    checkStatusChange(watch('phoneNumber'), dataApi?.phoneNumber) &&
    checkStatusChange(convertDateDob(watch('dob')), convertDateDob(dataApi?.dob)) &&
    checkStatusChange(watch('email')?.toLowerCase(), dataApi?.email?.toLowerCase()) &&
    checkStatusChange(Number(watch('gender')), dataApi?.gender) &&
    checkStatusCustomer(watch('status'), dataApi?.status) &&
    isNewImage;
  useEffect(() => {
    if (dataApi && itemId) {
      setAvatar(dataApi?.avatarPath);
      setValue('surnameAndMiddleName', dataApi?.surnameAndMiddleName || '');
      setValue('provinceId', dataApi?.provinceId || '');
      setValue('lastName', dataApi?.lastName || '');
      setValue('phoneNumber', dataApi?.phoneNumber || '');
      setValue('dob', dataApi?.dob || '');
      setValue('status', dataApi?.status === Active ? 3 : dataApi?.status ? dataApi?.status : '');
      setValue('email', dataApi?.email || '');
      setValue('gender', dataApi?.gender === 0 ? 2 : dataApi?.gender || '');
    }
  }, [dataApi, itemId]);

  const handleEdit = () => {
    setIsEdit(true);
  };
  const handleCancel = () => {
    setValue('surnameAndMiddleName', dataApi?.surnameAndMiddleName || '');
    setValue('provinceId', dataApi?.provinceId || null);
    setValue('lastName', dataApi?.lastName || '');
    setValue('phoneNumber', dataApi?.phoneNumber);
    setValue('dob', dataApi?.dob);
    setValue('status', dataApi?.status === Active ? 3 : dataApi?.status);
    setValue('email', dataApi?.email);
    setValue('gender', dataApi?.gender === 0 ? 2 : dataApi?.gender);
    setIsEdit(false);
    setAvatar(dataApi?.avatarPath ? dataApi?.avatarPath : '');
    clearErrors();
  };

  const handleSave = (data) => {
    const params = {
      Id: itemId,
      UserId: dataApi?.userId,
      LastName: data.lastName,
      SurnameAndMiddleName: data.surnameAndMiddleName,
      ProvinceId: data.provinceId || null,
      PhoneNumber: data.phoneNumber,
      DOB: data.dob ? convertDateDob(data.dob) : '',
      Email: data.email.toLowerCase(),
      Gender: data.gender === 2 ? 0 : data.gender,
      Status: data.status === 3 ? 0 : data.status,
    };

    const formData = new FormData();

    file ? formData.append('FileAvatar', file) : !isDelete && formData.append('AvatarPath', dataApi?.avatarPath);

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        if (params[key] || params[key] === 0) {
          formData.append(key, params[key]);
        }
      }
    }

    const payload = {
      params: formData,
      callbackSuccess: () => {
        showSuccessToast(lang.managementCustomer.successEdit);
        reset();
        itemId && dispatch(detailCustomer({ customerId: itemId }));
        setIsEdit(false);
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(editCustomer(payload));
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handleDeleteImage() {
    setAvatar(null);
    setFile(null);
    setIsDelete(true);
  }

  const actions = [ACTIONS.EDIT, ACTIONS.DELETE];
  const actionInfo: any = [
    {
      action: ACTIONS.EDIT,
      label: avatar ? lang.button.actions.editImage : lang.button.actions.addImage,
      disabled: false,
      icon: <Edit sx={{ mr: 2 }} />,
      isUpload: true,
    },
    {
      action: ACTIONS.DELETE,
      label: lang.button.actions.deleteImage,
      disabled: !avatar,
      icon: <DeleteOutline sx={{ mr: 2 }} />,
      onClick: handleDeleteImage,
      sx: { color: 'error.main' },
    },
  ];

  return (
    <>
      {isLoading ? (
        <>
          <LoadingPage />
        </>
      ) : (
        <>
          <Card sx={{ p: 2 }}>
            <AccountAvatar
              avatar={avatar}
              isEdit={isEdit}
              handleMenuClick={handleMenuClick}
              setAvatar={setAvatar}
              setFile={setFile}
              setIsDelete={setIsDelete}
            />
            <AvatarMenu
              actions={actions}
              actionInfo={actionInfo}
              handleCloseMenu={handleCloseMenu}
              anchorEl={anchorEl}
            />
            <Grid container spacing={!isMobile ? 8 : 1}>
              <Grid item xs={12} sm={6} md={6} className="driver-form-data">
                <Grid className="driver-form-spacing">
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Họ và tên đệm:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="surnameAndMiddleName"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {isMobile && (
                    <Grid container mt={2}>
                      <Grid item xs={5} sm={5} md={3} mt={1}>
                        <strong className="fz-14">
                          Tên:<span className="required">*</span>
                        </strong>
                      </Grid>
                      <Grid item xs={7} sm={7} md={9}>
                        <FormControl fullWidth>
                          <InputForm
                            name="lastName"
                            control={control}
                            size="small"
                            fullWidth
                            errors={errors}
                            disabled={!!itemId && !isEdit}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">Ngày sinh:</strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputFormDate
                          name="dob"
                          control={control}
                          errors={errors}
                          maxDate={false}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Email:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="email"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">Nơi sống:</strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <SelectSearch
                          options={dataApiProvince || []}
                          name="provinceId"
                          control={control}
                          size="small"
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="driver-form-data">
                <Grid className="driver-form-spacing">
                  {!isMobile && (
                    <Grid container mt={2}>
                      <Grid item xs={5} sm={5} md={3} mt={1}>
                        <strong className="fz-14">
                          Tên:<span className="required">*</span>
                        </strong>
                      </Grid>
                      <Grid item xs={7} sm={7} md={9}>
                        <FormControl fullWidth>
                          <InputForm
                            name="lastName"
                            control={control}
                            size="small"
                            fullWidth
                            errors={errors}
                            disabled={!!itemId && !isEdit}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Giới tính:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <RadioInputForm
                          options={genderSelect}
                          name="gender"
                          control={control}
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Số điện thoại:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="phoneNumber"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Trạng thái:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <SelectSearch
                          options={optionsStatusCustomer || []}
                          name="status"
                          control={control}
                          size="small"
                          errors={errors}
                          disabled={!!itemId && !isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <HandleButtonAddEdit
            itemId={itemId}
            isEdit={isEdit}
            handleEdit={handleEdit}
            handleCancel={handleCancel}
            handleSave={handleSubmit(handleSave)}
            onCancel={handleCancel}
            isNewData={!!isNewData}
          />
          <CustomBackdrop open={dataSelector?.loadingEditCustomer} />
        </>
      )}
    </>
  );
}
