import { Card, FormControl, Grid } from '@mui/material';
import { InputForm, InputFormDate, SelectSearch } from '@/common/Form';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TableComponent from '@/components/TableComponent';
import { MANAGEMENT_CUSTOMER_HISTORY } from '@/shared/constant/TableHead';
import { useEffect, useState } from 'react';
import { parseDataHistoryCustomer } from '@/shared/constant/dataTable';
import { NavigateOptions, useNavigate, useParams } from 'react-router-dom';
import { CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { compareDate, convertOptionsPhoneNumber, createNewPath, formatDate } from '@/shared/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { customerData, historyTripCustomer } from '@/redux/slices/customer';
import { driverData, getDropdownDriver } from '@/redux/slices/driver';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';

export default function HistoryDriver({ valueTab, setValueTab }: any) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const lang = window.lang;
  const dataSelector = useSelector(customerData);
  const { itemId } = useParams();
  const [queryFilter, setQueryfilter] = useState<any>();
  const router = useNavigate();

  const navigateToTrip = (id: string) => {
    const params = {
      isRouter: 'customer',
      valueTab: valueTab,
      id: itemId,
    };
    const newPath = createNewPath(CONST_ROUTER.TRIP_DETAIL1_ITEM(id), params);
    router(newPath);
  };

  const defaultValues = {
    PageSize: PAGE_SIZE,
    PageIndex: 1,
    CustomerId: itemId,
    startPoint: '',
    endPoint: '',
    StartDate: null,
    EndDate: null,
  };

  const dispatch = useDispatch<any>();
  const dataDriver = useSelector(driverData);

  const schema = yup.object().shape({
    text: yup.string().nullable(),
    startPoint: yup.string().nullable(),
    endPoint: yup.string().nullable(),
    StartDate: yup
      .date()
      .nullable()
      .min(new Date(2000, 0, 1), lang.schema.order.maxStartDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .test('dateFilter', lang.schema.day.startDayRequired, function (value) {
        const { parent } = this;
        return parent?.EndDate ? !!value : true;
      })
      .typeError(lang.schema.order.dateError),
    EndDate: yup
      .date()
      .nullable()
      .min(new Date(2000, 0, 1), lang.schema.order.maxEndDate)
      .max(new Date(), lang.schema.order.dateFeature)
      .test('endDate', lang.schema.day.endDayMoreStartDay, function (value: Date | any) {
        return value !== null ? compareDate(new Date(this.parent.startDate), new Date(value)) : true;
      })
      .test('dateFilter', lang.schema.day.endDayRequired, function (value) {
        const { parent } = this;
        return parent?.StartDate ? !!value : true;
      })
      .typeError(lang.managementCustomer.dateValid),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      text: '',
      startPoint: '',
      endPoint: '',
      StartDate: null,
      EndDate: null,
    },
  });

  useEffect(() => {
    dispatch(getDropdownDriver());
    dispatch(historyTripCustomer(defaultValues));
  }, []);

  const onSubmit = (data) => {
    setCurrentPage(1);
    const dataSubmit = {
      CustomerId: itemId,
      DriverId: data.text,
      PageSize: PAGE_SIZE,
      PageIndex: 1,
      DateType: data.StartDate || data.EndDate ? 1 : null,
      StartDate: formatDate(data.StartDate),
      EndDate: formatDate(data.EndDate),
      StartPoint: data.startPoint,
      EndPoint: data.endPoint,
    };
    setQueryfilter(dataSubmit);
    dispatch(historyTripCustomer(dataSubmit));
  };

  const handleResetFilter = () => {
    reset();
    setValue('text', '');
    setValue('startPoint', '');
    setValue('endPoint', '');
    setValue('StartDate', null);
    setValue('EndDate', null);
    setCurrentPage(1);
    setQueryfilter(null);
    dispatch(historyTripCustomer({ PageSize: PAGE_SIZE, PageIndex: 1, CustomerId: itemId }));
  };

  const dataTable = parseDataHistoryCustomer(
    dataSelector?.listHistoryTrip?.data,
    dataSelector?.listHistoryTrip?.totalCount,
    currentPage,
    navigateToTrip,
  );

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      CustomerId: itemId,
      PageIndex: page,
      PageSize: PAGE_SIZE,
    };
    dispatch(historyTripCustomer(data));
  };

  return (
    <>
      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <SelectSearch
              name="text"
              control={control}
              placeholder={lang.managementCustomer.nameDriver}
              size="small"
              options={convertOptionsPhoneNumber(dataDriver?.listDropdown) || []}
            />
          </Grid>

          <Grid item xs={6} md={3} lg={2.5}>
            <InputForm
              name="startPoint"
              control={control}
              placeholder={lang.managementCustomer.point1}
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} md={3} lg={2.5}>
            <InputForm
              name="endPoint"
              control={control}
              placeholder={lang.managementCustomer.point2}
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} sm={3} md={3} lg={2}>
            <FormControl fullWidth>
              <InputFormDate
                control={control}
                name="StartDate"
                label={lang.placeholderInput.fromDay}
                size="small"
                errors={errors}
                maxDate={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={2}>
            <FormControl fullWidth>
              <InputFormDate
                control={control}
                label={lang.placeholderInput.toDay}
                name="EndDate"
                size="small"
                errors={errors}
                maxDate={false}
              />
            </FormControl>
          </Grid>
        </Grid>
        <ButtonSearch
          submit={handleSubmit(onSubmit)}
          resetSearch={handleResetFilter}
          handleDownFile={null}
          add={null}
        />
      </Card>

      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={MANAGEMENT_CUSTOMER_HISTORY}
          listData={dataTable}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecord={dataSelector?.listHistoryTrip?.data?.length}
          totalItems={dataSelector?.listHistoryTrip?.totalCount}
          isLoading={dataSelector?.loadingListHistoryTrip}
          pagingCallback={(page) => handlePaging(page)}
          className="scroll-table"
        />
      </Grid>
    </>
  );
}
