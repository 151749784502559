import { SyntheticEvent, useState, ReactNode, useEffect } from 'react';
import { Box, Typography, Tab, Tabs } from '@mui/material';
import { CONST_ROUTER, TabsDriverDetailLabel } from '@/shared/constant/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackPage } from '@/components/BackPage';
import DriverDetail from './DriverDetail';
import TripsHistory from './TripsHistory';
import { useQueryParameters } from '@/shared/utils/common';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
  };
}

export default function TabsDriverDetail() {
  const queriesParams: any = useQueryParameters();
  const { valueTab } = queriesParams;
  const [value, setValue] = useState<number>(0);
  const tabData = [{ component: <DriverDetail /> }, { component: <TripsHistory valueTab={value} /> }];
  const navigate = useNavigate();
  const lang = window.lang;
  const { pathname } = useLocation();
  const router = useNavigate();

  useEffect(() => {
    if (valueTab) {
      setValue(+valueTab);
      router(pathname);
    }
  }, [queriesParams]);

  const handleBackPage = () => {
    navigate(CONST_ROUTER.DRIVER_MANAGEMENT);
  };
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <BackPage title={lang.driverManagement.driverDetail} handleBackPage={handleBackPage} marginbottom={0} />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            {TabsDriverDetailLabel.map((tab, index) => (
              <Tab className="fz-14" key={index} label={tab.label} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {tabData.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </Box>
    </>
  );
}
