import { TableRow, TableCell, TableHead } from '@mui/material';
import { TableHeadCustomProps } from '@/interfaces/index';
import _ from 'lodash';
import { BORDER_HEADER, BORDER_RIGHT } from '@/shared/constant/common';

export default function TableHeadCustom({ cols, align = 'left' }: TableHeadCustomProps) {
  const colSticky = ['actions', 'orderStatus', 'priceFix', 'coveAction', 'viewDetailRenueve'];
  const stt = ['stt'];

  return (
    <TableHead>
      <TableRow className="custom-subheading">
        {_.isArray(cols) &&
          _.map(cols, (item, index) => {
            return (
              <TableCell
                key={item.id}
                align={align}
                sx={{
                  fontWeight: 'bold',
                  verticalAlign: 'baseline',
                  minWidth: item.minWidth || undefined,
                  maxWidth: item.maxWidth || undefined,
                  borderRight: BORDER_RIGHT,
                  borderBottom: BORDER_HEADER,
                }}
                className={`${
                  colSticky.includes(item.id)
                    ? 'fixed-col-table'
                    : stt.includes(item.id)
                    ? 'border-left'
                    : 'tab-header-width'
                }  tab-header  ${item?.className || ''}`}
              >
                {item.label}
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
}
