import { InputForm, SelectSearch } from '@/common/Form';
import * as yup from 'yup';
import ConfirmPopup from '@/components/ConfirmPopup';
import TableComponent from '@/components/TableComponent';
import TitlePage from '@/components/TitlePage';
import { CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { parseDriverData } from '@/shared/constant/dataTable';
import { driverStatus } from '@/shared/options';
import { Card, FormControl, Grid, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { COLS_DRIVER } from '@/shared/constant/TableHead';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDriver,
  driverData,
  getDriver,
  getExportFileDriver,
  putDriverBlock,
  putUnBlockDriver,
  updateStatusDriver,
} from '@/redux/slices/driver';
import { createNewPath, useQueryParameters } from '@/shared/utils/common';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { regFileName } from '@/shared/utils/regex';
import { saveAs } from 'file-saver';
import { useRole } from '@/hooks/useRole';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';
import ModalResetDriverPassword from './ModalResetDriverPassword';

export default function DriverManagement() {
  const navigate = useNavigate();
  const { pathname } = location;
  const queriesParams: any = useQueryParameters();
  const dispatch = useDispatch<any>();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [queryFilter, setQueryfilter] = useState(queriesParams);
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const lang = window.lang;
  const { role } = useRole();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>('');
  const [userId, setUserId] = useState<string>('');

  const defaultValue = {
    page: 1,
    take: PAGE_SIZE,
    Text: queriesParams?.text || '',
    Status: +queriesParams?.status || null,
  };
  useEffect(() => {
    dispatch(getDriver(defaultValue));
  }, []);

  const dataSelector = useSelector(driverData);
  const {
    listDriver,
    loadingListDriver,
    loadingDeleteDriver,
    loadingPutBlockDriver,
    loadingUnblock,
    loadingDowFileDriver,
  } = dataSelector;

  const driverDataFormated = parseDriverData(listDriver?.data, currentPage, listDriver?.totalCount, role);

  const schema = yup.object().shape({
    text: yup.string().nullable(),
    status: yup.number().nullable(),
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      text: queriesParams?.text || '',
      status: +queriesParams?.status || null,
    },
  });

  const onSubmit = (data) => {
    setCurrentPage(1);
    const dataSubmit = {
      text: data.text,
      status: data.status || '',
      page: 1,
      take: PAGE_SIZE,
    };
    setQueryfilter(dataSubmit);
    dispatch(getDriver(dataSubmit));
    const newPath = createNewPath(pathname, dataSubmit);
    navigate(newPath);
  };

  const handleResetFilter = () => {
    setValue('text', '');
    setValue('status', null);
    setCurrentPage(1);
    setQueryfilter({
      page: 1,
      take: PAGE_SIZE,
      Text: '',
      Status: '',
    });
    dispatch(getDriver({ page: 1, take: PAGE_SIZE, Text: '', Status: '' }));
    navigate(pathname);
  };

  const handleAddDriver = () => {
    navigate(CONST_ROUTER.ADD_DRIVER);
  };

  const handleViewDetail = (id) => {
    navigate(CONST_ROUTER.DRIVER_DETAIL_ITEM(id));
  };

  const handleReject = (id) => {
    const confirmReject = () => {
      const payload = {
        params: {
          driverId: id,
        },
        callbackSuccess: () => {
          showSuccessToast(lang.driverManagement.successBlock);
          dispatch(getDriver({ ...defaultValue, page: currentPage }));
          dispatch(updateStatusDriver());
        },
        callbackError: (isError) => {
          showErrorToast(isError);
        },
      };
      dispatch(putDriverBlock(payload));
    };
    openPopup(lang.driverManagement.modalText.block, confirmReject);
  };

  const handleUnBlock = (id) => {
    const confirmUnBlock = () => {
      const payload = {
        params: {
          driverId: id,
        },
        callbackSuccess: () => {
          showSuccessToast(lang.driverManagement.unBlockSuccess);
          dispatch(getDriver({ ...defaultValue, page: currentPage }));
          dispatch(updateStatusDriver());
        },
        callbackError: (isError) => {
          showErrorToast(isError);
        },
      };
      dispatch(putUnBlockDriver(payload));
    };
    openPopup(lang.driverManagement.unBlockPopup, confirmUnBlock);
  };

  const handleDelete = (id) => {
    const confirmDelete = () => {
      const payload = {
        params: {
          id: id,
        },
        callbackSuccess: () => {
          showSuccessToast(lang.driverManagement.successDelete);
          dispatch(getDriver({ ...defaultValue, page: currentPage }));
          dispatch(updateStatusDriver());
        },
        callbackError: (isError) => {
          showErrorToast(isError);
        },
      };
      dispatch(deleteDriver(payload));
    };
    openPopup(lang.driverManagement.modalText.delete, confirmDelete);
  };
  const handleResetPassword = (id, userId) => {
    setOpenModal(true);
    setItemId(id);
    setUserId(userId);
  };
  const handleDownFile = () => {
    if (queryFilter) {
      const filteredQueryFilter = {};
      Object.keys(queryFilter).forEach((key) => {
        if (queryFilter[key] !== null && queryFilter[key] !== undefined && queryFilter[key] !== '') {
          filteredQueryFilter[key] = queryFilter[key];
        }
      });
    }
    const payload = {
      params: queryFilter,
      callbackSuccess: (response) => {
        const contentDispositionHeader = response.headers['content-disposition'];
        let filename = 'Trip.xlsx';

        if (contentDispositionHeader) {
          const matches = contentDispositionHeader.match(regFileName);
          if (matches && matches.length > 1) {
            filename = matches[1];
          }
        }
        const blob = new Blob([response.data]);
        saveAs(blob, filename);
        showSuccessToast('Xuất file thành công!');
      },
      callbackError: (isError) => {
        showErrorToast(isError || 'Xuất file không thành công, vui lòng thử lại sau');
      },
    };
    dispatch(getExportFileDriver(payload));
  };

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      page: page,
      take: PAGE_SIZE,
    };
    dispatch(getDriver(data));
  };

  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
          <TitlePage title={lang.driverManagement.pageTitle} />
        </Grid>
      </Grid>
      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} md={5} lg={4}>
            <InputForm
              name="text"
              control={control}
              size="small"
              placeholder={lang.driverManagement.placeHolder}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <FormControl fullWidth>
              <SelectSearch
                name="status"
                label={lang.driverManagement.status}
                options={driverStatus}
                control={control}
                size="small"
                errors={errors}
              />
            </FormControl>
          </Grid>
        </Grid>
        <ButtonSearch
          handleDownFile={handleDownFile}
          submit={handleSubmit(onSubmit)}
          resetSearch={handleResetFilter}
          add={handleAddDriver}
        />
      </Card>
      <Grid item xs={12} mt={1}>
        <TableComponent
          onDelete={handleDelete}
          onReject={handleReject}
          onUnBlock={handleUnBlock}
          onViewDetail={handleViewDetail}
          onResetPassword={handleResetPassword}
          tableHead={COLS_DRIVER}
          listData={driverDataFormated}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loadingListDriver}
          totalRecord={listDriver?.data?.length}
          totalItems={listDriver?.totalCount}
          pagingCallback={(page) => handlePaging(page)}
        />
      </Grid>
      <ConfirmationPopup />
      <CustomBackdrop open={loadingDeleteDriver || loadingPutBlockDriver || loadingUnblock || loadingDowFileDriver} />
      {openModal && <ModalResetDriverPassword id={itemId} open={openModal} setOpen={setOpenModal} userId={userId} />}
    </>
  );
}
