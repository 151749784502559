import TableComponent from '@/components/TableComponent';
import TitlePage from '@/components/TitlePage';
import { CONST_ROUTER, PAGE_SIZE_30, typeObjectNotificationList } from '@/shared/constant/common';
import { parseDataNotification } from '@/shared/constant/dataTable';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COLS_NOTIFICATION } from '@/shared/constant/TableHead';
import { createNewPath, useQueryParameters } from '@/shared/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import {
  getNotification,
  getNotification1,
  getUnredTotalNotification,
  readNotification,
  notificationData,
  updateStatusNotification,
  readAllNotification,
} from '@/redux/slices/notification';
import { CheckBoxInputForm } from '@/common/Form/inputCheckbox';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PopupConfirm from './PopupConfirm';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';

export default function Notification() {
  const navigate = useNavigate();
  const queriesParams: any = useQueryParameters();
  const dispatch = useDispatch<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [queryFilter, setQueryfilter] = useState(queriesParams);
  const router = useNavigate();
  const { page } = queriesParams;
  const { pathname } = useLocation();
  const dataSelector = useSelector(notificationData);
  const lang = window.lang;

  const schema = yup.object().shape({
    readAllNotification: yup.boolean(),
  });

  const {
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      readAllNotification: false,
    },
  });

  const defaultValue = {
    pageIndex: 1,
    pageSize: PAGE_SIZE_30,
  };

  useEffect(() => {
    if (page) {
      setCurrentPage(+page);
      dispatch(getNotification({ ...defaultValue, pageIndex: page }));
      router(pathname);
    } else dispatch(getNotification(defaultValue));
  }, []);

  const handleNavigate = (text) => {
    navigate(text);
  };

  const handleParseData = (item, data) => {
    let parseData;
    try {
      parseData = JSON.parse(data);
    } catch (error) {
      return null;
    }

    const message = typeObjectNotificationList(parseData, item?.type);

    return message;
  };

  const handleParseDataIdTrip = (data) => {
    return JSON.parse(data);
  };

  const navigateToTrip = (id: string) => {
    const params = {
      isRouter: 'notification',
      page: currentPage,
    };
    const newPath = createNewPath(CONST_ROUTER.TRIP_DETAIL1_ITEM(id), params);
    router(newPath);
  };

  const read = (item) => {
    const payload = {
      id: item?.id,
      data: {
        notificationId: item?.id,
      },
      callbackSuccess: () => {
        dispatch(getNotification1(defaultValue));
        dispatch(updateStatusNotification());
        dispatch(getUnredTotalNotification());
      },
    };
    !item?.isRead && dispatch(readNotification(payload));
    const tripData = handleParseDataIdTrip(item?.data);
    navigateToTrip(tripData?.id);
  };
  const driverDataFormated = parseDataNotification(
    dataSelector?.listNotification?.data,
    dataSelector?.listNotification?.totalCount,
    handleParseData,
    handleParseDataIdTrip,
    currentPage,
    read,
  );

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      pageIndex: page,
      pageSize: PAGE_SIZE_30,
    };
    dispatch(getNotification(data));
  };

  const handleClose = () => {
    setValue('readAllNotification', false);
  };

  const handleConfirm = () => {
    const payload = {
      callbackSuccess: () => {
        setValue('readAllNotification', false);
        dispatch(getNotification1(defaultValue));
        dispatch(getNotification(defaultValue));
        dispatch(getUnredTotalNotification());
        showSuccessToast(lang.notification.readSuccess);
      },
      callbackError: (error) => {
        showErrorToast(error);
      },
    };
    dispatch(readAllNotification(payload));
  };

  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
        <TitlePage title={lang.notification.pageTitle} />
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <CheckBoxInputForm
          label={lang.notification.markAllAsRead}
          control={control}
          name="readAllNotification"
          color="black"
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={COLS_NOTIFICATION}
          listData={driverDataFormated}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecord={dataSelector?.listNotification?.data?.length}
          totalItems={dataSelector?.listNotification?.totalCount}
          pagingCallback={(page) => handlePaging(page)}
          isPageSize={PAGE_SIZE_30}
          read={(id) => read(id)}
          isLoading={dataSelector?.loadingListNotification}
        />
      </Grid>
      {!!watch('readAllNotification') && (
        <PopupConfirm open={!!watch('readAllNotification')} handleConfirm={handleConfirm} closePopup={handleClose} />
      )}
    </>
  );
}
