import React from 'react';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '@/interfaces/index';
import { DateTimePicker, DigitalClock } from '@mui/x-date-pickers';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { addYears } from 'date-fns';
import { dayOfWeekFormatter, isChooseMaxDate, viewDate } from '@/shared/constant/common';

export const InputDateTime = ({
  control,
  label,
  name,
  size = 'small',
  errors,
  maxDate = true,
  ...rest
}: FormInputProps) => {
  const desktopMediaQuery = '(max-width: 1800px)';
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl error={!!errors}>
          <DateTimePicker
            maxDate={maxDate ? new Date() : addYears(new Date(), isChooseMaxDate)} // maxDate : 2500 year
            label={label}
            desktopModeMediaQuery={desktopMediaQuery}
            {...field}
            {...rest}
            value={field.value ? new Date(field.value) : null}
            onChange={(date) => field.onChange(date)}
            slotProps={{
              textField: {
                size: size,
                className: 'centeredTextField',
              },
            }}
            dayOfWeekFormatter={dayOfWeekFormatter}
            views={viewDate}
          />
          {errors?.[name] && <FormHelperText>{errors[name]?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
