import {
  CHART_REVENUE,
  CHART_TRIP,
  DOWNFILE_REVENUE,
  DOWNFILE_TRIP,
  GET_LIST_EVALUATION,
  GET_TOTAL_EVALUATION,
  PROVINCE_STATISTIC,
  TABLE_REVENUE,
  TABLE_TRIP,
} from '@/shared/constant/constantUrl';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api';

const initialState = {
  listChartRevenue: [],
  loadingListChartRevenue: false,
  listTableRevenue: [],
  loadingListTableRevenue: false,
  listChartTrip: [],
  loadingListChartTrip: false,
  listTableTrip: [],
  loadingListTableTrip: false,
  listProvinceStatistic: [],
  loadingListProvinceStatistic: false,
  listEvaluation: [],
  loadingListEvaluation: false,
  totalEvaluation: [],
  loadingTotalEvaluation: false,
};

export const getListChartRevenue = createAsyncThunk('getListChartRevenue', async (params: any) => {
  try {
    const response = await Api.get(CHART_REVENUE, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getListTableRevenue = createAsyncThunk('getListTableRevenue', async (params: any) => {
  try {
    const response = await Api.get(TABLE_REVENUE, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getListChartTrip = createAsyncThunk('getListChartTrip', async (params: any) => {
  try {
    const response = await Api.get(CHART_TRIP, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getProvinceStatistic = createAsyncThunk('getProvinceStatistic', async () => {
  try {
    const response = await Api.get(PROVINCE_STATISTIC);
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getListTableTrip = createAsyncThunk('getListTableTrip', async (params: any) => {
  try {
    const response = await Api.get(TABLE_TRIP, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getTotalEvaluation = createAsyncThunk('getTotalEvaluation', async (params: any) => {
  try {
    const response = await Api.get(GET_TOTAL_EVALUATION, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getListEvaluation = createAsyncThunk('getListEvaluation', async (params: any) => {
  try {
    const response = await Api.get(GET_LIST_EVALUATION, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getExportFileRevenue = createAsyncThunk(
  'getExportFileRevenue',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.get(DOWNFILE_REVENUE, { params, responseType: 'arraybuffer' });
      callbackSuccess?.(response);
      return response.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const getExportFileTripStatistic = createAsyncThunk(
  'getExportFileTripStatistic',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.get(DOWNFILE_TRIP, { params, responseType: 'arraybuffer' });
      callbackSuccess?.(response);
      return response.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const statistic = createSlice({
  name: 'statistic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListChartRevenue.pending, (state) => {
        state.loadingListChartRevenue = true;
      })
      .addCase(getListChartRevenue.fulfilled, (state, action) => {
        state.loadingListChartRevenue = false;
        state.listChartRevenue = action.payload;
      })
      .addCase(getListChartRevenue.rejected, (state) => {
        state.listChartRevenue = [];
      })

      .addCase(getListTableRevenue.pending, (state) => {
        state.loadingListTableRevenue = true;
      })
      .addCase(getListTableRevenue.fulfilled, (state, action) => {
        state.loadingListTableRevenue = false;
        state.listTableRevenue = action.payload;
      })
      .addCase(getListTableRevenue.rejected, (state) => {
        state.listTableRevenue = [];
      })

      .addCase(getListChartTrip.pending, (state) => {
        state.loadingListChartTrip = true;
      })
      .addCase(getListChartTrip.fulfilled, (state, action) => {
        state.loadingListChartTrip = false;
        state.listChartTrip = action.payload;
      })
      .addCase(getListChartTrip.rejected, (state) => {
        state.listChartTrip = [];
      })

      .addCase(getListTableTrip.pending, (state) => {
        state.loadingListTableTrip = true;
      })
      .addCase(getListTableTrip.fulfilled, (state, action) => {
        state.loadingListTableTrip = false;
        state.listTableTrip = action.payload;
      })
      .addCase(getListTableTrip.rejected, (state) => {
        state.listTableTrip = [];
      })

      .addCase(getProvinceStatistic.pending, (state) => {
        state.loadingListProvinceStatistic = true;
      })
      .addCase(getProvinceStatistic.fulfilled, (state, action) => {
        state.loadingListProvinceStatistic = false;
        state.listProvinceStatistic = action.payload;
      })
      .addCase(getProvinceStatistic.rejected, (state) => {
        state.listProvinceStatistic = [];
      })

      .addCase(getListEvaluation.pending, (state) => {
        state.loadingListEvaluation = true;
      })
      .addCase(getListEvaluation.fulfilled, (state, action) => {
        state.loadingListEvaluation = false;
        state.listEvaluation = action.payload;
      })
      .addCase(getListEvaluation.rejected, (state) => {
        state.listEvaluation = [];
      })

      .addCase(getTotalEvaluation.pending, (state) => {
        state.loadingTotalEvaluation = true;
      })
      .addCase(getTotalEvaluation.fulfilled, (state, action) => {
        state.loadingTotalEvaluation = false;
        state.totalEvaluation = action.payload;
      })
      .addCase(getTotalEvaluation.rejected, (state) => {
        state.totalEvaluation = [];
      });
  },
});

export const { actions } = statistic;
export const statisticData = (state) => state.statistic;
export default statistic.reducer;
