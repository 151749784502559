import { Card, Divider, FormControl, Grid, useMediaQuery } from '@mui/material';
import TitlePage from '@/components/TitlePage';
import { InputForm, InputFormDate, SelectSearch } from '@/common/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { optionsRoleList, optionsStatusAccount } from '@/shared/options';
import { Close, Done } from '@mui/icons-material';
import TableComponent from '@/components/TableComponent';
import { MANAGEMENT_ACCOUNT } from '@/shared/constant/TableHead';
import { useEffect, useState } from 'react';
import { parseDataAccount } from '@/shared/constant/dataTable';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import ConfirmPopup from '@/components/ConfirmPopup';
import { createNewPath, formatDate, useQueryParameters } from '@/shared/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { accountData, deleteAccount, getListEmployee, updateStatusAccount } from '@/redux/slices/account';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { schemaFormAccount } from '@/common/Schema';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';
import ModalResetPassword from './ModalResetPassword';
import { useRole } from '@/hooks/useRole';

export default function AccountManagement() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();
  const { pathname } = location;
  const queriesParams: any = useQueryParameters();
  const dispatch = useDispatch<any>();
  const [queryFilter, setQueryfilter] = useState(queriesParams);
  const dataSelector = useSelector(accountData);
  const { listAccount, loadingListAccount, loadingDelete } = dataSelector;
  const { userId } = useRole();
  const lang = window.lang;
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLarge = useMediaQuery('(min-width:1200px)');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>('');
  const defaultValue = {
    page: 1,
    take: PAGE_SIZE,
    Text: queriesParams?.text || '',
    roles: queriesParams?.roles === '4' ? '0' : queriesParams?.roles || '',
    Status: queriesParams?.status === '2' ? '0' : queriesParams?.status || '',
    startDate: queriesParams?.startDate || null,
    endDate: queriesParams?.endDate || null,
  };

  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaFormAccount(lang)),
    defaultValues: {
      text: queriesParams?.text || '',
      roles: queriesParams?.roles === '0' ? '4' : queriesParams?.roles || '',
      status: queriesParams?.status === '0' ? '2' : queriesParams?.status || '',
      startDate: null,
      endDate: null,
    },
  });

  useEffect(() => {
    dispatch(getListEmployee(defaultValue));
  }, []);

  const onSubmit = (data) => {
    setCurrentPage(1);
    const dataSubmit = {
      text: data.text,
      status: data.status === '2' ? '0' : data.status,
      page: 1,
      take: PAGE_SIZE,
      startDate: formatDate(data.startDate),
      endDate: formatDate(data.endDate),
      roles: data?.roles === '4' ? '0' : data.roles,
    };
    setQueryfilter(dataSubmit);
    dispatch(getListEmployee(dataSubmit));
    const newPath = createNewPath(pathname, dataSubmit);
    navigate(newPath);
  };

  const handleResetFilter = () => {
    reset();
    setValue('text', '');
    setValue('status', '');
    setValue('roles', '');
    setValue('startDate', null);
    setValue('endDate', null);
    setCurrentPage(1);
    setQueryfilter({
      page: 1,
      take: PAGE_SIZE,
      Text: '',
      Status: '',
    });
    dispatch(getListEmployee({ page: 1, take: PAGE_SIZE, Text: '', startDay: '', endDay: '' }));
    navigate(pathname);
  };

  const enumStatus = (value) => {
    const typeObject = {
      0: <Done color="success" />,
      1: <Close color="error" />,
    };
    return typeObject[value];
  };

  const enumRoles = (value) => {
    const typeObject = {
      0: <span>Admin</span>,
      1: <span>Nhân viên</span>,
    };
    return typeObject[value];
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (id) => {
    const payload = {
      params: {
        id,
      },
      callbackSuccess: () => {
        showSuccessToast(lang.account.successAccount);
        dispatch(getListEmployee({ ...defaultValue, page: currentPage }));
        dispatch(updateStatusAccount());
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    openPopup(lang.account.deleteAccount, () => dispatch(deleteAccount(payload)));
  };

  const dataTable = parseDataAccount(
    listAccount?.data,
    listAccount?.totalCount,
    currentPage,
    enumStatus,
    enumRoles,
    userId,
  );
  const handleAddAccount = () => {
    navigate(CONST_ROUTER.ADD_ACCOUNT);
  };

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      page: page,
      take: PAGE_SIZE,
    };
    dispatch(getListEmployee(data));
  };

  const handleViewDetail = (id) => {
    navigate(CONST_ROUTER.EDIT_ACCOUNT(id));
  };

  const handleResetPassword = (id) => {
    setIsOpen(true);
    setItemId(id);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mt={1}>
        <Grid item>
          <TitlePage title="QUẢN LÝ TÀI KHOẢN NHÂN VIÊN" />
        </Grid>
      </Grid>
      <Divider className="bd-title-tab" />
      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container gap={1} flexWrap={isLarge ? 'nowrap' : 'wrap'}>
          <Grid container xs={12} md={12} lg={9} gap={1} flexWrap={isMobile ? 'wrap' : 'nowrap'}>
            <Grid item xs={12} sm={6} md={6}>
              <InputForm
                name="text"
                control={control}
                placeholder="Nhập vào tên tài khoản, họ và tên, email, số điện thoại"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={6} md={6} gap={1} flexWrap="nowrap">
              <Grid item xs={5} md={5} lg={5}>
                <FormControl fullWidth>
                  <SelectSearch
                    label="Vai trò"
                    control={control}
                    name="roles"
                    options={optionsRoleList}
                    errors={errors}
                    // isLoading={isLoading}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={7} md={7}>
                <FormControl fullWidth>
                  <SelectSearch
                    label={lang.managementCustomer.status}
                    control={control}
                    name="status"
                    options={optionsStatusAccount}
                    errors={errors}
                    // isLoading={isLoading}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container gap={1} lg={3} wrap="nowrap">
            <Grid item xs={6} sm={3} md={3} lg={6}>
              <FormControl fullWidth>
                <InputFormDate
                  control={control}
                  name="startDate"
                  label={lang.placeholderInput.fromDay}
                  size="small"
                  errors={errors}
                  maxDate={false}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={6}>
              <FormControl fullWidth>
                <InputFormDate
                  control={control}
                  label={lang.placeholderInput.toDay}
                  name="endDate"
                  size="small"
                  errors={errors}
                  maxDate={false}
                />
              </FormControl>
            </Grid>
            {!isMobile && !isLarge && <Grid item sm={6}></Grid>}
          </Grid>
        </Grid>
        <ButtonSearch
          submit={handleSubmit(onSubmit)}
          resetSearch={handleResetFilter}
          add={handleAddAccount}
          handleDownFile={null}
        />
      </Card>
      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={MANAGEMENT_ACCOUNT}
          listData={dataTable}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loadingListAccount}
          totalRecord={listAccount?.data?.length}
          totalItems={listAccount?.totalCount}
          onViewDetail={handleViewDetail}
          onDelete={(id) => handleDelete(id)}
          onResetPassword={handleResetPassword}
          pagingCallback={(page) => handlePaging(page)}
          // onViewDetail={(id) => handleDetailGoods(id)}
          className="scroll-table"
        />
      </Grid>
      <CustomBackdrop open={loadingDelete} />
      <ConfirmationPopup />
      {isOpen && <ModalResetPassword id={itemId} open={isOpen} setOpen={setIsOpen} />}
    </>
  );
}
