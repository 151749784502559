import {
  ASSIGN_DRIVER,
  CANCEL_TRIP,
  DETAIL_TRIP,
  DOWLOAD_FILE,
  EDIT_TRIP,
  TRIP,
  TRIP_DASHBOARD,
  TRIP_DETAIL_HISTORY,
  WAITTING_FEE,
} from '@/shared/constant/constantUrl';
import Api from '@/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  listTrip: [],
  loadingListTrip: false,
  tripDetail: [],
  loadingTripDetail: false,
  listTripDashboard: [],
  loadingTripDashboard: false,
  loadingAssignDriver: false,
  loadingUnBlockCustomer: false,
  loadingEditCustomer: false,
  loadingPostWaittingFee: false,
  exportFileList: [],
  loadingExportFile: false,
  loadingCancelTrip: false,
  tripDetailHistory: [],
  loadingTripDetailHistory: false,
  loadingEditTrip: false,
};

export const putAssignDriver = createAsyncThunk(
  'putTrip/assignDriver',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(ASSIGN_DRIVER, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const priceWaittingFee = createAsyncThunk(
  'priceWaittingFee',
  async ({ id, params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(WAITTING_FEE(id), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const adminEditTrip = createAsyncThunk(
  'adminEditTrip',
  async ({ id, params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(EDIT_TRIP(id), params?.transformedData);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const putCancelTrip = createAsyncThunk(
  'putTrip/cancelTrip',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(CANCEL_TRIP, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const getTrip = createAsyncThunk('getTrip', async (params: any) => {
  try {
    const response = await Api.get(TRIP, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getDetailTrip = createAsyncThunk('getDetailTrip', async (id: string) => {
  try {
    const response = await Api.get(DETAIL_TRIP(id));
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getTripDetailHistory = createAsyncThunk(
  'getTripDetailHistory',
  async ({ id, params }: { id: string; params?: object }) => {
    try {
      const response = await Api.get(TRIP_DETAIL_HISTORY(id), { params });
      return response?.data;
    } catch (error: any) {
      return error;
    }
  },
);

export const getTripDashboard = createAsyncThunk('getDashboardTrip', async () => {
  try {
    const response = await Api.get(TRIP_DASHBOARD);
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getExportFileTrip = createAsyncThunk(
  'getExportFileTrip',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.get(DOWLOAD_FILE, { params, responseType: 'arraybuffer' });
      callbackSuccess?.(response);

      return response.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const trip = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    clearTripDetail: (state) => {
      state.tripDetail = [];
    },
    updateStatus: (state) => {
      state.loadingListTrip = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrip.pending, (state) => {
        state.loadingListTrip = true;
      })
      .addCase(getTrip.fulfilled, (state, action) => {
        state.loadingListTrip = false;
        state.listTrip = action.payload;
      })
      .addCase(getTrip.rejected, (state) => {
        state.listTrip = [];
      })

      .addCase(getTripDashboard.pending, (state) => {
        state.loadingTripDashboard = true;
      })
      .addCase(getTripDashboard.fulfilled, (state, action) => {
        state.loadingTripDashboard = false;
        state.listTripDashboard = action.payload;
      })
      .addCase(getTripDashboard.rejected, (state) => {
        state.listTripDashboard = [];
      })

      .addCase(getDetailTrip.pending, (state) => {
        state.loadingTripDetail = true;
      })
      .addCase(getDetailTrip.fulfilled, (state, action) => {
        state.loadingTripDetail = false;
        state.tripDetail = action.payload;
      })
      .addCase(getDetailTrip.rejected, (state) => {
        state.tripDetail = [];
      })

      .addCase(getTripDetailHistory.pending, (state) => {
        state.loadingTripDetailHistory = true;
      })
      .addCase(getTripDetailHistory.fulfilled, (state, action) => {
        state.loadingTripDetailHistory = false;
        state.tripDetailHistory = action.payload;
      })
      .addCase(getTripDetailHistory.rejected, (state) => {
        state.loadingTripDetailHistory = false;
        state.tripDetailHistory = [];
      })

      .addCase(putAssignDriver.pending, (state) => {
        state.loadingAssignDriver = true;
      })
      .addCase(putAssignDriver.fulfilled, (state, action) => {
        state.loadingAssignDriver = false;
      })
      .addCase(putAssignDriver.rejected, (state) => {})

      .addCase(putCancelTrip.pending, (state) => {
        state.loadingCancelTrip = true;
      })
      .addCase(putCancelTrip.fulfilled, (state, action) => {
        state.loadingCancelTrip = false;
      })
      .addCase(putCancelTrip.rejected, (state) => {})

      .addCase(priceWaittingFee.pending, (state) => {
        state.loadingPostWaittingFee = true;
      })
      .addCase(priceWaittingFee.fulfilled, (state, action) => {
        state.loadingPostWaittingFee = false;
      })
      .addCase(priceWaittingFee.rejected, (state) => {})

      .addCase(getExportFileTrip.pending, (state) => {
        state.loadingExportFile = true;
      })
      .addCase(getExportFileTrip.fulfilled, (state, action) => {
        state.loadingExportFile = false;
        state.exportFileList = action.payload;
      })
      .addCase(getExportFileTrip.rejected, (state) => {
        state.loadingExportFile = false;
        state.exportFileList = [];
      })
      .addCase(adminEditTrip.pending, (state) => {
        state.loadingEditTrip = true;
      })
      .addCase(adminEditTrip.fulfilled, (state, action) => {
        state.loadingEditTrip = false;
      })
      .addCase(adminEditTrip.rejected, (state) => {
        state.loadingEditTrip = false;
      });
  },
});

export const { actions } = trip;
export const tripData = (state) => state.trip;
export default trip.reducer;
export const { clearTripDetail, updateStatus } = trip.actions;
