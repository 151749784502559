import { Stack, Typography } from '@mui/material';

const NotFoundPage = () => {
  return (
    <>
      <Stack direction="column" alignItems="center" sx={{ mt: 20 }}>
        <Typography variant="h3">404 - Trang không tìm thấy</Typography>
        <Typography variant="h5"> Xin lỗi, trang bạn đang tìm kiếm không tồn tại.</Typography>
      </Stack>
    </>
  );
};

export default NotFoundPage;
