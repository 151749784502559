import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import LoadingPage from '@/components/LoadingPage';
import { TRIP_CANCEL, TRIP_SUCCESS } from '@/shared/constant/text';

const BasicBars = ({ listTripDashboard, loadingTripDashboard }) => {
  const [category, setCategory] = useState<any>([]);

  const [series, setSeries] = useState([
    {
      name: 'Tổng số hành trình thành công',
      data: [],
      color: TRIP_SUCCESS,
    },
    {
      name: 'Tổng số hành trình đã hủy',
      data: [],
      color: TRIP_CANCEL,
    },
  ]);

  const [options, setOptions] = useState<any>({
    chart: {
      height: 50,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 0, // Set borderRadius to 0 to make bars square
        columnWidth: '20px', // Width of the bars
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
      offsetY: -20,
      formatter: function (val) {
        return val ? val : '';
      },
    },
    xaxis: {
      categories: category,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
      title: {
        text: 'Tháng',
        style: {
          color: '#304758',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Tổng số hành trình',
        style: {
          color: '#304758',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      },
      labels: {
        formatter: function (val) {
          return Math.round(val).toString();
        },
      },
      axisBorder: {
        show: true,
      },
      tickAmount: 5,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: 'Arial',
      left: '450px !important',
      labels: {
        colors: ['#304758'],
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    tooltip: {
      enabled: true,
    },
  });

  useEffect(() => {
    const currentDate = new Date();
    const months = Array.from({ length: 6 }, (_, i) => {
      const monthOffset = 5 - i;
      const monthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - monthOffset, 1);
      const month = monthDate.getMonth() + 1;
      const year = monthDate.getFullYear();
      return `${month}/${year}`;
    });
    setCategory(months);

    const listData1 =
      listTripDashboard?.trips?.length > 0 ? listTripDashboard.trips.map((item) => item.totalSuccessfulTrips) : [];
    const listData2 =
      listTripDashboard?.trips?.length > 0 ? listTripDashboard.trips.map((item) => item.totalCancelledTrips) : [];

    setSeries([
      {
        ...series[0],
        data: listData1 || [],
      },
      {
        ...series[1],
        data: listData2 || [],
      },
    ]);

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: months,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
    }));
  }, [listTripDashboard]);

  return (
    <Card className="apex-chart-container">
      {loadingTripDashboard ? (
        <LoadingPage />
      ) : (
        <ReactApexChart options={options} series={series} type="bar" height="500px" />
      )}
    </Card>
  );
};

export default BasicBars;
