export const MANAGEMENT_CUSTOMER = [
  { id: 'stt', label: 'STT' },
  { id: 'name', label: 'Họ và tên' },
  { id: 'email', label: 'Email' },
  { id: 'address', label: 'Nơi sống' },
  { id: 'status', label: 'Trạng thái hoạt động' },
  { id: 'date', label: 'Ngày tạo' },
  { id: 'actions', label: '' },
];

export const MANAGEMENT_ACCOUNT = [
  { id: 'stt', label: 'STT' },
  { id: 'name', label: 'Tên tài khoản' },
  { id: 'email', label: 'Họ và tên' },
  { id: 'address', label: 'Email' },
  { id: 'address', label: 'Vai trò' },
  { id: 'status', label: 'Trạng thái hoạt động' },
  { id: 'address', label: 'Ngày tạo' },
  { id: 'actions', label: '' },
];

export const STATICTIS_VOTE = [
  { id: 'stt', label: 'STT' },
  { id: 'name', label: 'Khách hàng' },
  { id: 'email', label: 'Tài xế' },
  { id: 'address', label: 'Mã hành trình' },
  { id: 'address', label: 'Đánh giá' },
  { id: 'status', label: 'Nhận xét' },
  { id: 'address', label: 'Ngày đánh giá' },
];

export const PAYMENT_SETTING = [
  { id: 'stt', label: 'STT' },
  { id: 'name', label: 'Hình thức thanh toán' },
  { id: 'email', label: 'Trạng thái' },
  { id: 'actions', label: '' },
];

export const STATICTIS_REVENUE = [
  { id: 'stt', label: 'STT' },
  { id: 'name', label: 'Ngày' },
  { id: 'email', label: 'Tổng số cuốc' },
  { id: 'customer', label: 'Tổng số cuốc hoàn thành' },
  { id: 'address', label: 'Tổng số cuốc hủy' },
  { id: 'address', label: 'Tổng thu (VNĐ)' },
  { id: 'address', label: 'Tiền mặt (VNĐ)' },
  { id: 'address', label: 'VNPAY (VNĐ)' },
];

export const STATICTIS_TRIP = [
  { id: 'stt', label: 'STT' },
  { id: 'name', label: 'Ngày' },
  { id: 'email', label: 'Tổng số cuốc' },
  { id: 'customer', label: 'Số cuốc hoàn thành' },
  { id: 'address', label: 'Số cuốc hủy' },
  { id: 'address', label: 'Số cuốc khách hủy' },
  { id: 'address', label: 'Số cuốc tài xế hủy' },
  { id: 'address', label: 'Số cuốc nhân viên điều phối hủy)' },
];

export const VEHICLE_CLASS = [
  { id: 'stt', label: 'STT' },
  { id: 'name', label: 'Tên hạng xe' },
  { id: 'about', label: 'Mô tả' },
  { id: 'status', label: 'Trạng thái hoạt động' },
  { id: 'actions', label: '' },
];

export const MANAGEMENT_CUSTOMER_HISTORY = [
  { id: 'stt', label: 'STT' },
  { id: 'code', label: 'Mã hành trình' },
  { id: 'bookDate', label: 'Ngày đặt' },
  { id: 'departurePoint', label: 'Điểm đi' },
  { id: 'topPoint', label: 'Điểm dừng' },
  { id: 'destination', label: 'Điểm đến' },
  { id: 'destination', label: 'Trạng thái hành trình' },
  { id: 'paymentSettingName', label: 'Phương thức thanh toán' },
  { id: 'totalMoney', label: 'Tổng tiền (VNĐ)' },
  { id: 'nameDriver', label: 'Tên tài xế' },
];

export const TRIPS_HISTORY = [
  { id: 'stt', label: 'STT' },
  { id: 'code', label: 'Mã hành trình' },
  { id: 'bookDate', label: 'Ngày đặt' },
  { id: 'name', label: 'Họ và tên khách hàng' },
  { id: 'departurePoint', label: 'Điểm đi' },
  { id: 'topPoint', label: 'Điểm dừng' },
  { id: 'destination', label: 'Điểm đến' },
  { id: 'destination', label: 'Trạng thái hành trình' },
  { id: 'paymentSettingName', label: 'Phương thức thanh toán' },
  { id: 'totalMoney', label: 'Tổng tiền (VNĐ)' },
];

export const TRIPS_DETAIL_HISTORY = [
  { id: 'stt', label: 'STT' },
  { id: 'time', label: 'Thời gian' },
  { id: 'name', label: 'Người thực hiện thay đổi' },
  { id: 'role', label: 'Vai trò' },
  { id: 'content', label: 'Nội dung' },
];

export const COLS_DRIVER = [
  { id: 'stt', label: 'STT' },
  { id: 'fullName', label: 'Họ và tên' },
  { id: 'email', label: 'Email' },
  { id: 'address', label: 'Nơi sống' },
  { id: 'isActive', label: 'Trạng thái hoạt động' },
  { id: 'date', label: 'Ngày tạo' },
  { id: 'actions', label: '' },
];

export const COLS_NOTIFICATION = [
  { id: 'stt', label: 'STT' },
  { id: 'fullName', label: 'Ngày thông báo', maxWidth: '50px' },
  { id: 'fullName', label: 'Nội dung thông báo' },
];

export const COLS_OFFER = [
  { id: 'stt', label: 'STT' },
  { id: 'cols', label: 'Tên khuyến mãi' },
  { id: 'cols', label: 'Mô tả' },
  { id: 'cols', label: 'Ngày bắt đầu' },
  { id: 'cols', label: 'Ngày kết thúc' },
  { id: 'cols', label: 'Tổng số lượt đã sử dụng/Tổng số khuyến mãi' },
  { id: 'cols', label: 'Loại khuyến mãi' },
  { id: 'cols', label: 'Trạng thái' },
  { id: 'actions', label: '' },
];

export const COLS_AREA_PRICE = [
  { id: 'stt', label: 'STT' },
  { id: 'provinceName', label: 'Khu vực' },
  { id: 'firstPrice', label: '0 - 4km (VNĐ)' },
  { id: 'middlePrice', label: 'Trên 4km - 20km (VNĐ)' },
  { id: 'finalPrice', label: 'Trên 20km (VNĐ)' },
  { id: 'surcharge', label: 'Phụ phí sau 23h và trước 06h (VNĐ)' },
  { id: 'actions', label: '' },
];

export const TRIPS_MANAGEMENT = [
  { id: 'stt', label: 'STT' },
  { id: 'tripNumber', label: 'Mã hành trình' },
  { id: 'driver', label: 'Tài xế' },
  { id: 'name', label: 'Khách hàng' },
  { id: 'departurePoint', label: 'Điểm đi' },
  { id: 'destination', label: 'Điểm đến' },
  { id: 'status', label: 'Trạng thái' },
  { id: 'provisional', label: 'Tạm tính (VNĐ)' },
  { id: 'waittingMoney', label: 'Giá chờ (VNĐ)' },
  { id: 'totalMoney', label: 'Tổng tiền (VNĐ)' },
  { id: 'paymentType', label: 'Hình thức thanh toán' },
  { id: 'bookDate', label: 'Ngày đặt' },
  { id: 'driverStatus', label: 'Điều phối tài xế' },
  { id: 'actions', label: '' },
];

export const MANAGEMENT_VOTE = [
  { id: 'stt', label: 'STT' },
  { id: 'name', label: 'Khách hàng' },
  { id: 'driver', label: 'Tài xế' },
  { id: 'code', label: 'Mã hành trình' },
  { id: 'vote', label: 'Đánh giá' },
  { id: 'about', label: 'Hài lòng' },
  { id: 'notAbout', label: 'Chưa hài lòng' },
  { id: 'date', label: 'Ngày đánh giá' },
  { id: 'actions', label: '' },
];
