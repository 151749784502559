import { useState } from 'react';
import { Box, Tooltip, IconButton, Menu, MenuItem, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTER } from '@/shared/constant/common';
import { CustomBackdrop } from '../CustomBackdrop';
import { PROFILE, UPDATEPASSWORD, LOGOUT } from '@/shared/constant/text';
import { AccountCircle, Person2 } from '@mui/icons-material';
import PasswordIcon from '@mui/icons-material/Password';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ConfirmPopup from '../ConfirmPopup';
import { showErrorToast } from '@/hooks/useCustomToast';
import { authSliceData, postLogout } from '@/redux/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { handleError, logout401 } from '@/shared/utils/common';

const settings = [
  { label: 'Thông tin cá nhân', icon: <Person2 />, border: true, color: false },
  { label: 'Thay đổi mật khẩu', icon: <PasswordIcon />, border: true, color: false },
  { label: 'Đăng xuất', icon: <ExitToAppIcon color="error" />, border: true, color: true },
];

export default function AvatarAccount() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(authSliceData);
  const dataApi = dataSelector?.profileDetail?.data;
  const navigate = useNavigate();
  const lang = window.lang;
  const handleConfirm = () => {
    const payload = {
      params: {
        deviceId: null,
      },
      callBackSuccess: () => {
        logout401();
      },
      callBackError: (isError) => {
        showErrorToast(isError);
      },
    };

    openPopup(lang.profile.confirmLogout, () => dispatch(postLogout(payload)));
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (index) => {
    handleCloseUserMenu();
    if (settings[index].label === PROFILE) {
      navigate(CONST_ROUTER.PROFILE_USER);
    }
    if (settings[index].label === LOGOUT) {
      handleConfirm();
    }
    if (settings[index].label === UPDATEPASSWORD) {
      navigate(CONST_ROUTER.UPDATE_PASS);
    }
  };

  return (
    <Box flexGrow={0} display="flex" alignItems="center" sx={{ paddingLeft: '1rem' }}>
      <Tooltip title="">
        <IconButton onClick={handleOpenUserMenu}>
          {dataApi?.avatarPath ? (
            <Grid className="round-image-container">
              <img src={dataApi?.avatarPath} onError={handleError} alt="Selected" className="round-avatar" />
            </Grid>
          ) : (
            <AccountCircle
              sx={{ fontSize: 42, color: 'primary', filter: 'drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.3))' }}
            />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting, index) => (
          <MenuItem
            key={setting.label}
            onClick={() => handleMenuItemClick(index)}
            sx={{ mb: 1 }}
            className={setting.border ? 'border-bottom' : ''}
          >
            <Box display="flex" alignItems="center">
              {setting.icon}
              <Typography color={setting.color ? 'error' : ''} textAlign="center" ml={1}>
                {setting.label}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
      <ConfirmationPopup />
      <CustomBackdrop open={dataSelector.loadingLogout} />
    </Box>
  );
}
