import { CustomBackdrop } from '@/components/CustomBackdrop';
import { notificationData } from '@/redux/slices/notification';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const PopupConfirm = ({ closePopup, handleConfirm, open }) => {
  const dataSelector = useSelector(notificationData);

  return (
    <>
      <Dialog open={open} onClose={closePopup}>
        <DialogTitle className="popup-title">
          <b>Xác nhận đọc tất cả thông báo</b>
        </DialogTitle>
        <Divider className="popup-divider" />
        <DialogContent className="popup-text">
          <DialogContentText sx={{ whiteSpace: 'pre-line', color: 'var(--color-input-default)' }}>
            Bạn có chắc chắn muốn đọc tất cả các thông báo này không?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="group-button">
          <Button onClick={closePopup} color="error" variant="outlined">
            Đóng
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus variant="outlined">
            Xác nhận
          </Button>
          <CustomBackdrop open={dataSelector?.loadingReadAllNotification} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupConfirm;
