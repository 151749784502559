import { createTheme } from '@mui/material';

export const desktopTheme = createTheme({
  typography: {
    h3: {
      fontSize: 30,
    },
    h4: {
      fontSize: 30,
    },
  },

  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          alignSelf: 'center',
          lineHeight: 1.5,
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          marginTop: 16,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '500px',
        },
      },
    },
  },
});

export const mobileTheme = createTheme({
  typography: {
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 24,
    },
    body1: {
      fontSize: 14,
    },
  },

  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          alignSelf: 'center',
          lineHeight: 1.5,
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          marginTop: 8,
          padding: 5,
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        strong: {
          fontSize: 14,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 10,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 11,
          padding: 8,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 12,
          padding: 8,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '450px',
        },
      },
    },
  },
});

const getThemeForScreenSize = () => (window.innerWidth >= 768 ? desktopTheme : mobileTheme);

export const selectedTheme = getThemeForScreenSize();
