import { customerData } from '@/redux/slices/customer';
import { Card, Grid, Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';

const CircleContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'translate(-50%, -50%)',
});

const Circle = styled('div')({
  width: 32,
  height: 32,
  borderRadius: '50%',
  backgroundColor: 'black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
});

export default function InfoVehicle() {
  const lang = window.lang;
  const dataSelector = useSelector(customerData);
  const dataVehicle = dataSelector?.listVehicleCustomer;

  const renderGrid = (title, value) => (
    <>
      <Grid item xs={1}>
        <Typography variant="body2" fontSize={14}>
          <strong> {title}:</strong>
        </Typography>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="body2" fontSize={14}>
          {value}
        </Typography>
      </Grid>
    </>
  );

  return (
    <>
      {dataVehicle?.length > 0 ? (
        dataVehicle.map((item, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <CircleContainer>
              <Circle>{index + 1}</Circle>
            </CircleContainer>
            <Card sx={{ p: 2, mt: 3 }}>
              <Grid container spacing={1}>
                {renderGrid(lang.vehicleClass.rank, item?.vehicleTypeName)}
                {renderGrid(lang.vehicleClass.license, item?.licensePlates)}
                {renderGrid(lang.vehicleClass.color, item?.color)}
              </Grid>
            </Card>
          </div>
        ))
      ) : (
        <>
          <Card sx={{ p: 2, mt: 3, height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={12}>
              <Typography variant="body2" fontSize={14}>
                <strong>Chưa có thông tin xe của khách hàng</strong>
              </Typography>
            </Grid>
          </Card>
        </>
      )}
    </>
  );
}
