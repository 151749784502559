import { Card, FormControl, Grid, useMediaQuery } from '@mui/material';
import { InputForm, InputFormDate, RadioInputForm, SelectSearch } from '@/common/Form';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { genderSelect, optionsStatusCustomer } from '@/shared/options';
import { useNavigate } from 'react-router-dom';
import { ACTIONS, CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { BackPage } from '@/components/BackPage';
import { schemaAddcustomer } from '@/common/Schema';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { addCustomer, customerData, getCustomer } from '@/redux/slices/customer';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateDob } from '@/shared/utils/common';
import { useEffect, useState } from 'react';
import { getProvinceList, priceSystemData } from '@/redux/slices/priceSystem';
import { DeleteOutline, Edit } from '@mui/icons-material';
import AccountAvatar from '@/components/Avatar/AccountAvatar';
import AvatarMenu from '@/components/Avatar/AvatarMenu';

export default function AddCustomer() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const lang = window.lang;
  const dataSelector = useSelector(customerData);
  const dispatch = useDispatch<any>();
  const [avatar, setAvatar] = useState<string | null>(null);
  const [file, setFile] = useState<File | any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const dataAreaSelector = useSelector(priceSystemData);
  const dataApiProvince = dataAreaSelector?.listProvince;
  useEffect(() => {
    dispatch(getProvinceList(''));
  }, []);
  const defaultValue = {
    page: 1,
    take: PAGE_SIZE,
    Text: '',
    Status: '',
  };
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaAddcustomer(lang)),
    defaultValues: {
      provinceId: '',
      surnameAndMiddleName: '',
      lastName: '',
      phoneNumber: '',
      dob: undefined,
      status: 3,
      email: '',
      gender: 1,
    },
  });

  function handleDeleteImage() {
    setAvatar(null);
    setFile(null);
  }

  const onSubmit = (data) => {
    const params = {
      LastName: data.lastName,
      SurnameAndMiddleName: data.surnameAndMiddleName,
      ProvinceId: data.provinceId || null,
      PhoneNumber: data.phoneNumber,
      DOB: data.dob ? convertDateDob(data.dob) : '',
      Email: data.email,
      Gender: data.gender === 2 ? 0 : data.gender,
      Status: data.status === 3 ? 0 : data.status,
      UserId: null,
      Password: 'nopassword',
    };
    const formData = new FormData();
    file && formData.append('FileAvatar', file);
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        if (params[key] || params[key] === 0) {
          formData.append(key, params[key]);
        }
      }
    }
    const payload = {
      params: formData,
      callbackSuccess: () => {
        showSuccessToast('Thêm khách hàng thành công');
        reset();
        navigate(CONST_ROUTER.MANAGEMENT_CUSTOMER);
        dispatch(getCustomer(defaultValue));
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(addCustomer(payload));
  };

  const onCancel = () => {
    setAvatar('');
    reset();
  };

  const handleBackPage = () => {
    reset();
    navigate(CONST_ROUTER.MANAGEMENT_CUSTOMER);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const actions = [ACTIONS.EDIT, ACTIONS.DELETE];
  const actionInfo: any = [
    {
      action: ACTIONS.EDIT,
      label: avatar ? lang.button.actions.editImage : lang.button.actions.addImage,
      icon: <Edit sx={{ mr: 2 }} />,
      disabled: false,
      isUpload: true,
    },
    {
      action: ACTIONS.DELETE,
      label: lang.button.actions.deleteImage,
      disabled: !avatar,
      icon: <DeleteOutline sx={{ mr: 2 }} />,
      onClick: handleDeleteImage,
      sx: { color: 'error.main' },
    },
  ];

  return (
    <>
      <BackPage title={lang.managementCustomer.addNewCustomer} handleBackPage={handleBackPage} marginbottom={1} />
      <Card sx={{ p: 2 }}>
        <AccountAvatar
          avatar={avatar}
          handleMenuClick={handleMenuClick}
          setAvatar={setAvatar}
          setFile={setFile}
          setIsDelete={() => {}}
        />
        <AvatarMenu actions={actions} actionInfo={actionInfo} handleCloseMenu={handleCloseMenu} anchorEl={anchorEl} />
        <Grid container spacing={!isMobile ? 8 : 1}>
          <Grid item xs={12} sm={6} md={6} className="driver-form-data">
            <Grid className="driver-form-spacing">
              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">
                    Họ và tên đệm:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9}>
                  <FormControl fullWidth>
                    <InputForm name="surnameAndMiddleName" control={control} size="small" fullWidth errors={errors} />
                  </FormControl>
                </Grid>
              </Grid>
              {isMobile && (
                <Grid container mt={2}>
                  <Grid item xs={5} sm={5} md={3} mt={1}>
                    <strong className="fz-14">
                      Tên:<span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={7} sm={7} md={9}>
                    <FormControl fullWidth>
                      <InputForm name="lastName" control={control} size="small" fullWidth errors={errors} />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">Ngày sinh:</strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9}>
                  <FormControl fullWidth>
                    <InputFormDate name="dob" control={control} errors={errors} maxDate={false} />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">
                    Email:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9}>
                  <FormControl fullWidth>
                    <InputForm name="email" control={control} size="small" fullWidth errors={errors} />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">Nơi sống:</strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9}>
                  <FormControl fullWidth>
                    <SelectSearch
                      options={dataApiProvince || []}
                      name="provinceId"
                      control={control}
                      size="small"
                      errors={errors}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="driver-form-data">
            <Grid className="driver-form-spacing">
              {!isMobile && (
                <Grid container mt={2}>
                  <Grid item xs={5} sm={5} md={3} mt={1}>
                    <strong className="fz-14">
                      Tên:<span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={7} sm={7} md={9}>
                    <FormControl fullWidth>
                      <InputForm name="lastName" control={control} size="small" fullWidth errors={errors} />
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">
                    Giới tính:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9}>
                  <FormControl fullWidth>
                    <RadioInputForm options={genderSelect} name="gender" control={control} errors={errors} />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">
                    Số điện thoại:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9}>
                  <FormControl fullWidth>
                    <InputForm name="phoneNumber" control={control} size="small" fullWidth errors={errors} />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">
                    Trạng thái:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9}>
                  <FormControl fullWidth>
                    <SelectSearch
                      options={optionsStatusCustomer.filter((option) => option.id !== 2) || []}
                      name="status"
                      control={control}
                      size="small"
                      errors={errors}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Grid container mt={3}>
        <Grid item xs={12} display="flex" gap={3} justifyContent="flex-end">
          <ButtonCustom
            fullWidth={false}
            color="error"
            title={lang.button.actions.cancel}
            variant="contained"
            onClick={onCancel}
          />
          <ButtonCustom
            fullWidth={false}
            title={lang.button.actions.add}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          />
        </Grid>
      </Grid>
      <CustomBackdrop open={dataSelector?.loadingAddCustomer} />
    </>
  );
}
