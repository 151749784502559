import { Card, FormControl, Grid, Typography, useMediaQuery } from '@mui/material';
import { InputForm, InputFormDate, RadioInputForm, SelectSearch } from '@/common/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { genderSelect, optionsStatusAccount } from '@/shared/options';
import { ACTIONS } from '@/shared/constant/common';
import { schemaProfile } from '@/common/Schema';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { DeleteOutline, Edit } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { authSliceData, getProfileDetail, postProfileDetail } from '@/redux/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { HandleButtonAddEdit } from '@/components/HandleButtonAddEdit';
import { convertDateDob } from '@/shared/utils/common';
import { LoadingPage } from '../LoadingPage';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import TitlePage from '@/components/TitlePage';
import { ROLE_ADMIN, ROLE_EMPLOYEE } from '@/shared/config/setting';
import AccountAvatar from '@/components/Avatar/AccountAvatar';
import AvatarMenu from '@/components/Avatar/AvatarMenu';

export default function ProfileUser() {
  const [avatar, setAvatar] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [file, setFile] = useState<File | any>();
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(authSliceData);
  const isLoading = dataSelector?.loadingProfileDetail;
  const dataApi = dataSelector?.profileDetail?.data;
  const isMobile = useMediaQuery('(max-width:600px)');
  const lang = window.lang;

  const handleMenuClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handleDeleteImage() {
    setAvatar(null);
    setFile(null);
    setIsDelete(true);
  }

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaProfile(lang)),
    defaultValues: {
      surnameAndMiddleName: '',
      lastName: '',
      phoneNumber: '',
      dob: null,
      status: undefined,
      email: '',
      gender: undefined,
      address: '',
    },
  });

  useEffect(() => {
    dispatch(getProfileDetail());
  }, []);

  const FEMALE = 2;
  const NOTACTIVE = 2;

  useEffect(() => {
    if (dataApi) {
      setValue('surnameAndMiddleName', dataApi.surnameAndMiddleName);
      setValue('lastName', dataApi.lastName);
      setValue('email', dataApi.email);
      setValue('phoneNumber', dataApi.phoneNumber);
      setValue('address', dataApi.address);
      setValue('dob', dataApi?.dateOfBirth);
      setValue('gender', dataApi.gender === 0 ? FEMALE : dataApi.gender);
      setValue('status', dataApi.status === 0 ? NOTACTIVE : dataApi.status);
      setAvatar(dataApi.avatarPath);
    }
  }, [dataApi]);

  const handleEdit = () => {
    setIsEdit(true);
  };
  const CompareData = (input, dataApi) => {
    if (typeof dataApi === 'number') {
      return watch(input) === dataApi || watch(input) === dataApi.toString() ? true : false;
    }
    if (input === 'dob') {
      return watch(input) === dataApi || convertDateDob(watch(input)) === dataApi ? true : false;
    }
    return watch(input) === dataApi ? true : false;
  };
  const isNewImage = (isDelete || !file) && !isDelete;
  const isNewData =
    CompareData('surnameAndMiddleName', dataApi?.surnameAndMiddleName) &&
    CompareData('lastName', dataApi?.lastName) &&
    CompareData('email', dataApi?.email) &&
    CompareData('phoneNumber', dataApi?.phoneNumber) &&
    CompareData('address', dataApi?.address) &&
    CompareData('dob', dataApi?.dateOfBirth) &&
    CompareData('gender', dataApi.gender === 0 ? FEMALE : dataApi.gender) &&
    isNewImage;

  const handleCancel = () => {
    setAvatar(null);
    setIsEdit(false);
    setAvatar(dataApi?.avatarPath ? dataApi?.avatarPath : '');
    setValue('surnameAndMiddleName', dataApi.surnameAndMiddleName);
    setValue('lastName', dataApi.lastName);
    setValue('email', dataApi.email);
    setValue('phoneNumber', dataApi.phoneNumber);
    setValue('address', dataApi.address);
    setValue('dob', dataApi.dateOfBirth);
    setValue('gender', dataApi.gender === 0 ? FEMALE : dataApi.gender);
    setValue('status', dataApi.status === 0 ? NOTACTIVE : dataApi.status);
    setIsDelete(false);
    clearErrors();
  };
  const handleSave = (data) => {
    const formData = new FormData();
    formData.append('userId', dataApi?.userId);
    formData.append('lastName', data.lastName);
    formData.append('surnameAndMiddleName', data.surnameAndMiddleName);
    formData.append('email', data.email);
    data.phoneNumber && formData.append('phoneNumber', data.phoneNumber);
    data.address && formData.append('address', data.address);
    data.dob && formData.append('dateOfBirth', convertDateDob(data.dob));
    formData.append('gender', data.gender === FEMALE ? 0 : data.gender);
    file ? formData.append('avatar', file) : !isDelete && formData.append('avatarPath', dataApi?.avatarPath);
    const payload = {
      params: formData,
      callbackSuccess: () => {
        showSuccessToast(lang.account.editAccountSuccess);
        dispatch(getProfileDetail());
        setIsEdit(false);
        reset();
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(postProfileDetail(payload));
  };

  const actions = [ACTIONS.EDIT, ACTIONS.DELETE];
  const actionInfo = [
    {
      action: ACTIONS.EDIT,
      label: avatar ? lang.button.actions.editImage : lang.button.actions.addImage,
      disabled: false,
      icon: <Edit sx={{ mr: 2 }} />,
      isUpload: true,
    },
    {
      action: ACTIONS.DELETE,
      label: lang.button.actions.deleteImage,
      disabled: !avatar,
      icon: <DeleteOutline sx={{ mr: 2 }} />,
      onClick: handleDeleteImage,
      sx: { color: 'error.main' },
    },
  ];

  return (
    <>
      <Typography className="border-button-back page-title" mb={0}>
        <TitlePage variant="h4" title={lang.profile.infoUser} />
      </Typography>
      {isLoading ? (
        <>
          <LoadingPage />
        </>
      ) : (
        <Card sx={{ p: 2 }}>
          <AccountAvatar
            avatar={avatar}
            isEdit={isEdit}
            handleMenuClick={handleMenuClick}
            setAvatar={setAvatar}
            setFile={setFile}
            setIsDelete={setIsDelete}
          />
          <AvatarMenu actions={actions} actionInfo={actionInfo} handleCloseMenu={handleCloseMenu} anchorEl={anchorEl} />
          <Grid container spacing={!isMobile ? 8 : 1}>
            <Grid item xs={12} sm={6} md={6} className="driver-form-data">
              <Grid className="driver-form-spacing">
                <Grid container mt={2}>
                  <Grid item xs={5} sm={5} md={3} mt={1}>
                    <strong className="fz-14">
                      Họ và tên đệm:<span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={7} sm={7} md={9}>
                    <FormControl fullWidth>
                      <InputForm
                        name="surnameAndMiddleName"
                        control={control}
                        size="small"
                        fullWidth
                        errors={errors}
                        disabled={!isEdit}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {isMobile && (
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Tên:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="lastName"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <Grid container mt={2}>
                  <Grid item xs={5} sm={5} md={3} mt={1}>
                    <strong className="fz-14">
                      Email:<span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={7} sm={7} md={9}>
                    <FormControl fullWidth>
                      <InputForm
                        name="email"
                        control={control}
                        size="small"
                        fullWidth
                        errors={errors}
                        disabled={!isEdit}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid item xs={5} sm={5} md={3} mt={1}>
                    <strong className="fz-14">Địa chỉ:</strong>
                  </Grid>
                  <Grid item xs={7} sm={7} md={9}>
                    <FormControl fullWidth>
                      <InputForm
                        name="address"
                        control={control}
                        size="small"
                        fullWidth
                        errors={errors}
                        disabled={!isEdit}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid item xs={5} sm={5} md={3} mt={1}>
                    <strong className="fz-14">
                      Giới tính:<span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={7} sm={7} md={9}>
                    <FormControl fullWidth>
                      <RadioInputForm
                        options={genderSelect}
                        name="gender"
                        control={control}
                        errors={errors}
                        disabled={!isEdit}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid item xs={5} sm={5} md={3} mt={1}>
                    <strong className="fz-14">
                      Vai trò:<span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={7} sm={7} md={9} mt={1}>
                    <Typography>
                      {dataApi?.role === ROLE_ADMIN ? 'Quản lý' : dataApi?.role === ROLE_EMPLOYEE ? 'Nhân viên' : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="driver-form-data">
              <Grid className="driver-form-spacing">
                {!isMobile && (
                  <Grid container mt={2}>
                    <Grid item xs={5} sm={5} md={3} mt={1}>
                      <strong className="fz-14">
                        Tên:<span className="required">*</span>
                      </strong>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <FormControl fullWidth>
                        <InputForm
                          name="lastName"
                          control={control}
                          size="small"
                          fullWidth
                          errors={errors}
                          disabled={!isEdit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <Grid container mt={2}>
                  <Grid item xs={5} sm={5} md={3} mt={1}>
                    <strong className="fz-14">
                      Số điện thoại:<span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={7} sm={7} md={9}>
                    <FormControl fullWidth>
                      <InputForm
                        name="phoneNumber"
                        control={control}
                        size="small"
                        fullWidth
                        errors={errors}
                        disabled={!isEdit}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid item xs={5} sm={5} md={3} mt={1}>
                    <strong className="fz-14">Ngày sinh:</strong>
                  </Grid>
                  <Grid item xs={7} sm={7} md={9}>
                    <FormControl fullWidth>
                      <InputFormDate name="dob" control={control} errors={errors} disabled={!isEdit} />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid item xs={5} sm={5} md={3} mt={1}>
                    <strong className="fz-14">
                      Trạng thái:<span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={7} sm={7} md={9}>
                    <FormControl fullWidth>
                      <RadioInputForm
                        options={optionsStatusAccount || []}
                        name="status"
                        control={control}
                        size="small"
                        errors={errors}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
      <HandleButtonAddEdit
        isEdit={isEdit}
        handleEdit={handleEdit}
        handleCancel={handleCancel}
        handleSave={handleSubmit(handleSave)}
        isNewData={!!isNewData}
      />
      <CustomBackdrop open={dataSelector.loadingPostProfile} />
    </>
  );
}
