import { Card, FormControl, Grid, Typography, useMediaQuery } from '@mui/material';
import { SelectSearch } from '@/common/Form';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { CONST_ROUTER } from '@/shared/constant/common';
import { BackPage } from '@/components/BackPage';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { InputPriceForm } from '@/common/Form/inputPrice';
import { useEffect, useState } from 'react';
import { HandleButtonAddEdit } from '@/components/HandleButtonAddEdit';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPriceSystem,
  clearPriceSystemDetail,
  getPriceSystemList,
  getPriceSystemDetail,
  getProvinceList,
  priceSystemData,
  putEditPriceSystem,
} from '@/redux/slices/priceSystem';
import LoadingPage from '@/components/LoadingPage';
import { CustomBackdrop } from '@/components/CustomBackdrop';

export default function AddAreaPrice() {
  const schemaAddAreaPrice = (lang) =>
    yup.object().shape({
      provinceId: yup.string().required(lang.areaPriceSetting.provinceRequired),
      firstPrice: yup
        .string()
        .required(lang.areaPriceSetting.priceRequired)
        .matches(/^[^.]*$/, lang.schema.payment.special)
        .test('is-positive', lang.schema.payment.min, (value) => {
          const priceString = String(value);
          const price = Number(priceString.replace(/\./g, ''));
          return +price >= 0;
        }),
      middlePrice: yup
        .string()
        .required(lang.areaPriceSetting.priceRequired)
        .matches(/^[^.]*$/, lang.schema.payment.special)
        .test('is-positive', lang.schema.payment.min, (value) => {
          const priceString = String(value);
          const price = Number(priceString.replace(/\./g, ''));
          return +price >= 0;
        }),
      finalPrice: yup
        .string()
        .required(lang.areaPriceSetting.priceRequired)
        .matches(/^[^.]*$/, lang.schema.payment.special)
        .test('is-positive', lang.schema.payment.min, (value) => {
          const priceString = String(value);
          const price = Number(priceString.replace(/\./g, ''));
          return +price >= 0;
        }),
      surcharge: yup
        .string()
        .required(lang.areaPriceSetting.priceRequired)
        .matches(/^[^.]*$/, lang.schema.payment.special)
        .test('is-positive', lang.schema.payment.min, (value) => {
          const priceString = String(value);
          const price = Number(priceString.replace(/\./g, ''));
          return +price >= 0;
        }),
    });
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch<any>();

  const dataSelector = useSelector(priceSystemData);
  const isLoading = dataSelector?.loadingPriceSystemDetail && dataSelector?.loadingListProvince;
  const lang = window.lang;
  const { itemId } = useParams();
  const dataApiProvince = dataSelector?.listProvince;
  const dataApiDetail = dataSelector?.priceSystemDetail.data;
  const handleBackPage = () => {
    reset();
    navigate(CONST_ROUTER.SYSTEM_PRICE_SETTING);
  };

  const CompareData = (input, dataApi) => {
    if (typeof dataApi === 'number') {
      return watch(input) === dataApi || watch(input) === dataApi.toString() ? true : false;
    }
    return watch(input) === dataApi ? true : false;
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaAddAreaPrice(lang)),
    defaultValues: {
      provinceId: '',
      firstPrice: undefined,
      middlePrice: undefined,
      finalPrice: undefined,
      surcharge: undefined,
    },
  });
  const defaultValue = {
    textSearch: null,
  };

  useEffect(() => {
    dispatch(getProvinceList(defaultValue));
    if (itemId) {
      dispatch(getPriceSystemDetail(itemId));
    }
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // Fetch the province list first
  //     await dispatch(getProvinceList(defaultValue));

  //     // Check if itemId is available and then fetch price system detail
  //     if (itemId) {
  //       await dispatch(getPriceSystemDetail(itemId));
  //     }
  //   };
  //   fetchData();
  // }, []); // Ensure dependencies are correctly listed for reactivity

  const handleCancel = () => {
    if (!itemId) {
      setValue('provinceId', '');
      setValue('firstPrice', '');
      setValue('middlePrice', '');
      setValue('finalPrice', '');
      setValue('surcharge', '');
    } else {
      setValue('provinceId', dataApiDetail?.provinceId);
      setValue('firstPrice', dataApiDetail?.firstPrice);
      setValue('middlePrice', dataApiDetail?.middlePrice);
      setValue('finalPrice', dataApiDetail?.finalPrice);
      setValue('surcharge', dataApiDetail?.surcharge);
    }
    clearErrors();
  };

  const isNewData =
    CompareData('provinceId', dataApiDetail?.provinceId) &&
    CompareData('firstPrice', dataApiDetail?.firstPrice) &&
    CompareData('finalPrice', dataApiDetail?.finalPrice) &&
    CompareData('surcharge', dataApiDetail?.surcharge) &&
    CompareData('middlePrice', dataApiDetail?.middlePrice);

  useEffect(() => {
    if (itemId && dataApiDetail) {
      setValue('provinceId', dataApiDetail?.provinceId);
      setValue('firstPrice', dataApiDetail?.firstPrice);
      setValue('middlePrice', dataApiDetail?.middlePrice);
      setValue('finalPrice', dataApiDetail?.finalPrice);
      setValue('surcharge', dataApiDetail?.surcharge);
    }
    return () => {
      dispatch(clearPriceSystemDetail);
    };
  }, [itemId, dataApiDetail]);

  const convertStringToNumber = (value) => {
    return Number(value.replace(/,/g, ''));
  };
  const onSubmit = (data) => {
    const params = {
      firstPrice: convertStringToNumber(data.firstPrice),
      middlePrice: convertStringToNumber(data.middlePrice),
      finalPrice: convertStringToNumber(data.finalPrice),
      surcharge: convertStringToNumber(data.surcharge),
      provinceId: data.provinceId,
    };
    const payload = {
      params: params,
      callbackSuccess: () => {
        showSuccessToast(lang.areaPriceSetting.successAdd);
        reset();
        navigate(CONST_ROUTER.SYSTEM_PRICE_SETTING);
        dispatch(getPriceSystemList(defaultValue));
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(addPriceSystem(payload));
  };

  const handleSave = (data) => {
    const params = {
      firstPrice: convertStringToNumber(data.firstPrice),
      middlePrice: convertStringToNumber(data.middlePrice),
      finalPrice: convertStringToNumber(data.finalPrice),
      surcharge: convertStringToNumber(data.surcharge),
      provinceId: dataApiDetail?.isDefault === true ? dataApiDetail?.provinceId : data?.provinceId,
      id: itemId,
    };
    const payload = {
      params: params,
      callbackSuccess: (isError) => {
        showSuccessToast(lang.areaPriceSetting.successEdit);
        itemId && dispatch(getPriceSystemDetail(itemId));
        reset();
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(putEditPriceSystem(payload));
  };

  return (
    <>
      <BackPage
        title={!itemId ? lang.areaPriceSetting.addAreaPrice : lang.areaPriceSetting.editAreaPrice}
        handleBackPage={handleBackPage}
        marginbottom={1}
      />
      <Card sx={{ p: 2, minHeight: '475px' }}>
        {isLoading ? (
          <LoadingPage />
        ) : (
          <>
            <Grid container spacing={1} pl={isMobile ? 1 : 10}>
              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={4} sm={3} md={3}>
                  <strong className="fz-14">
                    Khu vực:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={8} sm={6} md={3} pr={1}>
                  {dataApiDetail?.isDefault === true && itemId ? (
                    <Typography>
                      <b>Mặc định</b>
                    </Typography>
                  ) : (
                    <FormControl fullWidth>
                      <SelectSearch
                        name="provinceId"
                        control={control}
                        size="small"
                        errors={errors}
                        options={dataApiProvince || []}
                      />
                    </FormControl>
                  )}
                </Grid>
              </Grid>
              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={4} sm={3} md={3}>
                  <strong className="fz-14">
                    0 - 4km (VNĐ):<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={8} sm={6} md={3} pr={1}>
                  <FormControl fullWidth>
                    <InputPriceForm
                      name="firstPrice"
                      control={control}
                      size="small"
                      fullWidth
                      arrayErrors={errors?.firstPrice}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={4} sm={3} md={3}>
                  <strong className="fz-14">
                    {' Trên 4km - 20km (VNĐ):'}
                    <span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={8} sm={6} md={3} pr={1}>
                  <FormControl fullWidth>
                    <InputPriceForm
                      name="middlePrice"
                      control={control}
                      size="small"
                      fullWidth
                      arrayErrors={errors?.middlePrice}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={4} sm={3} md={3}>
                  <strong className="fz-14">
                    Trên 20km (VNĐ):<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={8} sm={6} md={3} pr={1}>
                  <FormControl fullWidth>
                    <InputPriceForm
                      name="finalPrice"
                      control={control}
                      size="small"
                      fullWidth
                      arrayErrors={errors?.finalPrice}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2} mb={2} spacing={1} ml={0}>
                <Grid item xs={4} sm={3} md={3}>
                  <strong className="fz-14">
                    Phụ phí sau 23h và trước 06h (VNĐ):<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={8} sm={6} md={3} pr={1}>
                  <FormControl fullWidth>
                    <InputPriceForm
                      name="surcharge"
                      control={control}
                      size="small"
                      fullWidth
                      arrayErrors={errors?.surcharge}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <HandleButtonAddEdit
              itemId={itemId}
              isEdit={true}
              handleSave={handleSubmit(handleSave)}
              handleCancel={handleCancel}
              onCancel={handleCancel}
              onSubmit={handleSubmit(onSubmit)}
              isNewData={isNewData}
            />
            <CustomBackdrop open={dataSelector?.loadingEditPriceSystem || dataSelector?.loadingAddPriceSystem} />
          </>
        )}
      </Card>
    </>
  );
}
