import { TitlePageProps } from '@/interfaces/index';
import { Typography } from '@mui/material';

export default function TitlePage({
  title,
  variant = 'h3',
  className = 'titlePage',
  gutterBottom = false,
  ...rest
}: TitlePageProps) {
  return (
    <Typography variant={variant} gutterBottom={gutterBottom} className={className} {...rest} fontSize="16px">
      <strong>{title}</strong>
    </Typography>
  );
}
