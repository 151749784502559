import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box bgcolor="white" textAlign="center" py={1}>
      <Typography fontSize="14px">&copy; {new Date().getFullYear()} CÔNG TY TNHH CÔNG NGHỆ PLATINUM</Typography>
    </Box>
  );
};

export default Footer;
