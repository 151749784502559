import {
  Box,
  Grid,
  Paper,
  Button,
  FormControl,
  Container,
  InputAdornment,
  IconButton,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { schemaLoginDriver } from '@/common/Schema';
import { CONST_ROUTER, setUserCookie } from '@/shared/constant/common';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceData, postLogin } from '@/redux/slices/auth';
import { ITypeAuth } from '@/interfaces/auth';
import ImageLogin from '../../asset/img/LogoLogin.png';
import LogoDriver from '../../asset/img/logoSafe.png';
import { InputForm } from '@/common/Form';

export default function SignInSide() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const authData: ITypeAuth = useSelector(authSliceData);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isMobile = useMediaQuery('(max-width:600px)');
  const lang = window.lang;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaLoginDriver(lang)),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = async (data): Promise<void> => {
    const params = {
      data: {
        email: data?.username.trim(),
        password: data?.password,
      },
      callBackSuccess: (data) => {
        setUserCookie(data.token);
        showSuccessToast(lang.auth.successLogin);
        navigate(CONST_ROUTER.DASH_BOARD_CHART);
      },
      callBackError: (isError) => {
        const error = Array.isArray(isError) ? isError : [isError];
        error?.length > 0
          ? error?.map((item) => {
              showErrorToast(item);
            })
          : showErrorToast(lang.auth.errorToast);
      },
    };
    dispatch(postLogin(params));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  const handleForgetPass = () => {
    navigate(CONST_ROUTER.FORGET_PASS);
  };

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <Box mt={8}>
          <Grid container>
            <Grid
              item
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${ImageLogin})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain ',
                backgroundPosition: 'center',
              }}
            />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              className="background-login"
              component={Paper}
              elevation={6}
              square
              sx={{ ...(isMobile && { boxShadow: 'none' }) }}
            >
              <Box
                my={8}
                mx={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box mt={1} className="container-logo ">
                  <img src={LogoDriver} alt="" className="logo-welcome" />
                  <strong className="text-hire-driver">{lang.auth.hireDriver}</strong>
                </Box>

                <Box mt={1}>
                  <FormControl variant="outlined" fullWidth={true} className="border-form ">
                    <strong>{lang.auth.userName}</strong>
                    <InputForm
                      control={control}
                      name="username"
                      type="text"
                      size="small"
                      placeholder={lang.auth.userName}
                      inputProps={{ autoFocus: true }}
                      onKeyDown={handleKeyDown}
                      className="input-login margin-input-login"
                      errors={errors}
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <strong>{lang.auth.password}</strong>
                    <InputForm
                      control={control}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={lang.auth.password}
                      inputProps={{ autoComplete: 'off' }}
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      onKeyDown={handleKeyDown}
                      errors={errors}
                      className="input-login margin-input-login"
                    />
                  </FormControl>
                  <Box className="forget-pass" onClick={handleForgetPass}>
                    <strong>Quên mật khẩu</strong>
                  </Box>
                  <Button
                    disabled={authData?.loadingPostLogin}
                    onClick={handleSubmit(onSubmit)}
                    fullWidth
                    className="bg-color-disable"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {authData?.loadingPostLogin ? <CircularProgress size={24} /> : lang.auth.login}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
