import { ButtonPropsI } from '@/interfaces/index';
import { Button } from '@mui/material';

export default function ButtonCustom({
  icon,
  title,
  size = 'small',
  variant = 'outlined',
  color = 'primary',
  disabled,
  fullWidth = true,
  onClick,
  className,
  ...rest
}: ButtonPropsI) {
  return (
    <Button
      size={size}
      color={color}
      variant={variant}
      startIcon={icon}
      onClick={onClick}
      disabled={disabled}
      {...rest}
      fullWidth={fullWidth}
      className={className}
    >
      <span className="fz-text-global"> {title}</span>
    </Button>
  );
}
