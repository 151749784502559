import { Grid } from '@mui/material';
import ButtonCustom from '../Buttons/ButtonCustom';
import { Edit } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { CONST_ROUTER } from '@/shared/constant/common';

export const HandleButtonAddEdit = ({
  itemId,
  isEdit,
  handleEdit,
  handleCancel,
  handleSave,
  onCancel,
  onSubmit,
  isNewData,
}: any) => {
  const lang = window.lang;
  const { pathname } = useLocation();

  return (
    <>
      {!!itemId || pathname.includes(CONST_ROUTER.PROFILE_USER) ? (
        <Grid container mt={3}>
          <Grid item xs={12} display="flex" gap={3} justifyContent="flex-end">
            {!isEdit ? (
              <ButtonCustom
                fullWidth={false}
                title="Chỉnh sửa"
                variant="contained"
                onClick={handleEdit}
                icon={<Edit />}
              />
            ) : (
              <>
                <ButtonCustom
                  fullWidth={false}
                  title={lang.button.actions.cancel}
                  variant="contained"
                  color="error"
                  onClick={handleCancel}
                />
                <ButtonCustom
                  fullWidth={false}
                  title={lang.button.actions.save}
                  variant="contained"
                  onClick={handleSave}
                  disabled={isNewData}
                />
              </>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container mt={3}>
          <Grid item xs={12} display="flex" gap={3} justifyContent="flex-end">
            <ButtonCustom
              fullWidth={false}
              color="error"
              title={lang.button.actions.cancel}
              variant="contained"
              onClick={onCancel}
            />
            <ButtonCustom fullWidth={false} title={lang.button.actions.add} variant="contained" onClick={onSubmit} />
          </Grid>
        </Grid>
      )}
    </>
  );
};
