import { CircularProgress, Stack, Typography } from '@mui/material';

const LoadingPageV2 = () => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ minHeight: '200px' }}>
      <Typography variant="h6" component="div" align="center">
        <CircularProgress />
      </Typography>
    </Stack>
  );
};
export default LoadingPageV2;
