import { Switch, FormControl, FormLabel, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';
import { RadioInputFormProps } from '@/interfaces/input';
import styled from '@emotion/styled';

// CustomSwitch
const CustomSwitch = styled(Switch)<{ theme?: any }>(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><text x="2" y="14" fill="${encodeURIComponent(
          '#fff',
        )}" font-size="10" font-family="Arial" font-weight="bold">ON</text></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.success.main,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#dddddd' : '#dddddd',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><text x="0" y="14" fill="${encodeURIComponent(
        'rgba(0, 0, 0, 0.26)',
      )}" font-size="10" font-family="Arial" font-weight="bold">OFF</text></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const SwitchInputForm = ({
  control,
  label,
  name,
  size = 'small',
  disabled = false,
  errors,
  checked,
  ...rest
}: RadioInputFormProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <>
              <FormControl>
                <FormLabel sx={{ '&.Mui-focused': { color: '#000000' }, color: '#000000' }} disabled={disabled}>
                  {label}
                </FormLabel>
                <CustomSwitch
                  {...field}
                  {...rest}
                  checked={checked}
                  onChange={(event) => {
                    field.onChange(event);
                  }}
                />
                {errors?.[name] && <FormHelperText error>{errors[name]?.message}</FormHelperText>}
              </FormControl>
            </>
          );
        }}
      />
    </>
  );
};

export default SwitchInputForm;
