import {
  CREATE_ACCOUNT,
  GET_ACCOUNT,
  DETAIL_ACCOUNT,
  UPDATE_DETAIL_ACCOUNT,
  DELTETE_ACCOUNT,
  RESET_ACCOUNT_PASSWORD,
} from '@/shared/constant/constantUrl';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api';

const initialState = {
  loadingPostAccount: false,
  listAccount: [],
  loadingListAccount: false,
  loadingDelete: false,
  loadingAccountDetail: false,
  accounDetail: [],
  loadingPutAccount: false,
  loadingResetPass: false,
};

export const postAccount = createAsyncThunk(
  'postAccount/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(CREATE_ACCOUNT, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const deleteAccount = createAsyncThunk(
  'deleteAccount/delete',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.delete(DELTETE_ACCOUNT(params.id));
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const getListEmployee = createAsyncThunk('getListEmployee', async (params: any) => {
  try {
    const response = await Api.get(GET_ACCOUNT, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getAccountDetail = createAsyncThunk('getAccountDetail/get', async (id: string) => {
  try {
    const response = await Api.get(DETAIL_ACCOUNT(id));
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const putAccountDetail = createAsyncThunk(
  'putAccountDetail/put',
  async ({ id, params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(UPDATE_DETAIL_ACCOUNT(id), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const postResetPass = createAsyncThunk(
  'postResetPass/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(RESET_ACCOUNT_PASSWORD(params.id), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearAccountDetail: (state) => {
      state.accounDetail = [];
    },
    updateStatusAccount: (state) => {
      state.loadingListAccount = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAccount.pending, (state) => {
        state.loadingPostAccount = true;
      })
      .addCase(postAccount.fulfilled, (state, action) => {
        state.loadingPostAccount = false;
      })
      .addCase(postAccount.rejected, (state) => {})

      .addCase(getListEmployee.pending, (state) => {
        state.loadingListAccount = true;
      })
      .addCase(getListEmployee.fulfilled, (state, action) => {
        state.loadingListAccount = false;
        state.listAccount = action.payload;
      })
      .addCase(getListEmployee.rejected, (state) => {
        state.listAccount = [];
      })

      .addCase(deleteAccount.pending, (state) => {
        state.loadingDelete = true;
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.loadingDelete = false;
      })
      .addCase(deleteAccount.rejected, (state) => {})

      .addCase(getAccountDetail.pending, (state) => {
        state.loadingAccountDetail = true;
        state.accounDetail = [];
      })
      .addCase(getAccountDetail.fulfilled, (state, action) => {
        state.loadingAccountDetail = false;
        state.accounDetail = action.payload;
      })
      .addCase(getAccountDetail.rejected, (state) => {})

      .addCase(putAccountDetail.pending, (state) => {
        state.loadingPutAccount = true;
      })
      .addCase(putAccountDetail.fulfilled, (state, action) => {
        state.loadingPutAccount = false;
      })
      .addCase(putAccountDetail.rejected, (state) => {
        state.loadingPutAccount = false;
      })
      .addCase(postResetPass.pending, (state) => {
        state.loadingResetPass = true;
      })
      .addCase(postResetPass.fulfilled, (state, action) => {
        state.loadingResetPass = false;
      })
      .addCase(postResetPass.rejected, (state) => {
        state.loadingResetPass = false;
      });
  },
});

export const { actions } = account;
export const accountData = (state) => state.account;
export default account.reducer;
export const { clearAccountDetail, updateStatusAccount } = account.actions;
