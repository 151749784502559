import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function CustomLightbox({ images, isOpen, photoIndex, onClose, onMovePrev, onMoveNext }: any) {
  const isSingleImage = Array.isArray(images) && images.length === 1;

  return (
    isOpen && (
      <Lightbox
        mainSrc={Array.isArray(images) ? images[photoIndex] : images}
        nextSrc={!isSingleImage ? images[(photoIndex + 1) % images.length] : null}
        prevSrc={!isSingleImage ? images[(photoIndex + images.length - 1) % images.length] : null}
        onCloseRequest={onClose}
        onMovePrevRequest={!isSingleImage ? onMovePrev : undefined}
        onMoveNextRequest={!isSingleImage ? onMoveNext : undefined}
      />
    )
  );
}

export default CustomLightbox;
