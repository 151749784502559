import AvatarAccount from '@/components/Avatar';
import { ChevronLeft, Menu, Notifications } from '@mui/icons-material';
import {
  Badge,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  DrawerProps,
  Grid,
  IconButton,
  List,
  Popover,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { CONST_ROUTER, drawerWidth } from '@/shared/constant/common';
import { Fragment, useState } from 'react';
import { ListItemSideBar } from '@/components/ListItem';
import { navConfig } from '@/shared/constant/navConfig';
import LogoDriver from '../../../asset/img/logoSafe.png';
import { NotificationList } from './Notification';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { useRole } from '@/hooks/useRole';
import { useSelector } from 'react-redux';
import { authSliceData } from '@/redux/slices/auth';
import { ROLE_ADMIN, ROLE_EMPLOYEE } from '@/shared/config/setting';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface MuiDrawerProps extends DrawerProps {
  open?: boolean;
  isMobile?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MuiDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })<MuiDrawerProps>(
  ({ theme, open, isMobile }) => ({
    '& .MuiDrawer-paper': {
      position: (isMobile && 'absolute') || 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
      }),
    },
  }),
);
export default function MainNav(props) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState<boolean>(isMobile ? false : true);
  const { setIsNavOpen, totalNotification, listBadgeNotification, clickedItems } = props;
  const lang = window.lang;
  const navigate = useNavigate();
  const { role } = useRole();
  const dataSelector = useSelector(authSliceData);
  const dataApi = dataSelector?.profileDetail?.data;
  const toggleDrawer = () => {
    if (isMobile) {
      setOpen(!open);
    }
  };

  const buttonToggle = () => {
    setOpen(!open);
    setIsNavOpen(!open);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigate = (text) => {
    navigate(text);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const badgeContent = totalNotification === 0 ? null : totalNotification > 99 ? '99+' : totalNotification;

  return (
    <>
      <CssBaseline />
      <AppBar position="absolute" open={open} className="header-container">
        <Toolbar
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <Grid item display="flex" alignItems="center">
              <IconButton
                edge="start"
                onClick={buttonToggle}
                sx={{
                  margin: '0px',
                  color: 'black',
                  ...(open && { display: 'none' }),
                }}
              >
                <Menu />
              </IconButton>
            </Grid>

            <Grid item display="flex" alignItems="center">
              <IconButton className="icon-badge" onClick={handleClick}>
                <>
                  {totalNotification === 0 ? (
                    <Notifications className="notifications-icon" />
                  ) : (
                    <Badge badgeContent={badgeContent} color="primary">
                      <Notifications className="notifications-icon" />
                    </Badge>
                  )}
                </>
              </IconButton>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ margin: '0 10px', height: '30px', alignSelf: 'auto', borderColor: '#676767' }}
              />
              <Grid className="username">
                <Typography fontWeight="800">{dataApi?.userName ? dataApi?.userName : 'noname'}</Typography>
                <Typography variant="subtitle2" fontWeight="600">
                  {dataApi?.role === ROLE_ADMIN ? 'Quản lý' : dataApi?.role === ROLE_EMPLOYEE ? 'Nhân viên' : ''}
                </Typography>
              </Grid>
              <AvatarAccount />
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                className="popover-notification"
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  className="title-notification"
                  item
                  sx={{ textAlign: 'center', p: 2 }}
                >
                  <Grid item sx={{ mr: 1 }}>
                    <NotificationsIcon />
                  </Grid>
                  <Grid item ml="95px">
                    <strong>Thông báo</strong>
                  </Grid>
                </Grid>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <NotificationList
                    listBadgeNotification={listBadgeNotification}
                    clickedItems={clickedItems}
                    handleNavigate={handleNavigate}
                  />
                </List>
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  p={1}
                  onClick={() => handleNavigate(CONST_ROUTER.NOTIFICATION)}
                >
                  <strong style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1976d2' }}>
                    Xem tất cả
                  </strong>
                </Grid>
              </Popover>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <MuiDrawer className="nav-menu" variant="permanent" open={open} isMobile={isMobile}>
        <Toolbar
          className="background-dashbroad"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <Box className="container-nav">
            <img src={LogoDriver} alt="" className="logo-forget" />
            <strong className="text-hire-driver">{lang.auth.hireDriver}</strong>
          </Box>
          <IconButton onClick={buttonToggle} sx={{ color: 'white' }}>
            <ChevronLeft />
          </IconButton>
        </Toolbar>
        {/* <Divider sx={{ alignSelf: 'auto', borderColor: 'white' }} /> */}
        <List sx={{ p: 0 }} component="nav" className="scroll-nav">
          {navConfig(window?.lang).map((item: object, index: number) => {
            return (
              <Fragment key={index}>
                <ListItemSideBar item={item} role={role} />
              </Fragment>
            );
          })}
        </List>
      </MuiDrawer>
    </>
  );
}
