import { Grid } from '@mui/material';
import TitlePage from '@/components/TitlePage';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { AddCircle, Padding } from '@mui/icons-material';
import TableComponent from '@/components/TableComponent';
import { COLS_AREA_PRICE } from '@/shared/constant/TableHead';
import { useEffect, useState } from 'react';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import ConfirmPopup from '@/components/ConfirmPopup';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { deletePriceSystem, getPriceSystemList, priceSystemData } from '@/redux/slices/priceSystem';
import { useDispatch, useSelector } from 'react-redux';
import { parseSystemPriceData } from '@/shared/constant/dataTable';
import { useQueryParameters } from '@/shared/utils/common';
import { CustomBackdrop } from '@/components/CustomBackdrop';

export default function SystemPriceSetting() {
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(priceSystemData);
  const isLoading = dataSelector.loadingListPriceSystem;
  const dataApi = dataSelector.listPriceSystem.data;
  const lang = window.lang;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const queriesParams: any = useQueryParameters();
  const [queryFilter, setQueryfilter] = useState(queriesParams);
  const defaultValue = {
    pageIndex: 1,
    pageSize: PAGE_SIZE,
  };

  useEffect(() => {
    dispatch(getPriceSystemList(defaultValue));
  }, []);

  const systemPriceDataFormated = parseSystemPriceData(dataApi, currentPage, dataSelector.listPriceSystem.totalCount);

  const handleDelete = (id) => {
    const confirmDelete = () => {
      const payload = {
        params: {
          id: id,
        },
        callbackSuccess: () => {
          setCurrentPage(1);
          showSuccessToast(lang.areaPriceSetting.successDelete);
          dispatch(getPriceSystemList(defaultValue));
        },
        callbackError: (isError) => {
          showErrorToast(isError);
        },
      };
      dispatch(deletePriceSystem(payload));
    };
    openPopup(lang.areaPriceSetting.modalText.delete, confirmDelete);
  };

  const handleEdit = (id) => {
    setIsEdit(true);
    navigate(CONST_ROUTER.AREA_PRICE_DETAIL_ITEM(id));
  };
  const handleAdd = () => {
    navigate(CONST_ROUTER.ADD_AREA_PRICE);
  };

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      pageIndex: page,
      pageSize: PAGE_SIZE,
    };
    dispatch(getPriceSystemList(data));
  };
  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
          <TitlePage title={lang.areaPriceSetting.areaPriceTitle} />
        </Grid>
      </Grid>
      <Grid style={{display: 'flex', justifyContent: 'flex-end'}}>
        <ButtonCustom
          icon={<AddCircle />}
          color="primary"
          title={lang.driverManagement.addNew}
          variant="contained"
          onClick={handleAdd}
          fullWidth={false}
          sx={{padding: '8px 16px'}}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <Grid item xs={12} mt={1}>
          <TableComponent
            onDelete={handleDelete}
            onEdit={handleEdit}
            tableHead={COLS_AREA_PRICE}
            listData={systemPriceDataFormated}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isLoading={isLoading}
            totalRecord={dataApi?.data?.length}
            totalItems={dataSelector?.listPriceSystem.totalCount}
            pagingCallback={(page) => handlePaging(page)}
          />
        </Grid>
        <ConfirmationPopup />
        <CustomBackdrop open={dataSelector?.loadingEditPriceSystem || dataSelector?.loadingDeletePriceSystem} />
      </Grid>
    </>
  );
}
