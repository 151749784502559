import { Menu, MenuItem } from '@mui/material';

export default function AvatarMenu({ actions, actionInfo, handleCloseMenu, anchorEl }) {
  return (
    <Menu
      id="menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {actions.map((action) => {
        const info = actionInfo.find((item) => item.action === action);
        if (info) {
          return (
            <MenuItem
              key={info.action}
              onClick={() => {
                if (info && info.onClick) {
                  info.onClick();
                }
                handleCloseMenu();
              }}
              sx={info?.sx}
              disabled={info.disabled}
            >
              {info.isUpload ? (
                <label htmlFor="avatar" style={{ cursor: 'pointer', display: 'flex' }}>
                  {info.icon}
                  {info.label}
                </label>
              ) : (
                <>
                  {info.icon}
                  {info.label}
                </>
              )}
            </MenuItem>
          );
        }
        return null;
      })}
    </Menu>
  );
}
