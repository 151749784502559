import { IconButton, InputAdornment } from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { InputPasswordProps } from '@/interfaces';

export const IconPassword = ({ showPassword, onClick }: InputPasswordProps) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={onClick}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
    </InputAdornment>
  );
};
