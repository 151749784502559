import {
  CHANGE_PASSWORD,
  FORGET_PASS,
  LOGIN_URL,
  LOGOUT_URL,
  UPDATE_USER_INFO,
  USER_INFO,
} from '@/shared/constant/constantUrl';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api';
import { IPostLogin } from '@/interfaces/auth';

const initialState = {
  loadingPostLogin: false,
  loadingPostForgetPass: false,
  loadingLogout: false,
  loadingChangePass: false,
  loadingProfileDetail: false,
  profileDetail: [],
  loadingPostProfile: false,
};

export const getProfileDetail = createAsyncThunk('getProfileDetail/get', async () => {
  try {
    const response = await Api.get(USER_INFO);
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const postProfileDetail = createAsyncThunk(
  'postProfileDetail/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(UPDATE_USER_INFO, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const postLogin = createAsyncThunk('postLogin', async ({ data, callBackSuccess, callBackError }: IPostLogin) => {
  try {
    const response = await Api.post(LOGIN_URL, data);
    callBackSuccess?.(response?.data);
    return response?.data;
  } catch (error: any) {
    callBackError?.(error?.data?.message || error?.data?.Message || error?.message);
    return error;
  }
});

export const changePass = createAsyncThunk(
  'changePass/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(CHANGE_PASSWORD, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const postLogout = createAsyncThunk('postLogout', async ({ params, callBackSuccess, callBackError }: any) => {
  try {
    const response = await Api.post(LOGOUT_URL, params);
    callBackSuccess?.();
    return response?.data;
  } catch (error: any) {
    callBackError?.(error?.data?.message || error?.data?.Message || error?.message);
    return error;
  }
});

export const postForgetPass = createAsyncThunk(
  'postForgetPass',
  async ({ data, callBackSuccess, callBackError }: any) => {
    try {
      const response = await Api.post(FORGET_PASS, data);
      callBackSuccess?.(response?.data);
      return response?.data;
    } catch (error: any) {
      callBackError?.(error?.data?.message || error?.data?.Message || error?.message);
      return error;
    }
  },
);

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    clearProfileDetail: (state) => {
      state.profileDetail = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.pending, (state) => {
        state.loadingPostLogin = true;
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.loadingPostLogin = false;
      })
      .addCase(postLogin.rejected, (state) => {
        state.loadingPostLogin = false;
      })

      .addCase(postForgetPass.pending, (state) => {
        state.loadingPostForgetPass = true;
      })
      .addCase(postForgetPass.fulfilled, (state, action) => {
        state.loadingPostForgetPass = false;
      })
      .addCase(postForgetPass.rejected, (state) => {})

      .addCase(postLogout.pending, (state) => {
        state.loadingLogout = true;
      })
      .addCase(postLogout.fulfilled, (state, action) => {
        state.loadingLogout = false;
      })
      .addCase(postLogout.rejected, (state) => {
        state.loadingLogout = false;
      })

      .addCase(changePass.pending, (state) => {
        state.loadingChangePass = true;
      })
      .addCase(changePass.fulfilled, (state, action) => {
        state.loadingChangePass = false;
      })
      .addCase(changePass.rejected, (state) => {
        state.loadingChangePass = false;
      })
      .addCase(getProfileDetail.pending, (state) => {
        state.loadingProfileDetail = true;
      })
      .addCase(getProfileDetail.fulfilled, (state, action) => {
        state.loadingProfileDetail = false;
        state.profileDetail = action.payload;
      })
      .addCase(getProfileDetail.rejected, (state) => {
        state.loadingProfileDetail = false;
        state.profileDetail = [];
      })
      .addCase(postProfileDetail.pending, (state) => {
        state.loadingPostProfile = true;
      })
      .addCase(postProfileDetail.fulfilled, (state, action) => {
        state.loadingPostProfile = false;
      })
      .addCase(postProfileDetail.rejected, (state) => {
        state.loadingPostProfile = false;
      });
  },
});

export const { actions } = authSlice;
export const authSliceData = (state) => state.authSlice;
export default authSlice.reducer;
export const { clearProfileDetail } = authSlice.actions;
