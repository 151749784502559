import { Box, Pagination, useMediaQuery } from '@mui/material';
import { PAGE_SIZE } from '@/shared/constant/common';
import { PaginationTableProps } from '@/interfaces/table';

export default function TablePagination({
  currentPage,
  totalItems = 0,
  setCurrentPage = () => {},
  isHiddenRecord,
  pagingCallback,
  isLoading,
  isPageSize,
}: PaginationTableProps) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const pageCount = isPageSize ? Math.ceil(totalItems / isPageSize) : Math.ceil(totalItems / PAGE_SIZE);

  const handlePageChange = (event: unknown, page: number) => {
    if (pagingCallback) {
      pagingCallback(page);
    }
    setCurrentPage(page);
  };

  return (
    <div>
      <Box className="table_pagination_title">
        <strong className="fz-text-global">
          {!isHiddenRecord && !isLoading && totalItems > 0 && `Tổng số dòng: ${totalItems}`}
        </strong>
      </Box>
      <Box className="footer-table">
        {pageCount > 1 && (
          <Pagination
            sx={{ margin: 2, display: 'flex', justifyContent: 'center' }}
            variant="outlined"
            color="primary"
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            defaultPage={isMobile ? pageCount / 2 : undefined}
            siblingCount={isMobile ? 0 : undefined}
          />
        )}
      </Box>
    </div>
  );
}
