import { Collapse, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const ListItemSideBar = ({ item, role }) => {
  const { pathname } = useLocation();

  const checkActiveItem = (currentPath, targetPath) => (currentPath?.includes(targetPath) ? 'active' : '');
  const isActive = item?.subItem?.some((sub) => pathname?.includes(sub?.path));

  const [isOpenSubNav, setIsOpenSubNav] = useState(item?.subItem?.[0]?.path === '/user' || isActive);
  const Icon = item.icon;
  const handleClick = () => {
    setIsOpenSubNav(!isOpenSubNav);
  };

  useEffect(() => {
    isActive && setIsOpenSubNav(true);
  }, [pathname]);

  const renderListItem = (content, key) =>
    content.path ? (
      <Link
        key={key}
        className={`text_link ${checkActiveItem(pathname, content.path)}`}
        to={content.path}
        style={{ textDecoration: 'none', color: checkActiveItem(pathname, content.path) ? 'black' : 'white' }}
      >
        {content.element}
      </Link>
    ) : (
      <div
        key={key}
        className={`text_link ${checkActiveItem(pathname, content.path)}`}
        style={{ color: checkActiveItem(pathname, content.path) ? 'black' : 'white', cursor: 'default' }}
      >
        {content.element}
      </div>
    );

  return (
    item.role.includes(role) && (
      <>
        {renderListItem(
          {
            ...item,
            element: (
              <ListItemButton onClick={item.subItem ? handleClick : () => {}}>
                <ListItemIcon className="custom-icon-nav">
                  <Icon className={`text_link ${checkActiveItem(pathname, item.path)}`} />
                </ListItemIcon>
                <ListItemText primary={item.title} />
                {item.subItem && (isOpenSubNav ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
            ),
          },
          item.path,
        )}

        {item.subItem && (
          <Collapse in={isOpenSubNav} timeout="auto" unmountOnExit>
            {item.subItem.map((sub, subKey) =>
              renderListItem(
                {
                  ...sub,
                  element: (
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon className="custom-icon-nav" sx={{ color: 'white' }}>
                        <sub.iconSub className={`text_link ${checkActiveItem(pathname, sub.path)}`} />
                      </ListItemIcon>
                      <ListItemText primary={sub.title} />
                    </ListItemButton>
                  ),
                },
                subKey,
              ),
            )}
          </Collapse>
        )}
      </>
    )
  );
};
