import { showErrorToast } from '@/hooks/useCustomToast';
import { handleError } from '@/shared/utils/common';
import { AccountCircle, CameraAlt } from '@mui/icons-material';
import { Grid, TextField, Typography } from '@mui/material';

export default function AccountAvatar({
  avatar,
  isEdit = true,
  handleMenuClick,
  setAvatar,
  setFile,
  setIsDelete = {},
}) {
  const lang = window.lang;
  const handleChangingProfilePicture = (e, setImg, setFileImg, setIsDeleteImg?) => {
    const file = e.target.files[0];
    setFileImg(file);
    const files = e.target.files;
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    for (let i = 0; i < files.length; i++) {
      try {
        if (!allowedTypes.includes(file.type)) {
          throw new Error(lang.imageOrder.validateImg);
        } else {
          const imgUrl = URL.createObjectURL(file);
          setImg(imgUrl);
          setIsDeleteImg?.(false);
        }
      } catch (error) {
        showErrorToast(lang.errorMessage.fileType);
      }
    }
    e.target.value = '';
  };
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ position: 'relative' }}>
      <Grid sx={{ width: 'auto', position: 'relative', textAlign: 'center' }}>
        <Typography>
          <strong>{lang.profile.avatar}</strong>
        </Typography>
        {avatar ? (
          <div className="image-container">
            <img onError={handleError} src={avatar} alt="Selected" className="round-image" />
          </div>
        ) : (
          <AccountCircle sx={{ fontSize: 140, color: 'primary' }} />
        )}
        {isEdit && (
          <div className="camera-alt-position">
            <div className="camera-alt-background" onClick={handleMenuClick}>
              <CameraAlt sx={{ color: 'primary', fontSize: 22 }} />
              <TextField
                className="image-hidden"
                inputProps={{
                  accept: 'image/*',
                }}
                id="avatar"
                type="file"
                onChange={(event) => {
                  handleChangingProfilePicture(event, setAvatar, setFile, setIsDelete);
                }}
              />
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
}
