import { Card, Divider, FormControl, Grid, Rating, useMediaQuery } from '@mui/material';
import TitlePage from '@/components/TitlePage';
import { InputForm } from '@/common/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { convertToDateOrNull, convertToYYYY, createNewPath, formatDate } from '@/shared/utils/common';
import { useEffect, useState } from 'react';
import { schemaEvalutation } from '@/common/Schema';
import { FROM_DATE, MONTH_YEAR } from '@/shared/constant/text';
import StarIcon from '@mui/icons-material/Star';
import TableComponent from '@/components/TableComponent';
import { STATICTIS_VOTE } from '@/shared/constant/TableHead';
import { parseStatictisVote } from '@/shared/constant/dataTable';
import { CustomDateField } from '@/components/CustomDateField';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';
import { getListEvaluation, getTotalEvaluation, statisticData } from '@/redux/slices/statictis';
import { useDispatch, useSelector } from 'react-redux';
import { CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { useNavigate } from 'react-router-dom';

export default function EvaluationStatistics() {
  const lang = window.lang;
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLarge = useMediaQuery('(min-width:1200px)');
  const [activeRateVote, setActiveRateVote] = useState(null);
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(statisticData);
  const typeMonthYear = 2;
  const navigate = useNavigate();
  const { listEvaluation, loadingListEvaluation, totalEvaluation, loadingTotalEvaluation } = dataSelector;
  const defaultParams = {
    Page: 1,
    Take: PAGE_SIZE,
    DateType: typeMonthYear,
    StartDate: formatDate(new Date()),
    Rating: activeRateVote,
  };
  const [queryFilter, setQueryfilter] = useState<any>(defaultParams);

  const handleCardClick = (rateVote) => {
    setCurrentPage(1);
    setActiveRateVote(activeRateVote === rateVote ? null : rateVote);
  };

  useEffect(() => {
    const paramsIsRating = {
      ...queryFilter,
      Rating: activeRateVote || null,
    };
    dispatch(getTotalEvaluation(paramsIsRating));
    dispatch(getListEvaluation(paramsIsRating));
  }, [activeRateVote]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaEvalutation(lang)),
    defaultValues: {
      quarter: null,
      monthYear: new Date(),
      customer: '',
      driver: '',
      vote: '',
      endDay: convertToDateOrNull(null),
      year: null,
      quarterYear: null,
      startDay: convertToDateOrNull(null),
      dateFilter: MONTH_YEAR,
    },
  });
  const onSubmit = (data) => {
    const dataSubmit = {
      Customer: data.customer,
      Driver: data.driver,
      DateType: data.dateFilter,
      Quarter: data.quarter,
      Rating: activeRateVote,
      Year: convertToYYYY(data.year) || convertToYYYY(data.quarterYear),
      StartDate:
        data.dateFilter === FROM_DATE
          ? formatDate(data.startDay)
          : data.dateFilter === MONTH_YEAR
          ? formatDate(data.monthYear)
          : null,
      EndDate: data.dateFilter === FROM_DATE ? formatDate(data.endDay) : null,
      Page: 1,
      Take: PAGE_SIZE,
    };
    setCurrentPage(1);
    setQueryfilter(dataSubmit);
    dispatch(getTotalEvaluation(dataSubmit));
    dispatch(getListEvaluation(dataSubmit));
  };
  const handleResetFilter = () => {
    reset();
    setCurrentPage(1);
    setQueryfilter(defaultParams);
    dispatch(getTotalEvaluation(defaultParams));
    dispatch(getListEvaluation(defaultParams));
  };
  const dateFilter = watch('dateFilter');

  const handleNavigate = (id) => {
    const params = {
      isRouter: 'evaluation',
    };
    const newPath = createNewPath(CONST_ROUTER.TRIP_DETAIL1_ITEM(id), params);
    navigate(newPath);
  };

  const renderStar = (value) => {
    return (
      <>
        <Rating
          name="text-feedback"
          value={value}
          readOnly
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
      </>
    );
  };
  const dataTable = parseStatictisVote(listEvaluation?.data?.data, currentPage, handleNavigate, renderStar);

  const renderFieldGrid = (rateVote, vote, percentage) => {
    return (
      <Grid item xs={6} sm={2.4}>
        <Card
          className={`card-vote ${rateVote === activeRateVote ? 'active' : ''}`}
          onClick={() => handleCardClick(rateVote)}
        >
          <strong className="fz-16">Thống kê đánh giá {rateVote} sao: </strong>
          <Divider className="bd-vote" />
          <div
            className="text-red-statictis fz-18"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <strong>{vote} </strong>
            <Rating
              name="text-feedback"
              value={rateVote}
              readOnly
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
          </div>
          <strong className="fz-18">{percentage}</strong>
        </Card>
      </Grid>
    );
  };

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      Page: page,
      Take: PAGE_SIZE,
      Rating: activeRateVote,
    };
    dispatch(getTotalEvaluation(data));
    dispatch(getListEvaluation(data));
  };

  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
          <TitlePage title="THỐNG KÊ ĐÁNH GIÁ" />
        </Grid>
      </Grid>

      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container gap={2} flexWrap={isLarge ? 'nowrap' : 'wrap'}>
          <Grid container xs={12} md={12} lg={7} gap={1} flexWrap={isMobile ? 'wrap' : 'nowrap'}>
            <Grid container xs={12} sm={12} md={12} gap={1} flexWrap="nowrap">
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputForm
                    label="Nhập tên tài xế, số điện thoại"
                    control={control}
                    name="driver"
                    errors={errors}
                    // isLoading={isLoading}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputForm
                    label="Nhập tên khách hàng, số điện thoại"
                    control={control}
                    name="customer"
                    errors={errors}
                    // isLoading={isLoading}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <CustomDateField dateFilter={dateFilter} control={control} errors={errors} isAll={false} />
        </Grid>
        <ButtonSearch
          submit={handleSubmit(onSubmit)}
          resetSearch={handleResetFilter}
          handleDownFile={null}
          add={null}
        />
      </Card>

      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container spacing={2}>
          {renderFieldGrid(
            5,
            totalEvaluation?.data?.fiveStarRating?.totalRating || 0,
            `${totalEvaluation?.data?.fiveStarRating?.ratingPercentage || 0}%`,
          )}
          {renderFieldGrid(
            4,
            totalEvaluation?.data?.fourStarRating?.totalRating || 0,
            `${totalEvaluation?.data?.fourStarRating?.ratingPercentage || 0}%`,
          )}
          {renderFieldGrid(
            3,
            totalEvaluation?.data?.threeStarRating?.totalRating || 0,
            `${totalEvaluation?.data?.threeStarRating?.ratingPercentage || 0}%`,
          )}
          {renderFieldGrid(
            2,
            totalEvaluation?.data?.twoStarRating?.totalRating || 0,
            `${totalEvaluation?.data?.twoStarRating?.ratingPercentage || 0}%`,
          )}
          {renderFieldGrid(
            1,
            totalEvaluation?.data?.oneStarRating?.totalRating || 0,
            `${totalEvaluation?.data?.oneStarRating?.ratingPercentage || 0}%`,
          )}
        </Grid>
      </Card>

      <Grid item xs={12} mt={1}>
        <strong>Danh sách đánh giá của khách hàng</strong>
      </Grid>

      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={STATICTIS_VOTE}
          listData={dataTable}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loadingListEvaluation}
          totalRecord={listEvaluation?.data?.data?.length}
          totalItems={listEvaluation?.data?.totalCount}
          pagingCallback={(page) => handlePaging(page)}
          className="scroll-table"
        />
      </Grid>
    </>
  );
}
