import { Card, FormControl, Grid } from '@mui/material';
import { InputForm, SelectForm } from '@/common/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { optionsStatusVehicle } from '@/shared/options';
import { useNavigate, useParams } from 'react-router-dom';
import { CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { BackPage } from '@/components/BackPage';
import { schemaVehicleClass } from '@/common/Schema';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { useEffect, useState } from 'react';
import { HandleButtonAddEdit } from '@/components/HandleButtonAddEdit';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { useDispatch, useSelector } from 'react-redux';
import {
  detailVehicleClass,
  getVehicleClass,
  postVehicleClass,
  putVehicleClass,
  vehicleClassData,
} from '@/redux/slices/vehicleClass';
import ConfirmPopup from '@/components/ConfirmPopup';
import LoadingPage from '@/components/LoadingPage';

export default function AddVeihicleClass() {
  const navigate = useNavigate();
  const lang = window.lang;
  const { itemId } = useParams();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const selectorData = useSelector(vehicleClassData);
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const notActive = '2';

  useEffect(() => {
    if (itemId) {
      dispatch(detailVehicleClass(itemId));
    }
  }, [itemId]);

  const defaultValue = {
    page: 1,
    take: PAGE_SIZE,
    Text: '',
    Status: '',
  };

  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaVehicleClass(lang)),
    defaultValues: {
      name: '',
      status: '1',
      about: '',
    },
  });

  const NOTACTIVE = 0;
  const dataApi = selectorData?.listDetail?.data;
  const isLoading = selectorData?.loadingListDetail;

  useEffect(() => {
    if (dataApi && itemId) {
      setValue('name', dataApi?.name);
      setValue('about', dataApi?.desciption);
      setValue('status', dataApi?.status === NOTACTIVE ? '2' : dataApi?.status);
    }
  }, [dataApi, itemId]);

  const CompareData = (input, dataApi) => {
    if (typeof dataApi === 'number') {
      return watch(input) === dataApi || watch(input) === dataApi.toString() ? true : false;
    }
    return watch(input) === dataApi ? true : false;
  };
  const isNewData =
    CompareData('name', dataApi?.name) &&
    CompareData('about', dataApi?.desciption) &&
    CompareData('status', dataApi?.status === NOTACTIVE ? '2' : dataApi?.status);

  const onSubmit = (data) => {
    const params = {
      name: data.name,
      status: data.status === notActive ? 0 : +data.status,
      desciption: data.about,
    };

    const payload = {
      params: params,
      callbackSuccess: () => {
        showSuccessToast(lang.vehicleClass.addSuccess);
        dispatch(getVehicleClass(defaultValue));
        reset();
        navigate(CONST_ROUTER.VEHICLE_CLASS);
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(postVehicleClass(payload));
  };

  const handlePopup = (data) => {
    openPopup(lang.vehicleClass.popUpAdd, () => onSubmit(data));
  };

  const onCancel = () => {
    reset();
    clearErrors();
  };

  const handleBackPage = () => {
    reset();
    navigate(CONST_ROUTER.VEHICLE_CLASS);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    setValue('name', dataApi?.name);
    setValue('about', dataApi?.desciption);
    setValue('status', dataApi?.status === 0 ? '2' : dataApi?.status);
    setIsEdit(false);
    clearErrors();
  };

  const handleSave = (data) => {
    const payload = {
      id: itemId,
      params: {
        name: data.name,
        status: data.status === notActive ? 0 : +data.status,
        desciption: data.about,
      },
      callbackSuccess: () => {
        showSuccessToast('Chỉnh sửa hạng xe thành công');
        reset();
        setIsEdit(false);
        dispatch(detailVehicleClass(itemId));
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(putVehicleClass(payload));
  };
  return (
    <>
      <BackPage
        title={!!itemId ? lang.vehicleClass.editVehicleClass : lang.vehicleClass.addVehicleClass}
        handleBackPage={handleBackPage}
      />
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Card sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} display="flex" flexWrap="wrap" gap={2}>
              <Grid item xs={4} md={3}>
                <strong className="fz-14">
                  {lang.vehicleClass.name}<span className="required">*</span>
                </strong>
              </Grid>
              <Grid item xs={7} md={8}>
                <FormControl fullWidth>
                  <InputForm
                    name="name"
                    control={control}
                    size="small"
                    fullWidth
                    errors={errors}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4} md={3}>
                <strong className="fz-14">
                  {lang.vehicleClass.about}<span className="required">*</span>
                </strong>
              </Grid>
              <Grid item xs={7} md={8}>
                <FormControl fullWidth>
                  <InputForm
                    name="about"
                    control={control}
                    size="small"
                    fullWidth
                    errors={errors}
                    multiline={true}
                    rows={4}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4} md={3}>
                <strong className="fz-14">
                  {lang.vehicleClass.status}<span className="required">*</span>
                </strong>
              </Grid>
              <Grid item xs={7} md={8}>
                <FormControl fullWidth>
                  <SelectForm
                    options={optionsStatusVehicle}
                    name="status"
                    control={control}
                    size="small"
                    errors={errors}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
      <CustomBackdrop open={selectorData.loadingPostVehicleClass || selectorData.loadingPutVehicleClass} />
      <HandleButtonAddEdit
        itemId={itemId}
        isEdit={true}
        handleEdit={handleEdit}
        handleCancel={handleCancel}
        handleSave={handleSubmit(handleSave)}
        onCancel={onCancel}
        onSubmit={handleSubmit(handlePopup)}
        isNewData={isNewData}
      />
      <ConfirmationPopup />
    </>
  );
}
