export const optionStatusCustomer = [
  {
    id: 3,
    label: 'Đang hoạt động',
  },
  {
    id: 1,
    label: 'Không hoạt động',
  },
  {
    id: 2,
    label: 'Đã chặn',
  },
];

export const optionsRole = [
  {
    id: 3,
    label: 'Admin',
  },
  {
    id: 4,
    label: 'Nhân viên',
  },
];

export const optionsStatusVehicle = [
  {
    id: '1',
    label: 'Đang hoạt động',
  },
  {
    id: '2',
    label: 'Không hoạt động',
  },
];

export const optionsStatusOffer = [
  {
    id: '2',
    label: 'Đang hoạt động',
  },
  {
    id: '1',
    label: 'Không hoạt động',
  },
];

export const optionsStatusDriver = [
  {
    id: 1,
    label: 'Đang hoạt động',
  },
  {
    id: 2,
    label: 'Không hoạt động',
  },
  {
    id: 3,
    label: 'Đã chặn',
  },
];

export const optionsStatusCustomer = [
  {
    id: 3,
    label: 'Đang hoạt động',
  },
  {
    id: 1,
    label: 'Không hoạt động',
  },
  {
    id: 2,
    label: 'Đã chặn',
  },
];

export const optionsRoleList = [
  {
    id: '4',
    label: 'Admin',
  },
  {
    id: '1',
    label: 'Nhân viên',
  },
];

export const optionsStatusAccount = [
  {
    id: 2,
    label: 'Đang hoạt động',
  },
  {
    id: 1,
    label: 'Không hoạt động',
  },
];

export const statusDriver = [
  {
    id: '1',
    label: 'Tài xế đang rảnh',
  },
  {
    id: '2',
    label: 'Tài xế đang trong hành trình',
  },
  {
    id: '3',
    label: 'Tài xế sắp đến điểm kết thúc',
  },
];

export const optionsRoleAccount = [
  {
    id: 4,
    label: 'Admin',
  },
  {
    id: 1,
    label: 'Nhân viên',
  },
];

export const typeOffer = [
  {
    id: '2',
    label: 'Theo %',
  },
  {
    id: '1',
    label: 'Theo số tiền',
  },
];

export const subjectsApply = [
  {
    id: '2',
    label: 'Khuyến mãi chung',
  },
  {
    id: '1',
    label: 'Áp dụng theo số chuyến',
  },
];

export const genderSelect = [
  {
    id: 1,
    label: 'Nam',
  },
  {
    id: 2,
    label: 'Nữ',
  },
  {
    id: -1,
    label: 'Khác',
  },
];

export const driverStatus = [
  {
    id: 1,
    label: 'Đang hoạt động',
  },
  {
    id: 2,
    label: 'Không hoạt động',
  },
  {
    id: 3,
    label: 'Đã chặn',
  },
  {
    id: 4,
    label: 'Chưa kích hoạt',
  },
];

export const tooltipStatus = {
  1: 'Đang hoạt động',
  2: 'Không hoạt động',
  3: 'Đã chặn',
};

export const tripStatus = [
  {
    id: 7,
    label: 'Đang tìm tài xế',
  },
  {
    id: 1,
    label: 'Đã tìm thấy tài',
  },
  {
    id: 2,
    label: 'Tài xế đã đến',
  },
  {
    id: 3,
    label: 'Đã xuất phát',
  },
  {
    id: 4,
    label: 'Đã kết thúc',
  },
  {
    id: 5,
    label: 'Đã thanh toán',
  },
  {
    id: 6,
    label: 'Đã hủy',
  },
];

export const payment = [
  {
    id: 1,
    label: 'Tiền mặt',
  },
  {
    id: 2,
    label: 'VNPay',
  },
];

export const paymentStatus = [
  {
    id: 1,
    label: 'Đang hoạt động',
  },
  {
    id: 2,
    label: 'Không hoạt động',
  },
];

export const optionsFilterDate = [
  {
    value: '1',
    label: 'Khoảng ngày',
  },
  {
    value: '2',
    label: 'Tháng',
  },
  {
    value: '3',
    label: 'Quý',
  },
  {
    value: '4',
    label: 'Năm',
  },
];

export const optionsQuarter = [
  {
    value: '1',
    label: 'Quý 1',
  },
  {
    value: '2',
    label: 'Quý 2',
  },
  {
    value: '3',
    label: 'Quý 3',
  },
  {
    value: '4',
    label: 'Quý 4',
  },
];
