import {
  DELETE_VEHICLE_CLASS,
  DETAIL_VEHICLE_CLASS,
  POST_VEHICLE_CLASS,
  PUT_VEHICLE_CLASS,
  VEHICLE_CLASS,
} from '@/shared/constant/constantUrl';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api';

const initialState = {
  listVehicle: [],
  loadingListVehicle: false,
  loadingPostVehicleClass: false,
  loadingPutVehicleClass: false,
  loadingDeleteVehicleClass: false,
  listDetail: [],
  loadingListDetail: false,
};

export const postVehicleClass = createAsyncThunk(
  'postVehicleClass/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(POST_VEHICLE_CLASS, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const putVehicleClass = createAsyncThunk(
  'putVehicleClass/put',
  async ({ id, params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(PUT_VEHICLE_CLASS(id), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const getVehicleClass = createAsyncThunk('getVehicleClass', async (params: any) => {
  try {
    const response = await Api.get(VEHICLE_CLASS, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const detailVehicleClass = createAsyncThunk('detailVehicleClass', async (id: any) => {
  try {
    const response = await Api.get(DETAIL_VEHICLE_CLASS(id));
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const deleteVehicleClass = createAsyncThunk(
  'deleteVehicleClass/delete',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.delete(DELETE_VEHICLE_CLASS(params.id), { params });
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const vehicleClass = createSlice({
  name: 'vehicleClass',
  initialState,
  reducers: {
    updateStatusVehicle: (state) => {
      state.loadingListVehicle = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVehicleClass.pending, (state) => {
        state.loadingListVehicle = true;
      })
      .addCase(getVehicleClass.fulfilled, (state, action) => {
        state.loadingListVehicle = false;
        state.listVehicle = action.payload;
      })
      .addCase(getVehicleClass.rejected, (state) => {
        state.listVehicle = [];
      })

      .addCase(detailVehicleClass.pending, (state) => {
        state.loadingListDetail = true;
      })
      .addCase(detailVehicleClass.fulfilled, (state, action) => {
        state.loadingListDetail = false;
        state.listDetail = action.payload;
      })
      .addCase(detailVehicleClass.rejected, (state) => {
        state.listDetail = [];
      })

      .addCase(postVehicleClass.pending, (state) => {
        state.loadingPostVehicleClass = true;
      })
      .addCase(postVehicleClass.fulfilled, (state, action) => {
        state.loadingPostVehicleClass = false;
      })
      .addCase(postVehicleClass.rejected, (state) => {})

      .addCase(putVehicleClass.pending, (state) => {
        state.loadingPutVehicleClass = true;
      })
      .addCase(putVehicleClass.fulfilled, (state, action) => {
        state.loadingPutVehicleClass = false;
      })
      .addCase(putVehicleClass.rejected, (state) => {})

      .addCase(deleteVehicleClass.pending, (state) => {
        state.loadingDeleteVehicleClass = true;
      })
      .addCase(deleteVehicleClass.fulfilled, (state) => {
        state.loadingDeleteVehicleClass = false;
      })
      .addCase(deleteVehicleClass.rejected, (state) => {});
  },
});

export const { actions } = vehicleClass;
export const vehicleClassData = (state) => state.vehicleClass;
export default vehicleClass.reducer;
export const { updateStatusVehicle } = vehicleClass.actions;
