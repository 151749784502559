import { Stack } from '@mui/material';
import TitlePage from '../TitlePage';
import ButtonArrowBack from '../Buttons/ButtonArrowBack';

export const BackPage = ({ title, handleBackPage, marginbottom = 0 }) => {
  return (
    <>
      <Stack direction="row" alignItems="center" className="border-button-back page-title" mb={marginbottom}>
        <ButtonArrowBack onClick={handleBackPage} fontSize="small" />
        <TitlePage variant="h4" title={title} />
      </Stack>
    </>
  );
};
