import { Card, FormControl, Grid } from '@mui/material';
import TitlePage from '@/components/TitlePage';
import { InputForm, SelectSearch } from '@/common/Form';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { optionStatusCustomer } from '@/shared/options';
import { Block, Close, Done } from '@mui/icons-material';
import TableComponent from '@/components/TableComponent';
import { MANAGEMENT_CUSTOMER } from '@/shared/constant/TableHead';
import { useEffect, useState } from 'react';
import { parseDataCustomer } from '@/shared/constant/dataTable';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTER, PAGE_SIZE } from '@/shared/constant/common';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import ConfirmPopup from '@/components/ConfirmPopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  customerData,
  getCustomer,
  getExportFileCustomer,
  putCustomer,
  unBlockCustomer,
  updateStatusCustomer,
} from '@/redux/slices/customer';
import { createNewPath, useQueryParameters } from '@/shared/utils/common';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { regFileName } from '@/shared/utils/regex';
import { saveAs } from 'file-saver';
import { useRole } from '@/hooks/useRole';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';
import ModalResetPassword from './ModalResetPassword';

export default function ManagementCustomer() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();
  const lang = window.lang;
  const queriesParams: any = useQueryParameters();
  const [queryFilter, setQueryfilter] = useState(queriesParams);
  const { pathname } = location;
  const router = useNavigate();
  const Active = 3;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>('');
  const schema = yup.object().shape({
    text: yup.string().nullable(),
    status: yup.number().nullable(),
  });
  const defaultValue = {
    page: 1,
    take: PAGE_SIZE,
    Text: queriesParams?.text || '',
    Status: queriesParams?.status === Active ? 0 : queriesParams?.status || '',
  };
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(customerData);
  const { role } = useRole();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      text: queriesParams?.text || '',
      status: queriesParams?.status ? (queriesParams.status === '0' ? 3 : +queriesParams?.status) : undefined,
    },
  });

  useEffect(() => {
    dispatch(getCustomer(defaultValue));
  }, []);

  const onSubmit = (data) => {
    setCurrentPage(1);
    const dataSubmit = {
      text: data.text,
      status: data.status === Active ? '0' : +data.status || '',
      page: 1,
      take: PAGE_SIZE,
    };
    setQueryfilter(dataSubmit);
    dispatch(getCustomer(dataSubmit));
    const newPath = createNewPath(pathname, dataSubmit);
    router(newPath);
  };

  const handleResetFilter = () => {
    reset();
    setValue('text', '');
    setValue('status', undefined);
    setCurrentPage(1);
    setQueryfilter({
      page: 1,
      take: PAGE_SIZE,
      Text: '',
      Status: null,
    });
    dispatch(getCustomer({ page: 1, take: PAGE_SIZE, Text: '', Status: '' }));
    router(pathname);
  };

  const enumStatus = (value) => {
    const typeObject = {
      0: <Done color="success" />,
      1: <Close color="error" />,
      2: <Block color="error" />,
    };
    return typeObject[value];
  };

  const handleConfirmDelete = (id) => {
    showSuccessToast(lang.managementCustomer.successDelete);
  };

  const handleConfirmBlock = (id) => {
    const payload = {
      params: {
        customerId: id,
      },
      callbackSuccess: () => {
        showSuccessToast(lang.managementCustomer.blockSuccess);
        dispatch(getCustomer({ ...defaultValue, page: currentPage }));
        dispatch(updateStatusCustomer());
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(putCustomer(payload));
  };

  const handleConfirmUnBlock = (id) => {
    const payload = {
      params: {
        customerId: id,
      },
      callbackSuccess: () => {
        showSuccessToast(lang.managementCustomer.unBlockCustomer);
        dispatch(getCustomer({ ...defaultValue, page: currentPage }));
        dispatch(updateStatusCustomer());
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(unBlockCustomer(payload));
  };

  const handleDelete = (id) => {
    openPopup(lang.managementCustomer.confirmDelete, () => handleConfirmDelete(id));
  };

  const handleBlock = (id) => {
    openPopup(lang.managementCustomer.confirmBlock, () => handleConfirmBlock(id));
  };

  const handleUnBlock = (id) => {
    openPopup(lang.managementCustomer.popupUnBlock, () => handleConfirmUnBlock(id));
  };

  const dataTable = parseDataCustomer(
    dataSelector?.listCustomer?.data,
    currentPage,
    enumStatus,
    dataSelector?.listCustomer?.totalCount,
    role,
  );

  const handleAddCustomer = () => {
    navigate(CONST_ROUTER.ADD_CUSTOMER);
  };

  const handleViewDetail = (id) => {
    navigate(CONST_ROUTER.DETAIL_CUSTOMER_ITEM(id));
  };

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      page: page,
      take: PAGE_SIZE,
    };
    dispatch(getCustomer(data));
  };

  const handleDownFile = () => {
    if (queryFilter) {
      const filteredQueryFilter = {};
      Object.keys(queryFilter).forEach((key) => {
        if (queryFilter[key] !== null && queryFilter[key] !== undefined && queryFilter[key] !== '') {
          filteredQueryFilter[key] = queryFilter[key];
        }
      });
    }
    const payload = {
      params: queryFilter,
      callbackSuccess: (response) => {
        const contentDispositionHeader = response.headers['content-disposition'];
        let filename = 'Trip.xlsx';

        if (contentDispositionHeader) {
          const matches = contentDispositionHeader.match(regFileName);
          if (matches && matches.length > 1) {
            filename = matches[1];
          }
        }
        const blob = new Blob([response.data]);
        saveAs(blob, filename);
        showSuccessToast('Xuất file thành công!');
      },
      callbackError: (isError) => {
        showErrorToast(isError || 'Xuất file không thành công, vui lòng thử lại sau');
      },
    };
    dispatch(getExportFileCustomer(payload));
  };

  const handleResetPassword = (id) => {
    setIsOpen(true);
    setItemId(id);
  };

  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
          <TitlePage title="QUẢN LÝ KHÁCH HÀNG" />
        </Grid>
      </Grid>
      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} md={5} lg={4}>
            <InputForm
              name="text"
              control={control}
              placeholder={lang.managementCustomer.placeholderSearchText}
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={5} md={4} lg={3}>
            <FormControl fullWidth>
              <SelectSearch
                label={lang.managementCustomer.status}
                control={control}
                name="status"
                options={optionStatusCustomer || []}
                errors={errors}
                // isLoading={isLoading}
              />
            </FormControl>
          </Grid>
        </Grid>
        <ButtonSearch
          handleDownFile={handleDownFile}
          submit={handleSubmit(onSubmit)}
          resetSearch={handleResetFilter}
          add={handleAddCustomer}
        />
      </Card>
      <Grid item xs={12} mt={1}>
        <TableComponent
          tableHead={MANAGEMENT_CUSTOMER}
          listData={dataTable}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecord={dataSelector?.listCustomer?.data?.length}
          totalItems={dataSelector?.listCustomer?.totalCount}
          onViewDetail={handleViewDetail}
          onBlock={(id) => handleBlock(id)}
          onUnBlock={(id) => handleUnBlock(id)}
          onDelete={(id) => handleDelete(id)}
          isLoading={dataSelector?.loadingListCustomer}
          pagingCallback={(page) => handlePaging(page)}
          onResetPassword={handleResetPassword}
          className="scroll-table"
        />
      </Grid>
      <ConfirmationPopup />
      {isOpen && <ModalResetPassword id={itemId} open={isOpen} setOpen={setIsOpen} />}
      <CustomBackdrop
        open={
          dataSelector?.loadingPutCustomer ||
          dataSelector?.loadingUnBlockCustomer ||
          dataSelector?.loadingDowFileCustomer
        }
      />
    </>
  );
}
