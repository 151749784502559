import { NOTIFICATION, READ_ALL_NOTIFICATION, READ_NOTIFICATION } from '@/shared/constant/constantUrl';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api';

const initialState = {
  listNotification: [],
  loadingListNotification: false,
  listNotification1: [],
  loadingListNotification1: false,
  totalNotificationUnread: [],
  loadingReadAllNotification: false,
};

export const getNotification = createAsyncThunk('getNotification', async (params: any) => {
  try {
    const response = await Api.get(NOTIFICATION, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const readAllNotification = createAsyncThunk('readAllNotification', async (params: any) => {
  const { callbackSuccess, callbackError } = params;
  try {
    const response = await Api.put(READ_ALL_NOTIFICATION);
    callbackSuccess?.();
    return response?.data;
  } catch (error: any) {
    callbackError?.(error?.data?.message || error?.data?.Message);
    return error;
  }
});

export const getNotification1 = createAsyncThunk('getNotification1', async (params: any) => {
  try {
    const response = await Api.get(NOTIFICATION, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const readNotification = createAsyncThunk('readNotification', async (params: any) => {
  const { id, data, callbackSuccess } = params;
  try {
    const response = await Api.put(READ_NOTIFICATION(id), data);
    callbackSuccess?.();
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const getUnredTotalNotification = createAsyncThunk('getUnredTotalNotification', async () => {
  try {
    const response = await Api.get('/api/management/Notification/unread');
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    updateStatusNotification: (state) => {
      state.loadingListNotification = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        state.loadingListNotification = true;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.loadingListNotification = false;
        state.listNotification = action.payload;
      })
      .addCase(getNotification.rejected, (state) => {
        state.loadingListNotification = false;
        state.listNotification = [];
      })

      .addCase(getNotification1.pending, (state) => {})
      .addCase(getNotification1.fulfilled, (state, action) => {
        state.listNotification1 = action.payload;
      })
      .addCase(getNotification1.rejected, (state) => {
        state.listNotification1 = [];
      })

      .addCase(getUnredTotalNotification.pending, (state) => {})
      .addCase(getUnredTotalNotification.fulfilled, (state, action) => {
        state.totalNotificationUnread = action.payload;
      })
      .addCase(getUnredTotalNotification.rejected, (state) => {
        state.totalNotificationUnread = [];
      })

      .addCase(readAllNotification.pending, (state) => {
        state.loadingReadAllNotification = true;
      })
      .addCase(readAllNotification.fulfilled, (state, action) => {
        state.loadingReadAllNotification = false;
      })
      .addCase(readAllNotification.rejected, (state) => {
        state.loadingReadAllNotification = false;
      });
  },
});

export const { actions } = notificationSlice;
export const notificationData = (state) => state.notificationSlice;
export default notificationSlice.reducer;
export const { updateStatusNotification } = notificationSlice.actions;
