import { CookiesStorage } from '@/shared/config/cookie';
import { CONST_ROUTER } from '@/shared/constant/common';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  const user = CookiesStorage.getCookieData('token');
  if (user) {
    return true;
  } else {
    return false;
  }
};

const PublicRoutes = () => {
  const auth = useAuth();
  return auth ? <Navigate to={CONST_ROUTER.DASHBOARD} /> : <Outlet />;
};

export default PublicRoutes;
