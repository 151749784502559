import { MAIL_GOSAFE } from '@/shared/config/setting';
import { Card } from '@mui/material';

export default function PrivaceClause() {
  return (
    <>
      <Card className="width-privacy">
        <h1 className="title-privacy">
          <strong> ĐIỀU KHOẢN SỬ DỤNG </strong>
        </h1>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            Chào mừng Quý Khách hàng đến với Ứng dụng Gosafe – Dịch vụ lái xe hộ uy tín trên toàn quốc
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '21px' }}>1. QUY ĐỊNH CHUNG</span>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            Bạn vui lòng đọc kỹ Điều khoản và Điều kiện sử dụng Ứng dụng trước khi cài đặt và sử dụng Ứng dụng để sử
            dụng bất kỳ dịch vụ nào thông qua Ứng dụng. Điều khoản sử dụng này là thỏa thuận pháp lý dưới hình thức điện
            tử giữa Bạn và GOSAFE khi Bạn đã lựa chọn sử dụng Dịch vụ thông qua Ứng dụng.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            Điều khoản sử dụng này và bất kỳ sửa đổi, bổ sung nào của Điều khoản sử dụng này được công bố bởi GOSAFE có
            hiệu lực ngay tại từng thời điểm khi nội dung được đăng tải trên Ứng dụng. Việc sử dụng Ứng dụng và/hoặc
            tiếp tục sử dụng Dịch vụ thông qua Ứng dụng sau khi có bất kỳ thay đổi nào về Điều khoản sử dụng sẽ mặc
            nhiên được coi là Bạn đã chấp thuận và bị ràng buộc đối với Điều khoản sử dụng và những nội dung thay đổi
            đó.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '21px' }}>2. ĐỊNH NGHĨA VÀ GIẢI THÍCH TỪ NGỮ</span>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>2.1.&nbsp;</span>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '14px' }}>&ldquo;GOSAFE&rdquo; </span>
          </strong>
          <span style={{ color: '#283037', fontSize: '14px' }}>là dịch vụ lái xe hộ uy tín trên toàn quốc</span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>2.2.&nbsp;</span>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '14px' }}>&ldquo;Điều khoản sử dụng&rdquo; </span>
          </strong>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            là Điều khoản và Điều kiện sử dụng Ứng dụng GOSAFE và các sửa đổi, bổ sung của Điều khoản và Điều kiện sử
            dụng Ứng dụng GOSAFE, các chính sách liên quan đến Dịch vụ, các Quy chế sử dụng dịch vụ, các Quy tắc ứng xử
            được GOSAFE công bố tại từng thời điểm trên Ứng dụng GOSAFE;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>2.3.&nbsp;</span>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '14px' }}>&ldquo;Dịch vụ&rdquo; </span>
          </strong>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            là các dịch vụ vận chuyển Khách hàng, hoặc các dịch vụ khác do GOSAFE hoặc Đối tác của GOSAFE cung cấp được
            kết nối thông qua Ứng dụng;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>2.4.&nbsp; </span>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '14px' }}>&ldquo;Người d&ugrave;ng&rdquo; </span>
          </strong>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            nghĩa là bất kỳ người nào sử dụng Ứng dụng để tìm kiếm và/hoặc sử dụng Dịch vụ;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>2.5.&nbsp; </span>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '14px' }}>Khách hàng </span>
          </strong>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            nghĩa là bất kỳ người nào trực tiếp sử dụng Dịch vụ, bao gồm cả Người dùng và người được Người dùng cho phép
            tìm kiếm và sử dụng Dịch vụ được yêu cầu thông qua Tài khoản Người dùng;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>2.6.&nbsp;</span>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '14px' }}>&ldquo;Đối t&aacute;c&rdquo; </span>
          </strong>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            là cá nhân hợp tác kinh doanh với GOSAFE, có đủ điều kiện để cung cấp Dịch vụ cho Người dùng theo quy định
            của pháp luật Việt Nam;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>2.7.&nbsp;</span>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '14px' }}>&ldquo;Ứng dụng&rdquo; </span>
          </strong>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            là ứng dụng GOSAFE được cài đặt trên thiết bị di động, được quản lý, vận hành bởi GOSAFE, cho phép Người
            dùng truy cập để sử dụng/đặt Dịch vụ;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>2.8.&nbsp; </span>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
              &ldquo;T&agrave;i khoản Người d&ugrave;ng&rdquo;{' '}
            </span>
          </strong>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            là tài khoản sử dụng Ứng dụng của Người dùng được thiết lập và kích hoạt trên Ứng dụng
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '21px' }}>3. CAM KẾT</span>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <u>
              <em>
                <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
                  3.1. Cam kết trong suốt quá trình sử dụng Ứng dụng
                </span>
              </em>
            </u>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>Bạn cam kết rằng:</span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (i) C&oacute; năng lực h&agrave;nh vi d&acirc;n sự đầy đủ theo quy định của ph&aacute;p luật để chấp thuận
            v&agrave; đồng &yacute; với Điều khoản sử dụng;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (ii) Những thông tin cung cấp cho GOSAFE luôn đảm bảo cập nhật, đầy đủ và xác thực;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>(iii) Sử dụng Ứng Dụng cho nhu cầu hợp pháp;</span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (iv) Không cung cấp cho bất kỳ người nào khác quyền truy cập vào Tài khoản Người dùng, bao gồm cả việc
            chuyển giao Tài khoản Người dùng hoặc thông tin từ Tài khoản Người dùng cho bất kỳ người nào khác;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (v) Kiểm tra và đảm bảo tải đúng Ứng dụng tương thích dành cho thiết bị di động của mình.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <u>
              <em>
                <span style={{ color: '#3c3c3c', fontSize: '14px' }}>3.2. Cam kết khi sử dụng Dịch vụ</span>
              </em>
            </u>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>Bạn đồng &yacute; rằng:</span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (i) Không sử dụng Ứng dụng để gửi và lưu trữ bất kỳ tài liệu hoặc thông tin trái phép nào hoặc phục vụ các
            mục đích lừa đảo; để làm phiền, quấy nhiễu người khác hoặc thực hiện đặt sử dụng dịch vụ giả mạo; vi phạm
            điều cấm của pháp luật hiện hành của Việt Nam
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (ii) Không sử dụng thông tin của GOSAFE, của Đối tác, của Người dùng cho mục đích nào khác ngoài mục đích sử
            dụng Dịch vụ (nếu Bạn là Người dùng) hoặc cung cấp Dịch vụ (nếu Bạn là Đối tác)
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (iii) Không thực hiện các hành vi (cố ý hay vô ý) có thể gây tổn hại cho Ứng dụng, tổn hại tới uy tín thương
            hiệu, tài sản của GOSAFE và/hoặc Đối tác và/hoặc Người dùng
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (iv) Hoàn toàn chịu trách nhiệm bảo toàn và bảo mật mật khẩu Tài khoản Người dùng của mình hoặc bất kỳ
            phương thức nhận dạng nào mà GOSAFE cung cấp để Bạn sử dụng Ứng dụng
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (v) Cung cấp cho GOSAFE bất kỳ bằng chứng về nhận dạng nào mà GOSAFE có thể yêu cầu vì mục đích thiết lập
            Tài khoản Người dùng và cung cấp Dịch vụ;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (vi) Đồng ý cung cấp thông tin xác thực, kịp thời và đầy đủ. GOSAFE có quyền chấm dứt việc cung cấp, sử dụng
            Dịch vụ của Bạn tại bất kỳ thời điểm nào mà không cần thông báo;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (vii) Không thực hiện các hành vi lừa dối GOSAFE và các hành vi có tính chất tương tự nhằm hưởng lợi bất
            chính trong bất kỳ sự kiện, hoạt động khuyến mại nào do GOSAFE tiến hành;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>(viii) Không hủy cuốc xe nhiều lần liên tiếp;</span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (ix) GOSAFE có quyền chặn hoặc từ chối quyền truy cập vào Tài khoản Người dùng, và/hoặc chặn các tính năng
            tích hợp sẵn trong Ứng dụng, mà không ảnh hưởng đến các quyền và chế tài khác của GOSAFE khi GOSAFE cho rằng
            Bạn có hành vi vi phạm bất kỳ điều khoản nào trong Điều khoản sử dụng này và/hoặc quy định pháp luật Việt
            Nam;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (x) Khi yêu cầu Dịch vụ thông qua Ứng dụng hoặc khi sử dụng Dịch vụ, Bạn phải tự chi trả cước viễn thông
            theo chính sách của đơn vị cung cấp dịch vụ viễn thông mà Bạn sử dụng;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (xi) Việc sử dụng Ứng dụng, Dịch vụ và/hoặc các tính năng được tích hợp trên Ứng dụng của Bạn sẽ phù hợp và
            tuân theo chính sách bảo mật thông tin của GOSAFE;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (xii) Chịu trách nhiệm cuối cùng về sự an toàn của chính Bạn trong khi cung cấp, sử dụng Dịch vụ, và phải
            thực hiện tất cả các biện pháp phòng ngừa cần thiết để đảm bảo an toàn khi cung cấp, sử dụng Dịch vụ;
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (xiii) Cam kết hoàn toàn chịu trách nhiệm pháp lý, chịu trách nhiệm đối với toàn bộ tổn thất hoặc thiệt hại
            gây ra cho chính bản thân Bạn, Đối tác, GOSAFE, nhân sự của GOSAFE và bất kỳ bên thứ ba nào khi Bạn vi phạm
            bất kỳ quy định nào của pháp luật, Điều khoản sử dụng này
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '21px' }}>4. THANH TOÁN</span>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            4.1. Khi Bạn đã hoàn thành việc sử dụng Dịch vụ, Bạn được yêu cầu thanh toán đầy đủ cho Đối tác và GOSAFE
            bằng cách chọn một trong các phương thức thanh toán có sẵn trên Ứng dụng. Bất kỳ khoản thanh toán nào theo
            lựa chọn đó sẽ không được hoàn lại.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            4.2.Bạn có thể chọn thanh toán cho Đối tác và GOSAFE để cung cấp Dịch vụ bằng tiền mặt, bằng thẻ tín dụng
            hoặc thẻ ghi nợ (“Thẻ”), hoặc bất kỳ phương thức thanh toán nào khác có trong Ứng dụng.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            4.3. Nếu Bạn chọn thanh toán bằng Thẻ, Bạn cần đăng ký một Thẻ hợp lệ theo quy định được thể hiện trên Ứng
            dụng. Bạn đồng ý rằng GOSAFE có quyền xác minh và chấp nhận thông tin Thẻ của Bạn khi Bạn đăng ký Thẻ lần
            đầu với GOSAFE cũng như khi Bạn sử dụng Dịch vụ
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            4.4. Trong trường hợp việc thanh toán bằng Thẻ được xử lý ở nước ngoài, Bạn sẽ chịu các chi phí phát sinh từ
            việc thanh toán này
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            4.5. GOSAFE có thể từ chối chấp nhận thanh toán bất kỳ giao dịch nào bằng phương thức Thẻ trên Ứng dụng
            trong trường hợp có bất kỳ lỗi nào trong giao dịch dẫn đến việc tổ chức phát hành Thẻ từ chối thanh toán
            hoặc khi GOSAFE tin rằng Thẻ đã được sử dụng cho một giao dịch có thể là lừa đảo, bất hợp pháp hoặc liên
            quan đến bất kỳ hoạt động tội phạm nào hoặc khi GOSAFE tin rằng Bạn vi phạm Điều khoản sử dụng này.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            4.6. Bạn có trách nhiệm tự giải quyết các tranh chấp với tổ chức phát hành Thẻ
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            4.7. Khi Bạn là Đối tác, việc thanh toán được thực hiện theo hợp đồng hợp tác giữa Bạn và GOSAFE và các
            chính sách do GOSAFE ban hành và có hiệu lực tùy từng thời điểm.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '21px' }}>5. BẢO MẬT</span>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            Là người sử dụng Ứng dụng, Bạn phải bảo mật tất cả các thông tin và dữ liệu liên quan đến GOSAFE, các dịch
            vụ, sản phẩm, công việc kinh doanh, kế hoạch tiếp thị và quảng bá hoặc các hoạt động khác của GOSAFE và các
            công ty liên kết của GOSAFE, cũng như các thông tin/dữ liệu cá nhân liên quan đến Đối tác hoặc dịch vụ của
            Đối tác (nếu có), thông tin/dữ liệu cá nhân liên quan đến Người dùng mà đã được tiết lộ với Bạn bởi GOSAFE
            hoặc đại diện của GOSAFE (bất kể bằng lời nói hay bằng văn bản, trước, tại hoặc sau ngày của Điều khoản sử
            dụng này) hoặc đã được Bạn thu thập gián tiếp hay trực tiếp, từ GOSAFE hoặc bất kỳ các công ty liên kết nào
            khác, hoặc đã được tạo ra trong quá trình giao kết Điều khoản sử dụng này.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '21px' }}>6. MIỄN TRỪ TR&Aacute;CH NHIỆM</span>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            GOSAFE không đưa ra bất kỳ cam đoan, bảo đảm hoặc phát sinh bất kỳ một trách nhiệm nào đối với độ tin cậy,
            sự đúng hạn, kịp thời, chất lượng, sự phù hợp, tính sẵn có, tính chính xác hoặc hoàn thiện của Dịch vụ cung
            cấp thông qua Ứng dụng, và/hoặc về chế độ ưu đãi/quà tặng.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>GOSAFE không bảo đảm chắc chắn rằng:</span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (i) Ứng dụng, phần mềm hỗ trợ liên quan sẽ hoàn toàn không có lỗi hoặc khiếm khuyết.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (ii)Chất lượng của bất kỳ sản phẩm, dịch vụ, thông tin, quà tặng, ưu đãi mà Bạn mua hoặc có được thông qua
            Ứng dụng sẽ đáp ứng hoàn toàn các yêu cầu hoặc mong đợi của Bạn.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (iii) Dịch vụ, Ứng dụng sẽ hoàn toàn đạt yêu cầu hoặc mong đợi của Bạn.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (iv) Ứng dụng và/hoặc (các) máy chủ mà Ứng dụng hoạt động sẽ không có virus hoặc các thành phần có hại khác.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (v) Việc sử dụng Dịch vụ trên Ứng dụng và/hoặc phần mềm hỗ trợ khác sẽ được an toàn, kịp thời, không bị gián
            đoạn hoặc không có lỗi hoặc có thể vận hành kết hợp với bất kỳ phần cứng, phần mềm, hệ thống hoặc dữ liệu
            nào khác.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            GOSAFE sẽ được miễn trừ tr&aacute;ch nhiệm trong c&aacute;c trường hợp sau:
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (i) Các tổn thất có thể gây ra cho Bạn do không thể truy cập, sử dụng Ứng dụng vì các lý do:
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            ● Không thể truy cập, sử dụng ứng dụng vì lý do lỗi kết nối mạng Internet.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            ● Lỗi phần cứng hoặc phần mềm ngoài tầm kiểm soát của Công ty.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            (ii) Các tổn thất do lỗi mạng Internet, lỗi máy móc, hay lỗi khi bảo trì hệ thống.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '21px' }}>7. LỖI INTERNET</span>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            Dịch vụ được cung cấp thông qua Ứng dụng có thể có những hạn chế, chậm trễ, và các vấn đề khác xuất phát từ
            việc sử dụng internet và thông tin liên lạc theo phương thức điện tử bao gồm việc thiết bị Bạn sử dụng hoặc
            Đối tác sử dụng bị lỗi, không có kết nối, nằm ngoài miền phủ sóng, bị tắt nguồn hoặc không hoạt động. GOSAFE
            sẽ được miễn trừ hoàn toàn trách nhiệm đối với các thiệt hại hay tổn thất phát sinh từ những vấn đề này..
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '21px' }}>8. THÔNG BÁO</span>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            GOSAFE có thể gửi thông báo dưới dạng một thông báo chung trên Ứng dụng, qua thư điện tử đến địa chỉ thư
            điện tử của Bạn có trong hồ sơ của GOSAFE, hoặc bằng văn bản gửi bằng thư đảm bảo hoặc thư trả trước đến địa
            chỉ của Bạn lưu trong dữ liệu của Công ty.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            Thông báo của GOSAFE sẽ được coi là đã gửi tới Bạn sau 48 giờ kể từ lúc gửi bưu điện (nếu được gửi bằng thư
            bảo đảm hoặc thư trả trước) hoặc sau 01 giờ sau khi đã gửi (nếu gửi bằng thư điện tử).
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            Bạn có thể gửi thông báo cho GOSAFE bằng thư điện tử đến địa chỉ thư điện tử
          </span>
          <a href={`${MAIL_GOSAFE}`}>
            <span> Gosafe@gmail.com</span>
          </a>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            , hư gửi qua đường bưu điện hoặc thư bảo đảm tới địa chỉ của GOSAFE theo thông tin liên hệ như được cung cấp
            trên website hoặc Ứng dụng. Thời điểm thông báo này có hiệu lực là kể từ thời điểm GOSAFE nhận được thông
            báo.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '21px' }}>9. CHUYỂN GIAO</span>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            Bạn không được chuyển giao quyền, nghĩa vụ của mình phát sinh theo Điều khoản sử dụng này nếu không có sự
            chấp thuận trước bằng văn bản của GOSAFE. Bất kỳ hành động cố ý chuyển giao quyền, nghĩa vụ của mình phát
            sinh theo Điều khoản sử dụng này của Bạn vi phạm mục này sẽ bị coi là vô hiệu.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            GOSAFE có thể chuyển giao quyền, nghĩa vụ của mình phát sinh theo Điều khoản sử dụng này mà không cần sự
            chấp thuận của Bạn.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ color: '#3c3c3c', fontSize: '21px' }}>10. GIẢI QUYẾT TRANH CHẤP</span>
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            10.1. Trong trường hợp có bất kỳ tranh chấp nào phát sinh từ và/hoặc liên quan đến việc ký kết và thực hiện
            Điều khoản sử dụng này, GOSAFE và Bạn sẽ giải quyết thông qua thương lượng. Nếu tranh chấp không được giải
            quyết bằng cách thương lượng thì tranh chấp đó sẽ do Tòa án nhân dân có thẩm quyền giải quyết theo quy định
            pháp luật.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ color: '#3c3c3c', fontSize: '14px' }}>
            10.2. Không phụ thuộc vào các quy định trái với điều này, bất kỳ khiếu nại nào giữa Đối tác và Người dùng
            phải được giải quyết trực tiếp với nhau.
          </span>
        </p>
      </Card>
    </>
  );
}
