import axios from 'axios';
import { API_URL } from '@/shared/config/setting';
import { CookiesStorage } from '@/shared/config/cookie';
import { checkTimestamp, handleApi500, logout401 } from '@/shared/utils/common';
import { ACCESSS_TOKEN } from '@/shared/constant/text';
import { showErrorToast } from '@/hooks/useCustomToast';

const defaultOptions = {};

export const requestHeader = () => {
  const token = `${CookiesStorage.getCookieData(ACCESSS_TOKEN)}`;
  return {
    Authorization: `Bearer ${token}`,
  };
};

function getNotAuthApi(path: string, options: any = {}, apiURL?: string) {
  return axios.get(`${apiURL || API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  });
}

function getApi(path: string, options: any = {}, apiURL?: string) {
  return axios.get(`${apiURL || API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    headers: {
      responseType: 'blob',
      ...options.headers,
      ...requestHeader(),
    },
  });
}

function postApi(path: string, data: any = {}, options: any = {}) {
  return axios.post(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
      ...requestHeader(),
    },
  });
}

function putApi(path: string, data: any = {}, options: any = {}) {
  return axios.put(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
      ...requestHeader(),
    },
  });
}

function patchApi(path: string, data: any, options: any = {}) {
  return axios.patch(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
      ...requestHeader(),
    },
  });
}

function deleteApi(path: string, options: any = {}) {
  return axios.delete(`${API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
      ...requestHeader(),
    },
  });
}

function handleErrorStatus(error: any) {
  switch (error?.status) {
    case 401:
      showErrorToast(error?.data?.message || error?.data?.Message);
      return logout401();
    case 500:
      return handleApi500();
    default:
      return error;
  }
}

axios.interceptors.response.use(
  (response) => {
    const data = response?.data;
    return handleErrorStatus({ ...response, data });
  },
  (error) => {
    const errorResponse = error?.response || error;
    const token = CookiesStorage.getCookieData(ACCESSS_TOKEN);
    // const tokenTimestamp = token && checkTimestamp(token); // check timestamp token
    // const currentTimestamp = Math.floor(Date.now() / 1000);
    // if (token && currentTimestamp >= tokenTimestamp) {
    //   return logout401();
    // }
    return Promise.reject(handleErrorStatus(errorResponse));
  },
);

axios.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    if (newConfig.headers['Content-Type'] === 'multipart/form-data') return newConfig;
    if (config.params) {
      newConfig.params = config.params;
    }
    if (config.data) {
      newConfig.data = config.data;
    }
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const Api = {
  get: getApi,
  post: postApi,
  put: putApi,
  delete: deleteApi,
  patch: patchApi,
  getNotAuth: getNotAuthApi,
};

export default Api;
