import React, { useState } from 'react';
import { Container, Grid, Typography, TextField, Button, Box, InputAdornment, IconButton } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ConfirmPopup from '@/components/ConfirmPopup';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { deleteAccountData, putdeleteAccountCustomer } from '@/redux/slices/deleteAccount';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBackdrop } from '@/components/CustomBackdrop';

const DeleteAccountPage = () => {
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const lang = window.lang;
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const dispatch = useDispatch<any>();
  const dataDeleteAccount = useSelector(deleteAccountData);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Email không được để trống')
      .email('Email không hợp lệ')
      .matches(/^(?!.*\s{2,}).*$/, 'Email không hợp lệ')
      .matches(/^(?!^\s).*$/, 'Email không hợp lệ')
      .matches(/^(?=[^@]*[a-zA-Z])/, 'Email phải chứa ít nhất 1 chữ cái'),
    password: yup
      .string()
      .required('Mật khẩu không được để trống')
      .min(6, 'Mật khẩu phải có ít nhất 6 ký tự')
      .max(20, 'Mật khẩu không vượt quá 20 ký tự'),
  });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: undefined,
      password: undefined,
    },
  });

  const onSubmit = (data) => {
    const payload = {
      params: {
        Email: data.email,
        Password: data.password,
      },
      callbackSuccess: () => {
        showSuccessToast('Yêu cầu xóa tài khoản thành công');
        setShowPassword(false);
        reset();
      },
      callbackError: (error) => {
        showErrorToast(error || 'Yêu cầu xóa tài khoản không thành công');
      },
    };
    openPopup('Bạn có chắc chắn muốn xóa tài khoản không?', () => dispatch(putdeleteAccountCustomer(payload)));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box sx={{ mt: 4, p: 3, backgroundColor: '#fff', borderRadius: 2, boxShadow: 1, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom fontWeight="bold">
              {lang.deleteAccount.title}
            </Typography>
            <Typography variant="body1" paragraph>
              {lang.deleteAccount.delete1}
            </Typography>
            <Typography variant="body1" paragraph>
              {lang.deleteAccount.delete2}
            </Typography>
            <Typography variant="body1" paragraph>
              {lang.deleteAccount.delete3}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size="small"
                        error={!!error}
                        helperText={error ? error.message : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        size="small"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : ''}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2, backgroundColor: '#007aff' }}
                    onClick={handleClickOpen}
                  >
                    Xóa tài khoản
                  </Button>
                </Grid>
              </Grid>
            </form>
            <ConfirmationPopup />
            <CustomBackdrop open={dataDeleteAccount?.loadingDeleteCustomer} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DeleteAccountPage;
