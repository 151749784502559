import React from 'react';
import { FormControl, Checkbox, FormControlLabel, FormLabel, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';

export const CheckBoxInputForm = ({
  control,
  label,
  name,
  size = 'small',
  sizeLabel = 'small',
  disabled = false,
  errors,
  color = 'red',
  ...rest
}): any => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl>
          <FormControlLabel
            control={<Checkbox checked={field.value} onChange={field.onChange} />}
            label={<span style={{ color: color }}>{label}</span>}
          />
          {errors?.[name] && <FormHelperText error>{errors[name]?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
