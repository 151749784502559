import { useEffect, useMemo, useRef, useState } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import defaultAvatar from '../../asset/img/taxi3.png';
import { CustomBackdrop } from '../CustomBackdrop';
import location from '../../asset/img/location1.png';
import locationGreen from '../../asset/img/locationGreen.png';
import { DEFAULT_CENTER_MAP } from '@/shared/constant/text';

const adjustCoordinates = (list) => {
  const offset = 0.0001;
  const adjustedList = [];

  list.forEach((item) => {
    let newItem = { ...item, location: { ...item.location } };

    while (
      adjustedList.some((i) => i.location.lat === newItem.location.lat && i.location.lon === newItem.location.lon)
    ) {
      newItem.location.lat += offset;
      newItem.location.lon += offset;
    }

    adjustedList.push(newItem);
  });

  return adjustedList;
};

const MapComponent = ({ listDriverMap, loadingListDriverGoogleMap }) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);
  const adjustedListDriverMap = useMemo(() => adjustCoordinates(listDriverMap), [listDriverMap]);

  useEffect(() => {
    if (mapRef.current && adjustedListDriverMap.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      adjustedListDriverMap.forEach((item) => {
        bounds.extend({ lat: item.location.lat, lng: item.location.lon });
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [adjustedListDriverMap]);

  const handleMapClick = () => {
    setSelectedMarker(null);
  };

  return (
    <GoogleMap defaultZoom={15} defaultCenter={DEFAULT_CENTER_MAP} onClick={handleMapClick} ref={mapRef}>
      {adjustedListDriverMap.map((item, i) => {
        const coord = {
          lat: item.location && item.location.lat ? item.location.lat : 0,
          lng: item.location && item.location.lon ? item.location.lon : 0,
        };
        const avatar = item.avatarPath || defaultAvatar;
        return (
          <Marker
            key={i}
            icon={{
              url: avatar,
              scaledSize: new window.google.maps.Size(30, 30),
            }}
            zIndex={9999}
            position={coord}
          >
            <InfoBox
              options={{
                closeBoxURL: '',
                pixelOffset: new window.google.maps.Size(-20, -35),
              }}
            >
              <img
                onClick={() => {
                  if (selectedMarker && item.id === selectedMarker.id) {
                    handleMapClick();
                  } else {
                    setSelectedMarker(item);
                  }
                }}
                className={`avatar-google-map ${item.isBusy && 'avatar-google-map-busy'}`}
                src={avatar}
              />
            </InfoBox>

            <Marker
              position={coord}
              icon={{
                url: item.isBusy ? location : locationGreen,
                scaledSize: new window.google.maps.Size(30, 30),
                anchor: new window.google.maps.Point(14, 5),
              }}
            />
            {selectedMarker && selectedMarker.id === item.id ? (
              <InfoBox
                options={{
                  closeBoxURL: '',
                  enableEventPropagation: true,
                  pixelOffset: new window.google.maps.Size(-83, -130),
                }}
              >
                <div className="info-box-detail">
                  <p>
                    <strong>Họ và tên: </strong>
                    {item.fullName}
                  </p>
                  <p>
                    <strong>Số điện thoại: </strong>
                    {item.phoneNumber}
                  </p>
                </div>
              </InfoBox>
            ) : null}
          </Marker>
        );
      })}
      <CustomBackdrop open={loadingListDriverGoogleMap} />
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(MapComponent));
