import { InputPriceProps } from '@/interfaces/index';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

export const InputPriceForm = ({
  control,
  label,
  name,
  size = 'small',
  arrayErrors,
  onChange,
  errors,
  onBlur,
  readOnly = false,
  placeholder,
  className,
  inputRef,
  fullWidth = false,
  type,
  ...rest
}: InputPriceProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <>
              <FormControl error={!!arrayErrors || !!errors?.[name]}>
                <NumericFormat
                  customInput={TextField}
                  className={className}
                  size={size}
                  type={type}
                  label={label}
                  inputRef={inputRef}
                  {...field}
                  placeholder={placeholder}
                  thousandSeparator="." // Dot as thousand separator
                  decimalSeparator="," // Comma as decimal separator, if needed
                  allowNegative={false}
                  {...rest}
                  error={!!arrayErrors}
                  fullWidth={fullWidth}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  onBlur={onBlur}
                  onChange={(event) => {
                    const value = event.target.value;
                    const plainNumber = value.replace(/[^0-9]/g, '');
                    field.onChange(plainNumber);
                    if (typeof onChange === 'function') {
                      onChange({ ...event, target: { ...event.target, value: plainNumber } });
                    }
                  }}
                />
              </FormControl>
            </>
          );
        }}
      />
      {arrayErrors && (
        <FormHelperText className="center-error" error>
          {arrayErrors?.message}
        </FormHelperText>
      )}
      {errors?.[name] && <FormHelperText error>{errors[name]?.message}</FormHelperText>}
    </>
  );
};
