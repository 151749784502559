import './policy.css';
import listPaymentDiscount from '../../asset/img/listPaymentAccount.jpg';
import imgATM from '../../asset/img/imgATM.jpg';
import paymentCard from '../../asset/img/paymentCard.png';
import choosenPayment from '../../asset/img/choosenPayment.jpg';
import checkInformattionPayment from '../../asset/img/checkInfomationPayment.jpg';
import instructPayment from '../../asset/img/instructPayment.jpg';

export const PaymentPolicy = () => {
  return (
    <div className="payment-policy-container">
      <div>
        <strong className="title">Thanh toán, Ưu đãi và Hoàn tiền </strong>
      </div>
      <div>
        Địa chỉ email liên hệ: gosafe@gmail.com
        <br />
        <strong> Tên tổ chức</strong> : Gosafe -Dịch vụ lái xe hộ uy tín trên toàn quốc <br />
        <strong>Địa chỉ</strong>: 71 Ngô Thì Hương, P Nại Hiên Đông, Q Sơn Trà, Đà Nẵng <br />
        <strong>Số điện thoại</strong> : 0374.180.180 <br />
        Khi thanh toán, bạn đồng ý sử dụng phương thức thanh toán hợp lệ. Nếu bạn không hài lòng với nội dung, GoSafe sẽ
        hoàn lại tiền hoặc ưu đãi trong 30 ngày cho hầu hết các giao dịch mua nội dung.
      </div>
      <div>
        <strong className="title">1. Định giá</strong>
        <div>
          Giá bán nội dung trên GOSAFE được xác định dựa trên các điều khoản trong Điều khoản. Trong một số trường hợp,
          giá bán nội dung được niêm yết trên trang web của GOSAFE có thể không hoàn toàn giống với giá được niêm yết
          trên ứng dụng di động hoặc TV của chúng tôi, do có sự khác nhau trong hệ thống định giá của nhà cung cấp nền
          tảng di động và chính sách của họ về việc triển khai bán hàng và khuyến mại.
          <br /> Chúng tôi thỉnh thoảng sẽ chạy các chương trình khuyến mại và bán hàng cho nội dung của chúng tôi,
          trong đó một số nội dung nhất định sẽ được cung cấp với mức giá chiết khấu trong một khoảng thời gian nhất
          định. Giá áp dụng cho nội dung đó sẽ là giá tại thời điểm bạn hoàn tất giao dịch mua nội dung (lúc thanh
          toán). Mọi mức giá được đề xuất cho nội dung cụ thể đều có thể khác giữa thời điểm bạn đăng nhập vào tài khoản
          của bạn so với giá dành cho người dùng chưa đăng ký hoặc chưa đăng nhập, vì một số chương trình khuyến mại của
          chúng tôi chỉ dành cho người dùng mới. <br />
          Nếu bạn đã đăng nhập vào tài khoản của bạn, đơn vị tiền tệ niêm yết cho bạn là dựa trên vị trí của bạn khi tạo
          tài khoản. Nếu bạn chưa đăng nhập vào tài khoản, đơn vị tiền tệ cho mức giá sẽ dựa trên quốc gia nơi bạn sinh
          sống. Chúng tôi không cho phép người dùng xem giá bằng các đơn vị tiền tệ khác.
          <br /> Nếu bạn là học viên ở một quốc gia áp dụng thuế sử dụng và thuế bán hàng, thuế hàng hóa và dịch vụ hoặc
          thuế giá trị gia tăng cho giao dịch mua bán của người tiêu dùng thì chúng tôi có trách nhiệm thu và nộp khoản
          thuế đó cho cơ quan thuế có thẩm quyền. Tùy theo vị trí của bạn, mức giá mà bạn thấy có thể bao gồm các loại
          thuế đó hoặc thuế có thể được cộng thêm khi bạn tiến hành thanh toán.
        </div>
      </div>
      <div>
        <strong className="title">2. Thanh toán</strong>
        <div>
          Bạn đồng ý trả phí cho nội dung mà bạn mua và bạn ủy quyền cho chúng tôi tính phí vào thẻ ghi nợ hoặc thẻ tín
          dụng của bạn hoặc xử lý các khoản phí đó theo các phương thức thanh toán khác (chẳng hạn như Boleto, SEPA, ghi
          nợ trực tiếp hoặc ví điện tử trên thiết bị di động). GOSAFE hợp tác với các nhà cung cấp dịch vụ thanh toán để
          cung cấp cho bạn các phương thức thanh toán thuận tiện nhất ở quốc gia của bạn và để bảo mật thông tin thanh
          toán của bạn. Chúng tôi có thể cập nhật phương thức thanh toán của bạn bằng cách sử dụng thông tin do nhà cung
          cấp dịch vụ thanh toán của chúng tôi cung cấp. Vui lòng tham khảo Chính sách về quyền riêng tư của chúng tôi
          để biết thêm chi tiết.
          <br /> Khi mua hàng, bạn đồng ý không sử dụng phương thức thanh toán không hợp lệ hoặc trái phép. Nếu phương
          thức thanh toán của bạn không thành công và bạn vẫn có quyền truy cập vào nội dung đang ghi danh, bạn đồng ý
          thanh toán cho chúng tôi các khoản phí tương ứng trong vòng 30 ngày kể từ ngày chúng tôi gửi thông báo. Chúng
          tôi có toàn quyền vô hiệu hóa quyền truy cập vào bất kỳ nội dung nào mà chúng tôi chưa nhận được khoản thanh
          toán đầy đủ.
          <br />
          <div>
            <strong>
              <span>&#8226;</span> Chuyển khoản ngân hàng
            </strong>
          </div>
          <div>
            <strong>
              <span>&#8226;</span> Thanh toán trực tuyến qua cổng thanh toán VNPAY
            </strong>
            <br />
            Cổng thanh toán VNPAY là giải pháp thanh toán do Công ty Cổ phần Giải pháp Thanh toán Việt Nam (VNPAY) phát
            triển. Khách hàng sử dụng thẻ/tài khoản ngân hàng, tính năng QR Pay/VNPAY-QR được tích hợp sẵn trên ứng dụng
            Mobile Banking của các ngân hàng hoặc Ví điện tử liên kết để thanh toán các giao dịch và nhập mã giảm giá
            (nếu có).
            <br />
            <strong>Quét mã VNPAY-QR trên 35+ Ứng dụng Mobile Banking và 15+ Ví điện tử liên kết.</strong>
            <br />
            <img className="img-payment-discount" src={listPaymentDiscount} /> <br />
            <strong>40+ Thẻ ATM/nội địa/tài khoản ngân hàng</strong> <br />
            <img className="img-payment-atm" src={imgATM} /> <br />
            <strong>4 thẻ thanh toán quốc tế</strong> <br />
            <img className="img-card-payment" src={paymentCard} /> <br />
            <strong>Các phương thức thanh toán qua VNPAY:</strong> <br />
            <img className="choosen-vnpay" src={choosenPayment} /> <br />
            <strong>+ Hướng dẫn thanh toán qua “Ứng dụng thanh toán hỗ trợ VNPAY-QR”</strong> <br />
            <strong>Bước 1: </strong>Quý khách lựa chọn sản phẩm, dịch vụ và chọn Thanh toán ngay hoặc Đặt hàng <br />
            Tại trang thanh toán, vui lòng kiểm tra lại sản phẩm đã đặt, điền đầy đủ thông tin người nhận hàng, chọn
            phương thức thanh toán VNPAY và nhấn nút “Đặt hàng ngay”.
            <br />
            <strong>Bước 2: </strong> Màn hình thanh toán chuyển sang giao diện cổng thanh toán VNPAY. Chọn phương thức
            “Ứng dụng thanh toán hỗ trợ VNPAY-QR” <br />
            <strong>Bước 3: </strong>Hệ thống hiển thị mã QR cùng với số tiền cần thanh toán, Quý khách kiểm tra lại số
            tiền này. Sử dụng ứng dụng ngân hàng (theo danh sách liệt kê), chọn “Quét Mã” và tiến hành quét mã QR trên
            màn hình thanh toán website. <br />
            <strong>
              <i>
                *Lưu ý: Mã QR có hiệu lực trong 15 phút Để quá trình thanh toán thành công, khách hàng vui lòng tham
                khảo trước các điều kiện và thao tác quét mã trên điện thoại để sẵn sàng, tránh sự cố hết thời gian ảnh
                hưởng đến thanh toán và mã khuyến mại của quý khách.
              </i>
            </strong>
            <br />
            <strong>Bước 4: </strong> Kiểm tra thông tin, nhập mã giảm giá (nếu có) và hoàn tất thanh toán Khi thực hiện
            thanh toán hoàn tất Quý khách sẽ nhận được thông báo xác nhận đơn hàng đặt hàng thành công tại website.
            <br />
            <img className="img-payment-atm" src={checkInformattionPayment} /> <br />
            <strong>+ Hướng dẫn thanh toán qua “Thẻ nội địa và tài khoản ngân hàng”</strong>
            <br />
            <strong>Bước 1: </strong> Quý khách lựa chọn sản phẩm, dịch vụ và chọn Thanh toán ngay hoặc Đặt hàng Tại
            trang thanh toán, vui lòng kiểm tra lại sản phẩm đã đặt, điền đầy đủ thông tin người nhận hàng, chọn phương
            thức thanh toán VNPAY và nhấn nút “Đặt hàng ngay”. <br />
            <strong>Bước 2: </strong> Màn hình thanh toán chuyển sang giao diện cổng thanh toán VNPAY. Chọn phương thức
            “Thẻ nội địa và tài khoản ngân hàng” và chọn ngân hàng muốn thanh toán thẻ trong danh sách. <br />
            <strong>Bước 3: </strong> Quý khách vui lòng thực hiện nhập các thông tin thẻ/tài khoản theo yêu cầu và chọn
            “Tiếp tục”. Mã OTP sẽ được gửi về điện thoại đăng ký, nhập mã OTP để hoàn tất giao dịch *Lưu ý: Giao dịch sẽ
            hết hạn sau 15 phút. <br />
            <strong>Bước 4: </strong> Khi thực hiện thanh toán hoàn tất Quý khách sẽ nhận được thông báo xác nhận đơn
            hàng đặt hàng thành công tại website. <br />
            <img className="img-payment-atm" src={instructPayment} /> <br />
            <strong>
              + Phương thức thanh toán qua “Thẻ thanh toán quốc tế (Visa, MasterCard, JCB, UnionPay)”
            </strong>{' '}
            <br />
            Tương tự như phương thức thanh toán “Thẻ nội địa và tài khoản ngân hàng” <br />
            <strong>+ Phương thức thanh toán qua “Ví điện tử VNPAY” </strong>
            <br />
            Tương tự như phương thức thanh toán “Ứng dụng thanh toán hỗ trợ VNPAY-QR”. <br />
            --------------------- <br />
            <strong>KÊNH HỖ TRỢ VNPAY </strong> <br />
            - Tổng đài: *3388 hoặc 1900 55 55 77 <br />
            - Zalo OA: zalo.me/4134983655549474109 <br />
            - Email: hotro@vnpay.vn <br />- Fanpage: facebook.com/VNPAYQR.vn <br />
          </div>
        </div>
      </div>

      <div>
        <strong className="title">3. Hoàn tiền và Hoàn tiền ưu đãi</strong>
        <div>
          Nếu nội dung bạn đã mua không giống như những gì bạn mong đợi thì trong vòng 30 ngày kể từ ngày mua nội dung
          đó, bạn có thể yêu cầu GOSAFE hoàn tiền vào tài khoản của bạn. Chúng tôi có toàn quyền tùy ý quyết định hoàn
          tiền cho bạn dưới dạng ưu đãi hoàn tiền hoặc hoàn tiền theo phương thức thanh toán ban đầu của bạn, tùy thuộc
          vào khả năng của các nhà cung cấp dịch vụ thanh toán của chúng tôi, nền tảng mà bạn đã mua nội dung (trang
          web, ứng dụng dành cho thiết bị di động hoặc TV) và các yếu tố khác. Bạn sẽ không được hoàn tiền nếu gửi yêu
          cầu sau khi đã hết thời hạn bảo đảm 30 ngày. Tuy nhiên, nếu nội dung bạn đã mua trước đó bị vô hiệu hóa vì lý
          do pháp lý hoặc chính sách thì bạn có quyền được hoàn tiền sau giới hạn 30 ngày này. GOSAFE cũng có quyền hoàn
          tiền cho học viên sau thời hạn 30 ngày trong trường hợp nghi ngờ hoặc xác nhận có gian lận tài khoản.
          <br />
          Nếu chúng tôi quyết định hoàn tiền ưu đãi vào tài khoản của bạn thì các ưu đãi này sẽ tự động được áp dụng cho
          giao dịch mua nội dung tiếp theo của bạn trên trang web của chúng tôi, nhưng không thể sử dụng để mua trong
          ứng dụng di động hoặc TV. Hoàn tiền ưu đãi có thể hết hạn nếu không được sử dụng trong khoảng thời gian đã chỉ
          định và không thể quy đổi thành tiền mặt, trừ khi luật hiện hành có quy định khác.
          <br />
          Tùy theo quyết định riêng của chúng tôi, nếu chúng tôi tin rằng bạn đang lạm dụng chính sách hoàn tiền của
          chúng tôi, chẳng hạn như bạn đã sử dụng một phần đáng kể nội dung mà bạn muốn hoàn tiền hoặc nếu trước đó bạn
          đã từng hoàn tiền cho nội dung đó thì chúng tôi có quyền từ chối hoàn tiền cho bạn, hạn chế bạn hoàn tiền
          trong tương lai, cấm tài khoản của bạn và/hoặc hạn chế mọi hoạt động sử dụng Dịch vụ trong tương lai. Nếu
          chúng tôi cấm tài khoản của bạn hoặc vô hiệu hóa quyền truy cập của bạn vào nội dung do bạn vi phạm các Điều
          khoản này của chúng tôi, bạn sẽ không đủ điều kiện nhận hoàn tiền. <br />
        </div>
        <div>
          <strong className="title">4. Mã khuyến mại và quà tặng</strong>
          <div>
            GOSAFE hoặc các đối tác của chúng tôi có thể cung cấp mã khuyến mại và quà tặng cho học viên. Một số mã có
            thể được sử dụng để đổi lấy quà tặng hoặc ưu đãi khuyến mại áp dụng cho tài khoản GOSAFE của bạn, sau đó có
            thể dùng để mua nội dung đủ điều kiện trên nền tảng của chúng tôi, tuân theo các điều khoản đi kèm với mã
            đó. Các mã khác có thể được đổi trực tiếp để lấy nội dung cụ thể. Bạn không thể sử dụng ưu đãi quà tặng và
            khuyến mại để mua hàng trong ứng dụng di động hoặc TV của chúng tôi. <br />
            Các mã và ưu đãi này, cũng như bất kỳ giá trị khuyến mại nào được liên kết với chúng, có thể hết hạn nếu
            không được sử dụng trong khoảng thời gian được chỉ định trong tài khoản GOSAFE của bạn. Mã khuyến mại và quà
            tặng do GOSAFE cung cấp sẽ không thể đổi thành tiền mặt, trừ khi có quy định khác trong điều khoản đi kèm
            với mã của bạn hoặc theo yêu cầu của luật hiện hành. Mã khuyến mại và quà tặng do đối tác cung cấp sẽ tuân
            theo chính sách hoàn tiền của đối tác đó. Nếu bạn có nhiều ưu đãi đã lưu, GOSAFE có thể xác định ưu đãi nào
            sẽ áp dụng cho giao dịch mua của bạn. Tham khảo Trang Hỗ trợ của chúng tôi và bất kỳ điều khoản đi kèm với
            mã của bạn để biết thêm chi tiết.
          </div>
        </div>
      </div>
    </div>
  );
};
