export const isUserNameInvalid = /^[a-zA-Z0-9]+$/;
export const isNotAllNumber = ['123', '456', '789'];
export const containsDigitPattern = /^\d+$/;
export const passwordNonWhitespacePattern = /^\S*$/;
export const passwordRegExpLogin = /^\S{6,}$/;
export const googleEmailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const emailRegExp = /^(?:\S+@\S+\.\S+|\s*)$/;
export const fullnameRegExp = /^[A-Za-zÀ-ỹ\s]+$/;
export const regNameVehicleClass = /^[A-Za-zÀ-ỹ\s\d]+$/;
export const regFileName = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
