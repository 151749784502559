import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api';

const initialState = {
  loadingPutGoogleMapApi: false,
};

export const putGoogleMapApi = createAsyncThunk(
  'putGoogleMapApi/block',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.get('/Trip/search-address', { params });
      callbackSuccess(response?.data);
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const googleMapSlice = createSlice({
  name: 'googleMapSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putGoogleMapApi.pending, (state) => {
        state.loadingPutGoogleMapApi = true;
      })
      .addCase(putGoogleMapApi.fulfilled, (state, action) => {
        state.loadingPutGoogleMapApi = false;
      })
      .addCase(putGoogleMapApi.rejected, (state) => {
        state.loadingPutGoogleMapApi = false;
      });
  },
});

export const { actions } = googleMapSlice;
export const googleMapSliceData = (state) => state.googleMapSlice;
export default googleMapSlice.reducer;
