import { Card, FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { compareDateTime, formatDateTimeSell, fromatDateTimeUTC } from '@/shared/utils/common';
import { InputDateTime, InputEditor, InputForm, RadioInputForm, SelectForm } from '@/common/Form';
import { HandleButtonAddEdit } from '@/components/HandleButtonAddEdit';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BackPage } from '@/components/BackPage';
import { CONST_ROUTER } from '@/shared/constant/common';
import { InputPriceForm } from '@/common/Form/inputPrice';
import { optionsStatusOffer, subjectsApply, typeOffer } from '@/shared/options';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import CustomImage from '@/components/CustomImage';
import CustomLightbox from '@/components/CustomLightbox';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearOfferDetail,
  getListProvinceId,
  getOfferDetail,
  offerData,
  postOffer,
  putOfferDetail,
  updateStatusDetailOffer,
} from '@/redux/slices/offer';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import LoadingPage from '@/components/LoadingPage';

const checkNumberValidate = (value) => {
  const numericValue = Number(value);
  return /^[^.]+$/.test(value) && Number.isInteger(numericValue) && numericValue >= 0 && !/^0[0-9]+$/.test(value);
};

export const AddOffer = () => {
  const BY_PERCENTAGE = '2';
  const BY_PERCENTAGE_API = 0;
  const BY_MONEY = '1';
  const APPLY_TRIP = '1';
  const GENERAL_OFFER = '2';
  const ALLPROVINCE = '0';
  const SPECIFICPROVINCE = '1';
  const GENERAL_OFFER_API = 0;
  const ACTIVE = '2';
  const lang = window.lang;
  const [img, setImg] = useState<string>('');
  const [imgFile, setImgFile] = useState<File>();
  const { itemId } = useParams();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isOpen1, setIsOpen1] = useState<boolean>(false);
  const selectorOffer = useSelector(offerData);
  const dispatch = useDispatch<any>();
  const { loadingOfferDetail, loadingPostOffer, loadingPutOffer } = selectorOffer;
  const isDisabledInput = !!itemId && !isEdit;

  const schema = yup.object().shape({
    nameOffer: yup.string().required(lang.offer.nameOfferRequired).max(60, 'Tên khuyến mãi không được quá 60 kí tự'),
    about: yup.string().max(1000, 'Mô tả khuyến mãi không được quá 1000 kí tự'),
    maximumUseCustomer: yup
      .string()
      .test('maximumUseCustomerInvalid', lang.offer.maximumUseCustomerInvalid, (value) => {
        if (!value) return true;
        return checkNumberValidate(value);
      }),
    numberOfUse: yup.string().test('numberOfUseInvalid', lang.offer.numberOfUseInvalid, (value) => {
      if (!value) return true;
      return checkNumberValidate(value);
    }),
    img: yup.string(),
    typeOffer: yup.string().required(lang.offer.typeOfferRequired),
    percentDiscount: yup
      .string()
      .test('percentDiscountRequired', lang.offer.percentDiscountRequired, function (value) {
        const { parent } = this;
        return parent?.typeOffer === BY_PERCENTAGE ? !!value : true;
      })
      .test('percentDiscountMax', '% giảm giá không được lớn hơn 100%', function (value) {
        const { parent } = this;
        return parent?.typeOffer === BY_PERCENTAGE ? (value && +value > 100 ? false : !!value) : true;
      })
      .test('percentDiscountValid', '% giảm giá không hợp lệ', function (value) {
        const { parent } = this;
        return parent?.typeOffer === BY_PERCENTAGE ? checkNumberValidate(value) : true;
      }),
    priceMaximum: yup.string().test('priceMaximumRequired', lang.offer.priceMaximumRequired, function (value) {
      const { parent } = this;
      return parent?.typeOffer === BY_PERCENTAGE ? !!value : true;
    }),
    priceDiscount: yup
      .string()
      .test('priceDiscountRequired', lang.offer.priceDiscountRequired, function (value) {
        const { parent } = this;
        return parent?.typeOffer === BY_MONEY ? !!value : true;
      })
      .test('maximumUseCustomerInvalid', '% giảm giá không hợp lệ', (value) => {
        if (!value) return true;
        return checkNumberValidate(value);
      }),
    subjectsApply: yup.string().required(lang.offer.subjectsApplyRequired),
    status: yup.string().required(lang.offer.statusRequired),
    minimumTripNumber: yup
      .string()
      .test('minimumTripNumberInvalid', lang.offer.minimumTripNumberInvalid, function (value: any) {
        const { parent } = this;
        const numericValue = Number(value);
        return parent?.subjectsApply === APPLY_TRIP
          ? /^[^.]+$/.test(value) && Number.isInteger(numericValue) && numericValue >= 0 && !/^0[0-9]+$/.test(value)
          : true;
      }),
    ProvincedId: yup.string().required(lang.offer.areaRequired),
    startDate: yup
      .date()
      .nullable()
      .required('Thời gian bắt đầu không được để trống')
      .typeError('Thời gian bắt đầu không hợp lệ'),
    endDate: yup
      .date()
      .nullable()
      .required('Thời gian kết thúc không được để trống')
      .test('is-valid-end-date', 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu', function (value: Date | any) {
        const startDate = this.parent.startDate;
        return value ? compareDateTime(startDate, value) : true;
      })
      .typeError('Thời gian kết thúc không hợp lệ'),
  });
  const {
    setValue,
    watch,
    clearErrors,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nameOffer: '',
      about: '',
      startDate: undefined,
      endDate: undefined,
      maximumUseCustomer: '',
      numberOfUse: '',
      typeOffer: BY_PERCENTAGE,
      percentDiscount: '',
      priceMaximum: '',
      priceDiscount: '',
      subjectsApply: GENERAL_OFFER,
      minimumTripNumber: '',
      ProvincedId: ALLPROVINCE,
      status: ACTIVE,
    },
  });
  const typeOfferSelect = watch('typeOffer');
  const subjectApplySelect = watch('subjectsApply');
  const dataDetailOffer = selectorOffer?.offerDetail?.data;
  const setData = (dataDetailOffer) => {
    setValue('nameOffer', dataDetailOffer?.discountName);
    setValue('about', dataDetailOffer?.description);
    setValue('endDate', fromatDateTimeUTC(dataDetailOffer?.endDate));
    setValue('startDate', fromatDateTimeUTC(dataDetailOffer?.startDate));
    setValue('maximumUseCustomer', dataDetailOffer?.maxCustomerUsage || '');
    setValue('numberOfUse', dataDetailOffer?.totalDiscount || '');
    setValue(
      'typeOffer',
      dataDetailOffer?.type === BY_PERCENTAGE_API ? BY_PERCENTAGE : dataDetailOffer?.type?.toString(),
    );
    if (dataDetailOffer?.type === BY_PERCENTAGE_API) {
      setValue('percentDiscount', dataDetailOffer?.percent);
      setValue('priceMaximum', dataDetailOffer?.maxAmount);
    } else {
      setValue('priceDiscount', dataDetailOffer?.money);
    }
    setValue(
      'subjectsApply',
      dataDetailOffer?.objectType === GENERAL_OFFER_API ? GENERAL_OFFER : dataDetailOffer?.objectType?.toString(),
    );
    dataDetailOffer?.objectType !== GENERAL_OFFER_API && setValue('minimumTripNumber', dataDetailOffer?.totalTrip);
    setValue('ProvincedId', dataDetailOffer?.provinceId ? dataDetailOffer?.provinceId : ALLPROVINCE);
    setValue('status', dataDetailOffer?.status === 0 ? ACTIVE : dataDetailOffer?.status);
    setImg(dataDetailOffer?.imgPath);
  };
  useEffect(() => {
    if (itemId) {
      setData(dataDetailOffer);
    }
  }, [selectorOffer?.offerDetail, itemId]);

  useEffect(() => {
    if (itemId) {
      dispatch(getOfferDetail(itemId));
    }
  }, [itemId]);

  useEffect(() => {
    dispatch(getListProvinceId());
    return () => {
      dispatch(clearOfferDetail());
    };
  }, []);

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    setData(dataDetailOffer);
    setIsEdit(false);
    setImgFile(undefined);
    clearErrors();
  };

  const onCancel = () => {
    setImg('');
    setImgFile(undefined);
    reset();
  };

  const dataFormSubmit = (formData, data) => {
    const isPercentageType = data.typeOffer === BY_PERCENTAGE;
    const isApplyTrip = data.subjectsApply === APPLY_TRIP;
    const allProvinced = data.ProvincedId === ALLPROVINCE;
    formData.append('ProvinceId', allProvinced ? '' : data.ProvincedId);
    formData.append('DiscountName', data.nameOffer);
    formData.append('Description', data.about);
    formData.append('StartDate', formatDateTimeSell(data.startDate));
    formData.append('EndDate', formatDateTimeSell(data.endDate));
    formData.append('Type', data.typeOffer === BY_PERCENTAGE ? 0 : data.typeOffer);
    formData.append('ObjectType', data.subjectsApply === GENERAL_OFFER ? 0 : data.subjectsApply);
    formData.append('MaxCustomerUsage', data.maximumUseCustomer || '');
    formData.append('TotalDiscount', data.numberOfUse || '');
    formData.append('Status', data.status === ACTIVE ? 0 : data.status || null);
    formData.append('ProvinceType', allProvinced ? ALLPROVINCE : SPECIFICPROVINCE);
    isApplyTrip && formData.append('TotalTrip', data.minimumTripNumber);
    imgFile && formData.append('Img', imgFile);
    if (isPercentageType) {
      formData.append('Percent', data.percentDiscount);
      formData.append('MaxAmount', data.priceMaximum);
    } else {
      formData.append('Money', data.priceDiscount);
    }
  };

  const handleSave = (data) => {
    const formData = new FormData();
    dataFormSubmit(formData, data);
    const payload = {
      id: itemId,
      isChangeImage: !Boolean(img === dataDetailOffer?.imgPath),
      params: formData,
      callbackSuccess: () => {
        setIsEdit(false);
        onCancel();
        itemId && dispatch(getOfferDetail(itemId));
        dispatch(updateStatusDetailOffer());
        showSuccessToast('Sửa khuyến mãi cho hành trình thành công');
      },
      callbackError: (error) => {
        showErrorToast(error);
      },
    };
    dispatch(putOfferDetail(payload));
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    dataFormSubmit(formData, data);
    const payload = {
      params: formData,
      callbackSuccess: () => {
        onCancel();
        navigate(CONST_ROUTER.OFFER);
        showSuccessToast('Thêm khuyến mãi cho hành trình thành công');
      },
      callbackError: (error) => {
        showErrorToast(error);
      },
    };
    dispatch(postOffer(payload));
  };

  const handleBackPage = () => {
    reset();
    navigate(CONST_ROUTER.OFFER);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!file || !allowedTypes.includes(file.type)) {
      showErrorToast('Vui lòng chọn đúng định dạng ảnh');
      return;
    }
    const imgUrl = URL.createObjectURL(file);
    setImg(imgUrl);
    setImgFile(file);
  };
  const handleDeleteImage = () => {
    setImg('');
    setValue('img', '');
    setImgFile(undefined);
  };

  const lightboxData = [{ images: [img || null], isOpen: isOpen1, setIsOpen: setIsOpen1 }];

  const lightboxes = lightboxData.map((item, index) => (
    <CustomLightbox
      key={index}
      images={item.images}
      isOpen={item.isOpen}
      photoIndex={0}
      onClose={() => item.setIsOpen(false)}
    />
  ));

  const handleOnChangeTypeOffer = (e) => {
    setValue('percentDiscount', '');
    setValue('priceMaximum', '');
    setValue('priceDiscount', '');
  };

  const handleOnChangSubjectApply = (e) => {
    setValue('minimumTripNumber', '');
  };

  return (
    <>
      <BackPage
        title={!itemId ? lang.offer.editPromotion : lang.offer.addNewPromotion}
        handleBackPage={() => handleBackPage()}
      />
      <Card sx={{ mt: 2, p: 2 }}>
        {loadingOfferDetail ? (
          <LoadingPage />
        ) : (
          <>
            <Grid container spacing={8} justifyContent="space-between">
              <Grid item xs={12} sm={6} display="flex" height="100%">
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <strong>
                      {lang.offer.nameOffer}: <span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputForm
                        readOnly={isDisabledInput}
                        name="nameOffer"
                        control={control}
                        size="small"
                        fullWidth
                        errors={errors}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <strong>{lang.offer.about}: </strong>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputEditor
                        name="about"
                        control={control}
                        label={lang.offer.introduction}
                        errors={errors}
                        readOnly={isDisabledInput}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <strong>
                      {lang.offer.startDate}:<span className="required">*</span>{' '}
                    </strong>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputDateTime
                        control={control}
                        readOnly={isDisabledInput}
                        errors={errors}
                        label="Thời gian bắt đầu"
                        name="startDate"
                        size="small"
                        maxDate={false}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <strong>
                      {lang.offer.endDate}: <span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputDateTime
                        control={control}
                        errors={errors}
                        readOnly={isDisabledInput}
                        label="Thời gian kết thúc"
                        name="endDate"
                        size="small"
                        maxDate={false}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <strong>{lang.offer.maximumUseCustomer}: </strong>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputPriceForm
                        name="maximumUseCustomer"
                        control={control}
                        readOnly={isDisabledInput}
                        size="small"
                        fullWidth
                        errors={errors}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} mb={3} pt={0}>
                    <i style={{ fontSize: '12px' }}>
                      (Nếu không nhập trường này thì số lượng sử dụng của khách hàng là không giới hạn)*
                    </i>
                  </Grid>
                  <Grid item xs={4}>
                    <strong>{lang.offer.useNumber}: </strong>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputPriceForm
                        name="numberOfUse"
                        readOnly={isDisabledInput}
                        control={control}
                        size="small"
                        fullWidth
                        errors={errors}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} mb={2} pt={0}>
                    <i style={{ fontSize: '12px' }}>
                      (Nếu không nhập trường này thì số lượng khuyến mãi là không giới hạn)*
                    </i>
                  </Grid>

                  <Grid item xs={4}>
                    <strong>{lang.offer.image}: </strong>
                  </Grid>
                  <Grid item xs={8}>
                    {errors?.img && !img && (
                      <FormHelperText error sx={{ marginLeft: '14px' }}>
                        {errors?.img?.message}
                      </FormHelperText>
                    )}
                    {img ? (
                      <Grid item xs={12} className="avatar-container">
                        <CustomImage
                          itemId={itemId}
                          className="img-driver-id"
                          src={img}
                          isEdit={isEdit}
                          style={{ margin: '5px' }}
                          alt={`default_image`}
                          onDelete={() => {
                            handleDeleteImage();
                          }}
                          onClickImage={() => setIsOpen1(true)}
                        />
                      </Grid>
                    ) : isDisabledInput ? (
                      <Typography className="add-img-text opacity-text-offer">+ Thêm ảnh</Typography>
                    ) : (
                      <label className="add-img" htmlFor="img-commodity-upload">
                        <Typography className="add-img-text">+ Thêm ảnh</Typography>
                      </label>
                    )}

                    <Controller
                      name="img"
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            className="image-hidden"
                            inputProps={{
                              accept: 'image/*',
                            }}
                            id="img-commodity-upload"
                            type="file"
                            onChange={(event) => {
                              handleFileSelect(event);
                              field.onChange(event);
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} display="flex" height="100%">
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <strong>
                      {lang.offer.typeOffer}: <span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <SelectForm
                        readOnly={isDisabledInput}
                        options={typeOffer}
                        name="typeOffer"
                        control={control}
                        size="small"
                        onChange={handleOnChangeTypeOffer}
                        errors={errors}
                      />
                    </FormControl>
                  </Grid>
                  {typeOfferSelect === BY_PERCENTAGE ? (
                    <>
                      <Grid item xs={4}>
                        <strong>
                          {lang.offer.percentageDiscount}: <span className="required">*</span>
                        </strong>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid item xs={6} sm={5} md={3}>
                          <FormControl fullWidth>
                            <InputForm
                              name="percentDiscount"
                              inputProps={{
                                min: 0,
                                max: 100,
                              }}
                              control={control}
                              size="small"
                              readOnly={isDisabledInput}
                              fullWidth
                              isTextEnd={true}
                            />
                          </FormControl>
                        </Grid>
                        {errors?.percentDiscount && (
                          <FormHelperText error>{errors?.percentDiscount?.message}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <strong>
                          {lang.offer.maximumPrice}: <span className="required">*</span>
                        </strong>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl fullWidth>
                          <InputPriceForm
                            readOnly={isDisabledInput}
                            name="priceMaximum"
                            control={control}
                            size="small"
                            fullWidth
                            errors={errors}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={4}>
                        <strong>
                          {lang.offer.moneyDiscount}: <span className="required">*</span>
                        </strong>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl fullWidth>
                          <InputPriceForm
                            readOnly={isDisabledInput}
                            name="priceDiscount"
                            control={control}
                            size="small"
                            fullWidth
                            errors={errors}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={4}>
                    <strong>
                      {lang.offer.subjectsApply}: <span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <SelectForm
                        options={subjectsApply}
                        name="subjectsApply"
                        readOnly={isDisabledInput}
                        control={control}
                        size="small"
                        errors={errors}
                        onChange={handleOnChangSubjectApply}
                      />
                    </FormControl>
                  </Grid>
                  {subjectApplySelect === APPLY_TRIP && (
                    <>
                      <Grid item xs={4}>
                        <strong>
                          {lang.offer.minimumTripNumber}: <span className="required">*</span>
                        </strong>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl fullWidth>
                          <InputPriceForm
                            readOnly={isDisabledInput}
                            name="minimumTripNumber"
                            control={control}
                            size="small"
                            fullWidth
                            errors={errors}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} mb={2} pt={0}>
                        <i style={{ fontSize: '12px' }}>
                          (Khi quản lý nhập vào số chuyến tối thiểu, thì người dùng khi đạt được số chuyến tối thiểu
                          trên sẽ được áp dụng chương trình khuyến mãi này.Sau khi áp dụng thành công khuyến mãi và muốn
                          sử dụng lại khuyến mãi này thì cần phải đạt được số chuyến tối thiểu)*
                        </i>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={4}>
                    <strong>
                      {lang.offer.area}: <span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <SelectForm
                        name="ProvincedId"
                        control={control}
                        readOnly={isDisabledInput}
                        size="small"
                        errors={errors}
                        options={
                          [{ value: ALLPROVINCE, label: lang.labelInput.all }, ...selectorOffer?.listProvinces] || []
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <strong>
                      {lang.offer.status}: <span className="required">*</span>
                    </strong>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <RadioInputForm
                        options={optionsStatusOffer}
                        name="status"
                        control={control}
                        readOnly={isDisabledInput}
                        size="small"
                        errors={errors}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Card>
      {lightboxes}
      <CustomBackdrop open={loadingPostOffer || loadingPutOffer} />
      <HandleButtonAddEdit
        itemId={itemId}
        isEdit={isEdit}
        handleEdit={handleEdit}
        handleCancel={handleCancel}
        handleSave={handleSubmit(handleSave)}
        onCancel={onCancel}
        onSubmit={handleSubmit(onSubmit)}
        // isNewData={!!isNewData}
      />
    </>
  );
};
