import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailTrip, getTrip, putCancelTrip, tripData, updateStatus } from '@/redux/slices/trip';
import { dataAddress, formatCurrency, isBooleanArray } from '@/shared/utils/common';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { PAGE_SIZE_30 } from '@/shared/constant/common';
import LoadingPage from '@/components/LoadingPage';
import { UNKNOW } from '@/shared/constant/text';

export function ModalCancelTrip({ setOpen, open, tripId, setCurrentPage, queriesParams, queryFilter }) {
  const lang = window.lang;
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(tripData);
  const dataDetail = dataSelector?.tripDetail?.data;
  const StartingPoint = 0;
  const StoppingPoint = 1;
  const DestinationPoint = 2;
  const { loadingTripDetail, loadingCancelTrip } = dataSelector;

  const gridItems = [
    { label: 'Tên khách hàng', value: dataDetail?.customerName },
    { label: 'Điểm đi', value: dataAddress(dataDetail?.tripLocations, StartingPoint) },
    { label: 'Điểm dừng', value: dataAddress(dataDetail?.tripLocations, StoppingPoint), isAddressStop: true },
    {
      label: 'Điểm đến',
      value: isBooleanArray(dataAddress(dataDetail?.tripLocations, DestinationPoint))
        ? dataAddress(dataDetail?.tripLocations, DestinationPoint)
        : UNKNOW,
    },
    { label: 'Tổng quãng đường', value: `${dataDetail?.distance} Km` },
    { label: 'Tổng tiền ', value: formatCurrency(dataDetail?.lastPrice), isRed: true },
  ];
  useEffect(() => {
    if (tripId) {
      dispatch(getDetailTrip(tripId));
    }
  }, [tripId]);

  const schema = yup.object().shape({
    cancel: yup.string().required(lang.tripManagement.cancelReasonText).trim(),
  });
  const {
    control,
    setValue,
    reset,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cancel: '',
    },
  });
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelTrip = (data) => {
    const params = {
      reason: data.cancel,
      tripId: tripId,
    };
    const payload = {
      params: params,
      callbackSuccess: () => {
        reset();
        showSuccessToast('Hủy hành trình thành công!');
        setCurrentPage(+queriesParams?.Page || 1);
        dispatch(getTrip({ ...queryFilter, Page: +queriesParams?.Page, Take: PAGE_SIZE_30 }));
        dispatch(updateStatus());
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(putCancelTrip(payload));
    handleClose();
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        PaperProps={{
          style: {
            width: '100%',
            maxHeight: '100%',
          },
        }}
      >
        <DialogTitle className="popup-title">
          <b>Xác nhận hủy hành trình {dataDetail?.code}</b>
        </DialogTitle>
        <Divider className="popup-divider" />
        <DialogContent className="modal-dialog-trip">
          {loadingTripDetail ? (
            <LoadingPage />
          ) : (
            <Grid container className="dialog-content-trip" gap={1}>
              <Grid container spacing={1}>
                {gridItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={5}>
                      <strong>{item.label}: </strong>
                    </Grid>
                    <Grid item xs={7}>
                      {item?.isAddressStop ? (
                        <>
                          {item?.value?.map((item1, index1) => {
                            return (
                              <>
                                <div>
                                  Đ{index1 + 1}: {item1}
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <div>{item?.value}</div>
                      )}
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={5}>
                  <strong>{lang.tripManagement.cancelReason}: </strong>
                </Grid>
                <Grid item xs={7}>
                  <FormControl fullWidth>
                    <Controller
                      name="cancel"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline
                          variant="outlined"
                          rows={3}
                          required
                          error={!!errors.cancel}
                          helperText={errors.cancel?.message}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid>
                <Typography pt={2}>{lang.tripManagement.modalText1}</Typography>
                <Typography>{lang.tripManagement.modalText2}</Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className="dialog-button-action">
          <Grid container direction="row" justifyContent="center" alignItems="center" gap={2}>
            <ButtonCustom fullWidth={false} color="error" onClick={handleClose} title={lang.title.titlePage.close} />
            <ButtonCustom
              fullWidth={false}
              color="primary"
              onClick={handleSubmit(handleCancelTrip)}
              title={lang.title.titlePage.confirm}
            />
          </Grid>
        </DialogActions>
        <CustomBackdrop open={loadingCancelTrip} />
      </Dialog>
    </>
  );
}
