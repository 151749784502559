import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  path: '',
};

export const path = createSlice({
  name: 'path',
  initialState,
  reducers: {
    clearPath: (state) => {
      state.path = '';
    },
    updatePath: (state, action) => {
      state.path = action.payload;
    },
  },
});

export const { actions } = path;
export const pathData = (state) => state.path;
export default path.reducer;
export const { clearPath, updatePath } = path.actions;
