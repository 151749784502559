import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Divider, Grid } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CONST_ROUTER, PAGE_SIZE, PAGE_SIZE_30, typeObjectNotification } from '@/shared/constant/common';
import { useDispatch } from 'react-redux';
import { getNotification1, getUnredTotalNotification, readNotification } from '@/redux/slices/notification';
import { BG_NOTIFICATION } from '@/shared/constant/text';
import { BiTrip } from 'react-icons/bi';
import { calculateTimeDifference, readableTime } from '@/shared/utils/common';

export const NotificationList = ({ listBadgeNotification, clickedItems, handleNavigate }: any) => {
  const dispatch = useDispatch<any>();
  const handleClick = (index, id, idNotification, isRead) => {
    handleNavigate(CONST_ROUTER.TRIP_DETAIL1_ITEM(id));
    const payload = {
      id: idNotification,
      data: {
        notificationId: idNotification,
      },
      callbackSuccess: () => {
        dispatch(getNotification1({ pageIndex: 1, pageSize: PAGE_SIZE_30 }));
        dispatch(getUnredTotalNotification());
      },
    };
    idNotification && !isRead && dispatch(readNotification(payload));
  };
  return (
    <div className="notification-container">
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', overflowY: 'auto' }}>
        {listBadgeNotification?.length > 0 ? (
          listBadgeNotification.map((item, index) => {
            const dataParse = JSON.parse(item?.data);
            return (
              <>
                <React.Fragment key={index}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ background: !item?.isRead ? BG_NOTIFICATION : '' }}
                    onClick={() => handleClick(index, dataParse.id, item?.idNotification, item?.isRead)}
                  >
                    <Grid item xs={11}>
                      <ListItem
                        alignItems="flex-start"
                        className={`notification-list ${clickedItems.includes(index) ? 'clicked' : ''}`}
                      >
                        <ListItemAvatar>
                          <BiTrip style={{ fontSize: '40px' }} />
                        </ListItemAvatar>
                        <Grid item paddingRight={2}>
                          <ListItemText
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <div
                                    className="trip-span-blue"
                                    dangerouslySetInnerHTML={{
                                      __html: typeObjectNotification(dataParse, item?.type),
                                    }}
                                  />
                                </Typography>
                              </React.Fragment>
                            }
                          />
                          <ListItemText
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                ></Typography>
                              </React.Fragment>
                            }
                          />
                          <ListItemText
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <div className="readableTime">{calculateTimeDifference(item?.creationTime)}</div>
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </Grid>
                      </ListItem>
                    </Grid>
                  </Grid>
                  <Divider className="bd-vote" />
                </React.Fragment>
              </>
            );
          })
        ) : (
          <ListItem alignItems="flex-start" className="notification-list">
            <ListItemText sx={{ textAlign: 'center' }} primary="Không có thông báo mới" />
          </ListItem>
        )}
      </List>
    </div>
  );
};
