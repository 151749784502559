import { Card, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PAGE_SIZE } from '@/shared/constant/common';
import { useDispatch, useSelector } from 'react-redux';
import { getTripDetailHistory, tripData } from '@/redux/slices/trip';
import LoadingPage from '@/components/LoadingPage';
import TableComponent from '@/components/TableComponent';
import { TRIPS_DETAIL_HISTORY } from '@/shared/constant/TableHead';
import { parseTripDetailHistory } from '@/shared/constant/dataTable';
import { createNewPath } from '@/shared/utils/common';

export default function TripsLoglHistory() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { itemId } = useParams();
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(tripData);
  const { loadingTripDetailHistory, tripDetailHistory } = dataSelector;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (itemId) {
      const payload = {
        id: itemId,
        params: {
          pageIndex: 1,
          pageSize: PAGE_SIZE,
        },
      };
      dispatch(getTripDetailHistory(payload));
    }
  }, [itemId]);

  const handlePaging = (page: number) => {
    if (itemId) {
      const payload = {
        id: itemId,
        params: {
          pageIndex: page,
          pageSize: PAGE_SIZE,
        },
      };
      const newPath = createNewPath(pathname, { pageIndex: payload.params.pageIndex });
      navigate(newPath);
      dispatch(getTripDetailHistory(payload));
    }
  };

  const parseTripData = parseTripDetailHistory(
    tripDetailHistory?.data,
    currentPage,
    tripDetailHistory?.totalCount,
    PAGE_SIZE,
  );
  return (
    <>
      {loadingTripDetailHistory ? (
        <LoadingPage />
      ) : (
        <Grid container>
          <Grid item xs={12} mt={1}>
            <TableComponent
              tableHead={TRIPS_DETAIL_HISTORY}
              listData={parseTripData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalRecord={tripDetailHistory?.data?.length}
              totalItems={tripDetailHistory?.totalCount}
              isLoading={loadingTripDetailHistory}
              pagingCallback={(page) => handlePaging(page)}
              className="scroll-table"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
