import { useCallback, useEffect, useState } from 'react';
import { formatCurrency } from '@/shared/utils/common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormControl,
  IconButton,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Add, Delete } from '@mui/icons-material';
import { InputPriceForm } from '@/common/Form/inputPrice';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { getDetailTrip, getTrip, priceWaittingFee, tripData, updateStatus } from '@/redux/slices/trip';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_SIZE_30 } from '@/shared/constant/common';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import LoadingPage from '@/components/LoadingPage';

export const ModalEditPriceWaiting = ({ open, setOpen, setCurrentPage, queriesParams, queryFilter, itemId }) => {
  const schema = yup.object().shape({
    priceWaiting: yup.array().of(
      yup.object().shape({
        id: yup.string().nullable(),
        price: yup.string().nullable().required('Giá không được để trống'),
      }),
    ),
  });
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(tripData);
  const dataDetail = dataSelector?.tripDetail?.data;
  const isLoading = dataSelector?.loadingTripDetail;
  const [deletedItems, setDeletedItems] = useState<any>([]);

  const ACTION_TYPE = {
    ADD: 1,
    EDIT: 2,
    DELTETE: 0,
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      priceWaiting:
        dataDetail?.tripWaitDetails?.length > 0
          ? dataDetail.tripWaitDetails
          : [
              {
                id: '',
                price: '',
              },
            ],
    },
  });

  useEffect(() => {
    if (itemId) {
      dispatch(getDetailTrip(itemId));
    }
    return () => {
      setDeletedItems([]);
    };
  }, [itemId]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'priceWaiting',
  }) as {
    fields: { id: string; price: string; index: string }[];
    append: (data: any) => void;
    remove: (index: number) => void;
  };

  const handleRemove = (index) => {
    const item: any = (listPriceWaiting && listPriceWaiting?.length > 0 && listPriceWaiting[index]) || [];
    if (item && item.id) {
      setDeletedItems([...deletedItems, { id: item?.id, price: item?.price, actionType: ACTION_TYPE.DELTETE }]);
    }
    remove(index);
  };

  const listPriceWaiting = watch('priceWaiting');

  useEffect(() => {
    if (dataDetail) {
      setValue(
        'priceWaiting',
        dataDetail?.tripWaitDetails?.length > 0
          ? dataDetail.tripWaitDetails
          : [
              {
                id: '',
                price: '',
              },
            ],
      );
    }
  }, [dataDetail, itemId]);

  const totalQuantityPriceWaiting = useCallback(() => {
    const totalSum =
      listPriceWaiting &&
      listPriceWaiting?.length > 0 &&
      listPriceWaiting?.reduce((currrent, item: any) => {
        if (+item?.price) {
          currrent = +item?.price && currrent + +item?.price;
        }
        return currrent;
      }, 0);
    return <span className="color-red">{formatCurrency(totalSum || 0, true)}</span>;
  }, [listPriceWaiting]);

  const totalQuantityPrice = useCallback(() => {
    const totalSum =
      listPriceWaiting &&
      listPriceWaiting?.length > 0 &&
      listPriceWaiting?.reduce((currrent, item: any) => {
        if (item?.price) {
          currrent = +item?.price && currrent + +item?.price;
        }
        return currrent || 0;
      }, 0);
    return (
      typeof totalSum === 'number' && (
        <span className="color-red">{formatCurrency(totalSum + dataDetail?.price + dataDetail?.surcharge, true)}</span>
      )
    );
  }, [listPriceWaiting]);
  const onSubmit = (data) => {
    const listMap = data?.priceWaiting?.map((item, index) => {
      return {
        id: item?.id || null,
        price: +item?.price,
        index: index + 1,
        actionType: item?.id ? ACTION_TYPE.EDIT : ACTION_TYPE.ADD,
      };
    });
    const payload = {
      id: itemId,
      params: [...listMap, ...deletedItems],
      callbackSuccess: () => {
        setOpen(false);
        showSuccessToast('Cập nhật giá chờ cho hành trình thành công!');
        setCurrentPage(+queriesParams?.Page || 1);
        dispatch(getTrip({ ...queryFilter, Page: +queriesParams?.Page, Take: PAGE_SIZE_30 }));
        dispatch(updateStatus());
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(priceWaittingFee(payload));
  };

  const PriceItem = ({ label, value, isVND = false }) => (
    <>
      <Grid item xs={5} mt={1}>
        <strong>{label}</strong>
      </Grid>
      <Grid item xs={7} mt={1}>
        {isVND ? <strong className="color-red">{formatCurrency(value, true)}</strong> : <strong>{value}</strong>}
      </Grid>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        PaperProps={{
          style: {
            width: '100%',
            maxHeight: '100%',
          },
        }}
      >
        <DialogTitle className="popup-title">
          <b>Xác nhận chỉnh sửa giá chờ cho hành trình {dataDetail?.code}</b>
        </DialogTitle>
        <Divider className="popup-divider" />
        {isLoading ? (
          <LoadingPage />
        ) : (
          <>
            <DialogContent className="popup-text">
              <Grid container>
                <Grid item xs={5}>
                  <strong>Tạm tính: </strong>
                </Grid>
                <Grid item xs={7}>
                  <strong className="color-red">{formatCurrency(dataDetail?.price, true)} </strong>
                </Grid>
                {fields?.map((value, index) => {
                  return (
                    <>
                      <Grid container key={value?.id} mt={2} ml={1} spacing={1} alignItems="center">
                        <Grid item xs={4} sm={4.7}>
                          Giá chờ {index + 1} :
                        </Grid>
                        <Grid item xs={8} sm={7.3} display="flex">
                          <Grid item xs={8}>
                            <FormControl fullWidth>
                              <InputPriceForm
                                arrayErrors={
                                  errors?.priceWaiting &&
                                  errors?.priceWaiting[index] &&
                                  errors?.priceWaiting[index]?.price
                                }
                                control={control}
                                name={`priceWaiting.${index}.price`}
                                label="Giá chờ"
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={4} display="flex">
                            {fields?.length - 1 === index && (
                              <IconButton onClick={() => append({ id: '', price: '' })}>
                                <Add className="color-blue" />
                              </IconButton>
                            )}
                            {fields?.length > 1 && (
                              <IconButton onClick={() => handleRemove(index)}>
                                <Delete className="color-red" />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
                <PriceItem label="Tổng giá chờ:" value={totalQuantityPriceWaiting()} />
                <PriceItem label="Phí phụ thu:" value={dataDetail?.surcharge} isVND={true} />
                <PriceItem label="Tổng giá:" value={totalQuantityPrice()} />
                <PriceItem label="Hình thức thanh toán:" value={dataDetail?.paymentSettingName} />
              </Grid>
            </DialogContent>
          </>
        )}
        <DialogActions className="group-button">
          <Button color="error" variant="outlined" onClick={() => setOpen(false)}>
            Đóng
          </Button>
          <Button color="primary" autoFocus variant="outlined" onClick={handleSubmit(onSubmit)}>
            Xác nhận
          </Button>
        </DialogActions>
        <CustomBackdrop open={dataSelector?.loadingPostWaittingFee} />
      </Dialog>
    </>
  );
};

export default ModalEditPriceWaiting;
