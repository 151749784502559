import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import './index.css';
import MainRoutes from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { Provider, useDispatch } from 'react-redux';
import store from './redux/store';
import lang from '@/lang/vi.json';
import viLocale from 'date-fns/locale/vi';
import { ThemeProvider } from '@mui/material';
import { selectedTheme } from './themes/customeTheme';
import { useEffect } from 'react';
import { CONST_ROUTER } from './shared/constant/common';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});
declare global {
  interface Window {
    lang: any;
  }
}

const noop: () => void = () => {};

// if (process.env.NODE_ENV === 'production') {
//   console.log = noop;
//   console.warn = noop;
//   console.error = noop;
//   console.info = noop;
//   console.debug = noop;
// }

function App() {
  if (lang) {
    window.lang = lang;
  }
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/') {
      navigate(CONST_ROUTER.DASH_BOARD_CHART);
    }
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={selectedTheme}>
          <MainRoutes />
        </ThemeProvider>
        <ToastContainer autoClose={1000} />
      </QueryClientProvider>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={viLocale}>
      <App />
    </LocalizationProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
