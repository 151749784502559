export const API_URL = process.env.REACT_APP_API_DB_HOST;
export const MAX_DATE_DRIVER_YEAR = 18;

export const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN || 'Admin';
export const ROLE_EMPLOYEE = process.env.REACT_APP_ROLE_EMPLOYEE || 'Employee';

export const REACT_APP_CURRENCY_FORMAT = process.env.REACT_CURRENCY_FORMAT;
export const locate = process.env.REACT_CURRENCY_FORMAT?.toString().split('-')[0];
export const currency = process.env.REACT_CURRENCY_FORMAT?.toString().split('-')[1];

// GOOGLE MAP
export const API_KEY = process.env.REACT_APP_KEY;
export const URL_GOOGLEMAP = 'https://maps.googleapis.com/maps/api/';
export const TIME_REQUEST = 60000; // 60s

export const MAIL_GOSAFE = 'https://gosafe.com.vn/lien-he/Gosafe@gmail.com';
