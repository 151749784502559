import { SyntheticEvent, useState, ReactNode, useEffect } from 'react';
import { Box, Typography, Tab, Tabs } from '@mui/material';
import { CONST_ROUTER, TabsDetailCustomerLabel } from '@/shared/constant/common';
import DetailCustomer from './DetailCustomer';
import HistoryDriver from './HistoryDriver';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BackPage } from '@/components/BackPage';
import InfoVehicle from './InfoVehicle';
import { createNewPath, useQueryParameters } from '@/shared/utils/common';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
  };
}

export default function TabsDetailCustomer() {
  const queriesParams: any = useQueryParameters();
  const { valueTab } = queriesParams;
  const [value, setValue] = useState<number>(0);
  const router = useNavigate();
  const { pathname } = useLocation();

  const tabData = [
    { component: <DetailCustomer /> },
    { component: <InfoVehicle /> },
    { component: <HistoryDriver valueTab={value} setValueTab={setValue} /> },
  ];

  useEffect(() => {
    if (valueTab) {
      setValue(+valueTab);
      router(pathname);
    }
  }, [queriesParams]);

  const navigate = useNavigate();
  const lang = window.lang;
  const handleBackPage = () => {
    navigate(CONST_ROUTER.MANAGEMENT_CUSTOMER);
  };
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <BackPage title={lang.managementCustomer.detailCustomer} handleBackPage={handleBackPage} />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            {TabsDetailCustomerLabel.map((tab, index) => (
              <Tab className="fz-14" key={index} label={tab.label} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {tabData.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </Box>
    </>
  );
}
