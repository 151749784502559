import { authSliceData, getProfileDetail } from '@/redux/slices/auth';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useRole = () => {
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(authSliceData);

  useEffect(() => {
    dispatch(getProfileDetail());
  }, []);

  return { role: dataSelector?.profileDetail?.data?.role, userId: dataSelector?.profileDetail?.data?.userId };
};
